import { useEntriesByTimeCard } from '@hooks/useEntries'
import { useEntryByTimeCardAndTask } from '@features/time-logging/hooks/useEntrySums'
import { FC, useState, useCallback, useEffect, useMemo } from 'react'
import { secondsToHHMM, secondsToTimestamp } from '@utils/time'
import { Display, GridCell } from 'components/time-entry-table'
import { EntryInput, saveTrigger } from 'components/time-entry-table/EntryInput'
import { IncrementDecrementButtons } from './IncrementDecrementButtons'
import useKeyPress from '@hooks/useKeyPress'
import { useOptimisticMutation } from '@features/time-logging/hooks/useOptimisticMutation'
import { upsertOrDeleteEntry } from '@lib/api'
import { totalEntriesDuration } from 'utils/timeCards'
import { useUserId } from '@features/time-logging/hooks/useUserId'

const editKeys = [
  'Enter',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
] as const

interface Props {
  timeCardId: number
  date: DateTime
  task: SelectedTask
  xHoverHandler: HeaderHoverHandler
  yHoverHandler: (i: number) => void
  rowIndex: number
  focused: boolean
  onFocus: (coord: { x: number; y: number }) => void
  x: number
  y: number
  editable: boolean
  onSaveViaEnter: () => void
  incrementDecrementMode: 'step' | 'fill'
  timeCardTotalWorkedInSeconds: number
}

export const EntryCell: FC<Props> = ({
  timeCardId,
  date,
  task,
  xHoverHandler,
  yHoverHandler,
  rowIndex,
  focused,
  onFocus,
  x,
  y,
  editable,
  onSaveViaEnter,
  incrementDecrementMode = 'step',
  timeCardTotalWorkedInSeconds,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [initialEditDuration, setInitialEditDuration] = useState<
    string | null
  >()
  const [shouldRegisterEditKeys, setShouldRegisterEditKeys] = useState(false)
  const userId = useUserId()
  const entries = useEntriesByTimeCard(timeCardId, userId)
  const totalAllocatedInSeconds = totalEntriesDuration(entries)

  const allocationBalance =
    timeCardTotalWorkedInSeconds - totalAllocatedInSeconds

  const nullEntry = useMemo(
    () => ({
      date,
      duration: 0,
      timeCard: {
        id: timeCardId,
      },
      task,
    }),
    [date, timeCardId, task],
  )

  // This is a code smell.  This entry is eventually stored within the query
  // store within the useOptimisticMutation hook, so it needs to be defined
  // thoroughly
  const entry: Entry =
    useEntryByTimeCardAndTask(timeCardId, task.id) ?? nullEntry

  const handleMouseMove = () => {
    xHoverHandler(date)
    yHoverHandler(rowIndex + 1)
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    xHoverHandler(null)
    yHoverHandler(0)
    setIsHovered(false)
  }

  const isFocused = editable && focused

  const mutation = useOptimisticMutation({
    queryFunction: upsertOrDeleteEntry,
  })

  const updateEntryDuration = useCallback(
    (newDuration: number) => {
      mutation.mutate({
        entry: { ...entry, duration: newDuration },
      })
    },
    [mutation, entry],
  )

  // This forces the useKeyPress handler for edit keys to be registered
  // in the next cycle after the cell is focused, preventing
  // the handler from being registered in time to catch the enter key event
  // from the above cell after it was submitted
  // (this bug can be seen by removing this, editing a cell, saving via enter,
  // and the below cell will not only be focused, but it'll enter edit mode —
  // the desired outcome is that the below cell is focused, but not in edit mode)
  // TODO: Find a better way to do this
  useEffect(() => {
    if (isFocused) {
      setTimeout(() => setShouldRegisterEditKeys(true), 0)
    } else {
      setShouldRegisterEditKeys(false)
    }
  }, [isFocused])

  // When a cell is focused and not in edit mode,
  // pressing one of the "edit keys" should
  // immediately enter edit mode, with the input
  // value set to the selected key (or the existing value if Enter key was pressed)
  useKeyPress(
    editKeys,
    {
      shift: false,
    },
    {
      callback: useCallback(
        (key: string) => {
          if (isFocused && !isEditing) {
            if (key === 'Enter') {
              setInitialEditDuration(null)
            } else {
              setInitialEditDuration(key)
            }
            setIsEditing(true)
          }
        },
        [isFocused, isEditing],
      ),
      shouldAttachEvent: useCallback(
        () => isFocused && shouldRegisterEditKeys,
        [isFocused, shouldRegisterEditKeys],
      ),
    },
  )

  // When a cell is focused, pressing the delete or backspace keys
  // should clear the entry
  useKeyPress(['Delete', 'Backspace'], undefined, {
    callback: useCallback(() => {
      if (isFocused && !isEditing) {
        updateEntryDuration(0)
      }
    }, [isFocused, isEditing, updateEntryDuration]),
    shouldAttachEvent: useCallback(
      () => isFocused && !isEditing,
      [isFocused, isEditing],
    ),
  })

  const exitEditMode = () => {
    setIsEditing(false)
    setInitialEditDuration(null)
  }

  const saveHandler = (newDuration: number, trigger: saveTrigger) => {
    updateEntryDuration(newDuration)
    exitEditMode()
    if (trigger === 'enter') onSaveViaEnter()
  }

  const initialEditFieldValue =
    initialEditDuration ??
    (entry && entry.duration > 0 ? secondsToTimestamp(entry.duration) : '')

  return (
    <GridCell
      date={date}
      rowIndex={rowIndex}
      editable={editable}
      isEditing={isEditing}
      isFocused={isFocused}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={() => onFocus({ x, y })}
      onDoubleClick={() => setIsEditing(true)}
    >
      {editable && isHovered && !isEditing && (
        <IncrementDecrementButtons
          entry={entry}
          className="mr-auto"
          mode={incrementDecrementMode}
          fillAmount={allocationBalance}
        />
      )}

      {editable && isEditing && (
        <EntryInput
          initialValue={initialEditFieldValue}
          onSave={saveHandler}
          onCancel={exitEditMode}
        />
      )}

      {!isEditing && <Display contents={secondsToHHMM(entry?.duration ?? 0)} />}
    </GridCell>
  )
}

export default EntryCell
