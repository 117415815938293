import { FC } from 'react'
import { Button, Props as ButtonProps } from './Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { Spinner } from 'components/loaders'
import { useLanguage } from '@hooks/useLanguage'
import { twMerge } from '@lib/tailwind-merge'

type Tab = Omit<ButtonProps, 'variant'> & {
  label: string
  icon?: IconDefinition
  count?: number
  loading?: boolean
  selected?: boolean
}

interface Props {
  tabs: Tab[]
  className?: string
}

export const ButtonTabs: FC<Props> = ({ tabs, className }) => {
  const language = useLanguage()

  return (
    <div className={twMerge('grid grid-flow-col w-full', className)}>
      {tabs.map(
        ({ label, icon, count, loading, selected, ...buttonProps }, index) => (
          <Button
            key={index}
            className="border-r border-l-0 first:border-l rounded-none last:rounded-r-full first:rounded-l-full font-semibold text-sm flex-row justify-between px-4"
            variant={selected ? 'outlined-active' : 'outlined'}
            {...buttonProps}
          >
            <div
              className={twMerge(
                'flex flex-row gap-2 items-center',
                !selected && 'text-neutral-600',
              )}
            >
              {icon && (
                <FontAwesomeIcon icon={icon} fixedWidth={true} size="sm" />
              )}
              {label}
            </div>

            {(loading || count !== undefined) && (
              <div>
                <div
                  className={twMerge(
                    'h-4 px-1 min-w-4 font-bold border rounded-full text-[10px] text-bold flex items-center justify-center',
                    selected
                      ? 'bg-primary-100 border-primary-300'
                      : 'bg-neutral-200 border-neutral-300 text-neutral-700',
                  )}
                >
                  {loading ? <Spinner /> : count?.toLocaleString(language)}
                </div>
              </div>
            )}
          </Button>
        ),
      )}
    </div>
  )
}
