import { FC } from 'react'

interface Props {
  breadcrumbs?: string[]
  title: string
}

export const Heading: FC<Props> = ({ breadcrumbs, title }) => {
  return (
    <div className="mt-2">
      {breadcrumbs && (
        <h2 className="text-sm text-neutral-400">
          {breadcrumbs.join(' / ')} /
        </h2>
      )}
      <h1 className="text-2xl text-white">{title}</h1>
    </div>
  )
}
