import { Day } from './Day'
import { HeadingProps, Heading } from './Heading'
import { Header as PageHeader } from 'components/page-header'
import { WeekSelector } from '../week-selector/WeekSelector'
import { DateTime } from 'luxon'
import { useDayLocks } from '@hooks/useDayLocks'
import { useUserId } from '../../hooks/useUserId'

interface Props extends HeadingProps {
  hoverDate: DateTime | null
  dateRange: DateTime[]
}

export function Header(props: Props) {
  const userId = useUserId()
  const { data: dayLocks } = useDayLocks({ userId })

  return (
    <PageHeader>
      <Heading
        onTaskButtonClick={props.onTaskButtonClick}
        onLeaveButtonClick={props.onLeaveButtonClick}
      />
      <div className="items-end grid grid-areas-entries grid-cols-entries text-neutral-300">
        <div className="flex flex-row mb-3 grid-in-nav">
          <WeekSelector />
        </div>

        {props.dateRange.map((date) => (
          <Day
            key={`Header${date.get('day')}`}
            date={date}
            hoverDate={props.hoverDate}
            lock={dayLocks?.find((lock) => lock.date.hasSame(date, 'day'))}
          />
        ))}
        <div className="col-start-4 mb-2 w-2.5 h-9 border-r-2 border-neutral-700"></div>
        <div className="col-start-10 mb-2 w-2.5 h-9 border-r-2 border-neutral-700"></div>
      </div>
    </PageHeader>
  )
}
