import { useEntries } from '@hooks/useEntries'
import { useEntriesSumByDate } from '@features/time-logging/hooks/useEntrySums'
import { yyyyMMdd } from '@utils/dates'
import { col } from '@utils/styles'
import { useMemo } from 'react'
import { SummaryCell, SummaryRow } from 'components/time-entry-table'
import { useUserId } from '@features/time-logging/hooks/useUserId'

interface TotalByDateCellProps {
  date: DateTime
  skipBorder?: boolean
}

function TotalByDateCell(props: TotalByDateCellProps) {
  const total = useEntriesSumByDate([props.date])

  return (
    <SummaryCell
      key={`TotalByDateCell-${yyyyMMdd(props.date)}`}
      total={total}
      gridClass={col(props.date)}
      skipBorder={props.skipBorder}
    />
  )
}

interface Props {
  dateRange: DateTime[]
}

export function TotalByDateRow(props: Props) {
  const userId = useUserId()
  const entriesQuery = useEntries({ userId })
  const total = useMemo(() => {
    if (entriesQuery.data === undefined) return 0

    return entriesQuery.data.reduce((sum, entry) => sum + entry.duration, 0)
  }, [entriesQuery])

  return (
    <SummaryRow testId="allocation-summary">
      {props.dateRange.map((date, i) => (
        <TotalByDateCell
          key={`Allocation-TotalByDateCell${date.toISO()}`}
          date={date}
          skipBorder={props.dateRange.length === i + 1}
        />
      ))}
      <SummaryCell
        key="totalByDateCellTotal"
        total={total}
        gridClass="grid-in-totalByTask"
        skipBorder={true}
      />
    </SummaryRow>
  )
}
