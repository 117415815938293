import { FC } from 'react'

interface Props {
  children: React.ReactNode
}

export const SectionHeading: FC<Props> = ({ children }) => {
  return (
    <h2 className="text-lg leading-none text-neutral-900 mb-3">{children}</h2>
  )
}
