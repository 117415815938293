import { FC } from 'react'
import { Label } from 'components/indicators/label'

interface Props {
  className?: string
}

export const EnvLabel: FC<Props> = ({ className }) => {
  return (
    <Label color="warning" className={className}>
      {import.meta.env.VITE_INFRA_ENVIRONMENT.toUpperCase()}
    </Label>
  )
}
