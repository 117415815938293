import { faTrashCan } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from '@lib/tailwind-merge'

interface Props {
  onClick: () => void
  primaryText: string | number
  removable?: boolean
  secondaryText?: string | number
  disabled?: boolean
}

export const EntityItem: FC<Props> = ({
  onClick,
  primaryText,
  removable = false,
  secondaryText,
  disabled = false,
}) => {
  const { t } = useTranslation()

  return (
    <button
      className={twMerge(
        'flex flex-row items-center py-1 mb-2 min-h-10 flex-start text-neutral-900 border-l-2 border-l-transparent pl-1 text-left',
        disabled && 'opacity-50 cursor-not-allowed',
        !disabled && 'hover:border-l-primary-500',
      )}
      onClick={onClick}
    >
      <div className="flex flex-col items-start">
        <span className="text-sm font-medium text-neutral-900">
          {primaryText}
        </span>
        {secondaryText && (
          <span className="text-xs font-light text-neutral-500">
            {secondaryText}
          </span>
        )}
      </div>
      {removable && (
        <span className="self-center pl-1 ml-auto text-xs font-medium text-neutral-500 flex-nowrap whitespace-nowrap">
          <FontAwesomeIcon icon={faTrashCan} className="mr-1" />
          {t('common.remove')}
        </span>
      )}
    </button>
  )
}
