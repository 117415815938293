import { useEmployee } from '@hooks/useEmployee'
import { useUserId } from './useUserId'
import { useHolidaysByCalendar } from '@hooks/useHolidays'

export const useHolidays = () => {
  const userId = useUserId()
  const employeeQuery = useEmployee({ userId })

  return useHolidaysByCalendar({
    holidayCalendarId: employeeQuery.data?.holidayCalendarId,
  })
}
