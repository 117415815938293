import { useCallback } from 'react'
import { useModal } from '@hooks/useModal'
import { useTranslation } from 'react-i18next'
import { useDeleteWorkdayProjectLink } from './useDeleteWorkdayProjectLink'
import { toast } from '@lib/toasts'

export const useUnlinkWorkdayProject = () => {
  const modal = useModal()
  const { t } = useTranslation()

  const deleteLink = useDeleteWorkdayProjectLink()

  const performDelete = useCallback(
    async (project: AdminProject) => {
      if (project.workdayProject === null) return

      try {
        await deleteLink.mutateAsync({
          projectId: project.id,
        })

        toast({
          title: t('features.projectsAdmin.projectUnlinked'),
          variant: 'success',
          content: t(
            'features.projectsAdmin.successfullyUnlinkedWorkdayProject',
            {
              workdayProjectName: project.workdayProject.name,
              projectName: project.name,
            },
          ),
        })
      } catch (error) {
        toast({
          title: t('features.projectsAdmin.unableToUnlinkProject'),
          variant: 'error',
          content: t('features.projectsAdmin.failedToUnlinkProject'),
        })
      }
    },
    [deleteLink, t],
  )

  const unlinkWorkdayProject = useCallback(
    (project: AdminProject) => {
      if (project.workdayProject === null) return

      modal.confirm({
        title: t('features.projectsAdmin.unlinkProject'),
        content: (
          <div>
            <p>
              {t('features.projectsAdmin.confirmUnlink', {
                foreignProjectName: project.workdayProject.name,
                integrationName: 'Workday',
                projectName: project.name,
              })}
            </p>
            <p className="mt-4">
              {t(
                'features.projectsAdmin.timeLoggedAgainstThisProjectWillNotBeSendable',
                {
                  projectName: project.name,
                },
              )}
            </p>
          </div>
        ),
        confirmText: t('features.projectsAdmin.unlinkProject'),
        onConfirm: () => void performDelete(project),
        primaryButtonClassName: 'bg-error-500 hover:bg-error-700',
      })
    },
    [modal, t, performDelete],
  )

  return { unlinkWorkdayProject }
}
