import { FC } from 'react'
import { Column } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

interface Props {
  column: Column<unknown>
  values: string[]
}

const firstLetterUppercase = (str: string) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`

export const TableSelectFilterField: FC<Props> = ({ column, values }) => {
  const { t } = useTranslation()

  return (
    <select
      onChange={(e) => column.setFilterValue(e.target.value)}
      value={column.getFilterValue() as string | undefined}
      className="focus:ring-0 focus:border-neutral-400 bg-white border-neutral-400 border-[1.5px] rounded text-xs font-normal mt-3 pl-3 py-2"
      // Don't propagate clicks, otherwise we'll trigger column sorting
      onClick={(e) => e.stopPropagation()}
    >
      <option value="">
        {t('components.tables.tableSelectFilterField.all')}
      </option>
      {values.map((value) => (
        <option key={value} value={value}>
          {firstLetterUppercase(value)}
        </option>
      ))}
    </select>
  )
}
