import { useCallback } from 'react'
import { useModal } from '@hooks/useModal'
import { useTranslation } from 'react-i18next'
import {
  useArchiveProject,
  useUnarchiveProject,
} from './useArchiveUnarchiveProject'

export const useToggleProjectArchival = () => {
  const modal = useModal()
  const { t } = useTranslation()
  const archiveProject = useArchiveProject()
  const unarchiveProject = useUnarchiveProject()

  const toggleProjectArchival = useCallback(
    (project: AdminProject) => {
      modal.confirm({
        title: t(
          project.archivedAt
            ? 'features.projectsAdmin.unarchiveProject'
            : 'features.projectsAdmin.archiveProject',
        ),
        content: (
          <div>
            <p>
              {t(
                project.archivedAt
                  ? 'features.projectsAdmin.unarchivingProjectMakesImmediatelyAvailableForSelection'
                  : 'features.projectsAdmin.archivingProjectImmediatelyRemovesFromSelection',
              )}
            </p>
            <div className="text-lg font-semibold mt-4">{project.name}</div>
          </div>
        ),
        confirmText: t(
          project.archivedAt
            ? 'features.projectsAdmin.unarchiveProject'
            : 'features.projectsAdmin.archiveProject',
        ),
        onConfirm: () =>
          project.archivedAt
            ? unarchiveProject.mutate(project.id)
            : archiveProject.mutate(project.id),
      })
    },
    [modal, t, archiveProject, unarchiveProject],
  )

  return { toggleProjectArchival }
}
