import { coerceIntoArray } from '@utils/coerceIntoArray'
import { useCallback, useState } from 'react'

const copy = <T>(reference: Set<T>): Set<T> => {
  return new Set([...reference])
}

export const addItemsToSet =
  <T>(items: T | T[]) =>
  (reference: Set<T>) => {
    const set = copy(reference)
    coerceIntoArray(items).forEach((item) => set.add(item))
    return set
  }

export const removeItemsFromSet =
  <T>(items: T | T[]) =>
  (reference: Set<T>) => {
    const set = copy(reference)
    coerceIntoArray(items).forEach((item) => set.delete(item))
    return set
  }

export const clearSet = <T>(reference: Set<T>) => {
  const set = copy(reference)
  set.clear()
  return set
}

export const toggleItemsInSet =
  <T>(items: T | T[]) =>
  (reference: Set<T>) => {
    const set = copy(reference)
    coerceIntoArray(items).forEach((item) => {
      if (set.has(item)) {
        set.delete(item)
      } else {
        set.add(item)
      }
    })
    return set
  }

export const useSet = <T>(initial: Set<T> = new Set<T>()) => {
  const [set, update] = useState<Set<T>>(initial)

  const add = useCallback((item: T | T[]) => update(addItemsToSet(item)), [])
  const remove = useCallback(
    (item: T | T[]) => update(removeItemsFromSet(item)),
    [],
  )
  const clear = useCallback(() => update(clearSet), [])
  const toggle = useCallback(
    (item: T | T[]) => update(toggleItemsInSet(item)),
    [],
  )

  return { set, add, remove, clear, toggle }
}
