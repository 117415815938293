import { searchHolidays } from '@lib/api'
import { holidayKeys } from '@lib/keys'
import { useQuery } from '@tanstack/react-query'
import { DateTime, Interval } from 'luxon'
import { useWeekDates } from './useWeekDates'

interface Options {
  enabled?: boolean
  endDateOverride?: DateTime
  startDateOverride?: DateTime
  holidayCalendarId?: string | null
}

export const useHolidaysByCalendar = (options: Options) => {
  const { start: startDate, end: endDate } = useWeekDates()

  const start = options.startDateOverride ?? startDate
  const end = options.endDateOverride ?? endDate

  return useQuery(
    holidayKeys.search(options.holidayCalendarId ?? '', start, end),
    () => searchHolidays(options.holidayCalendarId ?? '', start, end),
    {
      enabled: Boolean(options.holidayCalendarId) && (options.enabled ?? true),
    },
  )
}

export const useHolidaysOnDate = (date: DateTime, options: Options) => {
  const { data } = useHolidaysByCalendar(options)

  if (!data) return []

  return data.filter(({ startDate, endDate }) =>
    Interval.fromDateTimes(startDate, endDate.endOf('day')).contains(date),
  )
}
