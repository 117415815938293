import { FC } from 'react'
import Row from '../Row'
import { CircleProgress } from 'components/indicators/circle-progress'
import { TotalRow } from '../TotalRow'
import classNames from 'classnames'
import { totalWorkedInSeconds } from '@utils/timeCards'
import { useTranslation } from 'react-i18next'
import { AutoTextSize } from 'auto-text-size'

interface Props {
  timeCard: TimeCard
  inFuture: boolean
  totalAllocatedInSeconds: number
  isValid: boolean
}

export const UnsubmittedBody: FC<Props> = ({
  timeCard,
  inFuture,
  totalAllocatedInSeconds,
  isValid,
}) => {
  const { t } = useTranslation()
  const startTimeMissing = timeCard.startAt == null
  const endTimeMissing = timeCard.endAt == null
  const startOrEndTimeMissing = startTimeMissing || endTimeMissing

  // Assume the user will work 8 hours, unless they've configured a start/end time
  // TODO: Once we have work schedules from Workday, we should defer to those, and only fall back
  //       on 8 hours if there's no schedule for the given day.
  const totalWorkedOrAnticipatedToWorkInSeconds = startOrEndTimeMissing
    ? 8 * 60 ** 2
    : totalWorkedInSeconds(timeCard)

  return (
    <div>
      {startOrEndTimeMissing ? (
        <Row subtle={inFuture} className="border-b border-dashed">
          <AutoTextSize
            mode="oneline"
            maxFontSizePx={14}
            className="text-neutral-500 mx-auto"
          >
            {startTimeMissing
              ? t('features.timeLogging.enterStartTime')
              : t('features.timeLogging.enterEndTime')}
          </AutoTextSize>
        </Row>
      ) : (
        <TotalRow
          subtle={inFuture}
          totalInSeconds={totalWorkedInSeconds(timeCard)}
          className={classNames('border-b border-dashed', {
            'border-success-300': isValid,
          })}
        />
      )}
      <TotalRow subtle={inFuture} totalInSeconds={totalAllocatedInSeconds}>
        {
          <CircleProgress
            percentGreen={
              (totalAllocatedInSeconds /
                totalWorkedOrAnticipatedToWorkInSeconds) *
              100
            }
            percentRed={
              ((totalAllocatedInSeconds -
                totalWorkedOrAnticipatedToWorkInSeconds) /
                totalWorkedOrAnticipatedToWorkInSeconds) *
              100
            }
            className="absolute w-5 h-5"
          />
        }
      </TotalRow>
    </div>
  )
}
