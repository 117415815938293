import { Timeline } from '@features/admin'
import { DateTime } from 'luxon'
import { FC, useMemo } from 'react'
import { useEmployeeDayEventLog } from '../../hooks/useEmployeeDayEventLog'
import { Spinner } from 'components/loaders'
import { useTranslation } from 'react-i18next'
import { getTimelineItemForEvent } from 'utils/auditEvents'

interface Props {
  date: DateTime
  employeeId: number
}

export const HistoryTab: FC<Props> = ({ date, employeeId }) => {
  const { t } = useTranslation()
  const { data: events, isLoading } = useEmployeeDayEventLog(employeeId, date)

  const sortedEvents = useMemo(
    () =>
      events?.sort((a, b) => {
        if (a.timestamp < b.timestamp) return 1
        if (a.timestamp > b.timestamp) return -1
        return 0
      }),
    [events],
  )

  if (sortedEvents === undefined || isLoading)
    return (
      <div className="mt-3 text-center">
        <Spinner />
      </div>
    )

  if (sortedEvents.length === 0)
    return (
      <div className="px-8 py-4 italic text-center text-neutral-400">
        {t('features.eventLogs.noHistory')}
      </div>
    )

  return (
    <Timeline
      timeline={sortedEvents.map((event) => getTimelineItemForEvent(event, t))}
    />
  )
}
