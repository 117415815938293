import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { Tooltip } from '@lib/tooltip'
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  useId,
  useMemo,
} from 'react'
import { twMerge } from '@lib/tailwind-merge'

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  iconProps?: Omit<FontAwesomeIconProps, 'icon'>
  icon?: IconDefinition
  srOnlyLabel?: string
  tooltip?: string
  tooltipProps?: Omit<React.ComponentProps<typeof Tooltip>, 'id'>

  // If true, the button will be a circle regardless of the theme's rounded-primary
  circle?: boolean
}

export const IconButton: FC<Props> = ({
  onClick,
  className,
  icon = faEllipsisVertical,
  iconProps,
  srOnlyLabel,
  tooltip,
  tooltipProps,
  circle = false,
  ...props
}) => {
  const id = useId()

  const buttonProps = useMemo(() => {
    const props: DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > = {}

    if (tooltip) {
      props['data-tooltip-id'] = id
    }

    return props
  }, [tooltip, id])

  return (
    <>
      {tooltip && (
        <Tooltip id={id} {...tooltipProps}>
          {tooltip}
        </Tooltip>
      )}
      <button
        {...buttonProps}
        {...props}
        onClick={onClick}
        className={twMerge(
          'w-6 h-6 hover:bg-neutral-200 disabled:cursor-not-allowed disabled:opacity-40 disabled:hover:bg-transparent',
          className,
          circle ? 'rounded-full' : 'rounded-primary',
        )}
        data-tooltip-id={id}
      >
        {srOnlyLabel && <label className="sr-only">{srOnlyLabel}</label>}
        <FontAwesomeIcon {...iconProps} icon={icon} />
      </button>
    </>
  )
}
