import { FC, ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons'
import { twMerge } from 'tailwind-merge'

interface PickerRenderOptions {
  hidden: boolean
}

interface Props {
  autoFocus?: boolean
  className?: string
  searchFieldClassName?: string
  error?: string
  hidePicker?: boolean
  onBlur?: () => void
  onChange: (newValue: string) => void
  onFocus?: () => void
  picker?: (options: PickerRenderOptions) => ReactNode
  pickerVariant?: 'floating' | 'fixed-below'
  placeholder: string
  value: string
}

export const SearchField: FC<Props> = ({
  autoFocus = false,
  className,
  searchFieldClassName,
  error,
  hidePicker = true,
  onBlur,
  onChange,
  onFocus,
  picker,
  pickerVariant = 'floating',
  placeholder,
  value,
}) => {
  const fixedBelowPickerVisible =
    picker && !hidePicker && pickerVariant === 'fixed-below'

  return (
    <div
      className={twMerge(
        'flex flex-col bg-white',
        fixedBelowPickerVisible && 'overflow-hidden',
        className,
      )}
    >
      <div
        className={twMerge(
          'relative flex items-center text-sm border-solid rounded-primary border-[1px] border-neutral-400 bg-neutral-100 text-neutral-500 px-4',
          !value && 'pr-9', // Reserve space for the clear button when no value entered
          searchFieldClassName,
          picker &&
            !hidePicker &&
            pickerVariant === 'floating' &&
            'rounded-b-none rounded-t-3xl border-2 border-primary-600 border-b border-b-neutral-400',
        )}
      >
        <FontAwesomeIcon icon={faSearch} />
        <input
          className="grow px-3 py-2.5 outline-none bg-transparent"
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onChange={(event) => onChange(event.currentTarget.value)}
          onFocus={onFocus}
          autoFocus={autoFocus}
        />
        {value && (
          <FontAwesomeIcon
            icon={faXmarkCircle}
            className="cursor-pointer w-5"
            onClick={() => onChange('')}
          />
        )}
        {picker && !hidePicker && pickerVariant === 'floating' && (
          <div className="absolute left-[-2px] right-[-2px] flex flex-col top-[100%] border-2 border-primary-600 border-t-0 rounded-b-3xl overflow-hidden p-4 bg-white z-40">
            <div className="max-h-[250px] flex flex-col overflow-auto">
              {picker({ hidden: hidePicker })}
            </div>
          </div>
        )}
      </div>
      {error && (
        <span className="mt-1 text-[11.5px] text-neutral-500">{error}</span>
      )}
      <div className="overflow-auto">
        {fixedBelowPickerVisible && picker({ hidden: hidePicker })}
      </div>
    </div>
  )
}
