import React, { FC, useState, useCallback, useRef, FocusEvent } from 'react'
import { parseInputValueToSeconds } from '@utils/entry_input'
import { secondsToHHMM } from '@utils/time'

const textClasses = 'text-sm text-right leading-none text-neutral-900'
const resetClasses =
  'outline-0 rounded-none border-0 bg-transparent m-0 p-0 px-2'

// Adds/subtracts from the duration
// Returns the greater of duration or zero
const adjustDuration = (duration: number, seconds: number) => {
  return Math.max(0, duration + seconds)
}

export type saveTrigger = 'blur' | 'enter' | 'tab'

interface Props {
  onCancel: () => void
  initialValue: string
  onSave: (newDuration: number, trigger: saveTrigger) => void
}

export const EntryInput: FC<Props> = ({ initialValue, onSave, onCancel }) => {
  const ref = useRef<HTMLInputElement>(null)
  const [tempValue, setTempValue] = useState(initialValue ?? '')

  const incrementDecrementHandler = (delta: number) => {
    setTempValue(
      secondsToHHMM(adjustDuration(parseInputValueToSeconds(tempValue), delta)),
    )
  }

  const changeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTempValue(e.target.value)
    },
    [],
  )

  const save = useCallback(
    (value: string, trigger: saveTrigger) => {
      // Parse input duration
      onSave(parseInputValueToSeconds(value.trim()), trigger)
    },
    [onSave],
  )

  const saveCurrentValue = useCallback(
    (trigger: saveTrigger) => {
      save(ref.current?.value ?? '', trigger)
    },
    [save],
  )

  const blurHandler = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      save(e.target.value, 'blur')
    },
    [save],
  )

  const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    let stopPropagation = true

    switch (e.key) {
      case 'Escape':
        // Immediately cancels, without saving value
        onCancel()
        break
      case 'Tab':
        // Saves and then "Tabs" (or shift+tabs)
        saveCurrentValue('tab')
        stopPropagation = false
        break
      case 'ArrowUp':
        incrementDecrementHandler(3600)
        break
      case 'ArrowDown':
        incrementDecrementHandler(-900)
        break
    }

    // Trap all key presses in input (i.e. while focused)
    // Those interactions should not impact the grid in any way
    // (e.g. the left/right arrow keys should move the cursor position within the input,
    // not change the focused grid cell)
    if (stopPropagation) e.stopPropagation()
  }

  // Save when enter key is released
  // (this helps solve a bug where the key press would be picked up here
  // and also by the cell beneath this one, causing it to enter edit mode
  // once it's focused)
  const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      saveCurrentValue('enter')
    }
  }

  return (
    <input
      ref={ref}
      autoFocus={true}
      value={tempValue}
      placeholder={'hh:mm'}
      // Note: w-0 because input elements don't respect flexbox
      // https://stackoverflow.com/questions/42421361/input-button-elements-not-shrinking-in-a-flex-container
      className={`w-0 grow ${resetClasses} ${textClasses}`}
      onChange={changeHandler}
      onBlur={blurHandler}
      onKeyDown={keyDownHandler}
      onKeyUp={keyUpHandler}
    />
  )
}
