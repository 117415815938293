import { extendTailwindMerge } from 'tailwind-merge'

export const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      // Include our custom rounded-primary in the rounded group
      // (this will put it in conflict with any class that the rounded-* classes conflict with)
      rounded: ['rounded-primary'],
    },
  },
})
