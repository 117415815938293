import { noop } from 'utils/noop'
import { Modal, ModalProps } from 'components/notifications'
import { createContext, FC, ReactNode, useMemo, useState } from 'react'
import { useInhibitKeyPressOverride } from '@hooks/useInhibitKeyPressOverride'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

const getModalNameFromId = (id: number) => `modal-${id}`

interface AlertOptions {
  title?: string
  content?: string
  buttonText?: string
  onButtonClick?: () => void
  onClose?: () => void
}

interface BaseConfirmOptions {
  cancelText?: string
  confirmText?: string
  primaryButtonClassName?: string
  content?: ReactNode
  onCancel?: () => void
  primaryButtonPendingText?: string
  title?: string
}

interface ConfirmOptionsSync extends BaseConfirmOptions {
  onConfirm: () => void
}
interface ConfirmOptionsAsync extends BaseConfirmOptions {
  onConfirmAsync: () => Promise<unknown>
}

type ConfirmOptions = ConfirmOptionsSync | ConfirmOptionsAsync

type ModalPropOptions = Omit<ModalProps, 'hidden'> & { onClose?: () => void }

type FormModalOptions = Omit<
  ModalPropOptions,
  | 'onPrimaryButtonClick'
  | 'onSecondaryButtonClick'
  | 'primaryButtonText'
  | 'secondaryButtonText'
  | 'onClose'
>

interface ModalContext {
  alert: (options: AlertOptions) => void
  close: (id: number) => void
  confirm: (options: ConfirmOptions) => number
  open: (options: ModalPropOptions) => number
  form: (options: FormModalOptions) => number
}

const initialContext: ModalContext = {
  alert: () => -1,
  close: noop,
  confirm: () => -1,
  open: () => -1,
  form: () => -1,
}

export const ModalContext = createContext(initialContext)

export const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation()
  const { registerMenuOpened, registerMenuClosed } =
    useInhibitKeyPressOverride()
  const [openModals, setOpenModals] = useState<
    (ModalPropOptions & { id: number })[]
  >([])

  const value = useMemo(() => {
    const v: ModalContext = {
      alert: ({
        title = 'Alert',
        content,
        buttonText = 'Close',
        onButtonClick = noop,
        onClose = noop,
      }) => {
        const id = v.open({
          title,
          content,
          primaryButtonText: buttonText,
          onPrimaryButtonClick: () => {
            onButtonClick()
            v.close(id)
          },
          onClose: () => {
            onClose()
            v.close(id)
          },
        })
      },
      close: (id: number) => {
        registerMenuClosed(getModalNameFromId(id))
        setOpenModals((modals) => modals.filter((modal) => modal.id !== id))
      },
      confirm: ({
        cancelText = t('common.cancel'),
        confirmText = t('common.confirm'),
        content = 'Do you confirm?',
        onCancel = noop,
        title = t('common.confirm'),
        ...options
      }) => {
        const handlePrimaryButtonClick = async () => {
          if ('onConfirmAsync' in options) await options.onConfirmAsync()
          if ('onConfirm' in options) options.onConfirm()

          v.close(id)
        }
        const id = v.open({
          title,
          content,
          primaryButtonPendingText: options.primaryButtonPendingText,
          primaryButtonText: confirmText,
          primaryButtonClassName: options.primaryButtonClassName,
          secondaryButtonText: cancelText,
          onPrimaryButtonClick: () => void handlePrimaryButtonClick(),
          onSecondaryButtonClick: () => {
            onCancel()
            v.close(id)
          },
          onClose: () => v.close(id),
        })
        return id
      },
      form: ({ title, content }) => {
        const id = v.open({
          title,
          closeOnBackgroundClick: false,
          content,
          onClose: () => {
            v.close(id)
          },
          variant: 'form',
        })
        return id
      },
      open: (options) => {
        const id = DateTime.local().toMillis()
        registerMenuOpened(getModalNameFromId(id))
        setOpenModals((modals) => [...modals, { ...options, id }])
        return id
      },
    }
    return v
  }, [setOpenModals, registerMenuOpened, registerMenuClosed, t])

  return (
    <ModalContext.Provider value={value}>
      <>
        {children}
        {openModals.map((modalProps) => (
          <Modal key={modalProps.id} {...modalProps} hidden={false} />
        ))}
      </>
    </ModalContext.Provider>
  )
}
