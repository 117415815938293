import { Column, StackItem } from '../types'

export const getNonNodeStackItem = (
  stack: StackItem[],
  column: Exclude<Column, 'node'>,
) => {
  const item = stack.find((s) => s.column === column)

  if (item?.column === 'node')
    throw new Error(
      'Unreachable.  Cannot retrieve node stack item with non-node column name',
    )

  return item
}
