import { useModal } from '@hooks/useModal'
import { useTranslation } from 'react-i18next'
import { AdminManagedGroupNameForm } from '../components/AdminManagedGroupNameForm'
import { useManagedGroupOutletContext } from '../routes/ManagedGroup'

export const useNameAdminManageGroupModal = () => {
  const { isLoading } = useManagedGroupOutletContext()
  const modal = useModal()
  const { t } = useTranslation()

  const showModal = (defaultName?: string) => {
    return new Promise<string>((resolve) => {
      const id = modal.form({
        title: t('features.adminManagedGroups.nameYourGroup'),
        content: (
          <AdminManagedGroupNameForm
            defaultName={defaultName}
            onCancel={() => modal.close(id)}
            onSave={(name) => {
              resolve(name)
              modal.close(id)
            }}
            isSaving={isLoading}
          />
        ),
      })
    })
  }

  return showModal
}
