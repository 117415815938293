import { TopNavBar } from 'components/navigation'
import { FC, ReactNode } from 'react'
import { twMerge } from '@lib/tailwind-merge'

export interface Props {
  accountInfo: ReactNode
  children: ReactNode
  onMenuOpen: () => void
  sidebar?: ReactNode
}

export const MainLayout: FC<Props> = ({
  accountInfo,
  children,
  onMenuOpen,
  sidebar,
}) => {
  const theme = import.meta.env.VITE_THEME

  return (
    <div className={twMerge('relative flex flex-col min-h-screen', theme)}>
      <TopNavBar accountInfo={accountInfo} onMenuClick={onMenuOpen} />
      <>
        {sidebar}
        {children}
      </>
    </div>
  )
}
