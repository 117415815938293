import { FC, useCallback, useState } from 'react'
import { useCreateWorkdayProjectLink } from '../hooks/useCreateWorkdayProjectLink'
import { useTranslation } from 'react-i18next'
import { toast } from '@lib/toasts'
import { ModalForm } from 'components/notifications'
import { RemoteProjectPicker } from './RemoteProjectPicker'
import { faRemove } from '@fortawesome/pro-solid-svg-icons'
import { IconButton } from 'components/buttons'
import { useWorkdayProjects } from '../hooks/useWorkdayProjects'

interface Props {
  onCancel: () => void
  onSave: () => void
  project: AdminProject
}

export const LinkWorkdayProjectForm: FC<Props> = ({
  onCancel,
  onSave,
  project,
}) => {
  const { t } = useTranslation()
  const createWorkdayProjectLink = useCreateWorkdayProjectLink()
  const workdayProjectsQuery = useWorkdayProjects()

  const [workdayProject, setWorkdayProject] = useState<WorkdayProject | null>(
    null,
  )

  const save = useCallback(async () => {
    try {
      if (workdayProject === null) return

      await createWorkdayProjectLink.mutateAsync({
        projectId: project.id,
        workdayProjectId: workdayProject.id,
      })

      onSave()

      toast({
        title: t('features.projectsAdmin.projectLinked'),
        variant: 'success',
        content: t('features.projectsAdmin.successfullyLinkedWorkdayProject', {
          workdayProjectName: workdayProject.name,
          projectName: project.name,
        }),
      })
    } catch (error) {
      toast({
        title: t('features.projectsAdmin.unableToLinkProject'),
        variant: 'error',
        content: t('features.projectsAdmin.failedToLinkProject'),
      })
    }
  }, [onSave, createWorkdayProjectLink, project, t, workdayProject])

  const formFields = (
    <>
      {workdayProject ? (
        <div className="flex justify-between">
          {workdayProject.name}
          <IconButton icon={faRemove} onClick={() => setWorkdayProject(null)} />
        </div>
      ) : (
        <RemoteProjectPicker
          projectsQuery={workdayProjectsQuery}
          onSelect={setWorkdayProject}
        />
      )}
    </>
  )

  return (
    <ModalForm
      fields={formFields}
      onCancel={() => onCancel()}
      onSave={() => void save()}
      saveDisabled={workdayProject === null}
      isSaving={createWorkdayProjectLink.isLoading}
      saveButtonText={t('features.projectsAdmin.linkProject')}
    />
  )
}
