import { FC } from 'react'
import { Status } from 'types'
import { CharmButton } from 'components/buttons/CharmButton'
import { statusIcons, statusColors } from '@utils/statuses'
import { useLanguage } from '@hooks/useLanguage'
import { Trans } from 'react-i18next'
import { CircleProgress } from 'components/indicators/circle-progress'

interface Props {
  status: Status
  enabled: boolean
  count: number
  total?: number
  onDoubleClick: (status: Status) => void
  onClick: (status: Status) => void
}

export const MiniSilo: FC<Props> = ({
  status,
  enabled,
  count,
  total,
  onClick,
  onDoubleClick,
}) => {
  const language = useLanguage()

  return (
    <CharmButton
      onClick={() => onClick(status)}
      onDoubleClick={() => onDoubleClick(status)}
      darken={!enabled}
      icon={statusIcons[status]}
      variant={statusColors[status]}
      mode="normal"
    >
      {total === undefined ? (
        count.toLocaleString(language)
      ) : (
        <span className="flex items-center &>span">
          <Trans
            i18nKey="components.silo.quantityOfTotal"
            values={{ quantity: count, total }}
          />
          <CircleProgress
            percentGreen={(count / total) * 100}
            className="h-4 ml-2"
          />
        </span>
      )}
    </CharmButton>
  )
}
