import { Checkbox } from 'components/form/checkbox'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  isMeal: boolean
  onChange: (isMeal: boolean) => void
}

export const MealCheckbox: FC<Props> = ({ isMeal, onChange }) => {
  const { t } = useTranslation()

  return (
    <label className="flex flex-row" htmlFor="meal">
      {t('features.timeLogging.mealBreak')}
      <Checkbox
        className="ml-1"
        checked={isMeal}
        onChange={() => onChange(!isMeal)}
        id="meal"
        title={t('features.timeLogging.thisBreakIsAMeal')}
      />
    </label>
  )
}
