import { useEmployeeName } from '@features/time-logging/hooks/useEmployeeName'
import { useAbsences } from '@hooks/useAbsences'
import { range } from '@utils/range'
import { Leave } from 'components/leave'
import { Spinner } from 'components/loaders'
import { Sidebar } from 'components/sidebar'
import { secondsToHours } from 'date-fns'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserId } from '@features/time-logging/hooks/useUserId'
import { DateTime } from 'luxon'
import { useDateTimeToLocaleString } from '@hooks/useDateTimeWithLocale'
import { useFormatStartAndEnd } from '@utils/dates'

/**
 * I had initially thought that I'd get the year range from the backend, but
 * that would require that I search for all years.  Certainly a possibility, but
 * the only endpoint seems to require a date range
 *
 * AF 1/12/22
 */
const years = range(2022, 2122)

const getDateRange = (year: number) => {
  const startDate = DateTime.local().set({ year }).startOf('year')
  return { startDate, endDate: startDate.endOf('year') }
}

interface Props {
  hidden: boolean
  onClose: () => void
}

export const LeaveSidebar: FC<Props> = ({ hidden, onClose }) => {
  const { t } = useTranslation()
  const toLocaleString = useDateTimeToLocaleString()
  const formatStartAndEnd = useFormatStartAndEnd(toLocaleString)
  const [selectedYear, setSelectedYear] = useState(DateTime.now().get('year'))
  const [selectedLeaveType, setSelectedLeaveType] = useState<string>(
    t('common.all'),
  )
  const { isSelf, name } = useEmployeeName()

  const title = isSelf
    ? t('features.leaveSidebar.myLeaveAndTimeOffSummary')
    : t('features.leaveSidebar.usersLeaveAndTimeOffSummary', { name })

  const { startDate, endDate } = getDateRange(selectedYear)

  const contentFor = (absence: Absence) => {
    if (absence.leave) {
      return formatStartAndEnd(absence.startDate, absence.estimatedEndDate)
    } else {
      const hours = secondsToHours(absence.duration)

      return t('features.leaveSidebar.hours', { count: hours })
    }
  }

  const userId = useUserId()
  const absencesQuery = useAbsences({
    userId,
    startDateOverride: startDate,
    endDateOverride: endDate,
  })

  const absences = absencesQuery.data ?? []

  const absenceTypes = absences
    .reduce(
      (acc: string[], absence) => {
        if (acc.includes(absence.type)) return acc

        return [...acc, absence.type]
      },
      [t('common.all')],
    )
    .sort()

  const items = absences
    .filter((absence) => {
      if (selectedLeaveType === t('common.all')) return true

      return absence.type === selectedLeaveType
    })
    .map((absence) => ({
      date: absence.leave ? absence.startDate : absence.date,
      heading: absence.type,
      content: contentFor(absence),
    }))

  return (
    <Sidebar hidden={hidden} onClose={onClose} title={title}>
      {absencesQuery.data ? (
        <Leave
          types={absenceTypes}
          years={years}
          selectedLeaveType={selectedLeaveType}
          selectedYear={selectedYear}
          onLeaveTypeChange={setSelectedLeaveType}
          onYearChange={setSelectedYear}
          items={items}
        />
      ) : (
        <div className="text-center">
          <Spinner className="mt-4 text-3xl" />
        </div>
      )}
    </Sidebar>
  )
}
