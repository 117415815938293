import { updateProjectApprovers } from '@lib/api'
import { adminProjectsKeys } from '@lib/keys'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useUpdateProjectApprovers = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, approverUserIds }: { id: number; approverUserIds: number[] }) =>
      updateProjectApprovers(id, approverUserIds),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(adminProjectsKeys.all)
      },
    },
  )
}
