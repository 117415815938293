import { FC, useMemo, useState } from 'react'
import { SearchField } from 'components/form/search-field'
import { Item } from '../components/Item'
import { includes, itemsFromTasks } from '../helpers'
import { useNonProjectTasks } from '@features/time-logging/hooks/useTasks'
import { Spinner } from 'components/loaders'
import { useTranslation } from 'react-i18next'

type TasksByStepCode = {
  [key: NonNullable<NonProjectTask['step']>['id']]: NonProjectTask[]
  noStepCode: NonProjectTask[]
}

const groupTasksByStepId = (tasks: NonProjectTask[]): TasksByStepCode => {
  return tasks.reduce<TasksByStepCode>(
    (acc, task) => {
      const key = task.step?.id ?? 'noStepCode'
      const tasksWithCode = acc[key] ?? []
      return { ...acc, [key]: [...tasksWithCode, task] }
    },
    { noStepCode: [] },
  )
}

interface Props {
  disabledTaskIds: Set<number>
  hidden: boolean
  onTaskClick: (task: SelectedTask, checked: boolean) => void
  selectedTaskIds: Set<number>
}

const NonProject: FC<Props> = ({
  disabledTaskIds,
  hidden,
  onTaskClick,
  selectedTaskIds,
}) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const nonProjectTasksQuery = useNonProjectTasks({ enabled: !hidden })

  const groups = useMemo(() => {
    if (nonProjectTasksQuery.data === undefined) return []

    const includesSearch = includes(search.toLowerCase().trim())

    const filteredTasks = nonProjectTasksQuery.data.filter((task) => {
      return (
        includesSearch(task.name) ||
        includesSearch(task.id.toString()) ||
        includesSearch(task.step?.code ?? null)
      )
    })

    const { noStepCode: tasksWithNoStep, ...tasksByStepId } =
      groupTasksByStepId(filteredTasks)

    return Object.values(tasksByStepId)
      .map((tasks) => {
        return [
          tasks[0].step?.code ?? '', // This smells because step.code will always be defined
          itemsFromTasks(
            tasks.sort((taskA, taskB) => (taskA.name > taskB.name ? 1 : -1)),
            disabledTaskIds,
            selectedTaskIds,
          ),
        ] as const
      })
      .sort(([codeA], [codeB]) => (codeA > codeB ? 1 : -1))
      .concat(
        tasksWithNoStep.length > 0
          ? [
              [
                t('features.timeLogging.noPipelineStep'),
                itemsFromTasks(
                  tasksWithNoStep.sort((taskA, taskB) =>
                    taskA.name > taskB.name ? 1 : -1,
                  ),
                  disabledTaskIds,
                  selectedTaskIds,
                ),
              ],
            ]
          : [],
      )
  }, [nonProjectTasksQuery.data, search, selectedTaskIds, disabledTaskIds, t])

  if (hidden) return null

  if (nonProjectTasksQuery.isLoading === undefined) {
    return (
      <div className="px-4 text-center">
        <Spinner className="text-3xl" />
      </div>
    )
  }

  return (
    <div className="px-9">
      <SearchField
        autoFocus={true}
        value={search}
        onChange={setSearch}
        placeholder={t('features.timeLogging.searchNonProjectTasks')}
      />
      <div className="pt-4">
        {groups.map(([stepCode, items]) => (
          <div key={stepCode} className="mb-4">
            <h3 className="text-xs font-medium text-neutral-500">{stepCode}</h3>
            {items.map((item) => (
              <Item
                key={item.task.id}
                item={item}
                onItemClick={(_id, checked) => {
                  onTaskClick(item.task, checked)
                }}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default NonProject
