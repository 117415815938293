import { WorkerAndTimeCards } from '../types'

type Result = [employeeId: number, timeCard: AdminTimeCard | undefined][]

export const getAllEmployeeIdsAndTimeCardsForDate = (
  workerAndTimeCards: WorkerAndTimeCards[],
  date: DateTime,
): Result => {
  return workerAndTimeCards.reduce<Result>((acc, { worker, timeCards }) => {
    const employeeId = worker.workdayWorkerId
    const timeCard = timeCards.find((timeCard) =>
      timeCard.date.hasSame(date, 'day'),
    )

    return [...acc, [employeeId, timeCard]]
  }, [])
}
