import { FC } from 'react'
import { Header } from './Header'
import { MenuButton } from 'components/buttons'
import { faRefresh } from '@fortawesome/pro-regular-svg-icons'
import { useAdminProjects } from '@features/admin/hooks/useAdminProjects'
import { Table } from './Table'
import { Spinner } from 'components/loaders'
import { useRefreshWorkdayProjects } from '../hooks/useRefreshWorkdayProjects'
import { useModal } from '@hooks/useModal'
import { useTranslation } from 'react-i18next'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { useNewProject } from '../hooks/useNewProject'
import { useProjectIntegrations } from 'hooks/useProjectIntegrations'

export const ProjectsAdmin: FC = () => {
  const { t } = useTranslation()
  const { data: projects } = useAdminProjects()
  const { data: projectIntegrations } = useProjectIntegrations()
  const refreshWorkdayProjects = useRefreshWorkdayProjects()
  const { newProject } = useNewProject()
  const modal = useModal()

  const refresh = () => {
    refreshWorkdayProjects.mutate(undefined, {
      onError: () => {
        modal.alert({
          title: t('common.error'),
          content: t(
            'features.projectsAdmin.anErrorWasEncounteredWhileRefreshingProjectsFromWorkday',
          ),
        })
      },
    })
  }

  return (
    <div className="flex flex-col flex-grow">
      <Header
        actionButton={
          <div className="flex flex-row items-center">
            <MenuButton
              className="ml-2"
              title={t('common.action')}
              menuItems={[
                {
                  icon: faPlus,
                  label: t('features.projectsAdmin.addProject'),
                  onClick: newProject,
                },
                {
                  icon: faRefresh,
                  label: t('features.projectsAdmin.refreshWorkdayProjects'),
                  onClick: refresh,
                  disabled: refreshWorkdayProjects.isLoading,
                },
              ]}
            >
              {t('common.action')}
            </MenuButton>
          </div>
        }
      />

      {projects && projectIntegrations ? (
        <Table projects={projects} projectIntegrations={projectIntegrations} />
      ) : (
        <Spinner />
      )}
    </div>
  )
}
