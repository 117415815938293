import { useParams } from 'react-router-dom'

export const useUserId = () => {
  const userId = Number(useParams().userId)

  if (isNaN(userId)) throw new Error('user id param is invalid')
  if (!userId) throw new Error('user id must be set')

  return userId
}
