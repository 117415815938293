import { FC } from 'react'
import { EntityPicker, EntityItem } from '../entity-picker'
import { useDepartments } from '@hooks/useDepartments'
import { useTranslation } from 'react-i18next'

interface Props {
  columnNameTranslationKey: TranslationKey
  onChange: (ids: Set<string>) => void
  selectedIds: Set<string>
}
export const WorkdayDepartmentPicker: FC<Props> = ({
  columnNameTranslationKey,
  onChange,
  selectedIds,
}) => {
  const { t } = useTranslation()

  return (
    <EntityPicker
      className="w-72"
      columnNameTranslationKey={columnNameTranslationKey}
      filter={(department, search) => {
        return department.name.toLowerCase().includes(search.toLowerCase())
      }}
      placeholder={t('common.search')}
      isSelected={(department, selectedIds) => selectedIds.has(department.id)}
      renderSearchItem={(department) => (
        <EntityItem
          key={department.id}
          onClick={() => onChange(new Set(selectedIds).add(department.id))}
          primaryText={department.name}
          disabled={selectedIds.has(department.id)}
        />
      )}
      renderSelectedItem={(department) => (
        <EntityItem
          key={department.id}
          onClick={() => {
            const s = new Set(selectedIds)
            s.delete(department.id)
            onChange(s)
          }}
          primaryText={department.name}
          removable={true}
        />
      )}
      selectedIds={selectedIds}
      useQuery={useDepartments}
    />
  )
}
