import { Interval } from 'luxon'

export const isLeave = (absence: Absence): absence is Leave => absence.leave
export const isTimeOff = (absence: Absence): absence is TimeOff =>
  !absence.leave

export const dateIntersectsAbsence = (
  date: DateTime,
  absence: Absence,
): boolean => {
  if (isTimeOff(absence)) return date.hasSame(absence.date, 'day')

  const timeOffInterval = Interval.fromDateTimes(
    absence.startDate,
    absence.estimatedEndDate.endOf('day'),
  )
  return timeOffInterval.contains(date)
}
