import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons'
import { useOnClickOutside } from '@hooks/useOnClickOutside'
import { DropdownButton, IconButton } from 'components/buttons'
import { Input } from 'components/form/input'
import { Spinner } from 'components/loaders'
import { FC, useRef, useState, useEffect, useCallback } from 'react'
import { useTranslation, Trans } from 'react-i18next'

interface Props {
  onPageNumberChange: (number: number) => void
  onPageSizeChange: (size: number) => void
  pageNumber: number
  pageSize: number
  pending: boolean
  totalPages?: number
  totalRows?: number
}

export const Footer: FC<Props> = ({
  onPageNumberChange,
  onPageSizeChange,
  pageNumber,
  pageSize,
  pending,
  totalPages,
  totalRows = 0,
}) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const [pageSizeSelectorHidden, setPageSizeSelectorHidden] = useState(true)
  const [manuallySpecifiedPageNumber, setManuallySpecifiedPageNumber] =
    useState(pageNumber.toString())

  const totalPageCount = totalPages ?? Math.ceil(totalRows / pageSize)

  useOnClickOutside(ref, () => setPageSizeSelectorHidden(true))

  const endRowCount = Math.min(pageSize * pageNumber, totalRows ?? 0)
  const startRowCount = pageSize * pageNumber - pageSize + 1

  const setPageToManuallyEnteredPageNumber = useCallback(() => {
    let newPage = parseInt(manuallySpecifiedPageNumber)

    // Ensure that entered value is an int and within range
    // Adjust accordingly
    if (isNaN(newPage)) {
      newPage = pageNumber
    } else if (newPage <= 0) {
      newPage = 1
    } else if (totalPages && newPage > totalPages) {
      newPage = totalPages
    }

    // Set page number
    onPageNumberChange(newPage)

    // Update field with new value
    // (eg if we had to transform NaN into a number)
    setManuallySpecifiedPageNumber(newPage.toString())
  }, [
    pageNumber,
    manuallySpecifiedPageNumber,
    onPageNumberChange,
    setManuallySpecifiedPageNumber,
    totalPages,
  ])

  // Update manual page number field when page number changes externally
  // (eg arrows)
  useEffect(
    () => setManuallySpecifiedPageNumber(pageNumber.toString()),
    [setManuallySpecifiedPageNumber, pageNumber],
  )

  return (
    <div className="flex flex-row text-sm border-t border-b text-neutral-900 border-neutral-300">
      <div className="px-4 py-2 border-r border-neutral-300 flex items-center">
        <div className="relative" ref={ref}>
          <DropdownButton
            className="font-normal bg-white text-neutral-900"
            label={t('features.reporting.resultsPerPage', { pageSize })}
            onClick={() => setPageSizeSelectorHidden(false)}
            expanded={!pageSizeSelectorHidden}
            readonly={false}
          />
          {!pageSizeSelectorHidden && (
            <div className="absolute flex flex-col bg-white rounded shadow right-2 w-28 bottom-6">
              {[10, 25, 50, 100].map((size) => (
                <button
                  key={size}
                  className="py-2 border-b border-neutral-300 hover:bg-neutral-100 last-of-type:border-b-0"
                  onClick={() => {
                    onPageSizeChange(size)
                    setPageSizeSelectorHidden(true)
                  }}
                >
                  {size}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-row items-center px-4 py-2 border-r border-neutral-300">
        {pending ? (
          <Spinner />
        ) : (
          t('features.reporting.rowCount', {
            startRowCount,
            endRowCount,
            totalRows,
          })
        )}
      </div>
      <div className="px-4 py-2 ml-auto border-l border-neutral-300">
        {pending ? (
          <Spinner />
        ) : (
          <Trans
            i18nKey="features.reporting.pageOf"
            totalPageCount={totalPageCount}
          >
            <Input
              value={manuallySpecifiedPageNumber}
              onChange={(e) => setManuallySpecifiedPageNumber(e.target.value)}
              onBlur={setPageToManuallyEnteredPageNumber}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setPageToManuallyEnteredPageNumber()
                }
              }}
              onFocus={(e) => e.target.select()}
              className="py-2 w-14 h-8 text-center"
            />
            of {{ totalPageCount }}
          </Trans>
        )}
      </div>
      <div className="flex items-center px-2 border-l border-neutral-300">
        <IconButton
          icon={faChevronLeft}
          disabled={pageNumber <= 1}
          onClick={() => onPageNumberChange(Math.max(1, pageNumber - 1))}
        />
      </div>
      <div className="flex items-center px-2 border-l border-neutral-300">
        <IconButton
          icon={faChevronRight}
          disabled={pageNumber >= totalPageCount}
          onClick={() =>
            onPageNumberChange(Math.min(totalPageCount, pageNumber + 1))
          }
        />
      </div>
    </div>
  )
}
