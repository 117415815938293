import { FC } from 'react'
import { RelativeDateRangePicker } from 'components/form/date-range-picker/RelativeDateRangePicker'
import { ButtonInput, FilterRenderer } from '../components'
import { useTranslation } from 'react-i18next'
import { useLocalizedDateRange } from '@hooks/useDateTimeLocalization'

interface Props {
  columnNameTranslationKey?: TranslationKey
  endDate?: DateTime
  onChange: (dates: {
    startDate?: DateTime
    endDate?: DateTime
    relativeDateRangeValue?: RelativeDateRangeValue
  }) => void
  startDate?: DateTime
  relativeDateRangeValue?: RelativeDateRangeValue
}

export const DateRange: FC<Props> = ({
  columnNameTranslationKey,
  endDate,
  onChange,
  startDate,
  relativeDateRangeValue,
}) => {
  const { t } = useTranslation()
  const renderDateRange = useLocalizedDateRange()

  return (
    <FilterRenderer
      columnNameTranslationKey={columnNameTranslationKey}
      // Date provides its own padding, and since our bg runs to the edge, we need to clip overflow for the rounded corners
      dropdownWrapperClassName="p-0 overflow-clip"
      renderDropdown={() => (
        <RelativeDateRangePicker
          relativeValues={[
            'today',
            'thisWeek',
            'weekToDate',
            'lastWeek',
            'thisMonth',
            'monthToDate',
            'lastMonth',
            'thisYear',
            'yearToDate',
          ]}
          selectedRelativeValue={relativeDateRangeValue}
          clearTitle={t('common.clear')}
          endDate={endDate}
          startDate={startDate}
          onChange={onChange}
        />
      )}
      renderTrigger={(clickHandler) => (
        <ButtonInput onClick={clickHandler}>
          {relativeDateRangeValue
            ? t(`relativeDateRangePicker.values.${relativeDateRangeValue}`)
            : renderDateRange(startDate, endDate, {
                month: 'short',
                day: 'numeric',
              })}
        </ButtonInput>
      )}
    />
  )
}
