import { Navigate } from 'react-router-dom'
import { Members } from './members'
import { ListAdminManagedGroups } from './list-admin-managed-groups'
import { ManagedTemplates } from './ManagedTemplates'
import { ManagedGroup } from './ManagedGroup'
import { WorkWeekAndAllocation } from './work-week-and-allocation'

const manageGroupLeafRoutes = [
  {
    path: 'members',
    element: <Members />,
  },
  {
    path: 'work-week-and-allocation',
    element: <WorkWeekAndAllocation />,
  },
  {
    index: true,
    element: <Navigate to="members" replace={true} />,
  },
]

export const managedGroupsRoutes = [
  {
    path: '/admin/managed-groups',
    element: <ManagedTemplates />,
    children: [
      {
        index: true,
        element: <ListAdminManagedGroups />,
      },
      {
        path: 'new',
        element: <ManagedGroup />,
        children: manageGroupLeafRoutes,
      },
      {
        path: ':id',
        element: <ManagedGroup />,
        children: manageGroupLeafRoutes,
      },
    ],
  },
]
