import { FC } from 'react'
import { Tooltip } from '@lib/tooltip'

interface Props {
  task: { id: number; displayPath: string[]; name: string }
}

export const TaskCell: FC<Props> = ({ task }) => {
  const pathString = task.displayPath.join(' • ')

  return (
    <div className="grow grid grid-cols-2 gap-2 text-xs">
      <div
        className="font-medium text-neutral-600 text-ellipsis overflow-hidden whitespace-nowrap"
        data-tooltip-id={`selected-task-cell-task-path-${task.id}`}
      >
        <Tooltip id={`selected-task-cell-task-path-${task.id}`} delayShow={500}>
          {pathString}
        </Tooltip>

        {pathString}
      </div>
      <div
        className="font-semibold text-neutral-900 text-ellipsis overflow-hidden whitespace-nowrap"
        data-tooltip-id={`selected-task-cell-task-name-${task.id}`}
      >
        <Tooltip id={`selected-task-cell-task-name-${task.id}`} delayShow={500}>
          {task.name}
        </Tooltip>

        {task.name}
      </div>
    </div>
  )
}
