import { getEmployeeDayEventLog } from '@lib/api'
import { employeeDayEventLogKeys } from '@lib/keys'
import { useQuery } from '@tanstack/react-query'

export const useEmployeeDayEventLog = (employeeId: number, date: DateTime) => {
  return useQuery(
    employeeDayEventLogKeys.getByEmployeeAndDate(employeeId, date),
    () => getEmployeeDayEventLog(employeeId, date),
  )
}
