import { FC, useId } from 'react'
import { ColumnDroppableData, ReportTypeMap } from '../../../types'
import { labelKeyByColumnGroupId } from '../../../lib/labelKeyByColumnGroupId'
import { useTranslation } from 'react-i18next'
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { Ghost } from './Ghost'
import { DroppableSlot } from './DroppableSlot'
import { Item } from './Item'
import { Column } from './Column'

type ReportTypeGroupProps<RT extends keyof ReportTypeMap> = {
  reportType: RT
  groupId: ReportTypeMap[RT]['groupId']
  columns: ReportTypeMap[RT]['columnId'][]
  onColumnSelectDeselect: (columnId: ReportTypeMap[RT]['columnId']) => void
}

type GroupProps = {
  selected: boolean
  droppable: boolean
  draggingEnabled: boolean
} & ReportTypeGroupProps<keyof ReportTypeMap>

export const Group: FC<GroupProps> = ({
  groupId,
  columns,
  onColumnSelectDeselect,
  selected,
  droppable,
  draggingEnabled,
  reportType,
}) => {
  const id = useId()
  const { t } = useTranslation()

  const dndData = { type: 'group', columns }

  const {
    setNodeRef: draggableRef,
    attributes,
    listeners,
    transform,
    isDragging,
    over,
  } = useDraggable({ id, data: dndData, disabled: !draggingEnabled })

  // Unfortunately there does not appear to be any way to pass a data type to dndkit
  const overData = over?.data.current as ColumnDroppableData | undefined

  const style = transform
    ? {
        transform: CSS.Translate.toString(transform),
      }
    : undefined

  const groupLabelItem = (
    <Item
      onSelectDeselect={() => columns.forEach(onColumnSelectDeselect)}
      selected={selected}
      dragHandleProps={{ ...attributes, ...listeners }}
      draggingEnabled={draggingEnabled}
    >
      {t(labelKeyByColumnGroupId({ reportType, groupId }))}
    </Item>
  )

  const content = (
    <div>
      {droppable ? (
        <DroppableSlot data={dndData} className="my-2 py-1">
          {groupLabelItem}
        </DroppableSlot>
      ) : (
        groupLabelItem
      )}

      {columns.map((column) => (
        <Column
          key={column}
          id={column}
          groupId={groupId}
          onSelectDeselect={onColumnSelectDeselect}
          selected={selected}
          droppable={droppable}
          draggingEnabled={draggingEnabled}
          reportType={reportType}
        />
      ))}
    </div>
  )

  const ghostContent = (
    <Ghost removing={overData?.type === 'deselected-list'}>
      {t(labelKeyByColumnGroupId({ reportType, groupId }))} ({columns.length})
    </Ghost>
  )

  return (
    <div ref={draggableRef} style={style}>
      {isDragging ? ghostContent : content}
    </div>
  )
}
