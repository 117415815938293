import { Button } from 'components/buttons'
import { NewHeader as PageHeader } from 'components/page-header'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Table } from './Table'

export const ListAdminManagedGroups = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col flex-grow">
      <PageHeader
        title={t('features.adminManagedGroups.adminManagedGroups')}
        renderRightSlot={() => (
          <Link to="new">
            <Button>{t('features.adminManagedGroups.newGroup')}</Button>
          </Link>
        )}
      />
      <Table className="mt-8" />
    </div>
  )
}
