import { ReportTypeMap } from '../types'
import { getColumnGroupParent } from './getColumnGroupParent'

type GroupedColumns<RT extends keyof ReportTypeMap> = {
  [key in ReportTypeMap[RT]['groupId']]?: ReportTypeMap[RT]['columnId'][]
}

// Groups columns together without regard for column ordering
// To maintain column order and group contiguous columns together,
// use coalesceContiguousColumnParents
export const groupColumns = <RT extends keyof ReportTypeMap>(
  columnIds: ReportTypeMap[RT]['columnId'][],
) => {
  return columnIds.reduce<GroupedColumns<RT>>((acc, columnId) => {
    const groupId = getColumnGroupParent(columnId)

    return {
      ...acc,
      [groupId]: [...(acc[groupId] ?? []), columnId],
    }
  }, {})
}
