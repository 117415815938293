import { useMutation, useQueryClient } from '@tanstack/react-query'
import { entryKeys, timeCardKeys } from '@lib/keys'
import {
  deleteTimeCard,
  postTimeCard,
  submitAllTimeCards,
  updateTimeCard,
  submitTimeCard,
  unsubmitTimeCard,
} from '@lib/api'
import { useMemo } from 'react'
import { useWeekDates } from '@hooks/useWeekDates'
import { useUserId } from './useUserId'
import { noop } from 'utils/noop'
import { useTimeCards } from '@hooks/useTimeCards'

interface ReturnTimeCardOption<T extends boolean> {
  returnNullTimeCard: T
}

export interface UpdateTimeCardMutationOptions {
  id: number
  startAt: number | null
  endAt: number | null
}

export function useTimeCardByDate(
  date: DateTime,
  options: ReturnTimeCardOption<true>,
): TimeCard
export function useTimeCardByDate(
  date: DateTime,
  options?: ReturnTimeCardOption<false>,
): TimeCard | undefined
export function useTimeCardByDate(
  date: DateTime,
  options?: ReturnTimeCardOption<true> | ReturnTimeCardOption<false>,
) {
  const userId = useUserId()
  const timeCardsQuery = useTimeCards({ userId })

  return useMemo(() => {
    const nullTimeCard: TimeCard = {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      breaks: [],
      id: 0,
      index: 0,
      date,
      startAt: null,
      endAt: null,
      submitted: false,
      partiallyOrFullyApproved: false,
      fullyApproved: false,
      sentToPayrollAt: null,
      totalTaggedSeconds: 0,
      correction: false,
      correctionState: 'current',
      capturesMealBreaks: false,
    }

    const timeCard = timeCardsQuery.data?.find((card) =>
      card.date.hasSame(date, 'day'),
    )

    if (timeCard) return timeCard
    if (options?.returnNullTimeCard) return nullTimeCard
  }, [timeCardsQuery.data, date, options])
}

export const useUpdateTimeCard = () => {
  const queryClient = useQueryClient()
  const userId = useUserId()
  const { start, end } = useWeekDates()

  return useMutation(
    (options: UpdateTimeCardMutationOptions) =>
      updateTimeCard({ ...options, userId }),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(
          timeCardKeys.search(userId, start, end),
        )
      },
    },
  )
}

export const useDeleteTimeCard = () => {
  const queryClient = useQueryClient()
  const userId = useUserId()
  const { start, end } = useWeekDates()

  return useMutation({
    mutationFn: (timeCardId: number) => deleteTimeCard({ userId, timeCardId }),
    onSuccess: () => {
      // Invalidate and refetch all entries
      void queryClient.invalidateQueries(entryKeys.search(userId, start, end))

      // Invalidate and refetch all Time Cards
      void queryClient.invalidateQueries(
        timeCardKeys.search(userId, start, end),
      )
    },
  })
}

interface UseAddTimeCardOptions {
  onSuccess?: () => void
  onError?: () => void
}

export const useAddTimeCard = (
  { onSuccess = noop, onError = noop }: UseAddTimeCardOptions = {
    onSuccess: noop,
    onError: noop,
  },
) => {
  const queryClient = useQueryClient()
  const userId = useUserId()
  const { start, end } = useWeekDates()

  return useMutation((date: DateTime) => postTimeCard(userId, date), {
    onSuccess: () => {
      void queryClient.invalidateQueries(
        timeCardKeys.search(userId, start, end),
      )
      onSuccess()
    },
    onError,
  })
}

export const useSubmitAllTimeCards = (userId?: number) => {
  const queryClient = useQueryClient()
  const currentUserId = useUserId()

  const id = userId ?? currentUserId

  return useMutation(() => submitAllTimeCards(id), {
    onSuccess: () => {
      void queryClient.invalidateQueries(timeCardKeys.user(id))
    },
  })
}

export const useSubmitTimeCard = () => {
  const queryClient = useQueryClient()
  const userId = useUserId()
  const { start, end } = useWeekDates()

  return useMutation({
    mutationFn: (timeCardId: number) => submitTimeCard({ userId, timeCardId }),
    onSuccess: () => {
      // Invalidate and refetch all Time Cards
      void queryClient.invalidateQueries(
        timeCardKeys.search(userId, start, end),
      )
    },
  })
}

export const useUnsubmitTimeCard = () => {
  const queryClient = useQueryClient()
  const userId = useUserId()
  const { start, end } = useWeekDates()

  return useMutation({
    mutationFn: (timeCardId: number) =>
      unsubmitTimeCard({ userId, timeCardId }),
    onSuccess: () => {
      // Invalidate and refetch all Time Cards
      void queryClient.invalidateQueries(
        timeCardKeys.search(userId, start, end),
      )
    },
  })
}
