import { useTranslation } from 'react-i18next'
import { useLanguage } from './useLanguage'
import { renderDateRange } from '@utils/renderDateRange'
import { DateTime } from 'luxon'

export const useLocalizedDateRange = () => {
  const { t } = useTranslation()
  const language = useLanguage()

  return (
    startDate: DateTime | undefined,
    endDate: DateTime | undefined,
    formatOptions: Intl.DateTimeFormatOptions,
  ) => renderDateRange(startDate, endDate, t, language, formatOptions)
}
