import { createProject } from '@lib/api'
import { adminProjectsKeys } from '@lib/keys'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useCreateProject = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ name, approverUserIds }: { name: string; approverUserIds: number[] }) =>
      createProject(name, approverUserIds),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(adminProjectsKeys.all)
      },
    },
  )
}
