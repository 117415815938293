import { FC, useMemo } from 'react'
import { secondsToHHMM } from '@utils/time'
import { useTranslation } from 'react-i18next'
import { SelectionBar } from 'components/indicators/selection-bar'
import { SelectedById, SelectedEmployeeValues } from '../types'
import { useLanguage } from '@hooks/useLanguage'

interface Props {
  selections: SelectedById
}

interface SelectionTotals {
  days: number
  timeCards: number
  timeCardSeconds: number
}

export const CellSelectionBar: FC<Props> = ({ selections }) => {
  const { t } = useTranslation()
  const language = useLanguage()

  const selectionTotals = useMemo(
    () =>
      Object.values(selections).reduce<SelectionTotals>(
        (totals, { dates, ...timeCardsById }: SelectedEmployeeValues) => ({
          timeCards: totals.timeCards + Object.keys(timeCardsById).length,
          timeCardSeconds:
            totals.timeCardSeconds +
            Object.values(timeCardsById).reduce(
              (subTotal, timeCard) => subTotal + timeCard.totalSecondsLogged,
              0,
            ),
          days: totals.days + dates.size,
        }),
        {
          days: 0,
          timeCards: 0,
          timeCardSeconds: 0,
        },
      ),
    [selections],
  )

  return (
    <SelectionBar
      design="new"
      data={[
        {
          value: selectionTotals.days.toLocaleString(language),
          label: t('features.admin.day', { count: selectionTotals.days }),
        },
        {
          value: selectionTotals.timeCards.toLocaleString(language),
          label: t('common.timeCardsTitlePluralized', {
            count: selectionTotals.timeCards,
          }),
        },
        {
          value:
            selectionTotals.timeCardSeconds > 0
              ? secondsToHHMM(selectionTotals.timeCardSeconds, { language })
              : '0h',
          label: t('features.approvals.timeAllocated'),
        },
        /* TODO: OT section */
      ]}
    />
  )
}
