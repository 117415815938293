import { useTimeCardByDate } from '@features/time-logging/hooks/useTimeCardManagement'
import { col } from '@utils/styles'
import { FC } from 'react'
import { Holiday } from './Holiday'
import { TimeOff } from './TimeOff'
import { Leave } from './Leave'
import { dateIntersectsAbsence, isLeave, isTimeOff } from '@utils/absence'
import { Interval } from 'luxon'

interface Props {
  date: DateTime
  absences: Absence[]
  holidays: Holiday[]
  onTimeOffClick: () => void
}

type timeOffAnnotation = {
  duration: number
  type: 'timeOff'
}

type leaveAnnotation = {
  value: Leave
  type: 'leave'
}

type holidayAnnotation = {
  value: string
  type: 'holiday'
}

export const DayAnnotation: FC<Props> = ({
  date,
  absences,
  holidays,
  onTimeOffClick,
}) => {
  const isTimeCardPresent = !!useTimeCardByDate(date)?.id

  const holidayNames = holidays.reduce<string[]>(
    (acc, { startDate, endDate, name: holidayName }) => {
      return Interval.fromDateTimes(startDate, endDate.endOf('day')).contains(
        date,
      )
        ? [...acc, holidayName]
        : acc
    },
    [],
  )

  const timeOffOnDate: timeOffAnnotation = absences
    .filter(
      (absence): absence is TimeOff =>
        isTimeOff(absence) && dateIntersectsAbsence(date, absence),
    )
    .reduce(
      (acc, timeOff) => {
        const duration = acc.duration
        return { ...acc, duration: duration + timeOff.duration }
      },
      { type: 'timeOff', duration: 0 },
    )

  const leaveOnDate = absences.filter(
    (absence) => isLeave(absence) && dateIntersectsAbsence(date, absence),
  ) as Leave[]

  const annotations: (
    | holidayAnnotation
    | timeOffAnnotation
    | leaveAnnotation
  )[] = [
    ...holidayNames.map<holidayAnnotation>((holidayName) => ({
      value: holidayName,
      type: 'holiday',
    })),

    ...leaveOnDate.map<leaveAnnotation>((leave) => ({
      value: leave,
      type: 'leave',
    })),
  ]

  if (timeOffOnDate.duration > 0) annotations.push(timeOffOnDate)

  return (
    <div
      key={`annotations-${date.toString()}`}
      className={`flex flex-col justify-end ${col(date)} space-y-1`}
    >
      {annotations.map((annotation, index) => {
        const isLastAnnotation = annotations.length - 1 === index
        const isAttachment = isLastAnnotation && isTimeCardPresent
        const variant = isAttachment ? 'attachment' : 'floating'

        switch (annotation.type) {
          case 'holiday':
            return (
              <Holiday
                name={annotation.value}
                key={`holiday-${annotation.value}`}
                variant={variant}
              />
            )
          case 'timeOff':
            return (
              <TimeOff
                key={`time-off-${annotation.duration}`}
                onClick={onTimeOffClick}
                totalSeconds={annotation.duration}
                variant={variant}
              />
            )
          case 'leave':
            return (
              <Leave
                key={`leave-${annotation.value.type}`}
                onClick={onTimeOffClick}
                variant={variant}
              />
            )
        }
      })}
    </div>
  )
}
