import { coerceIntoArray } from '@utils/coerceIntoArray'
import { StatusMiniSilo } from 'components/buttons/StatusMiniSilo'
import { FC } from 'react'
import { Status } from 'types'

type StatusCounts = { [status in Status]: number }

const getOtherStatuses = (status: Status) => {
  const set = new Set([
    Status.Open,
    Status.Approved,
    Status.Sent,
    Status.Submitted,
  ])
  set.delete(status)
  return Array.from(set)
}

const generateOtherHandlerFn =
  (cb: (statuses: Status[]) => void) => (status: Status) =>
    cb(getOtherStatuses(status))

const generateHandler =
  (cb: (statuses: Status[]) => void) => (status: Status) =>
    cb(coerceIntoArray(status))

interface Props {
  counts: StatusCounts
  hiddenStatuses: Set<Status>
  onHide: (statuses: Status[]) => void
  onSelect: (statuses: Status[]) => void
  onShow: (statuses: Status[]) => void
  onUnselect: (statuses: Status[]) => void
  selectedCounts: StatusCounts
  loading?: boolean
}

export const StatusMiniSilos: FC<Props> = ({
  counts,
  hiddenStatuses,
  onHide,
  onSelect,
  onShow,
  onUnselect,
  selectedCounts,
  loading,
}) => {
  const statusIsAllSelected = (status: Status) => {
    return counts[status] > 0 && counts[status] === selectedCounts[status]
  }

  const statusIsHidden = (status: Status) => {
    return hiddenStatuses.has(status)
  }

  const otherStatusesAreHidden = (status: Status) => {
    if (hiddenStatuses.size > 1) return true

    return hiddenStatuses.size === 1 && !hiddenStatuses.has(status)
  }

  const onHideHandler = generateHandler(onHide)
  const onSelectHandler = generateHandler(onSelect)
  const onShowHandler = generateHandler(onShow)
  const onUnselectHandler = generateHandler(onUnselect)

  const getCount = (status: Status) => counts[status]

  return (
    <div className="flex flex-row items-center px-2 border-x-2 gap-2 border-neutral-200">
      <StatusMiniSilo
        allSelected={statusIsAllSelected}
        count={getCount}
        onHide={onHideHandler}
        onHideOthers={generateOtherHandlerFn(onHide)}
        othersHidden={otherStatusesAreHidden}
        onSelect={onSelectHandler}
        onShow={onShowHandler}
        onShowOthers={generateOtherHandlerFn(onShow)}
        onUnselect={onUnselectHandler}
        status={Status.Open}
        statusIsHidden={statusIsHidden}
        loading={loading}
      />
      <StatusMiniSilo
        allSelected={statusIsAllSelected}
        count={getCount}
        onHide={onHideHandler}
        onHideOthers={generateOtherHandlerFn(onHide)}
        othersHidden={otherStatusesAreHidden}
        onSelect={onSelectHandler}
        onShow={onShowHandler}
        onShowOthers={generateOtherHandlerFn(onShow)}
        onUnselect={onUnselectHandler}
        status={Status.Submitted}
        statusIsHidden={statusIsHidden}
        loading={loading}
      />
      <StatusMiniSilo
        allSelected={statusIsAllSelected}
        count={getCount}
        onHide={onHideHandler}
        onHideOthers={generateOtherHandlerFn(onHide)}
        othersHidden={otherStatusesAreHidden}
        onSelect={onSelectHandler}
        onShow={onShowHandler}
        onShowOthers={generateOtherHandlerFn(onShow)}
        onUnselect={onUnselectHandler}
        status={Status.Approved}
        statusIsHidden={statusIsHidden}
        loading={loading}
      />
      <StatusMiniSilo
        allSelected={statusIsAllSelected}
        count={getCount}
        onHide={onHideHandler}
        onHideOthers={generateOtherHandlerFn(onHide)}
        othersHidden={otherStatusesAreHidden}
        onSelect={onSelectHandler}
        onShow={onShowHandler}
        onShowOthers={generateOtherHandlerFn(onShow)}
        onUnselect={onUnselectHandler}
        status={Status.Sent}
        statusIsHidden={statusIsHidden}
        loading={loading}
        total={Object.values(counts).reduce((sum, count) => sum + count, 0)}
      />
    </div>
  )
}
