import { Table } from '@tanstack/react-table'
import { mkConfig, generateCsv, download, ColumnHeader } from 'export-to-csv'

export function csvHeadersFromTable<T>(table: Table<T>): ColumnHeader[] {
  return table.getAllLeafColumns().map((col) => ({
    key: col.id,
    displayLabel: col.columnDef.header?.toString() || '',
  }))
}

export function downloadTableCsv<T>({
  table,
  headers,
  filename = 'export',
}: {
  table: Table<T>
  headers: ColumnHeader[]
  filename?: string
}) {
  const csvConfig = mkConfig({ columnHeaders: headers, filename })

  const data = table.getRowModel().rows.map((r) =>
    table.getAllLeafColumns().reduce(
      (acc, col) => ({
        ...acc,
        [col.id]: r.getValue(col.id),
      }),
      {},
    ),
  )
  const csv = generateCsv(csvConfig)(data)
  download(csvConfig)(csv)
}
