import { rangeInTimeOfDay, secondsToTimeOfDay } from '@utils/time'

interface Options {
  clock: '12' | '24'
}

export const formatTime = (
  timeInSeconds: number | undefined | null,
  referenceDate: DateTime,
  timeZone: string,
  options: Options = { clock: '12' },
) => {
  if (timeInSeconds === undefined) return ''
  if (timeInSeconds === null) return ''

  return secondsToTimeOfDay(timeInSeconds, referenceDate, timeZone, options)
}

export const formatDuration = (
  b: { startAt?: number; endAt?: number } | undefined,
  referenceDate: DateTime,
  timeZone: string,
  options: Options = { clock: '12' },
) => {
  if (b === undefined) return ''
  if (b.startAt === undefined) return ''
  if (b.endAt === undefined) return ''

  return rangeInTimeOfDay(b.startAt, b.endAt, referenceDate, timeZone, options)
}
