import { useCallback } from 'react'
import { useModal } from '@hooks/useModal'
import { EditApprovalGroupForm } from '../components/EditApprovalGroupForm'
import { useTranslation } from 'react-i18next'

export const useEditApprovalGroup = () => {
  const modal = useModal()
  const { t } = useTranslation()

  const edit = useCallback(
    (approvalGroup: AdminApprovalGroup) => {
      const id = modal.form({
        title: t('features.approvalGroupsAdmin.editApprovalGroupTitle'),
        content: (
          <EditApprovalGroupForm
            onCancel={() => modal.close(id)}
            onSave={() => modal.close(id)}
            approvalGroup={approvalGroup}
          />
        ),
      })
    },
    [modal, t],
  )

  return { edit }
}
