import { FC } from 'react'
import { ColumnDroppableData, ReportTypeMap } from '../../../types'
import { useTranslation } from 'react-i18next'
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { Ghost } from './Ghost'
import { DroppableSlot } from './DroppableSlot'
import { labelKeyByColumnGroupId } from '../../../lib/labelKeyByColumnGroupId'
import { infoByColumnId } from '../../../lib/infoByColumnId'
import { Item } from './Item'

type ReportTypeColumnProps<RT extends keyof ReportTypeMap> = {
  reportType: RT
  id: ReportTypeMap[RT]['columnId']
  groupId: ReportTypeMap[RT]['groupId']
  onSelectDeselect: (columnId: ReportTypeMap[RT]['columnId']) => void
}

type ColumnProps = {
  selected: boolean
  droppable: boolean
  draggingEnabled: boolean
} & ReportTypeColumnProps<keyof ReportTypeMap>

export const Column: FC<ColumnProps> = ({
  id,
  onSelectDeselect,
  groupId,
  selected,
  droppable,
  draggingEnabled,
  reportType,
}) => {
  const { t } = useTranslation()

  const dndData = { type: 'column', columnId: id }

  const {
    setNodeRef: draggableRef,
    attributes,
    listeners,
    transform,
    isDragging,
    over,
  } = useDraggable({ id, data: dndData, disabled: !draggingEnabled })

  // Unfortunately there does not appear to be any way to pass a data type to dndkit
  const overData = over?.data.current as ColumnDroppableData | undefined

  const style = transform
    ? {
        transform: CSS.Translate.toString(transform),
      }
    : undefined

  const content = (
    <Item
      className="ml-8"
      onSelectDeselect={() => onSelectDeselect(id)}
      selected={selected}
      dragHandleProps={{ ...attributes, ...listeners }}
      draggingEnabled={draggingEnabled}
    >
      {t(infoByColumnId({ reportType, id }).labelKey) as string}
    </Item>
  )

  const ghostContent = (
    <Ghost removing={overData?.type === 'deselected-list'}>
      {t(labelKeyByColumnGroupId({ reportType, groupId }))}:{' '}
      {t(infoByColumnId({ reportType, id }).labelKey) as string}
    </Ghost>
  )

  const draggable = (
    <div ref={draggableRef} style={style}>
      {isDragging ? ghostContent : content}
    </div>
  )

  if (droppable) {
    return (
      <DroppableSlot data={dndData} className="my-2 py-1">
        {draggable}
      </DroppableSlot>
    )
  } else {
    return <div className="my-2 py-1">{draggable}</div>
  }
}
