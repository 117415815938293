import { Global } from './Global'
import { Settings } from './Settings'
import { TimeLogging } from './TimeLogging'

export const settingsRoutes = [
  {
    path: '/settings',
    element: <Settings />,
    children: [
      {
        path: 'global',
        element: <Global />,
      },
      {
        path: 'time-logging',
        element: <TimeLogging />,
      },
    ],
  },
]
