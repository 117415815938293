import i18n from 'lib/react18next'
import { secondsToTimestamp } from './time'

type TagTotals = Partial<Record<TaggedTime['tag'], number>>

const totalByTag = (taggedTime: TaggedTime[]) =>
  taggedTime?.reduce<TagTotals>(
    (memo, taggedTime) => ({
      ...memo,
      [taggedTime.tag]: (memo[taggedTime.tag] || 0) + taggedTime.duration,
    }),
    {},
  )

export const createTotalByTagRenderObj = (
  taggedTime: TaggedTime[],
): Record<string, string> => {
  const totals = totalByTag(taggedTime)

  return Object.entries(totals).reduce<Record<string, string>>(
    (acc, [tag, duration]) => {
      return {
        ...acc,
        [i18n.t(`timeTags.${tag as TaggedTime['tag']}`)]:
          secondsToTimestamp(duration),
      }
    },
    {},
  )
}
