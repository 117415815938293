import {
  faChevronDown,
  faChevronUp,
  faDownload,
  faEye,
  faEyeSlash,
  faFileCsv,
  faFilter,
  faFilterCircleXmark,
  faTableColumns,
  faInfoCircle,
  faSigma,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, MenuButton } from 'components/buttons'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from '@lib/tailwind-merge'
import { FilterDetails } from '../FilterDetails'
import { DateRangeFilterValue, ReportTypeMap } from '../../types'
import { dateRangeForRelativeValue } from '@utils/dateRangeForRelativeValue'
import { Tooltip } from '@lib/tooltip'
import { useLocalizedDateRange } from '@hooks/useDateTimeLocalization'

type Props<RT extends keyof ReportTypeMap> = {
  filtersShown: boolean
  dateFilter?: DateRangeFilterValue
  onClearAllFiltersClick: () => void
  onFilterShownClick: () => void
  onColumnsSidebarClick: () => void
  totalHours?: number
  totalRows?: number
  csvDownloadURL: string
  filters: ReportTypeMap[RT]['filters']
  subtitle: string
  reportType: RT
  totalRowSupported: boolean
  totalRowShown: boolean
  onTotalRowShownClick: () => void
}

export const SubHeader = <RT extends keyof ReportTypeMap>({
  filtersShown,
  dateFilter,
  onClearAllFiltersClick,
  onFilterShownClick,
  onColumnsSidebarClick,
  subtitle,
  totalRows,
  csvDownloadURL,
  filters,
  reportType,
  totalRowSupported,
  totalRowShown,
  onTotalRowShownClick,
}: Props<RT>) => {
  const { t } = useTranslation()
  const renderDateRange = useLocalizedDateRange()

  const activeFilterCount = useMemo(
    () => Object.values(filters).filter((v) => v).length,
    [filters],
  )

  const renderTitle = () => {
    let startDate, endDate

    if (dateFilter) {
      if (dateFilter?.relativeDateRange) {
        ;({ startDate, endDate } = dateRangeForRelativeValue(
          dateFilter.relativeDateRange,
        ))
      } else {
        ;({ start: startDate, end: endDate } = dateFilter)
      }
    }

    const dateRange = renderDateRange(startDate, endDate, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })

    return (
      <div className="flex items-center gap-2 text-neutral-900 font-medium">
        <h2 className="text-lg">{dateRange}</h2>
        {dateFilter?.relativeDateRange && (
          <div
            className="bg-neutral-150 border border-neutral-200 rounded-sm px-1 text-xs flex items-center gap-1 cursor-help"
            data-tooltip-id="relative-date-description"
          >
            {t(
              `relativeDateRangePicker.values.${dateFilter.relativeDateRange}`,
            )}

            <FontAwesomeIcon icon={faInfoCircle} />
            <Tooltip id="relative-date-description" delayShow={500}>
              {t(
                'relativeDateRangePicker.thisReportUsesADateRangeRelativeToToday',
              )}
            </Tooltip>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="flex flex-row items-center mb-4">
      <div className="flex flex-col flex-grow">
        {renderTitle()}

        <span className="text-xs text-neutral-600">{subtitle}</span>
      </div>
      <div className="flex flex-row gap-2">
        <MenuButton
          button={({ disabled, menuHidden, toggleMenuHidden }) => (
            <Button
              disabled={disabled}
              variant={filtersShown ? 'outlined-active' : 'outlined'}
              onClick={toggleMenuHidden}
              className="px-3"
            >
              <FontAwesomeIcon icon={faFilter} />
              {activeFilterCount > 0 && (
                <span
                  className={twMerge(
                    'border rounded-lg text-[10px] leading-4 align-middle px-2 h-4 border-neutral-600 items-center flex',
                    filtersShown && 'border-primary-700',
                  )}
                >
                  {activeFilterCount}
                </span>
              )}
              <FontAwesomeIcon
                icon={menuHidden ? faChevronDown : faChevronUp}
              />
            </Button>
          )}
          beforeMenuItems={
            activeFilterCount > 0 && (
              <FilterDetails filters={filters} reportType={reportType} />
            )
          }
          menuItems={[
            {
              icon: filtersShown ? faEyeSlash : faEye,
              label: filtersShown
                ? t('features.reporting.hideFilters')
                : t('features.reporting.showFilters'),
              onClick: () => onFilterShownClick(),
            },
            {
              icon: faFilterCircleXmark,
              label: t('features.reporting.clearAllFilters'),
              onClick: () => onClearAllFiltersClick(),
              disabled: activeFilterCount < 1,
            },
          ]}
        />

        {totalRowSupported && (
          <Button
            icon={faSigma}
            variant={totalRowShown ? 'outlined-active' : 'outlined'}
            onClick={onTotalRowShownClick}
          />
        )}

        <Button
          icon={faTableColumns}
          variant="outlined"
          onClick={onColumnsSidebarClick}
        />

        <MenuButton
          buttonVariant="outlined"
          menuItems={[
            {
              icon: faFileCsv,
              label: 'CSV',
              disabled: totalRows === undefined || totalRows > 1_000_000,
              href: csvDownloadURL,
            },
          ]}
        >
          <FontAwesomeIcon icon={faDownload} />
          {t('common.download')}
        </MenuButton>
      </div>
    </div>
  )
}
