import { faClock } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { secondsToHHMM } from '@utils/time'
import { FC } from 'react'
import { Tooltip } from 'react-tooltip'
import { Total } from '../Total'
import { TaggedTimeTooltipTable } from './TaggedTimeTooltipTable'

interface Props {
  timeCard: TimeCard
  totalAllocatedInSeconds: number
}

export const SubmittedBody: FC<Props> = ({
  timeCard,
  totalAllocatedInSeconds,
}) => (
  <div className="flex items-center justify-center text-center grow h-20">
    <div>
      <Total
        seconds={totalAllocatedInSeconds}
        className="text-lg font-medium"
      />

      {timeCard.totalTaggedSeconds > 0 && (
        <div
          className="mt-1 flex gap-1 items-center justify-center text-primary-800 text-xs font-semibold"
          data-tooltip-id={timeCard.id.toString()}
          title="" // Prevent Time Card title tooltip from displaying when tagged time total is hovered
        >
          <FontAwesomeIcon icon={faClock} />{' '}
          {secondsToHHMM(timeCard.totalTaggedSeconds)}
        </div>
      )}
    </div>

    {/* NOTE: Setting a fixed width on the tooltip ensures it's uniform across cards
              and also fixes an issue where the tooltip will be positioned in the wrong place
              when loading the breakdown initially because its contents will change drastically
              (from a spinner to a breakdown table) —JR */}
    <Tooltip id={timeCard.id.toString()} place="bottom" className="w-36">
      <TaggedTimeTooltipTable timeCard={timeCard} />
    </Tooltip>
  </div>
)
