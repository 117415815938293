import { searchTasks, searchExploreTasks } from '@lib/api'
import { searchTasksKeys } from '@lib/keys'
import { useInfiniteQuery } from '@tanstack/react-query'

export const useSearchTasks = ({
  enabled = true,
  onCountChange = () => undefined,
  ...options
}: SearchTaskOptions & {
  enabled?: boolean
  onCountChange?: (count?: number) => void
}) => {
  return useInfiniteQuery({
    queryKey: searchTasksKeys.search(options),
    queryFn: ({ pageParam }) => {
      onCountChange(undefined)
      return searchTasks({ ...options, after: pageParam as string | undefined })
    },
    getNextPageParam: (lastPage) => lastPage.meta?.nextPage,
    getPreviousPageParam: (lastPage) => lastPage.meta?.previousPage,
    enabled,
    onSuccess: (data) => onCountChange(data.pages.at(0)?.meta?.total ?? 0),
  })
}

export const useSearchExploreTasks = ({
  enabled = true,
  onCountChange = () => undefined,
  ...options
}: SearchExploreTaskOptions & {
  enabled?: boolean
  onCountChange?: (count?: number) => void
}) => {
  return useInfiniteQuery({
    queryKey: searchTasksKeys.searchExplore(options),
    queryFn: ({ pageParam }) => {
      onCountChange(undefined)
      return searchExploreTasks({
        ...options,
        after: pageParam as string | undefined,
      })
    },
    getNextPageParam: (lastPage) => lastPage.meta?.nextPage,
    getPreviousPageParam: (lastPage) => lastPage.meta?.previousPage,
    enabled,
    onSuccess: (data) => onCountChange(data.pages.at(0)?.meta?.total ?? 0),
  })
}
