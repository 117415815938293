import { FC } from 'react'
import { twMerge } from '@lib/tailwind-merge'

interface Props {
  contents: string
  className?: string
}

export const Display: FC<Props> = (props: Props) => (
  <div className="flex flex-row">
    <div
      className={twMerge(
        'px-2 text-sm leading-none select-none text-neutral-900',
        props.className,
      )}
    >
      {props.contents}
    </div>
  </div>
)
