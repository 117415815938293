import { FC, useMemo } from 'react'
import { secondsToHHMM } from '@utils/time'
import { useTranslation } from 'react-i18next'
import { SelectionBar } from 'components/indicators/selection-bar'
import { useLanguage } from '@hooks/useLanguage'

interface Props {
  selectedApprovals: Approval[]
}

export const ApprovalSelectionBar: FC<Props> = ({ selectedApprovals }) => {
  const language = useLanguage()
  const { t } = useTranslation()
  const totalDomainSeconds = useMemo(
    () =>
      selectedApprovals.reduce(
        (total, approval) => total + approval.totalDomainSecondsLogged,
        0,
      ),
    [selectedApprovals],
  )

  if (totalDomainSeconds <= 0) return null

  return (
    <SelectionBar
      data={[
        {
          value: selectedApprovals.length.toLocaleString(language),
          label: t('features.approvals.selected'),
        },
        {
          value: secondsToHHMM(totalDomainSeconds, { language }),
          label: t('features.approvals.timeAllocated'),
        },
        /* TODO: OT section */
      ]}
      design="new"
    />
  )
}
