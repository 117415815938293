import {
  faChevronRight,
  faCalendar,
  faChevronLeft,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import { FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DateRangePicker,
  Props as DateRangePickerProps,
} from './DateRangePicker'
import { twMerge } from '@lib/tailwind-merge'
import { dateRangeForRelativeValue } from '@utils/dateRangeForRelativeValue'
import { useLocalizedDateRange } from '@hooks/useDateTimeLocalization'

const Label = ({ children }: { children: ReactNode }) => (
  <span className="text-xs bg-neutral-200 rounded px-1 font-semibold">
    {children}
  </span>
)

const Item = ({
  children,
  onClick,
  selected = false,
}: {
  children: ReactNode
  onClick: () => void
  selected?: boolean
}) => {
  return (
    <div
      className={twMerge(
        'py-3 px-5 border-b border-neutral-200 last-of-type:border-b-0 flex items-center justify-between hover:bg-primary-100',
        selected && 'bg-primary-100',
      )}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

interface Props extends DateRangePickerProps {
  relativeValues: RelativeDateRangeValue[]
  selectedRelativeValue?: RelativeDateRangeValue
  onChange: (value: {
    startDate?: DateTime
    endDate?: DateTime
    relativeDateRangeValue?: RelativeDateRangeValue
  }) => void
}

export const RelativeDateRangePicker: FC<Props> = ({
  relativeValues,
  selectedRelativeValue,
  startDate,
  endDate,
  onChange,
  ...dateRangeProps
}) => {
  const { t } = useTranslation()
  const renderDateRange = useLocalizedDateRange()
  const [useCustomDate, setUseCustomDate] = useState(
    Boolean(startDate || endDate),
  )

  const renderDateRangeForValue = (value: RelativeDateRangeValue) => {
    const { startDate, endDate } = dateRangeForRelativeValue(value)

    return renderDateRange(startDate, endDate, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
  }

  if (useCustomDate) {
    return (
      <div className="p-4">
        <div
          className="text-neutral-900 font-semibold mb-4 flex items-center gap-2"
          onClick={() => setUseCustomDate(false)}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
          {t('relativeDateRangePicker.selectCustomDate')}
        </div>

        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          {...dateRangeProps}
        />
      </div>
    )
  }

  // Relative value picker
  return (
    // Min width matches DateRangePicker
    <div className="min-w-[280px] text-neutral-900 text-sm">
      <Item onClick={() => setUseCustomDate(true)}>
        <div className="flex gap-2.5 items-center">
          <FontAwesomeIcon icon={faCalendar} className="text-neutral-600" />
          {t('common.custom')}
        </div>

        <FontAwesomeIcon icon={faChevronRight} />
      </Item>

      <Item
        onClick={() => onChange({})}
        selected={
          startDate === undefined &&
          endDate === undefined &&
          selectedRelativeValue === undefined
        }
      >
        <Label>{t('common.allTimeDateRange')}</Label>
      </Item>

      {relativeValues.map((value) => (
        <Item
          key={value}
          onClick={() => onChange({ relativeDateRangeValue: value })}
          selected={selectedRelativeValue === value}
        >
          <div className="flex gap-2.5 items-center">
            <Label>{t(`relativeDateRangePicker.values.${value}`)}</Label>

            {renderDateRangeForValue(value)}
          </div>
        </Item>
      ))}
    </div>
  )
}
