import { FC } from 'react'
import { statusColors, statusIcons } from 'utils/statuses'
import { Label } from 'components/indicators/label'
import { useTranslation } from 'react-i18next'

interface Props {
  status: Status
  borderShade?: number
  className?: string
}

export const StatusBadge: FC<Props> = ({
  status,
  borderShade = 300,
  className,
}) => {
  const { t } = useTranslation()
  const color = statusColors[status]
  const icon = statusIcons[status]

  return (
    <Label
      color={color}
      icon={icon}
      borderShade={borderShade}
      className={className}
    >
      {t(`common.statuses.${status}`)}
    </Label>
  )
}
