import { Status } from 'types'

export const approvalStatus = (approval: Approval) => {
  // NOTE: We don't consider Approvals to be "sent",
  // so Approved approvals include ones that are attached to sent TCs
  if (approval.approved) return Status.Approved
  if (approval.timeCard.submitted) return Status.Submitted

  return Status.Open
}
