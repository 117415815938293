import { FC } from 'react'
import { EntityPicker, EntityItem } from '../entity-picker'
import { usePipelineSteps } from 'hooks/usePipelineSteps'

interface Props {
  columnNameTranslationKey: TranslationKey
  onChange: (ids: Set<number>) => void
  selectedIds: Set<number>
}
export const PipelineStepPicker: FC<Props> = ({
  columnNameTranslationKey,
  onChange,
  selectedIds,
}) => {
  return (
    <EntityPicker
      columnNameTranslationKey={columnNameTranslationKey}
      filter={(pipelineStep, search) =>
        pipelineStep.code.toLowerCase().includes(search.toLowerCase()) &&
        !selectedIds.has(pipelineStep.id)
      }
      placeholder="Search Pipeline Steps..."
      isSelected={(pipelineStep, selectedIds) =>
        selectedIds.has(pipelineStep.id)
      }
      renderNoItemSelected={() => <span>None selected</span>}
      renderSearchItem={(pipelineStep) => (
        <EntityItem
          key={pipelineStep.id}
          onClick={() => onChange(new Set(selectedIds).add(pipelineStep.id))}
          primaryText={pipelineStep.code}
        />
      )}
      renderSelectedItem={(pipelineStep) => (
        <EntityItem
          key={pipelineStep.id}
          onClick={() => {
            const s = new Set(selectedIds)
            s.delete(pipelineStep.id)
            onChange(s)
          }}
          primaryText={pipelineStep.code}
          removable={true}
        />
      )}
      selectedIds={selectedIds}
      useQuery={usePipelineSteps}
    />
  )
}
