import { col } from '@utils/styles'

interface Props {
  children?: React.ReactNode
  date: DateTime
}

function GridColumn(props: Props) {
  return <div className={`${col(props.date)} contents`}>{props.children}</div>
}

export default GridColumn
