import { ReactNode } from 'react'
import { toLowerCaseDay } from '@utils/dates'
import { abbreviateDayOfWeek } from '@utils/abbreviateDayOfWeek'
import { twMerge } from 'tailwind-merge'

function EditableCell(props: {
  children?: React.ReactNode
  editable?: boolean
  isError?: boolean
}) {
  return (
    <div
      className={twMerge(
        'flex items-center justify-end grow h-[30px]',
        props.editable && 'bg-neutral-150',
        props.isError && 'bg-error-100',
      )}
    >
      {props.children}
    </div>
  )
}

type GridCellProps = {
  children?: ReactNode
  rowIndex: number
  classNames?: string
  editable?: boolean
  isEditing?: boolean
  isError?: boolean
  isFocused?: boolean
  onMouseMove?: React.MouseEventHandler
  onMouseLeave?: React.MouseEventHandler
  onClick?: React.MouseEventHandler
  onDoubleClick?: React.MouseEventHandler
  testId?: string
} & ({ date: DateTime } | { day: DayOfWeek })

export function GridCell(props: GridCellProps) {
  const style = { gridRowStart: props.rowIndex + 1 }

  return (
    <div
      className={twMerge(
        'flex transition-shadow p-1 border border-neutral-300 -mt-[1px] -ml-[1px]',
        `col-start-${
          'date' in props
            ? toLowerCaseDay(props.date)
            : abbreviateDayOfWeek(props.day)
        }`,
        props.isFocused && 'border-primary-600 border-2 p-[3px] z-10',
        props.isEditing && 'shadow-md shadow-neutral-400',
        props.classNames,
      )}
      data-testid={props.testId}
      style={style}
      onMouseMove={props.onMouseMove}
      onMouseLeave={props.onMouseLeave}
      onClick={props.onClick}
      onDoubleClick={props.onDoubleClick}
    >
      <EditableCell editable={props.editable} isError={props.isError}>
        {props.children}
      </EditableCell>
    </div>
  )
}
