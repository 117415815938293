import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faFileCsv } from '@fortawesome/pro-regular-svg-icons'
import { MenuButton } from 'components/buttons/MenuButton'
import { csvHeadersFromTable, downloadTableCsv } from '@utils/tableCsvExport'
import { ColumnHeader } from 'export-to-csv'
import { Table } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

interface Props<T> {
  table: Table<T>
  headers?: ColumnHeader[]
  filename?: string
}

export function DownloadCsvButton<T>({ table, headers, filename }: Props<T>) {
  const { t } = useTranslation()
  return (
    <MenuButton
      className="text-sm"
      buttonVariant="outlined"
      menuItems={[
        {
          icon: faFileCsv,
          label: 'CSV',
          onClick: () => {
            downloadTableCsv({
              table,
              headers: headers || csvHeadersFromTable(table),
              filename,
            })
          },
        },
      ]}
    >
      <FontAwesomeIcon icon={faDownload} />
      {t('common.download')}
    </MenuButton>
  )
}
