import { ComponentProps } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'

type Props = ComponentProps<typeof ReactTooltip>

/**
 * In order to override react-tooltip's styling, the docs suggest making use of
 * css specificity (achieved by wrapping each tooltip in a component with its
 * on class).  I felt that would introduce some unnecessary each time we wanted
 * to make use of the tooltip, so I instead to opt for !important styling
 * for the few things we need to adjust
 *
 * docs: https://react-tooltip.com/docs/examples/styling
 *
 * - AF 3/17/22
 */
export const Tooltip = (props: Props) => (
  <ReactTooltip
    {...props}
    className="!bg-neutral-900 !text-white !px-4 !py-2 !text-xs !leading-5 z-50 !rounded"
  />
)
