import { Field } from 'components/form/field'
import { TimeInput } from 'components/form/time-input/TimeInput'
import { FC } from 'react'
import { DialTimePicker } from './DialTimePicker'
import { useTranslation } from 'react-i18next'

export interface Props {
  assumePm?: boolean
  displayMode?: '12' | '24'
  errors?: string[]
  hidden?: boolean
  clockHour: number | null
  clockMinute: number | null
  adjusting: 'start' | 'end' | 'duration'
  onChange: (clockHour: number | null, clockMinute: number | null) => void
  fieldPrefix?: string
  autoFocus?: boolean
  tabIndex?: number
}

export const Adjuster: FC<Props> = ({
  assumePm = false,
  displayMode = '12',
  errors = [],
  hidden = false,
  clockHour,
  clockMinute,
  adjusting,
  onChange,
  fieldPrefix,
  autoFocus = false,
  tabIndex,
}) => {
  const { t } = useTranslation()

  if (hidden) return null

  const getDefaultTimePickerValue = (
    value: number | null,
    defaultValue: number,
  ) => {
    if (value === null) return defaultValue
    if (isNaN(value)) return defaultValue

    return value
  }

  const getBaseLabel = () => {
    if (adjusting === 'start') return t('common.startTime')
    if (adjusting === 'end') return t('common.endTime')

    return t('components.timeWidget.duration')
  }

  const getLabel = () => {
    return [fieldPrefix, getBaseLabel()].filter(Boolean).join(' ')
  }

  return (
    <div className="flex flex-col justify-between flex-1">
      <Field
        label={getLabel()}
        errors={errors}
        htmlFor={`${adjusting}-time-input`}
      >
        {(id) => (
          <TimeInput
            assumePm={assumePm}
            displayMode={displayMode}
            id={id}
            hour={clockHour}
            minute={clockMinute}
            onChange={onChange}
            autoFocus={autoFocus}
            tabIndex={tabIndex}
          />
        )}
      </Field>
      <DialTimePicker
        hour={getDefaultTimePickerValue(clockHour, 12)}
        minute={getDefaultTimePickerValue(clockMinute, 0)}
        mode={displayMode}
        onChange={onChange}
      />
    </div>
  )
}
