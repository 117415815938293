import { useState, useRef, useMemo } from 'react'
import { DropdownButton } from 'components/buttons'
import { useEmployeeName } from '@features/time-logging/hooks/useEmployeeName'
import { useUserId } from '@features/time-logging/hooks/useUserId'
import { useOnClickOutside } from '@hooks/useOnClickOutside'
import { Spinner } from 'components/loaders'
import { UserPickerList } from './UserPickerList'
import { useUsers } from '@hooks/useUsers'
import { useAuth } from '@hooks/useAuth'

export const UserPicker = () => {
  const ref = useRef(null)
  const userId = useUserId()
  const { isSelf } = useEmployeeName()
  const [selectorHidden, setSelectorHidden] = useState(true)
  const { data: users } = useUsers()
  const { user: signedInUser } = useAuth()

  useOnClickOutside(ref, () => setSelectorHidden(true))

  const accessibleUsers = useMemo(() => {
    if (!users || !signedInUser) return []

    // Super admins can access all users
    if (signedInUser.superAdmin) return users

    return users.filter(
      (user) =>
        // Users can log time for themselves
        user.id === signedInUser.id ||
        // Users can log time for users who delegated that responsibility to them
        signedInUser.timeLoggingDelegators.some(
          (delegator) => delegator.id === user.id,
        ) ||
        // Time Log Admins can log time for users that are, or have ever been,
        // associated with the facilities they administer
        user.associatedFacilityIds.some((id) =>
          signedInUser?.administeredFacilities.some(
            (facility) => facility.id === id,
          ),
        ),
    )
  }, [users, signedInUser])

  const selectedUser = useMemo(
    () => accessibleUsers.find((u) => u.id === userId),
    [accessibleUsers, userId],
  )

  const selectedUserName = useMemo(() => {
    // Optimize for scenario where user is viewing themself
    // (no need to wait for /users request to resolve)
    if (isSelf && signedInUser) return signedInUser.name

    // But if the user is not viewing themself,
    // we should fetch their name from the /users request
    // (this will be the same name the user sees in the user selector)
    if (selectedUser) return selectedUser.name

    // If somehow we still do not have a name, simply respond
    // with an empty string. This happens while fetching data,
    // and may happen if a user directly modifies the URL (or a bug, of course!)
    return ''
  }, [isSelf, signedInUser, selectedUser])

  // Display a spinner in place of the selected user name/user switcher
  // while loading the list of Users, unless the user is viewing themself,
  // in which case we can display their name immediately, and
  // if they end up being able to access other users, we'll simply add
  // the dropdown caret once the /users request resolves.
  if (!isSelf && users === undefined) return <Spinner />

  return (
    <div className="relative z-40 flex flex-col items-start -ml-2.5" ref={ref}>
      <DropdownButton
        readonly={accessibleUsers.length <= 1}
        label={selectedUserName}
        onClick={() => setSelectorHidden(false)}
        expanded={!selectorHidden}
      />
      {!selectorHidden && (
        <UserPickerList
          users={accessibleUsers}
          onSelection={() => setSelectorHidden(true)}
        />
      )}
    </div>
  )
}
