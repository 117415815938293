import { DateTime } from 'luxon'
import { SelectedById } from '../types'

type Data = { workdayWorkerId: number; date: DateTime }

export const buildLockPayloadData = (selected: SelectedById): Data[] => {
  return Object.keys(selected).flatMap((workdayWorkerIdString) => {
    const workdayWorkerId = Number(workdayWorkerIdString)
    const { dates } = selected[workdayWorkerId]

    return Array.from(dates).map((epoch) => ({
      workdayWorkerId,
      date: DateTime.fromMillis(epoch),
    }))
  })
}
