import { Button } from 'components/buttons'
import { FC, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type BaseProps = {
  subtitle: string
  title: string
  className?: string
}

type Props =
  | BaseProps
  | (BaseProps & { buttonContent: ReactNode; onButtonClick: () => void })

export const SectionHeader: FC<Props> = ({
  className,
  subtitle,
  title,
  ...props
}) => {
  return (
    <div className={twMerge('flex flex-col', className)}>
      <h2 className={'text-lg text-neutral-900'}>{title}</h2>
      <p className="text-neutral-600 text-xs max-w-[388px]">{subtitle}</p>
      {'buttonContent' in props && (
        <Button
          variant="outlined"
          className="max-w-fit mt-2 text-sm"
          onClick={props.onButtonClick}
        >
          {props.buttonContent}
        </Button>
      )}
    </div>
  )
}
