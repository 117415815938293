import { FC, useCallback, useState } from 'react'
import { useCreateProject } from '../hooks/useProjectManagement'
import { useTranslation } from 'react-i18next'
import { toast } from '@lib/toasts'
import { Label } from 'components/form/label'
import { Input } from 'components/form/input'
import { UserSelection } from '@features/user-selection'
import { useSet } from '@hooks/useSet'
import { ModalForm } from 'components/notifications'

interface Props {
  onCancel: () => void
  onSave: () => void
}

export const NewProjectForm: FC<Props> = ({ onCancel, onSave }) => {
  const { t } = useTranslation()
  const creatProject = useCreateProject()
  const [projectName, setProjectName] = useState<string>('')
  const {
    set: approverUserIds,
    add: addApproverUserId,
    remove: removeApproverUserId,
  } = useSet<number>(new Set())

  const save = useCallback(
    async (groupName: string, approverUserIds: number[]) => {
      try {
        await creatProject.mutateAsync({
          name: groupName,
          approverUserIds: approverUserIds,
        })

        onSave()

        toast({
          title: t('features.projectsAdmin.projectCreated'),
          variant: 'success',
          content: t('features.projectsAdmin.successfullyCreatedProject', {
            name: groupName,
          }),
        })
      } catch (error) {
        toast({
          title: t('features.projectsAdmin.unableToCreateProject'),
          variant: 'error',
          content: t('features.projectsAdmin.failedToCreateProject'),
        })
      }
    },
    [onSave, creatProject, t],
  )

  const formFields = (
    <>
      <div>
        <Label>{t('features.projectsAdmin.projectName')}</Label>
        <Input
          value={projectName}
          onChange={(event) => setProjectName(event.target.value)}
          required
        />
      </div>
      <UserSelection
        label={t('common.approvers')}
        placeholder={t('common.searchByName')}
        selectedUserIds={approverUserIds}
        onSelect={(user) => addApproverUserId(user.id)}
        onRemove={(user) => removeApproverUserId(user.id)}
      />
    </>
  )

  return (
    <ModalForm
      fields={formFields}
      onCancel={onCancel}
      onSave={() => void save(projectName, Array.from(approverUserIds))}
      isSaving={creatProject.isLoading}
    />
  )
}
