import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'

type Props = Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>,
  'children'
> & {
  children: ReactNode | ((inView: boolean) => ReactNode)
  estimatedHeight?: number // reduces "scroll jank" as children render
  virtualized?: boolean
}

export const Tr: FC<Props> = ({
  children,
  estimatedHeight = 30,
  virtualized = false,
  ...props
}) => {
  const { ref, inView } = useInView()

  const hideChildren = virtualized && !inView

  return (
    <tr
      {...props}
      ref={ref}
      style={hideChildren ? { height: estimatedHeight } : {}}
    >
      {hideChildren
        ? null
        : typeof children === 'function'
        ? children(inView)
        : children}
    </tr>
  )
}
