import { FC, InputHTMLAttributes } from 'react'
import { twMerge } from '@lib/tailwind-merge'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean
}

export const Checkbox: FC<Props> = ({
  className: overrideClassNames,
  indeterminate = false,
  ...props
}) => {
  return (
    <div
      className={twMerge(
        'flex items-center flex-shrink-0 w-5 h-5 border rounded max-h-fit p-[1px] border-neutral-400 max-w-fit',
        overrideClassNames,
      )}
    >
      <input
        {...props}
        ref={(input) => {
          if (!input) return

          input.indeterminate = indeterminate
        }}
        type="checkbox"
        className="border-none rounded cursor-pointer form-checkbox checked:bg-primary-600 disabled:cursor-not-allowed indeterminate:bg-primary-600"
      />
    </div>
  )
}
