import { getSettings, saveSettings } from '@lib/api'
import { settingsKeys } from '@lib/keys'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuth } from './useAuth'

export const useSettings = () => {
  const userId = useAuth().user?.id

  if (userId === undefined)
    throw new Error(
      'Unreable.  useSettings can only be used within the portion of the app that is authenticated',
    )

  return useQuery(settingsKeys.global(userId), () => getSettings(userId))
}

export const useSaveSettings = () => {
  const userId = useAuth().user?.id
  const queryClient = useQueryClient()

  return useMutation(
    (settings: Settings) => {
      if (userId === undefined)
        throw new Error(
          'Unreachable.  useSaveSettings can only be used with the portion of the app that is authenticated',
        )

      return saveSettings(userId, settings)
    },
    {
      onSuccess: ({ data }) => {
        if (userId !== undefined) {
          queryClient.setQueryData(settingsKeys.global(userId), data)
        }
      },
    },
  )
}
