import { faFolderTree, faListCheck } from '@fortawesome/pro-regular-svg-icons'
import { ButtonTabs } from 'components/buttons/ButtonTabs'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  activeTab: 'tasks' | 'explore'
  exploreCount?: number
  onTabChange: (tab: Props['activeTab']) => void
  taskCount?: number
}

export const Tabs: FC<Props> = ({
  activeTab,
  exploreCount,
  onTabChange,
  taskCount,
}) => {
  const { t } = useTranslation()

  return (
    <ButtonTabs
      className="px-9"
      tabs={[
        {
          selected: activeTab === 'explore',
          icon: faFolderTree,
          count: exploreCount,
          loading: exploreCount === undefined,
          label: t('common.explore').toString(),
          onClick: () => onTabChange('explore'),
        },
        {
          selected: activeTab === 'tasks',
          icon: faListCheck,
          count: taskCount,
          loading: taskCount === undefined,
          label: t('features.timeLogging.taskSelection.tasks').toString(),
          onClick: () => onTabChange('tasks'),
        },
      ]}
    />
  )
}
