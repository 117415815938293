import { FC } from 'react'
import {
  LeftHeader,
  SideColumn,
  SummaryCell,
  SummaryRow,
  Table,
  WorkDayCell,
} from 'components/time-entry-table'
import { abbreviateDayOfWeek } from '@utils/abbreviateDayOfWeek'
import { daysOfWeek } from '../../../utils/daysOfWeek'
import { useManagedGroupOutletContext } from '../../ManagedGroup'
import { useDisplayTwentyForHourTime } from '../../../hooks/useDisplayTwentyForHourTime'
import { convertTwentyFourHourTimeToSeconds } from '../../../utils/convertTwentyFourHourTimeToSeconds'
import { padDigit } from '@utils/padDigit'
import { EnrichedBreak } from '../../../types'
import { useTranslation } from 'react-i18next'

interface Props {
  breaks: number[]
}

export const WorkWeekTable: FC<Props> = ({ breaks }) => {
  const { t } = useTranslation()
  const { adminManagedGroup, setBreak, setDayStartEnd, submitted, totals } =
    useManagedGroupOutletContext()
  const displayTwentyFourHourTime = useDisplayTwentyForHourTime()
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return (
    <>
      <Table className="mt-4">
        <SideColumn side="left">
          <LeftHeader
            highlight={false}
            labels={[t('common.start')]}
            rowIndex={1}
          />
          {breaks.map((breakNumber) => (
            <LeftHeader
              key={breakNumber}
              highlight={false}
              labels={['Break', padDigit(breakNumber)]}
              rowIndex={breakNumber + 1}
            />
          ))}
          <LeftHeader
            highlight={false}
            labels={[t('common.end')]}
            rowIndex={breaks.length + 2}
          />
        </SideColumn>
        {daysOfWeek.map((day) => {
          const dayTemplate = adminManagedGroup.weekTemplate.dayTemplates[day]

          return (
            <div
              className={`grid-in-${abbreviateDayOfWeek(day)} contents`}
              key={day}
            >
              <WorkDayCell
                rowIndex={0}
                day={day}
                editable={dayTemplate.enabled}
                formattedContents={displayTwentyFourHourTime({
                  time: dayTemplate.start,
                })}
                isError={
                  submitted &&
                  (dayTemplate.errors.includes('missing-start') ||
                    dayTemplate.errors.includes('invalid-start-end'))
                }
                variant="time"
                startAtSecondsSinceMidnight={
                  dayTemplate.start
                    ? convertTwentyFourHourTimeToSeconds(dayTemplate.start)
                    : null
                }
                endAtSecondsSinceMidnight={
                  dayTemplate.end
                    ? convertTwentyFourHourTimeToSeconds(dayTemplate.end)
                    : null
                }
                onSubmit={(start: number | null, end: number | null) =>
                  setDayStartEnd(day, { start, end })
                }
                autoFocusedTimeWidgetAdjuster="start"
                timeZone={timeZone}
                testId="work-day-start"
              />
              {breaks.map((breakNumber) => {
                const dayBreak: EnrichedBreak | undefined =
                  dayTemplate.breaks[breakNumber - 1]

                const errors = dayBreak?.errors || []

                return (
                  <WorkDayCell
                    key={breakNumber}
                    rowIndex={breakNumber}
                    day={day}
                    editable={dayTemplate.enabled}
                    formattedContents={displayTwentyFourHourTime(dayBreak)}
                    isError={submitted && errors.length > 0}
                    variant="time-and-duration"
                    startAtSecondsSinceMidnight={
                      dayBreak?.start
                        ? convertTwentyFourHourTimeToSeconds(dayBreak.start)
                        : null
                    }
                    endAtSecondsSinceMidnight={
                      dayBreak?.end
                        ? convertTwentyFourHourTimeToSeconds(dayBreak.end)
                        : null
                    }
                    onSubmit={(start: number | null, end: number | null) => {
                      setBreak(day, breakNumber, { start, end })
                    }}
                    autoFocusedTimeWidgetAdjuster="start"
                    timeZone={timeZone}
                    testId="work-day-start"
                    isMeal={false}
                    isMealByDefault={false}
                    showIsMealCheckbox={false}
                  />
                )
              })}
              <WorkDayCell
                rowIndex={breaks.length + 1}
                day={day}
                editable={dayTemplate.enabled}
                formattedContents={displayTwentyFourHourTime({
                  time: dayTemplate.end,
                })}
                isError={
                  submitted &&
                  (dayTemplate.errors.includes('missing-end') ||
                    dayTemplate.errors.includes('invalid-start-end'))
                }
                variant="time"
                startAtSecondsSinceMidnight={
                  dayTemplate.start
                    ? convertTwentyFourHourTimeToSeconds(dayTemplate.start)
                    : null
                }
                endAtSecondsSinceMidnight={
                  dayTemplate.end
                    ? convertTwentyFourHourTimeToSeconds(dayTemplate.end)
                    : null
                }
                onSubmit={(start: number | null, end: number | null) =>
                  setDayStartEnd(day, { start, end })
                }
                autoFocusedTimeWidgetAdjuster="end"
                timeZone={timeZone}
                testId="work-day-start"
              />
            </div>
          )
        })}
      </Table>
      <SummaryRow>
        <SideColumn side="left">
          <LeftHeader
            highlight={false}
            labels={[t('features.adminManagedGroups.totalWorkWeekHours')]}
            rowIndex={1}
          />
        </SideColumn>
        {daysOfWeek.map((day, i) => (
          <SummaryCell
            key={day}
            gridClass={`grid-in-${abbreviateDayOfWeek(day)}`}
            total={
              adminManagedGroup.weekTemplate.dayTemplates[day].totals.workWeek
            }
            skipBorder={daysOfWeek.length == i + 1}
          />
        ))}
        <SummaryCell
          gridClass="grid-in-totalByTask"
          total={totals.workWeek}
          skipBorder={true}
        />
      </SummaryRow>
    </>
  )
}
