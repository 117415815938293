import { useCallback } from 'react'
import { useModal } from '@hooks/useModal'
import { LinkIntegrationProjectForm } from '../components/LinkIntegrationProjectForm'
import { useTranslation } from 'react-i18next'

export const useLinkIntegrationProject = () => {
  const modal = useModal()
  const { t } = useTranslation()

  const linkIntegrationProject = useCallback(
    (project: AdminProject, projectIntegration: ProjectIntegration) => {
      const id = modal.form({
        title: t('features.projectsAdmin.linkProjectTitle', {
          integrationName: projectIntegration.name,
          projectName: project.name,
        }),
        content: (
          <LinkIntegrationProjectForm
            onCancel={() => modal.close(id)}
            onSave={() => modal.close(id)}
            project={project}
            projectIntegration={projectIntegration}
          />
        ),
      })
    },
    [modal, t],
  )

  return { linkIntegrationProject }
}
