import { DateTime } from 'luxon'
import { buildSearchParam } from '@hooks/useWeekDatesSearchParams'

export const timeLoggerRouteBuilder = (
  userId: number,
  startDate?: DateTime,
) => {
  const prefix = `/time-logger/user/${userId}/`

  if (!startDate) return prefix

  const params = buildSearchParam(startDate)
  return `/time-logger/user/${userId}/?${params.toString()}`
}
