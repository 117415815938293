import { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import classNames from 'classnames'

interface Props {
  className?: string
}

export const Spinner: FC<Props> = ({ className }) => {
  return (
    <FontAwesomeIcon
      icon={faSpinnerThird}
      spin
      className={classNames('text-neutral-500', className)}
      style={
        {
          '--fa-animation-duration': '1s',
        } as React.CSSProperties
      }
    />
  )
}
