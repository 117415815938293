import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { secondsToHHMM } from 'utils/time'
import { Annotation } from './Annotation'

interface Props {
  totalSeconds: number
  onClick: () => void
  variant: 'floating' | 'attachment'
}

export const TimeOff: FC<Props> = ({ totalSeconds, onClick, variant }) => {
  const { t } = useTranslation()

  return (
    <Annotation position={variant} type="timeOff">
      <span onClick={onClick} className="cursor-pointer">
        {t('features.timeLogging.timeOff')}{' '}
        <span className="font-semibold">{secondsToHHMM(totalSeconds)}</span>
      </span>
    </Annotation>
  )
}
