import { useQueries } from '@tanstack/react-query'
import { getFacilityTimeCardStatusTotals } from '@lib/api'
import { facilityTimeCardStatusTotalsKeys } from '@lib/keys'
import { Interval } from 'luxon'

interface Options {
  weeks: Interval[]
}

export const useWeeklyFacilityTimeCardStatusTotals = ({ weeks }: Options) => {
  return useQueries({
    queries: weeks.map(({ start, end }) => {
      const endDate = end.minus({ second: 1 }) // Interval end dates are exclusive

      return {
        queryKey: facilityTimeCardStatusTotalsKeys.get(start, endDate),
        queryFn: () => getFacilityTimeCardStatusTotals(start, endDate),
      }
    }),
  })
}
