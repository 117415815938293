import { FC, useCallback } from 'react'
import { useUpdateProjectApprovers } from '../hooks/useUpdateProjectApprovers'
import { useTranslation } from 'react-i18next'
import { toast } from '@lib/toasts'
import { useSet } from '@hooks/useSet'
import { ModalForm } from 'components/notifications'
import { UserSelection } from '@features/user-selection'

interface Props {
  onCancel: () => void
  onSave: () => void
  project: AdminProject
}

export const AssignProjectApproversForm: FC<Props> = ({
  onCancel,
  onSave,
  project,
}) => {
  const { t } = useTranslation()
  const updateProjectApprovers = useUpdateProjectApprovers()

  const {
    set: approverUserIds,
    add: addApproverUserId,
    remove: removeApproverUserId,
  } = useSet<number>(new Set(project.approverUsers.map((u) => u.id)))

  const save = useCallback(async () => {
    try {
      await updateProjectApprovers.mutateAsync({
        id: project.id,
        approverUserIds: [...approverUserIds],
      })

      onSave()

      toast({
        title: t('features.projectsAdmin.approversUpdated'),
        variant: 'success',
        content: t('features.projectsAdmin.successfullyUpdatedApprovers', {
          name: project.name,
        }),
      })
    } catch (error) {
      toast({
        title: t('features.projectsAdmin.unableToUpdateApprovers'),
        variant: 'error',
        content: t('features.projectsAdmin.failedToUpdateApprovers', {
          name: project.name,
        }),
      })
    }
  }, [onSave, updateProjectApprovers, project, t, approverUserIds])

  const formFields = (
    <>
      <UserSelection
        label={t('common.approvers')}
        placeholder={t('common.searchByName')}
        selectedUserIds={approverUserIds}
        onSelect={(user) => addApproverUserId(user.id)}
        onRemove={(user) => removeApproverUserId(user.id)}
      />
    </>
  )

  return (
    <ModalForm
      fields={formFields}
      onCancel={() => onCancel()}
      onSave={() => void save()}
      isSaving={updateProjectApprovers.isLoading}
    />
  )
}
