import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import { annotationColors } from 'utils/annotations'

interface Props {
  children: ReactNode
  position: 'floating' | 'attachment'
  type: keyof typeof annotationColors
}

export const Annotation: FC<Props> = ({ children, position, type }) => {
  const color = annotationColors[type]

  return (
    <div
      className={classNames(
        'border rounded-t text-xs mx-2 py-1 px-4 flex items-center justify-center',
        `bg-${color}-100 border-${color}-300 text-${color}-800`,
        {
          'border-b-0': position === 'attachment',
          'rounded-b': position === 'floating',
        },
      )}
    >
      {children}
    </div>
  )
}
