import {
  faCloudArrowUp,
  faLock,
  faLockA,
  faUnlock,
} from '@fortawesome/pro-regular-svg-icons'
import { faLock as faLockSolid } from '@fortawesome/pro-solid-svg-icons'
import { MenuButton } from 'components/buttons'
import { NewHeader } from 'components/page-header'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectedById } from '../types'
import { CellSelectionBar } from './CellSelectionBar'

interface Props {
  onFullLock: () => void
  onSendToWorkday: () => void
  onSubmit: () => void
  onUnapproveLock: () => void
  onUnsubmit: () => void
  onUnsubmitLock: () => void
  onUnlock: () => void
  selections: SelectedById
  lockingEnabled: boolean
  sendToWorkdayEnabled: boolean
}

export const Header: FC<Props> = ({
  onFullLock,
  onSendToWorkday,
  // onSubmit,
  onUnapproveLock,
  // onUnsubmit,
  onUnsubmitLock,
  selections,
  onUnlock,
  lockingEnabled,
  sendToWorkdayEnabled,
}) => {
  const { t } = useTranslation()

  return (
    <NewHeader
      title={t('features.admin.timeCardAdmin')}
      renderRightSlot={() => (
        <div className="flex gap-4">
          {Object.values(selections).length > 0 && (
            <CellSelectionBar selections={selections} />
          )}
          <MenuButton
            className="z-30"
            menuItems={[
              // {
              //   label: t('features.admin.submission'),
              //   children: [
              //     { label: t('features.admin.submitAll'), onClick: onSubmit },
              //     {
              //       label: t('features.admin.unsubmitAll'),
              //       onClick: onUnsubmit,
              //     },
              //   ],
              // },
              {
                label: t('features.admin.locking'),
                children: [
                  {
                    icon: faUnlock,
                    label: t('features.admin.unlock'),
                    onClick: onUnlock,
                    disabled: !lockingEnabled,
                  },
                  {
                    icon: faLock,
                    label: t('features.admin.lockType.unsubmit'),
                    onClick: onUnsubmitLock,
                    disabled: !lockingEnabled,
                  },
                  {
                    icon: faLockA,
                    label: t('features.admin.lockType.unapprove'),
                    onClick: onUnapproveLock,
                    disabled: !lockingEnabled,
                  },
                  {
                    icon: faLockSolid,
                    label: t('features.admin.lockType.full'),
                    onClick: onFullLock,
                    disabled: !lockingEnabled,
                  },
                ],
              },
              {
                icon: faCloudArrowUp,
                label: t('features.admin.sendToWorkday'),
                onClick: onSendToWorkday,
                disabled: !sendToWorkdayEnabled,
              },
            ]}
          >
            Action
          </MenuButton>
        </div>
      )}
    />
  )
}
