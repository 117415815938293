import { FC, useId } from 'react'
import project from 'assets/project.svg'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { twMerge } from '@lib/tailwind-merge'
import { Tooltip } from 'lib/tooltip'
import { ItemPath } from './ItemPath'

interface Props {
  label: string
  onClick: () => void
  showImage?: boolean
  skip?: boolean
  tooltip?: string
  path?: string[]
  className?: string
}

export const NavItemSkeleton = () => (
  <div className="flex grow">
    <div className="h-5 grow my-2.5 mx-9 bg-neutral-100 animate-pulse"></div>
  </div>
)

export const NavItem: FC<Props> = ({
  label,
  tooltip,
  onClick,
  showImage = false,
  path,
  className,
}) => {
  const tooltipId = useId()

  return (
    <div className={twMerge('flex grow', className)}>
      <button
        data-tooltip-id={tooltipId}
        onClick={onClick}
        className={twMerge(
          'flex flex-row items-center h-10 overflow-hidden text-sm font-medium grow text-neutral-800 px-9 hover:bg-neutral-50',
        )}
      >
        <>
          {showImage && <img src={project} className="w-8 h-8 mr-2" />}
          <div className="flex flex-col grow items-start">
            {path && <ItemPath path={path} />}
            <span className="mr-auto overflow-hidden text-ellipsis whitespace-nowrap">
              {label}
            </span>
          </div>
          <FontAwesomeIcon icon={faChevronRight} />
        </>
      </button>
      {label.length > 20 && (
        <Tooltip id={tooltipId} content={tooltip} place="bottom" />
      )}
    </div>
  )
}
