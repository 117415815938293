import { FC, useState, useEffect, useRef } from 'react'
import { twMerge } from '@lib/tailwind-merge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'

interface TabProps {
  active: boolean
  label: string
  onClick: () => void
  className?: string
}

const activeClassName = 'font-semibold border-primary-600'
const inactiveClassName = 'border-transparent'

export const Tab: FC<TabProps> = ({ active, label, onClick, className }) => (
  <button
    className={twMerge(
      'px-3 py-2 border-b-2 text-sm text-neutral-800 whitespace-nowrap',
      active ? activeClassName : inactiveClassName,
      className,
    )}
    onClick={onClick}
  >
    {label}
  </button>
)

interface ScrollArrowProps {
  direction: 'left' | 'right'
  onClick: () => void
}

const ScrollArrow: FC<ScrollArrowProps> = ({ direction, onClick }) => {
  const icon = direction === 'left' ? faChevronLeft : faChevronRight
  const directionClasses =
    direction === 'left'
      ? 'left-0 bg-gradient-to-r'
      : 'right-0 bg-gradient-to-l'

  return (
    <button
      className={twMerge(
        'absolute top-0 bottom-0 z-10 from-white via-white to-transparent text-neutral-800 px-3 flex items-center border-b-2 border-transparent text-sm',
        directionClasses,
      )}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  )
}

interface TabType {
  id: string
  label: string
  error?: boolean
}

export interface Props<T extends TabType> {
  className?: string
  tabs: readonly T[]
  activeTab: Props<T>['tabs'][number]['id']
  onTabClick: (tab: Props<T>['activeTab']) => void
  variant?: 'text-width-tabs' | 'full-width-tabs'
}

export const Tabs = <T extends TabType>({
  className,
  tabs,
  activeTab,
  onTabClick,
  variant = 'text-width-tabs',
}: Props<T>) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(false)

  const updateScrollButtons = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current
      const tolerance = 1 // Adjust for minor floating-point differences
      setCanScrollLeft(scrollLeft > tolerance)
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth - tolerance)
    }
  }

  const scroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const { scrollLeft, clientWidth } = scrollRef.current
      const scrollAmount =
        direction === 'left' ? -clientWidth / 2 : clientWidth / 2
      scrollRef.current.scrollTo({
        left: scrollLeft + scrollAmount,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    updateScrollButtons()
    const handleResize = () => updateScrollButtons()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className={twMerge('relative', className)}>
      {/* Left Chevron */}
      {canScrollLeft && (
        <ScrollArrow direction="left" onClick={() => scroll('left')} />
      )}
      <div
        ref={scrollRef}
        className={twMerge(
          'flex flex-row border-b border-neutral-300 overflow-x-auto no-scrollbar',
          variant === 'text-width-tabs' && 'px-8',
        )}
        onScroll={updateScrollButtons}
      >
        {tabs.map(({ id, label, error = false }) => (
          <Tab
            key={id}
            active={activeTab === id}
            label={label}
            onClick={() => onTabClick(id)}
            className={twMerge(
              variant === 'full-width-tabs' && 'flex-grow',
              error && 'text-error-700',
            )}
          />
        ))}
      </div>
      {canScrollRight && (
        <ScrollArrow direction="right" onClick={() => scroll('right')} />
      )}
    </div>
  )
}
