import { FC, useCallback } from 'react'
import { Checkbox } from 'components/form/checkbox/Checkbox'
import { useToggleAutoApproval } from '../hooks/useApprovalGroupsManagement'
import { Spinner } from 'components/loaders'
import { useModal } from '@hooks/useModal'
import { useTranslation } from 'react-i18next'

interface Props {
  approvalGroup: AdminApprovalGroup
}

// TODO: Extract and share with projects-admin
export const AutoApprovalToggle: FC<Props> = ({ approvalGroup }) => {
  const { t } = useTranslation()
  const toggleAutoApproval = useToggleAutoApproval()
  const modal = useModal()

  const confirm = useCallback(() => {
    modal.confirm({
      title: t(
        approvalGroup.autoApproval
          ? 'components.autoApprovalToggle.disablingAutoApproval'
          : 'components.autoApprovalToggle.enablingAutoApproval',
      ),
      content: (
        <div>
          <p>
            {t(
              approvalGroup.autoApproval
                ? 'components.autoApprovalToggle.disablingAutoApprovalRequiresManualApproval'
                : 'components.autoApprovalToggle.enablingAutoApprovalWillApprovePendingAndFuture',
              { approvalDomainType: 'approval group' },
            )}
          </p>
          <div className="text-lg font-semibold mt-4">{approvalGroup.name}</div>
        </div>
      ),
      confirmText: t(
        approvalGroup.autoApproval
          ? 'components.autoApprovalToggle.disableAutoApproval'
          : 'components.autoApprovalToggle.enableAutoApproval',
      ),
      onConfirm: () => toggleAutoApproval.mutate(approvalGroup.id),
    })
  }, [approvalGroup, modal, toggleAutoApproval, t])

  if (toggleAutoApproval.isLoading) return <Spinner />

  return (
    <div className="flex items-center text-xs text-neutral-600 gap-3">
      <Checkbox checked={approvalGroup.autoApproval} onChange={confirm} />

      {approvalGroup.autoApproval && (
        <span>
          Auto-approval: <span className="font-bold">ON</span>
        </span>
      )}
    </div>
  )
}
