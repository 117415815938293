import { Calculation } from '../types'

export const validateFullyAllocated = (
  totals: Calculation,
): 'not-fully-allocated'[] => {
  if (totals.allocation === 0) return ['not-fully-allocated']
  if (totals.allocation === totals.workWeek) return []

  return ['not-fully-allocated']
}
