import { useDateTimeToLocaleString as useDateTimeToLocaleStringImported } from 'hooks/useDateTimeWithLocale'
import { DateInput as DateInputComponent } from 'components/form/date-input'
import { FC } from 'react'
import { IconButton } from 'components/buttons'
import { faClose } from '@fortawesome/pro-regular-svg-icons'

interface Props {
  clearTitle?: string
  date?: DateTime
  onChange: (date?: DateTime) => void
  useDateTimeToLocaleString?: typeof useDateTimeToLocaleStringImported
}

export const DateInput: FC<Props> = ({
  clearTitle,
  date,
  onChange,
  useDateTimeToLocaleString = useDateTimeToLocaleStringImported,
}) => {
  return (
    <div className="relative group">
      <IconButton
        className="hidden group-hover:block absolute right-[2px] top-[20%] text-neutral-900"
        icon={faClose}
        onClick={() => onChange()}
        title={clearTitle}
      />
      <DateInputComponent
        value={date}
        onChange={(date) => onChange(date)}
        useDateTimeToLocaleString={useDateTimeToLocaleString}
      />
    </div>
  )
}
