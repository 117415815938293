import { FC } from 'react'

interface Props {
  heading?: string
  subheading?: string
  className?: string
}

export const ColumnHeading: FC<Props> = ({
  heading,
  subheading,
  className,
}) => {
  return (
    <div className={className}>
      {heading && <h2 className="font-medium text-neutral-900">{heading}</h2>}
      {subheading && <h3 className="text-sm text-neutral-500">{subheading}</h3>}
    </div>
  )
}
