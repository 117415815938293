import { useLinkedProjectIntegrations } from '@hooks/useLinkedProjectIntegrations'
import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavItem } from '../../components/NavItem'
import { Column } from './Column'

interface Props {
  onItemClick: (item: { id: number; name: string }) => void
  projectId: number
  shown: boolean
  displaySearch?: boolean
  onSearchToggle: (displayed: boolean) => void
  searchValue?: string
  onSearchChange: (search: string) => void
  heading?: string
}

export const ProjectIntegrationsColumn: FC<Props> = memo(
  ({
    onItemClick,
    projectId,
    shown,
    displaySearch,
    onSearchToggle,
    searchValue = '',
    onSearchChange,
    heading,
  }) => {
    const { t } = useTranslation()

    const projectIntegrationsResult = useLinkedProjectIntegrations({
      enabled: shown,
      projectId,
    })

    const items = useMemo(
      () => projectIntegrationsResult.data ?? [],
      [projectIntegrationsResult.data],
    )

    const filteredItems = useMemo(() => {
      if (!searchValue?.trim()) return items

      return items.filter((item) =>
        item.name.toLowerCase().includes(searchValue.trim().toLowerCase()),
      )
    }, [items, searchValue])

    if (!shown) return null

    return (
      <Column
        heading={heading}
        subheading={t('features.timeLogging.taskSelection.linkedIntegrations')}
        displaySearch={displaySearch}
        onSearchToggle={onSearchToggle}
        searchValue={searchValue}
        onSearchChange={onSearchChange}
        isFetching={
          projectIntegrationsResult.isFetching ||
          projectIntegrationsResult.isLoading
        }
        isInitiallyLoading={projectIntegrationsResult.isLoading}
        items={filteredItems}
        loadMoreItems={() => undefined}
        renderItem={(item) => (
          <NavItem
            key={`${item.id ?? ''}-${item.name}`}
            label={item.name}
            onClick={() => onItemClick(item)}
            showImage={false}
            skip={false}
          />
        )}
        totalItems={filteredItems.length}
      />
    )
  },
)

ProjectIntegrationsColumn.displayName = 'ProjectIntegrationsColumn'
