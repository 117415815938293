import { addItemsToSet, removeItemsFromSet } from '@hooks/useSet'
import { useCallback, useState } from 'react'
import {
  SelectedById,
  SelectedEmployeeValues,
  SelectedPayloadItem,
} from '../types'

export const isSelected = (selected: SelectedById, employeeId: number) => {
  const { dates, ...timeCardsById } = selected[employeeId] ?? {
    dates: new Set<DateTime>(),
  }

  return (date: DateTime, timeCardId?: number): boolean => {
    if (dates.has(date.toMillis())) return true
    if (timeCardId === undefined) return false

    return timeCardsById[timeCardId] !== undefined
  }
}

export const useSelectedCells = () => {
  const [state, setState] = useState<SelectedById>({})

  const select = useCallback((selectItems: SelectedPayloadItem[]) => {
    setState((prevState) => {
      return selectItems.reduce<SelectedById>((acc, item) => {
        const { dates: selectedDates, ...timeCardsById } = acc[
          item.employeeId
        ] ?? { dates: new Set<DateTime>() }

        const updated: SelectedEmployeeValues = {
          dates: addItemsToSet(item.dates.map((d) => d.toMillis()))(
            selectedDates,
          ),
          ...timeCardsById,
        }

        item.timeCards?.forEach((timeCard) => {
          updated[timeCard.id] = timeCard
        })

        return { ...acc, [item.employeeId]: updated }
      }, prevState)
    })
  }, [])

  const unSelect = useCallback((selectItems: SelectedPayloadItem[]) => {
    setState((prevState) => {
      return selectItems.reduce<SelectedById>((acc, item) => {
        const { dates: selectedDates, ...timeCardsById } = acc[
          item.employeeId
        ] ?? { dates: new Set<DateTime>() }

        const updated: SelectedEmployeeValues = {
          dates: removeItemsFromSet(item.dates.map((d) => d.toMillis()))(
            selectedDates,
          ),
          ...timeCardsById,
        }

        item.timeCards?.forEach((timeCard) => {
          delete updated[timeCard.id]
        })

        if (updated.dates.size === 0 && Object.keys(updated).length <= 1) {
          const a = { ...acc }
          delete a[item.employeeId]
          return a
        }

        return { ...acc, [item.employeeId]: updated }
      }, prevState)
    })
  }, [])

  const clear = useCallback(() => setState({}), [])

  return { clear, selected: state, select, unSelect }
}
