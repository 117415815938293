import { FC, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { CellText } from './cells/CellText'
import { SideCell } from './cells/SideCell'

export const LeftHeader: FC<{
  children?: ReactNode
  highlight: boolean
  labels: string[]
  rowIndex: number
  showBorder?: boolean
}> = ({ children, highlight, labels, rowIndex, showBorder }) => {
  return (
    <SideCell
      rowIndex={rowIndex}
      highlight={highlight}
      className="group"
      showBorder={showBorder}
    >
      {labels.map((label, index) => (
        <div
          className={twMerge(
            'text-xs leading-none 3xl:text-sm last-of-type:font-semibold',
            labels.length > 1 && 'w-1/3',
          )}
          key={`left-header-cell-text-${index}`}
        >
          <CellText text={label} />
        </div>
      ))}
      {children}
    </SideCell>
  )
}
