import { useEntriesSumByTask } from '@features/time-logging/hooks/useEntrySums'
import { SideColumn } from 'components/time-entry-table'
import { ReactNode } from 'react'
import { TaskTotalCell } from './cells/TaskTotalCell'

const TaskTotalById = ({
  taskId,
  children,
}: {
  taskId: number
  children: (total: number) => ReactNode
}) => {
  const total = useEntriesSumByTask(taskId)

  return <>{children(total)}</>
}

interface Props {
  tasks: { id: number }[]
}

export function TaskTotalColumn({ tasks }: Props) {
  return (
    <SideColumn side="right">
      {tasks.map((task, index) => (
        <TaskTotalById key={`TotalByTask${task.id}`} taskId={task.id}>
          {(total) => <TaskTotalCell row={index + 1} total={total} />}
        </TaskTotalById>
      ))}
    </SideColumn>
  )
}
