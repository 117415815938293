import { getLinkedProjectIntegrations } from '@lib/api'
import { projectIntegrationsKeys } from '@lib/keys'
import { useQuery } from '@tanstack/react-query'

export const useLinkedProjectIntegrations = ({
  enabled = true,
  projectId,
}: {
  enabled?: boolean
  projectId: number
}) => {
  return useQuery(
    projectIntegrationsKeys.linkedToProject(projectId),
    () => getLinkedProjectIntegrations(projectId),
    { enabled },
  )
}
