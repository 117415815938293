import { useSettings } from '@hooks/useSettings'
import { rangeInTimeOfDay, secondsToTimeOfDay } from '@utils/time'
import { DateTime } from 'luxon'
import { convertTwentyFourHourTimeToSeconds } from '../utils/convertTwentyFourHourTimeToSeconds'

type Options =
  | { time: Partial<TwentyFourHourTime> | undefined }
  | {
      start: Partial<TwentyFourHourTime> | undefined
      end: Partial<TwentyFourHourTime> | undefined
    }

export const useDisplayTwentyForHourTime = () => {
  const { data } = useSettings()

  const clock = data?.timeFormat === '24_hour' ? '24' : '12'

  return (options?: Options) => {
    if (options === undefined) return ''

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if ('time' in options) {
      if (options.time === undefined) return ''

      const seconds = convertTwentyFourHourTimeToSeconds(options.time)
      return secondsToTimeOfDay(seconds, DateTime.now(), timeZone, { clock })
    }

    if (options.start === undefined || options.end === undefined) return ''

    const startSeconds = convertTwentyFourHourTimeToSeconds(options.start)
    const endSeconds = convertTwentyFourHourTimeToSeconds(options.end)

    return rangeInTimeOfDay(
      startSeconds,
      endSeconds,
      DateTime.now(),
      timeZone,
      { clock },
    )
  }
}
