import { DateTime, DateTimeFormatOptions } from 'luxon'
import { useDateTimeToLocaleString } from '@hooks/useDateTimeWithLocale'

const defaultFn = (arg: DateTimeFormatOptions) => (date: DateTime) =>
  date.toLocaleString(arg)

export const useRenderSelectedWeek =
  (toLocaleString: ReturnType<typeof useDateTimeToLocaleString> = defaultFn) =>
  (startOfWeek: DateTime) => {
    const start = startOfWeek.startOf('day')
    const end = start.plus({ days: 6 }).startOf('day')

    if (startOfWeek.hasSame(end, 'month')) {
      return `${toLocaleString({ month: 'long', day: 'numeric' })(
        start,
      )} – ${end.get('day')}, ${start.get('year')}`
    }

    return `${toLocaleString({ month: 'short', day: 'numeric' })(
      start,
    )} – ${toLocaleString({ month: 'short', day: 'numeric' })(end)}, ${end.get(
      'year',
    )}`
  }
