import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { useDateTimeToLocaleString } from '@hooks/useDateTimeWithLocale'
import { range } from '@utils/range'
import { IconButton, MenuButton } from 'components/buttons'
import { DateTime } from 'luxon'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from '@lib/tailwind-merge'

export const getOtherWeekdays = (weekDay: number) => {
  const days = new Set([1, 2, 3, 4, 5, 6, 7])
  days.delete(weekDay)
  return Array.from(days)
}

interface Props {
  allSelected: (date: DateTime) => boolean
  date: DateTime
  hiddenDays: Set<number>
  onHide: (weekDay: number | number[]) => void
  onSelectAll: (date: DateTime) => void
  onShow: (weekDay: number | number[]) => void
  onUnSelectAll: (date: DateTime) => void
}

export const DayHeaderCell: FC<Props> = ({
  allSelected,
  date,
  hiddenDays,
  onHide,
  onSelectAll,
  onShow,
  onUnSelectAll,
}) => {
  const toLocaleString = useDateTimeToLocaleString()
  const { t } = useTranslation()

  const weekday = date.weekday
  const otherWeekdays = getOtherWeekdays(weekday)
  /**
   * 7 is Sunday, according to Luxon, so doing a bit of light
   * gymnastics to make sure the weekday numbers are right
   */
  const weekdaysToTheRight = range(weekday === 7 ? 1 : weekday + 1, 6)
  const dayHidden = hiddenDays.has(weekday)
  const disableHideOtheresToRightFunctionality = weekday === 6
  const othersHidden = otherWeekdays.some((otherDay) =>
    hiddenDays.has(otherDay),
  )
  const othersToTheRightAreHidden = weekdaysToTheRight.some((otherDay) =>
    hiddenDays.has(otherDay),
  )

  const menuItems = [
    allSelected(date)
      ? {
          label: t('common.deselectAll'),
          onClick: () => onUnSelectAll(date),
        }
      : {
          label: t('common.selectAll'),
          onClick: () => onSelectAll(date),
        },
    dayHidden
      ? {
          label: t('common.show'),
          onClick: () => onShow(weekday),
        }
      : {
          label: t('common.hide'),
          onClick: () => onHide(weekday),
        },
    othersHidden
      ? {
          label: t('common.showOthers'),
          onClick: () => onShow(otherWeekdays),
        }
      : {
          label: t('common.hideOthers'),
          onClick: () => onHide(otherWeekdays),
        },
  ]

  if (!disableHideOtheresToRightFunctionality) {
    menuItems.push(
      othersToTheRightAreHidden
        ? {
            label: t('common.showDaysToTheRight'),
            onClick: () => onShow(weekdaysToTheRight),
          }
        : {
            label: t('common.hideDaysToTheRight'),
            onClick: () => onHide(weekdaysToTheRight),
          },
    )
  }

  return (
    <div className="flex flex-col items-center flex-grow w-16 h-full">
      <div className="relative">
        <span className={dayHidden ? 'opacity-30' : undefined}>
          {toLocaleString({ weekday: 'short' })(date)}
        </span>
        <MenuButton
          button={({ disabled, menuHidden, toggleMenuHidden }) => (
            <IconButton
              onClick={toggleMenuHidden}
              disabled={disabled}
              icon={menuHidden ? faChevronDown : faChevronUp}
            />
          )}
          className="hidden absolute right-[-25px] top-[-2px] group-hover:inline opacity-100"
          closeOnMouseLeave={true}
          menuItems={menuItems}
          dropdownMenuHeader={toLocaleString({ weekday: 'long' })(date)}
        />
      </div>
      <span
        className={twMerge(
          'flex-grow mt-2 text-2xl font-light',
          dayHidden && 'opacity-30',
        )}
      >
        {date.day}
      </span>
    </div>
  )
}
