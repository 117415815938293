import { ReactNode } from 'react'
import { InfiniteScrollList } from 'components/containers/infinite-scroll-list'
import { SearchField } from 'components/form/search-field'
import { NavItemSkeleton } from '../../components/NavItem'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'components/buttons'
import { faClose, faSearch } from '@fortawesome/pro-solid-svg-icons'
import { ColumnHeading } from './ColumnHeading'

type Props<T> = {
  children?: ReactNode
  isFetching: boolean
  isInitiallyLoading: boolean
  items: T[]
  loadMoreItems: () => void
  renderItem: (item: T) => ReactNode
  skeletonItem?: ReactNode
  totalItems?: number
  heading?: string
  subheading?: string
  displaySearch?: boolean
  onSearchToggle: (displayed: boolean) => void
  placeholder?: string
  searchError?: string
  searchValue: string
  onSearchChange: (search: string) => void
}

export const Column = <T,>({
  children,
  isFetching,
  isInitiallyLoading,
  items,
  loadMoreItems,
  renderItem,
  skeletonItem = <NavItemSkeleton />,
  totalItems,
  heading,
  subheading,
  onSearchToggle,
  displaySearch,
  ...props
}: Props<T>) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="mb-2 mx-9 flex justify-between items-center">
        <ColumnHeading heading={heading} subheading={subheading} />
        <IconButton
          icon={displaySearch ? faClose : faSearch}
          onClick={() => onSearchToggle(!displaySearch)}
          iconProps={{ size: 'sm' }}
          className="text-neutral-700"
          srOnlyLabel={displaySearch ? 'Close Search' : 'Search'}
        />
      </div>

      {displaySearch && (
        <SearchField
          autoFocus={true}
          error={props.searchError}
          onChange={props.onSearchChange}
          placeholder={props.placeholder ?? t('common.search').toString()}
          value={props.searchValue}
          className="mx-9 mb-4"
        />
      )}
      {children && (
        <div className="mb-1 flex flex-col items-center">{children}</div>
      )}
      <InfiniteScrollList
        items={items}
        loadMore={loadMoreItems}
        isFetching={isFetching}
        isInitiallyLoading={isInitiallyLoading}
        renderItem={renderItem}
        expectedItemHeight={40}
        totalItems={totalItems ?? 0}
        skeletonItem={skeletonItem}
      />
    </>
  )
}
