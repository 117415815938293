import { QueryClient } from '@tanstack/react-query'
import { defaultQueryFunction } from '../lib/api'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFunction,
    },
  },
})
