import { TFunction } from 'react-i18next'
import { CoalescedParent } from './coalesceContiguousColumnParents'
import { labelKeyByColumnGroupId } from '../lib/labelKeyByColumnGroupId'
import { infoByColumnId } from '../lib/infoByColumnId'
import { ReportTypeMap } from '../types'

export const filterColumnGroupsBySearchQuery = <
  RT extends keyof ReportTypeMap,
>({
  groups,
  searchQuery,
  t,
  reportType,
}: {
  reportType: RT
  groups: CoalescedParent<RT>[]
  searchQuery: string
  t: TFunction<'translation', undefined>
}) => {
  if (searchQuery.trim() === '') return groups

  return groups.reduce((acc, { id: groupId, columns }) => {
    const groupLabel = t(labelKeyByColumnGroupId({ reportType, groupId }))

    // If the group matches, include the entire group
    if (groupLabel.toLowerCase().includes(searchQuery.toLowerCase())) {
      return [...acc, { id: groupId, columns }]
    }

    // Filter columns within the group
    const filteredColumns = columns.filter((columnId) =>
      (t(infoByColumnId({ reportType, id: columnId }).labelKey) as string)
        .toLowerCase()
        .includes(searchQuery.toLowerCase()),
    )

    // If any columns in the group match,
    // include the group, but just with the matching columns
    if (filteredColumns.length > 0) {
      return [...acc, { id: groupId, columns: filteredColumns }]
    }

    // If neither the group nor any of its columns match, omit it entirely
    return acc
  }, [] as typeof groups)
}
