import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDateTimeToLocaleString as useDateTimeWithLocaleImported } from '@hooks/useDateTimeWithLocale'
import { DateTime } from 'luxon'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from '@lib/tailwind-merge'
import { generateCalendar } from './utils/generateCalendar'

const Button: FC<{
  onClick: () => void
  direction: 'back' | 'forward'
  title: string
}> = ({ onClick, direction, title }) => (
  <button
    className="w-8 h-8 rounded bg-neutral-100 border-neutral-400 [&>svg]:text-neutral-900 border-[1.5px]"
    onClick={onClick}
    title={title}
  >
    <FontAwesomeIcon
      icon={direction === 'back' ? faChevronLeft : faChevronRight}
      size="xs"
    />
  </button>
)

interface WeekSelectorProps {
  selectedWeek: DateTime
  onWeekSelect: (startOfWeek: DateTime) => void
}

interface AbitraryStartAndProps {
  selected: { start?: DateTime; end?: DateTime }
  onSelect: (options: { start?: DateTime; end?: DateTime }) => void
}

type Props = {
  className?: string
  initiallyDisplayedMonth: DateTime
  useDateTimeToLocaleString?: typeof useDateTimeWithLocaleImported
} & (AbitraryStartAndProps | WeekSelectorProps)

export const MonthCalendar: FC<Props> = ({
  className,
  initiallyDisplayedMonth,
  useDateTimeToLocaleString = useDateTimeWithLocaleImported, // injecting dependency in this way for storybook
  ...props
}) => {
  const { t } = useTranslation()
  const toLocaleString = useDateTimeToLocaleString()

  const [displayedMonth, setDisplayedMonth] = useState<DateTime>(
    initiallyDisplayedMonth.startOf('month'),
  )

  const isInWeekMode = 'selectedWeek' in props

  const { start, end } = isInWeekMode
    ? { start: props.selectedWeek, end: props.selectedWeek.plus({ days: 6 }) }
    : { start: props.selected.start, end: props.selected.end }

  const goBackOneMonth = () => {
    setDisplayedMonth((month) => month.minus({ month: 1 }))
  }

  const goForwardOneMonth = () => {
    setDisplayedMonth((month) => month.plus({ month: 1 }))
  }

  return (
    <div className={twMerge('flex-col', className)}>
      <div className="flex flex-row items-center justify-between">
        <Button
          onClick={() => goBackOneMonth()}
          direction="back"
          title={t('components.weekSelector.goToPreviousMonth')}
        />
        <span className="text-sm font-semibold text-neutral-900">
          {toLocaleString({ month: 'long', year: 'numeric' })(displayedMonth)}
        </span>
        <Button
          onClick={() => goForwardOneMonth()}
          direction="forward"
          title={t('components.weekSelector.goToNextMonth')}
        />
      </div>
      <div className="flex flex-row items-center mt-5 mb-3">
        {[
          t('common.dayOfWeek.sunday.abr'),
          t('common.dayOfWeek.monday.abr'),
          t('common.dayOfWeek.tuesday.abr'),
          t('common.dayOfWeek.wednesday.abr'),
          t('common.dayOfWeek.thursday.abr'),
          t('common.dayOfWeek.friday.abr'),
          t('common.dayOfWeek.saturday.abr'),
        ].map((dayOfWeek) => (
          <span
            className="flex-1 text-xs text-center uppercase text-neutral-500"
            key={dayOfWeek}
          >
            {dayOfWeek}
          </span>
        ))}
      </div>
      <div className="flex flex-col">
        {generateCalendar(displayedMonth).map((week) => {
          return (
            <div
              className={twMerge(
                'flex flex-row overflow-hidden text-neutral-900',
                isInWeekMode && 'group',
              )}
              key={week[0].toISODate()}
              onClick={() => isInWeekMode && props.onWeekSelect(week[0])}
            >
              {week.map((day) => {
                const isWithinDisplayMonth = day.hasSame(
                  displayedMonth,
                  'month',
                )
                const isStartOfSelected = start && day.hasSame(start, 'day')
                const isEndOfSelected = end && day.hasSame(end, 'day')
                const isAfterStart = start === undefined || day > start
                const isBeforeEnd = end === undefined || day < end

                return (
                  <button
                    className={twMerge(
                      'flex flex-row items-center justify-center flex-1 w-8 h-8 text-sm rounded',
                      !isWithinDisplayMonth && 'text-neutral-400',
                      isAfterStart &&
                        isBeforeEnd &&
                        'font-semibold text-primary-700 bg-primary-300 rounded-none',
                      (isStartOfSelected || isEndOfSelected) &&
                        'bg-primary-600 font-semibold text-white',
                      isStartOfSelected && !isEndOfSelected && 'rounded-r-none',
                      isEndOfSelected &&
                        !isStartOfSelected &&
                        'rounded-l-none rounded-r',
                      isInWeekMode &&
                        'first-of-type:group-hover:bg-neutral-300 last-of-type:group-hover:bg-neutral-300 group-hover:text-neutral-900 group-hover:bg-neutral-150',
                    )}
                    key={day.toISODate()}
                    onClick={() => {
                      if (!('onSelect' in props)) return
                      const select = ({
                        start,
                        end,
                      }: {
                        start?: DateTime
                        end?: DateTime
                      }) =>
                        props.onSelect({
                          start: start?.startOf('day'),
                          end: end?.endOf('day'),
                        })

                      if (start && start.hasSame(day, 'day')) {
                        select({ start: day, end: day })
                      } else if (end && end.hasSame(day, 'day')) {
                        select({ start: day, end: day })
                      } else if (start && !end) {
                        if (day < start) {
                          select({ start: day, end: start })
                        } else {
                          select({ start, end: day })
                        }
                      } else if (end && !start) {
                        if (day < end) {
                          select({ start: day, end })
                        } else {
                          select({ start: end, end: day })
                        }
                      } else if (!start && !end) {
                        select({ start: day })
                      } else if (start && end) {
                        if (day <= start) {
                          select({ start: day, end })
                        } else {
                          select({ start, end: day })
                        }
                      } else {
                        select({ start })
                      }
                    }}
                  >
                    {day.day}
                  </button>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}
