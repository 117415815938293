import { useDateTimeToLocaleString as useDateTimeToLocaleStringImported } from '@hooks/useDateTimeWithLocale'
import { DateTime } from 'luxon'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePickerDropdown } from '../date-picker-dropdown/DatePickerDropdown'
import { MonthCalendar } from 'components/navigation/month-calendar'
import { useRenderSelectedWeek } from './utils/useRenderSelectedWeek'

interface Props {
  onMenuHiddenChange?: (hidden: boolean) => void
  onWeekSelect: (startOfWeek: DateTime) => void
  selectedWeek: DateTime
  useDateTimeToLocaleString?: typeof useDateTimeToLocaleStringImported
  variant?: 'dark' | 'light'
}

export const WeekSelector: FC<Props> = ({
  onMenuHiddenChange,
  onWeekSelect,
  selectedWeek,
  useDateTimeToLocaleString = useDateTimeToLocaleStringImported,
  variant = 'dark',
}) => {
  const { t } = useTranslation()
  const startOfSelectedWeek = selectedWeek.startOf('day')
  const previousWeek = startOfSelectedWeek.minus({ week: 1 })
  const nextWeek = startOfSelectedWeek.plus({ week: 1 })
  const toLocaleString = useDateTimeToLocaleString()
  const renderSelectedWeek = useRenderSelectedWeek(toLocaleString)

  return (
    <DatePickerDropdown
      content={renderSelectedWeek(selectedWeek)}
      nextButtonTitle={t('components.weekSelector.goToNextWeek')}
      onMenuHiddenChange={onMenuHiddenChange}
      onNextButtonClick={() => onWeekSelect(nextWeek)}
      onPreviousButtonClick={() => onWeekSelect(previousWeek)}
      previousButtonTitle={t('components.weekSelector.goToPreviousWeek')}
      title={t('components.weekSelector.selectADifferentWeek')}
      variant={variant}
    >
      {({ menuHidden }) => {
        if (menuHidden) return null

        return (
          <MonthCalendar
            className="absolute left-0 z-20 top-12 border-neutral-200 rounded-lg p-4 bg-white border w-[280px] drop-shadow"
            initiallyDisplayedMonth={selectedWeek}
            onWeekSelect={onWeekSelect}
            selectedWeek={selectedWeek}
            useDateTimeToLocaleString={useDateTimeToLocaleString}
          />
        )
      }}
    </DatePickerDropdown>
  )
}
