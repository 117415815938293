import './instrument'
import React from 'react'
import ReactDOM from 'react-dom/client'
import 'lib/react18next'
import 'core-js/stable'
import App from './App'
import 'react-tooltip/dist/react-tooltip.css'
import './index.css'
import { ErrorBoundary } from 'ErrorBoundary'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
)
