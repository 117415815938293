import { useEffect } from 'react'
import { toast } from '@lib/toasts'
import { Trans, useTranslation } from 'react-i18next'

const INTERVAL_SECONDS = 30

export const NewClientVersionNotification = () => {
  const { t } = useTranslation()

  useEffect(() => {
    const interval = setInterval(() => {
      void (async () => {
        const response = await fetch('/.version')

        // If the version file doesn't exist for some reason,
        // we'll just skip version checking altogether
        if (response.ok) {
          const currentVersion = await response.text()

          // If the current version is different than this client's build version,
          // alert the user
          if (
            currentVersion.trim() !== import.meta.env.VITE_CLIENT_VERSION.trim()
          ) {
            toast({
              title: t(
                'features.newClientVersionNotification.newVersionAvailable',
              ),
              variant: 'alert',
              dismissable: false,
              sticky: true,
              content: (
                <Trans
                  i18nKey="features.newClientVersionNotification.reloadToGetTheLatestUpdate"
                  components={{
                    reloadLink: (
                      <a
                        href="#"
                        className="text-hyperlink-blue"
                        onClick={(e) => {
                          e.preventDefault()
                          location.reload()
                        }}
                      />
                    ),
                  }}
                />
              ),
            })

            // No need to check anymore
            clearInterval(interval)
          }
        }
      })()
    }, 1000 * INTERVAL_SECONDS)

    return () => clearInterval(interval)
  }, [t])

  return <></>
}
