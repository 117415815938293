import { SelectedById, SelectedEmployeeValues } from '../types'

export const timeCardsFromSelected = (
  selected: SelectedById,
): AdminTimeCard[] => {
  /**
   * The following cast is a bit gross, but it exists to appease Object.values.
   * Object.values wants an object whose keys are strings. Odd thing is, though,
   * that it doesn't need to concern itself with the keys type since it's iterating
   * through the values.  So, hence, the cast.
   */
  return Object.values<SelectedEmployeeValues>(
    selected as SelectedById & { [key: string]: SelectedEmployeeValues },
  ).flatMap(({ dates: _dates, ...timeCardsById }) =>
    Object.values<AdminTimeCard>(timeCardsById),
  )
}
