import { ReportTypeMap } from '../types'
import { getColumnGroupParent } from './getColumnGroupParent'

export type CoalescedParent<RT extends keyof ReportTypeMap> = {
  id: ReportTypeMap[RT]['groupId']
  columns: ReportTypeMap[RT]['columnId'][]
}

export const coalesceContiguousColumnParents = <RT extends keyof ReportTypeMap>(
  columnIds: ReportTypeMap[RT]['columnId'][],
) => {
  return columnIds.reduce<CoalescedParent<RT>[]>((acc, columnId) => {
    const currentColumnGroupParent = getColumnGroupParent(columnId)
    const currentGroup: CoalescedParent<RT> = acc[acc.length - 1] ?? {}

    return currentColumnGroupParent === currentGroup.id
      ? [
          ...acc.slice(0, -1),
          { ...currentGroup, columns: [...currentGroup.columns, columnId] },
        ]
      : [...acc, { id: currentColumnGroupParent, columns: [columnId] }]
  }, [])
}
