import { FC, useMemo } from 'react'
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  ColumnFiltersState,
  ColumnDef,
  OnChangeFn,
} from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownAZ, faArrowUpAZ } from '@fortawesome/pro-regular-svg-icons'
import { TableFilterField } from 'components/tables/TableFilterField'
import { titleize } from '@utils/titleize'
import { ApprovalsProgress } from './ApprovalsProgress'
import { useNavigate } from 'react-router-dom'
import { StatusToggleMap } from '@hooks/useStatusToggle'
import { Status } from 'types'
import { approvalStatus } from '../utils/approvalStatus'
import { buildSearchParam as buildWeekDatesSearchParam } from '@hooks/useWeekDatesSearchParams'
import { useTranslation } from 'react-i18next'
import { renderDateRange } from '@utils/renderDateRange'
import { useLanguage } from '@hooks/useLanguage'
import { DomainWithApprovals } from '../types'
import { Tr } from 'components/tables'
import { uniqueCountFromItems } from '@utils/uniqueCountFromItems'

const useShouldHideRow =
  (showOnlyStatuses: StatusToggleMap) =>
  (approvalDomain: DomainWithApprovals) =>
    !(Object.entries(showOnlyStatuses) as [Status, boolean][])
      .filter(([_, isShown]) => isShown)
      .some(([status]) =>
        approvalDomain.approvals.some(
          (approval) => approvalStatus(approval) === status,
        ),
      )

interface Props {
  approvalDomains: DomainWithApprovals[]
  weekStart: DateTime
  weekEnd: DateTime
  showOnlyStatuses: StatusToggleMap
  sorting: SortingState
  onSortingChange: OnChangeFn<SortingState>
  columnFilters: ColumnFiltersState
  onColumnFiltersChange: OnChangeFn<ColumnFiltersState>
}

const columnHelper = createColumnHelper<DomainWithApprovals>()

export const Table: FC<Props> = ({
  approvalDomains,
  weekStart,
  weekEnd,
  showOnlyStatuses,
  sorting,
  onSortingChange,
  columnFilters,
  onColumnFiltersChange,
}) => {
  const { t } = useTranslation()
  const language = useLanguage()
  const shouldHideRow = useShouldHideRow(showOnlyStatuses)

  const navigate = useNavigate()

  const columns = [
    columnHelper.accessor('name', {
      header: t('features.approvalsAdmin.domainName'),
      enableColumnFilter: true,
      filterFn: 'includesString',
      footer: () => {
        const count = visibleRows.length
        return (
          <div className="flex flex-col p-3 whitespace-nowrap text-xs">
            <span className="font-semibold text-neutral-900">
              {t('common.total')}
            </span>
            <span>
              <>
                {t('features.approvalsAdmin.approvalDomainsCountAndTotal', {
                  count,
                  total: approvalDomains.length,
                })}
              </>
            </span>
          </div>
        )
      },
    }),
    {
      header: t('features.approvalsAdmin.domainType'),
      enableColumnFilter: true,
      filterFn: 'equalsString',
      accessorFn: (row) => titleize(row.type),
      footer: () => (
        <span className="px-3 whitespace-nowrap italic">
          {t('features.approvalsAdmin.domainTypesWithCount', {
            count: uniqueCountFromItems(
              visibleRows.map(({ original }) => original),
              ({ type }) => type,
            ),
          })}
        </span>
      ),
    } as ColumnDef<DomainWithApprovals>,
    {
      id: 'approvals',
      header: () => (
        <div className="text-center text-neutral-900 grow flex flex-col gap-3.5 justify-center min-w-[150px]">
          <div className="text-sm font-semibold">
            {renderDateRange(weekStart, weekEnd, t, language, {
              month: 'short',
            })}
          </div>
          <div className="text-2xl font-light flex gap-1 justify-center">
            <span>
              {weekStart.toLocaleString(
                { day: 'numeric' },
                { locale: language },
              )}
            </span>
            <span>-</span>
            <span>
              {weekEnd.toLocaleString({ day: 'numeric' }, { locale: language })}
            </span>
          </div>
        </div>
      ),
      cell: ({ row }) => (
        <ApprovalsProgress
          approvals={row.original.approvals}
          onSegmentClick={() =>
            navigate(
              `/approvals?${buildWeekDatesSearchParam(weekStart).toString()}`,
            )
          }
        />
      ),
      footer: () => (
        <div className="px-3">
          <ApprovalsProgress
            approvals={visibleRows.flatMap(
              ({ original }) => original.approvals,
            )}
            // TODO: Support click after Approvals redesign (we need to link to approvals page without domain scope)
            // onSegmentClick={() =>
            //   navigate(
            //     `/approvals/${row.original.id}?${buildWeekDatesSearchParam(
            //       weekStart,
            //     ).toString()}`,
            //   )
            // }
          />
        </div>
      ),
    } as ColumnDef<DomainWithApprovals>,
    {
      header: t('common.approvers'),
      enableColumnFilter: true,
      filterFn: 'includesString',
      accessorFn: (row) =>
        row.approverUsers.map((user) => user.name).join(', '),
      footer: () => (
        <span className="px-3 whitespace-nowrap italic">
          {t('features.approvalsAdmin.approversWithCount', {
            count: uniqueCountFromItems(
              visibleRows.map(({ original }) => original),
              ({ approverUsers }) => approverUsers.map(({ id }) => id),
            ),
          })}
        </span>
      ),
    } as ColumnDef<DomainWithApprovals>,
    {
      header: t('common.facilities'),
      enableColumnFilter: true,
      filterFn: 'includesString',
      accessorFn: (row) =>
        [
          ...new Set(
            row.approvals.map((approval) => approval.timeCard.facility.name),
          ),
        ].join(', '),
      footer: () => (
        <span className="px-3 whitespace-nowrap italic">
          {t('common.facilityCount', {
            count: uniqueCountFromItems(
              visibleRows.map(({ original }) => original),
              ({ approvals }) =>
                approvals.map(({ timeCard }) => timeCard.facility.id),
            ),
          })}
        </span>
      ),
    } as ColumnDef<DomainWithApprovals>,
  ]

  const table = useReactTable({
    data: approvalDomains,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onColumnFiltersChange,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange,
    defaultColumn: {
      // Disable filtering by default
      enableColumnFilter: false,
    },
  })

  const visibleRows = useMemo(
    () =>
      table.getRowModel().rows.filter((row) => !shouldHideRow(row.original)),
    [table, shouldHideRow],
  )

  // TODO: Explore using ScrollableTable
  return (
    <div className="px-8 flex flex-col flex-grow">
      <div className="relative flex-grow">
        <div className="absolute top-0 bottom-0 left-0 right-0 overflow-auto">
          <table className="min-w-full text-sm">
            <thead className="sticky top-0 shadow z-10">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className="p-4 px-4 py-6 font-semibold text-left align-top border border-t-0 cursor-pointer bg-neutral-100 text-neutral-900 border-neutral-300 hover:bg-neutral-200 first-of-type:border-l-0 last-of-type:border-r-0"
                      onClick={header.column.getToggleSortingHandler()}
                      colSpan={header.colSpan}
                    >
                      <div className="flex justify-between">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}

                        {header.column.getIsSorted() && (
                          <FontAwesomeIcon
                            icon={
                              header.column.getIsSorted() === 'asc'
                                ? faArrowDownAZ
                                : faArrowUpAZ
                            }
                          />
                        )}
                      </div>
                      {header.column.getCanFilter() && (
                        <div className="max-w-sm">
                          <TableFilterField
                            table={table}
                            column={header.column}
                          />
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                if (shouldHideRow(row.original)) return null

                return (
                  <tr key={row.id} className="hover:bg-neutral-100 group/row">
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="px-4 h-16 border border-neutral-300 first-of-type:border-l-0 last-of-type:border-r-0"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
            <tfoot className="sticky" style={{ insetBlockEnd: 0 }}>
              {table.getFooterGroups().map((footerGroup) => (
                <Tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <td
                      key={header.id}
                      className="bottom-0 font-normal text-left border-t border-r bg-neutral-100 border-neutral-300"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext(),
                          )}
                    </td>
                  ))}
                </Tr>
              ))}
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  )
}
