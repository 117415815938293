import { secondsToHHMM } from '@utils/time'
import { ReportTypeMap } from '../types'

export const formattedColumnTotal = <T extends keyof ReportTypeMap>(
  reportType: T,
  columnId: ReportTypeMap[T]['columnId'],
  value: number,
  language: string,
) => {
  if (reportType === 'time_log') {
    switch (columnId) {
      case 'time-log.duration':
      case 'time-log.work-duration':
      case 'time-log.straight-time':
      case 'time-log.overtime':
      case 'time-log.double-time':
      case 'time-log.toil':
      case 'time-log.toil1_5x':
      case 'time-log.time-off-duration':
      case 'time-log.leave-duration':
      case 'time-log.holiday-duration':
        return secondsToHHMM(value, { language })
    }
  }

  return value
}
