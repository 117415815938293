import { FC, useMemo } from 'react'
import { useTaggedTime } from '@hooks/useTaggedTime'
import { createTotalByTagRenderObj } from '@utils/taggedTime'
import { Spinner } from 'components/loaders'

interface Props {
  timeCard: TimeCard
}

// TODO: Consider if we can share some of this with ApprovalTooltip
//       after holiday and leave/time off are added to the ApprovalTooltip —JR
export const TaggedTimeTooltipTable: FC<Props> = ({ timeCard }) => {
  const { data: taggedTime, isLoading } = useTaggedTime(timeCard.id)

  const tagTotals = useMemo(
    () => (taggedTime ? createTotalByTagRenderObj(taggedTime) : {}),
    [taggedTime],
  )

  if (isLoading || taggedTime === undefined) return <Spinner />

  return (
    <table className="mx-auto">
      <tbody>
        {Object.entries(tagTotals).map(([key, value]) => (
          <tr key={key}>
            <td>{key}</td>
            <td className="pl-4">{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
