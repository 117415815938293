import { secondsToHHMM } from '@utils/time'
import { twMerge } from 'tailwind-merge'

interface Props {
  total: number
  row: number
}

export function TaskTotalCell(props: Props) {
  return (
    <div
      className="flex items-center justify-end col-start-totalByTask border-t border-dashed border-neutral-300 first:border-t-0"
      style={{ gridRowStart: props.row }}
    >
      <div
        className={twMerge(
          'text-sm text-neutral-900',
          props.total > 0 && 'font-bold',
        )}
      >
        {secondsToHHMM(props.total) || '-'}
      </div>
    </div>
  )
}
