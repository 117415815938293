import { FC } from 'react'
import { useInView } from 'react-intersection-observer'

interface Props {
  expectedItemHeight: number
  children: React.ReactNode
}

// Renders a placeholder element with the expected height of the item
// when out of view.
export const InfiniteScrollListItem: FC<Props> = ({
  children,
  expectedItemHeight,
}) => {
  const { ref, inView } = useInView()

  const style = inView ? {} : { height: `${expectedItemHeight}px` }

  return (
    <div ref={ref} style={style} className="flex flex-col">
      {inView && children}
    </div>
  )
}
