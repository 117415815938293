import { faLock as faLockSolid } from '@fortawesome/pro-solid-svg-icons'
import { faLock, faLockA } from '@fortawesome/pro-regular-svg-icons'
import { FC } from 'react'
import { DayLockType } from 'types'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { twMerge } from '@lib/tailwind-merge'

export const LockIcon: FC<
  { type?: DayLockType } & Omit<FontAwesomeIconProps, 'icon'>
> = ({ type, className, ...props }) => {
  if (type === undefined) return null

  const icon = { unsubmit: faLock, unapprove: faLockA, full: faLockSolid }[type]
  return (
    <FontAwesomeIcon
      fixedWidth={true}
      icon={icon}
      size="sm"
      className={twMerge('text-neutral-900', className)}
      {...props}
    />
  )
}
