import { useWorkdayTimeBlockErrors } from '@hooks/useWorkdayTimeBlockErrors'
import { Spinner } from 'components/loaders'
import { FC } from 'react'

interface Props {
  timeCard: AdminTimeCard
  className?: string
}

export const WorkdayTimeBlockErrors: FC<Props> = ({ timeCard, className }) => {
  const { data: errors, isLoading } = useWorkdayTimeBlockErrors(timeCard.id)

  if (isLoading)
    return (
      <div className="text-center m-4">
        <Spinner />
      </div>
    )

  return (
    <div className={className}>
      {errors?.map((error) => (
        <div key={error.id}>{error.errorMessage}</div>
      ))}
    </div>
  )
}
