import { twMerge } from 'tailwind-merge'

interface TableProps {
  children?: React.ReactNode
  className?: string
}

export function Table(props: TableProps) {
  return (
    <div
      className={twMerge(
        'grid auto-rows-fr grid-areas-entries grid-cols-entries',
        props.className,
      )}
    >
      {props.children}
    </div>
  )
}
