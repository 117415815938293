import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, ReactNode } from 'react'

interface Props {
  children: ReactNode
  expanded: boolean
  icon: IconDefinition
  label: string
  onClick: () => void
}

export const ParentItem: FC<Props> = ({
  children,
  expanded,
  icon,
  label,
  onClick,
}) => {
  return (
    <>
      <li
        className="flex flex-row items-center text-sm font-medium cursor-pointer text-neutral-300 h-11 hover:bg-neutral-700 pl-4 gap-2"
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} className="text-neutral-400" />
        {label}
        <FontAwesomeIcon
          icon={expanded ? faChevronUp : faChevronDown}
          className="ml-auto mr-5 text-neutral-400"
        />
      </li>
      {expanded ? (
        <li>
          <ul>{children}</ul>
        </li>
      ) : null}
    </>
  )
}
