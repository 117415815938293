import { useCallback } from 'react'
import { useModal } from '@hooks/useModal'
import { useTranslation } from 'react-i18next'
import { ReportTypeMap } from '../types'
import { useCreateReport } from './useSavedReports'
import { ReportForm } from '../components/ReportForm'
import { toast } from '@lib/toasts'
import { useNavigate } from 'react-router-dom'

type ReportTypeArgs<RT extends keyof ReportTypeMap> = {
  reportType: RT
  filters: ReportTypeMap[RT]['filters']
  columns: ReportTypeMap[RT]['columnId'][]
  sortColumn: ReportTypeMap[RT]['columnId']
}

type CreateReportArgs = {
  sortDirection: 'desc' | 'asc'
} & ReportTypeArgs<keyof ReportTypeMap>

export const useSaveNewReport = () => {
  const modal = useModal()
  const { t } = useTranslation()
  const createReport = useCreateReport()
  const navigate = useNavigate()

  const saveNewReport = useCallback(
    ({
      filters,
      columns,
      sortColumn,
      sortDirection,
      reportType,
    }: CreateReportArgs) => {
      const save = async (
        reportName: string,
        reportDescription: string,
        isPublic: boolean,
        modalId: number,
      ) => {
        try {
          const { data: report } = await createReport.mutateAsync({
            name: reportName,
            description: reportDescription,
            isPublic,
            filters,
            columns,
            sortColumn,
            sortDirection,
            type: reportType,
          })

          modal.close(modalId)

          navigate(`/reporting/${reportType}_reports/${report.id}`)

          toast({
            title: t('features.reporting.reportCreated'),
            variant: 'success',
            content: t('features.reporting.successfullySavedNewReport', {
              name: reportName,
            }),
          })
        } catch (error) {
          toast({
            title: t('features.reporting.unableToCreateReport'),
            variant: 'error',
            content: t('features.reporting.failedToSaveReport'),
          })
        }
      }

      const id = modal.form({
        title: t('features.reporting.saveNewReport'),
        content: (
          <ReportForm
            save={(reportName, reportDescription, isPublic) =>
              void save(reportName, reportDescription, isPublic, id)
            }
            isSaving={createReport.isLoading}
            cancel={() => modal.close(id)}
          />
        ),
      })
    },
    [modal, t, createReport, navigate],
  )

  return { saveNewReport }
}
