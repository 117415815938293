const parseInputValueToSeconds = (value: string): number => {
  // e.g. 42, 120, 500, etc.
  if (value.match(/^\d+$/)) {
    // If the string is just all numbers, interpret them as hours if < 10,
    // and minutes if >= 10
    const num = Number(value)

    if (value.length <= 2) {
      return num < 15
        ? num * 60 ** 2 // interpret all 2-digit numbers as hours, if less than 15
        : num * 60 // interpret as minutes
    } else if (value.length > 2 && value.length <= 4 && num <= 2400) {
      // assume we're working with military time
      const [minOnesPlace, minTensPlace, ...reversedHoursDigits] =
        Array.from(value).reverse()
      const min = Number([minTensPlace, minOnesPlace].join(''))
      const hour = Number(reversedHoursDigits.reverse().join(''))

      return hour * 60 ** 2 + min * 60
    } else {
      // assume seconds
      return num
    }
  }

  // e.g. 1.5, 4.25, 5., etc.
  if (value.match(/^\d*\.\d*$/) && value != '.') {
    // Interpret strings that are digits with a single period
    // and aren't *just* a single period as hours
    // rounded to the nearest minute
    return Math.round(Number(value) * 60) * 60
  }

  // e.g. 1:30, 4:15, 2 : 32, etc.
  if (value.match(/^\d*\s*:\s*\d*$/) && value != ':') {
    // Interpret strings that look like timestamps (: flanked by digits)
    // as timestamps
    return value
      .split(':')
      .reverse()
      .reduce(
        (seconds, part, i) => seconds + Number(part.trim()) * 60 ** (i + 1),
        0,
      )
  }

  // e.g. 1h 30m, 2h15m, 4h, 30m, etc.
  const hhmmMatch = value.match(/^((?<hours>\d+)h)?\s*((?<minutes>\d+)m)?$/)
  if (hhmmMatch) {
    let seconds = 0
    const { hours, minutes } = hhmmMatch.groups as {
      hours?: string
      minutes?: string
    }

    if (hours) seconds += Number(hours) * 60 ** 2
    if (minutes) seconds += Number(minutes) * 60

    return seconds
  }

  // Unable to parse, interpret as 0
  return 0
}

export { parseInputValueToSeconds }
