import { FC, ReactNode } from 'react'

interface Props {
  data?: Map<ReactNode, ReactNode> | Record<string, ReactNode>
}
export const TooltipTable: FC<Props> = ({ data }) => {
  if (!data) return null

  const d = data instanceof Map ? [...data] : Object.entries(data)

  return (
    <table>
      <tbody>
        {d.map(([key, value], index) => (
          <tr key={index}>
            <td>{key}</td>
            <td className="pl-4">{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
