import { noop } from 'utils/noop'
import {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  useState,
} from 'react'
import { Input } from '../input'
import { secondsToHHMM } from '@utils/time'
import { parseInputValueToSeconds } from '@utils/entry_input'

interface Props
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'onChange' | 'value'
  > {
  onChange: (seconds?: number) => void
  value?: number // seconds
}

export const TimeDurationInput: FC<Props> = ({
  onBlur = noop,
  onChange,
  onFocus = noop,
  value,
  ...props
}) => {
  const [rawInput, setRawInput] = useState('')
  const [isActive, setIsActive] = useState(false)

  const getDisplayValue = () => {
    if (isActive) return rawInput
    if (!value) return rawInput

    return secondsToHHMM(value)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const raw = event.currentTarget.value
    setRawInput(raw)

    const parseResult = parseInputValueToSeconds(raw)
    onChange(parseResult === 0 ? undefined : parseResult)
  }

  const handleBlur: Props['onBlur'] = (event) => {
    setIsActive(false)
    onBlur(event)

    if (!value) return setRawInput('')

    setRawInput(secondsToHHMM(value))
  }

  const handleFocus: Props['onFocus'] = (event) => {
    setIsActive(true)
    onFocus(event)

    if (!value) return setRawInput('')

    setRawInput(secondsToHHMM(value))
  }

  return (
    <Input
      {...props}
      onChange={handleChange}
      value={getDisplayValue()}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  )
}
