import { useAddTimeCard as useAddTimeCardBase } from '@features/time-logging/hooks/useTimeCardManagement'
import { useDateTimeWithLocale } from '@hooks/useDateTimeWithLocale'
import { toast } from 'lib/toasts'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useAddTimeCard = (date: DateTime) => {
  const { t } = useTranslation()
  const withLocale = useDateTimeWithLocale()

  const { mutate, ...rest } = useAddTimeCardBase({
    onSuccess: () => {
      toast({
        title: t('features.timeLogging.timeCardCreated'),
        content: t('features.timeLogging.timeCardSuccessfullyCreated', {
          date: withLocale(date).toLocaleString({ weekday: 'long' }),
        }),
        variant: 'default',
      })
    },
    onError: () => {
      toast({
        title: t('features.timeLogging.errorCreatingTimeCard'),
        content: t('features.timeLogging.timeCardCouldNotBeCreated'),
        variant: 'error',
      })
    },
  })

  return useMemo(
    () => ({ ...rest, mutate: () => mutate(date) }),
    [mutate, rest, date],
  )
}
