import { FC, ReactNode } from 'react'
import { twMerge } from '@lib/tailwind-merge'
import { Label } from '../label'

const Errors: FC<{ errors: string[]; hidden: boolean }> = ({
  errors,
  hidden,
}) => {
  if (hidden) return null

  return (
    <>
      <span key={errors[0]} className="mt-1 text-xs text-red min-h-[1rem]">
        {errors[0]}
      </span>
      {errors.slice(1).map((e) => (
        <span key={e} className="mt-1 text-xs text-red min-h-[1rem]">
          {e}
        </span>
      ))}
    </>
  )
}

interface Props {
  className?: string
  errors?: string[]
  htmlFor: string
  label: string
  leaveRoomForErrors?: boolean
  children: (id: string) => ReactNode
}

export const Field: FC<Props> = ({
  className,
  errors = [],
  htmlFor,
  label,
  leaveRoomForErrors = true,
  children,
}) => {
  return (
    <div className={twMerge('flex flex-col', className)}>
      <Label
        htmlFor={htmlFor}
        className="flex flex-1 mb-1 text-xs font-medium text-neutral-400"
      >
        {label}
      </Label>
      {children(htmlFor)}
      <Errors errors={errors} hidden={!leaveRoomForErrors} />
    </div>
  )
}
