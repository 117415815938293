import { useQueryClient, useMutation } from '@tanstack/react-query'
import { approveApprovals, unapproveApprovals } from '@lib/api'
import { approvalKeys } from '@lib/keys'

export const useBulkApproval = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (approvalIds: number[]) => {
      return approveApprovals(approvalIds)
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(approvalKeys.all)
      },
    },
  )
}

export const useBulkUnapproval = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (approvalIds: number[]) => {
      return unapproveApprovals(approvalIds)
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(approvalKeys.all)
      },
    },
  )
}
