import { FC, ReactNode, useId } from 'react'
import { useDroppable, Data } from '@dnd-kit/core'
import { twMerge } from '@lib/tailwind-merge'

interface Props {
  children: ReactNode
  data: Data
  className?: string
}

export const DroppableSlot: FC<Props> = ({ children, data, className }) => {
  const id = useId()
  const { setNodeRef: droppableRef, isOver } = useDroppable({ id, data })

  return (
    <div
      ref={droppableRef}
      className={twMerge(
        'border-t',
        isOver && 'border-primary-600',
        !isOver && 'border-transparent',
        className,
      )}
    >
      {children}
    </div>
  )
}
