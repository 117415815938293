import { useQueryClient, useMutation } from '@tanstack/react-query'
import { refreshWorkdayProjects } from '@lib/api'
import { adminProjectsKeys } from '@lib/keys'

export const useRefreshWorkdayProjects = () => {
  const queryClient = useQueryClient()

  return useMutation(
    () => {
      return refreshWorkdayProjects()
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(adminProjectsKeys.all)
      },
    },
  )
}
