import { useQuery } from '@tanstack/react-query'
import { searchDayLocks } from '@lib/api'
import { dayLockKeys } from '@lib/keys'
import { useWeekDates } from './useWeekDates'

interface Options {
  userId: number
}

export const useDayLocks = ({ userId }: Options) => {
  const { start, end } = useWeekDates()

  return useQuery(dayLockKeys.searchByUser(userId, start, end), () =>
    searchDayLocks(userId, start, end),
  )
}
