import { FilterWorkersWithVisibleItems } from 'components/buttons'

type SerializedParams = Partial<
  Record<
    | 'hiddenStatuses'
    | 'facilityIds'
    | 'approvalGroupIds'
    | 'hiddenDays'
    | 'workerNameQuery'
    | 'workersWithVisibleTimeCardsFilter',
    string
  >
>

export const serializeParams = ({
  hiddenStatuses,
  facilityIds,
  approvalGroupIds,
  hiddenDays,
  workerNameQuery,
  filterEmployeesWithVisibleTimeCards,
}: {
  hiddenStatuses?: Set<Status>
  facilityIds?: Set<number>
  approvalGroupIds?: Set<number>
  hiddenDays?: Set<number>
  workerNameQuery?: string
  filterEmployeesWithVisibleTimeCards?: FilterWorkersWithVisibleItems
}) => {
  const params: SerializedParams = {}

  if (hiddenStatuses && hiddenStatuses.size > 0) {
    params.hiddenStatuses = JSON.stringify(Array.from(hiddenStatuses))
  }

  if (facilityIds && facilityIds.size > 0)
    params.facilityIds = JSON.stringify(Array.from(facilityIds))

  if (approvalGroupIds && approvalGroupIds.size > 0)
    params.approvalGroupIds = JSON.stringify(Array.from(approvalGroupIds))

  if (hiddenDays && hiddenDays.size > 0)
    params.hiddenDays = JSON.stringify(Array.from(hiddenDays))

  if (workerNameQuery) params.workerNameQuery = workerNameQuery
  if (filterEmployeesWithVisibleTimeCards)
    params.workersWithVisibleTimeCardsFilter =
      filterEmployeesWithVisibleTimeCards

  return params
}

const setFromParam = <T>(param: string | null) =>
  new Set(param ? (JSON.parse(param) as T[]) : undefined)

export const deserializeParams = (params: URLSearchParams) => {
  const hiddenStatuses = setFromParam<Status>(params.get('hiddenStatuses'))
  const facilityIds = setFromParam<number>(params.get('facilityIds'))
  const approvalGroupIds = setFromParam<number>(params.get('approvalGroupIds'))
  const hiddenDays = setFromParam<number>(params.get('hiddenDays'))
  const workerNameQuery = params.get('workerNameQuery') || ''
  const filterEmployeesWithVisibleTimeCards = params.get(
    'workersWithVisibleTimeCardsFilter',
  ) as FilterWorkersWithVisibleItems | undefined

  return {
    hiddenStatuses,
    facilityIds,
    approvalGroupIds,
    hiddenDays,
    workerNameQuery,
    filterEmployeesWithVisibleTimeCards,
  }
}
