type Interval = { startAt: number; endAt: number }

export const sortIntervals = <T extends Interval>(
  intervals: readonly T[],
): T[] => {
  return [...intervals].sort((i1, i2) => i1.startAt - i2.startAt)
}

// Sorts and deduplicates objects that have a startAt and endAt property
export const deduplicateIntervals = <T extends Interval>(
  intervals: readonly T[],
) => {
  const sortedIntervals = sortIntervals(intervals)
  const uniqueIntervals = []
  for (let i = 0; i < sortedIntervals.length; i++) {
    const currentInterval = sortedIntervals[i]

    for (let j = i + 1; j < sortedIntervals.length; j++) {
      const nextInterval = sortedIntervals[j]

      if (currentInterval.endAt < nextInterval.startAt) break
      if (currentInterval.endAt > nextInterval.endAt) {
        i = j + 1
        continue
      }

      currentInterval.endAt = nextInterval.endAt
      i = j
    }
    uniqueIntervals.push(currentInterval)
  }

  return uniqueIntervals
}

export const filterOutUndefinedIntervals = <R extends Interval>(
  intervals: readonly Partial<R>[],
): R[] => {
  return [...intervals].filter(
    (interval) =>
      interval.startAt !== undefined && interval.endAt !== undefined,
  ) as R[]
}
