import { useTimeCards } from '@hooks/useTimeCards'
import { col } from '@utils/styles'
import classNames from 'classnames'
import { WeeklyProgressGauge } from 'components/indicators/weekly-progress-gauge'
import { Spinner } from 'components/loaders'
import { FC, useMemo } from 'react'
import { WorkedAndAllocated } from './WorkedAndAllocated'
import { useAbsences } from '@hooks/useAbsences'
import { Day } from './Day'
import { useTotalsForWeek } from '@features/time-logging/hooks/useTotalsForWeek'
import { DayAnnotation } from './day-annotation'
import { useWeekDates } from '@hooks/useWeekDates'
import { useHolidays } from '@features/time-logging/hooks/useHolidays'
import { useEmployee } from '@hooks/useEmployee'
import { dateIntersectsAbsence } from '@utils/absence'
import { useUserId } from '@features/time-logging/hooks/useUserId'
import { useDayLocks } from '@hooks/useDayLocks'

interface Props {
  onTimeOffClick: () => void
  readOnly: boolean
}

export const TimeCards: FC<Props> = ({ onTimeOffClick, readOnly }) => {
  const userId = useUserId()
  const employeeQuery = useEmployee({ userId })
  const timeCardsQuery = useTimeCards({ userId })
  const holidaysQuery = useHolidays()
  const absencesQuery = useAbsences({ userId })
  const { leaveSeconds, holidaySeconds, allocatedSeconds } = useTotalsForWeek()
  const { range } = useWeekDates()
  const { data: dayLocks } = useDayLocks({ userId })

  const absences = absencesQuery.data ?? []

  const cardsLoading = useMemo(
    () => timeCardsQuery.data === undefined,
    [timeCardsQuery],
  )

  const cards = useMemo(() => {
    if (timeCardsQuery.data === undefined) return []

    let activeCardCount = 0

    const cards: ({ active: boolean; numLabel: number } & TimeCard)[] = []

    timeCardsQuery.data.forEach((card) => {
      const active = card.id !== 0
      const numLabel = active ? activeCardCount + 1 : activeCardCount
      cards.push({ ...card, active, numLabel })
      activeCardCount = numLabel
    })

    return cards
  }, [timeCardsQuery.data])

  const totalHours = Object.values(
    employeeQuery.data?.workSchedule ?? {},
  ).reduce((sum, hoursPerDay) => sum + parseFloat(hoursPerDay), 0)

  return (
    <div className="px-8 py-5 border-b bg-neutral-50 border-neutral-200">
      <div className="grid grid-areas-entries grid-cols-entries mb-[-2px]">
        {range.map((date) => (
          <DayAnnotation
            key={`annotations-${date.toString()}`}
            date={date}
            absences={absences}
            holidays={holidaysQuery?.data ?? []}
            onTimeOffClick={onTimeOffClick}
          />
        ))}
      </div>
      <div className="grid grid-areas-entries grid-cols-entries">
        <WorkedAndAllocated />
        {cardsLoading
          ? range.map((date) => (
              <div
                key={date.toString()}
                className={classNames(
                  'flex items-center justify-center',
                  col(date),
                )}
              >
                <Spinner />
              </div>
            ))
          : cards.map((card) => (
              <div
                className={`flex px-1 ${col(card.date)}`}
                key={`time-card-slot-${card.date.toISO()}`}
              >
                <Day
                  date={card.date}
                  numberLabel={card.numLabel}
                  absences={absences.filter((absence) =>
                    dateIntersectsAbsence(card.date, absence),
                  )}
                  readOnly={readOnly}
                  lock={dayLocks?.find((lock) =>
                    lock.date.hasSame(card.date, 'day'),
                  )}
                />
              </div>
            ))}
        <div className="flex flex-col justify-end grid-in-totalByTask">
          <WeeklyProgressGauge
            numLeaveSeconds={leaveSeconds}
            numHolidaySeconds={holidaySeconds}
            numWorkSeconds={allocatedSeconds}
            totalHours={totalHours}
            loading={cardsLoading}
            className="grow"
          />
        </div>
      </div>
    </div>
  )
}
