import { FC, ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons'
import { twMerge } from '@lib/tailwind-merge'

interface Props {
  children: ReactNode | ReactNode[]
  removing?: boolean
}

export const Ghost: FC<Props> = ({ children, removing = false }) => {
  return (
    <div
      className={twMerge(
        'p-3 rounded shadow bg-white/90 flex gap-2 items-center z-50',
        removing ? ' text-neutral-500 ' : 'text-primary-700',
      )}
    >
      {removing && <FontAwesomeIcon icon={faTrashCan} />}
      {children}
    </div>
  )
}
