import * as changeKeys from 'change-case/keys'
import { toDotSnakeCase } from '@utils/toDotSnakeCase'
import { ReportFilter } from '@lib/api'
import { ReportTypeMap } from '../types'

// Client uses camelCased column accessor keys and filter keys, but API expects snake
export const filterApiSerializer = <RT extends keyof ReportTypeMap>(
  filters: ReportFilter<RT>,
) =>
  filters.map((filter) =>
    changeKeys.snakeCase({
      ...filter,
      column: toDotSnakeCase(filter.column),
    }),
  )
