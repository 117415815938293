import { useEntriesSumByWeek } from '@features/time-logging/hooks/useEntrySums'
import { useAbsences } from '../../../hooks/useAbsences'
import { useEmployee } from '@hooks/useEmployee'
import { DateTime, Interval } from 'luxon'
import { useWeekDates } from '@hooks/useWeekDates'
import { getOverlappingDaysInIntervals } from 'date-fns'
import { useUserId } from './useUserId'
import { useHolidaysByCalendar } from '@hooks/useHolidays'
import { isTimeOff } from '@utils/absence'

const getDayOfWeek = (date: DateTime): DayOfWeek => {
  return date.toFormat('EEEE').toLowerCase() as DayOfWeek
}

export const useTotalsForWeek = (
  startDateOverride?: DateTime,
  endDateOverride?: DateTime,
) => {
  const { start: startDate, end: endDate } = useWeekDates()
  const start = startDateOverride ?? startDate
  const end = endDateOverride ?? endDate

  const userId = useUserId()
  const employeeQuery = useEmployee({ userId, startDateOverride: start })
  const absencesQuery = useAbsences({
    userId,
    startDateOverride: start,
    endDateOverride: end,
  })
  const holidayQuery = useHolidaysByCalendar({
    startDateOverride: start,
    endDateOverride: end,
    holidayCalendarId: employeeQuery.data?.holidayCalendarId,
  })
  const allocatedSeconds = useEntriesSumByWeek(start, end)

  // TODO: This should use the employee's scheduled work hours to calculate
  //       leave hours for each day instead of the current hardcoded 8 hours
  const workDaySeconds = 8 * 60 ** 2

  const leaveSeconds =
    absencesQuery.data?.reduce((total, absence) => {
      if (isTimeOff(absence)) return total + absence.duration

      return (
        total +
        getOverlappingDaysInIntervals(
          {
            start: absence.startDate.toJSDate(),
            end: absence.estimatedEndDate.toJSDate(),
          },
          { start: start.toJSDate(), end: end.toJSDate() },
        ) *
          workDaySeconds
      )
    }, 0) ?? 0

  const dayOfWeekWithHolidays =
    holidayQuery.data?.reduce((acc, holiday) => {
      const days = Interval.fromDateTimes(
        holiday.startDate,
        holiday.endDate.endOf('day'),
      ).splitBy({
        days: 1,
      })

      for (const day of days) {
        acc.add(getDayOfWeek(day.start))
      }

      return acc
    }, new Set<DayOfWeek>()) ?? new Set()

  const holidaySeconds = Array.from(dayOfWeekWithHolidays).reduce(
    (sum, dayOfWeek) => {
      return (
        sum +
        parseFloat(employeeQuery.data?.workSchedule[dayOfWeek] ?? '0') * 60 ** 2
      )
    },
    0,
  )

  const totalSeconds = leaveSeconds + holidaySeconds + allocatedSeconds

  return {
    leaveSeconds,
    holidaySeconds,
    allocatedSeconds,
    totalSeconds,
  }
}
