import useKeyPress from '@hooks/useKeyPress'
import { useTimeCards } from '@hooks/useTimeCards'
import { useMemo } from 'react'
import GridColumn from '../table/GridColumn'
import EntryCell from './cells/entry-cell/EntryCell'
import { useUserId } from '@features/time-logging/hooks/useUserId'
import { DateTime } from 'luxon'
import { totalWorkedInSeconds } from 'utils/timeCards'

interface Props {
  project: Project
  tasks: SelectedTask[]
  headerHoverHandler: (date: DateTime | null) => void
  yHoverHandler: (value: React.SetStateAction<number>) => void
  focusedCell: Cell
  rowStart: number
  onSaveViaEnter: () => void
  onCellFocus: (cell: Cell) => void
  readOnly: boolean
}

export function EntriesGrid(props: Props) {
  const userId = useUserId()
  const timeCardsQuery = useTimeCards({ userId })
  const cards = useMemo(() => timeCardsQuery.data ?? [], [timeCardsQuery.data])
  const { keyPressed: metaKeyPressed } = useKeyPress(
    // Switch modes when Meta (Cmd on Mac, Windows on Windows) or Ctrl key is pressed,
    // Meta (Cmd) is a more common modifier on Mac, and Ctrl is more common on Windows,
    // so we simply support both instead of attempting to identify the OS.
    ['Meta', 'Control'],
    {},
    {
      shouldPreventDefault: false,
    },
  )

  return (
    <>
      {cards.map((card, cardIndex) => (
        <GridColumn
          date={card.date}
          key={`EntriesColumn${props.project.id}-${card.date.toString()}`}
        >
          {props.tasks.map((task, rowIndex) => (
            <EntryCell
              timeCardId={card.id}
              key={`EntryCell${card.id}-${task.id}`}
              date={card.date}
              task={task}
              xHoverHandler={props.headerHoverHandler}
              yHoverHandler={props.yHoverHandler}
              rowIndex={rowIndex}
              focused={
                props.focusedCell.x === cardIndex &&
                props.focusedCell.y === props.rowStart + rowIndex
              }
              x={cardIndex}
              y={props.rowStart + rowIndex}
              editable={
                card.id != 0 &&
                card.date <= DateTime.now() &&
                !props.readOnly &&
                !card.submitted
              }
              onSaveViaEnter={props.onSaveViaEnter}
              onFocus={props.onCellFocus}
              incrementDecrementMode={metaKeyPressed ? 'fill' : 'step'}
              timeCardTotalWorkedInSeconds={totalWorkedInSeconds(card)}
            />
          ))}
        </GridColumn>
      ))}
    </>
  )
}
