import { useContext, useCallback } from 'react'
import { ModalContext } from 'providers/ModalProvider'
import { Spinner } from 'components/loaders'
import { useTranslation } from 'react-i18next'

export const useModal = () => useContext(ModalContext)

export const usePendingModal = () => {
  const modal = useModal()
  const { t } = useTranslation()

  const open = useCallback(
    (message?: string) =>
      modal.open({
        content: (
          <>
            <Spinner className="mr-2" />
            {message ?? t('common.pleaseWait')}
          </>
        ),
        dismissable: false,
      }),
    [modal, t],
  )

  return { open }
}
