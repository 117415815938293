import { secondsToHHMM } from '@utils/time'
import { twMerge } from 'tailwind-merge'

type Props = {
  gridClass: string
  skipBorder?: boolean
} & ({ total: number } | { percentage: number })

export function SummaryCell(props: Props) {
  return (
    <div
      className={twMerge(
        props.gridClass,
        'h-10 mt-2 border-dashed border-neutral-300 flex items-center justify-end',
        !props.skipBorder && 'border-r',
        props.gridClass === 'grid-in-totalByTask' ? 'pr-0' : 'pr-3',
      )}
    >
      <div
        className={twMerge(
          'text-sm text-neutral-900',
          'total' in props && props.total > 0 && 'font-bold',
          'percentage' in props && props.percentage > 0 && 'font-bold',
        )}
      >
        {'total' in props && (secondsToHHMM(props.total) || '-')}
        {'percentage' in props &&
          (props.percentage > 0 ? `${props.percentage}%` : '-')}
      </div>
    </div>
  )
}
