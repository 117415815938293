import { FC } from 'react'
import { approvalStatus } from '../utils/approvalStatus'
import { statusColors, statusIcons } from 'utils/statuses'
import { Status } from 'types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StatusSegment } from 'components/indicators/status-segment'

interface Props {
  approvals: Approval[]
  onSegmentClick?: (status: Status) => void
}

export const ApprovalsProgress: FC<Props> = ({ approvals, onSegmentClick }) => {
  const openApprovals = approvals.filter(
    (approval) => approvalStatus(approval) === Status.Open,
  )
  const submittedApprovals = approvals.filter(
    (approval) => approvalStatus(approval) === Status.Submitted,
  )
  const approvedApprovals = approvals.filter(
    (approval) => approvalStatus(approval) === Status.Approved,
  )

  if (openApprovals.length === 0 && submittedApprovals.length === 0) {
    if (approvedApprovals.length === 0) return null

    return (
      <div className="flex justify-center items-center gap-1 text-xs font-medium text-neutral-600">
        <FontAwesomeIcon
          icon={statusIcons[Status.Approved]}
          className={`text-${statusColors[Status.Approved]}-500`}
        />
        {approvedApprovals.length}
      </div>
    )
  }

  return (
    <div className="flex w-full gap-0.5 text-xs font-medium">
      <StatusSegment
        status={Status.Open}
        value={openApprovals.length}
        total={approvals.length}
        onClick={onSegmentClick}
      />
      <StatusSegment
        status={Status.Submitted}
        value={submittedApprovals.length}
        total={approvals.length}
        onClick={onSegmentClick}
      />
      <StatusSegment
        status={Status.Approved}
        value={approvedApprovals.length}
        total={approvals.length}
        onClick={onSegmentClick}
      />
    </div>
  )
}
