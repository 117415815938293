import { FC, ReactNode, useMemo } from 'react'
import { useTaggedTime } from '@hooks/useTaggedTime'
import { useTranslation } from 'react-i18next'
import { createTotalByTagRenderObj } from '@utils/taggedTime'
import { TooltipTable } from 'components/indicators/tooltip-table'

interface Props {
  timeCardId?: number
  extraData: Map<ReactNode, ReactNode>
}

export const ApprovalTooltip: FC<Props> = ({ timeCardId, extraData }) => {
  const { data: taggedTime } = useTaggedTime(timeCardId)
  const { t } = useTranslation()

  const tableData = useMemo(() => {
    const massageTaggedTimeIntoTableData = () => {
      if (!taggedTime) return []

      return Object.entries(createTotalByTagRenderObj(taggedTime))
    }

    return new Map([...extraData, ...massageTaggedTimeIntoTableData()])
  }, [taggedTime, extraData])

  if (tableData.size === 0)
    return <div className="italic">{t('features.approvals.regularHours')}</div>

  return <TooltipTable data={tableData} />
}
