import { isSearchQuery } from './filterTypes'
import {
  DateRangeFilterValue,
  NumericRangeFilterValue,
  SearchQueryFilterValue,
} from '../types'

// type Value = FilterValue<keyof Filters>

type Value =
  | SearchQueryFilterValue
  | Array<unknown>
  | undefined
  | DateRangeFilterValue
  | NumericRangeFilterValue

const isDateRange = (value: Value): value is DateRangeFilterValue => {
  if (value === undefined) return false
  if (value instanceof Array) return false
  if (typeof value === 'string') return false

  return (
    ('start' in value || 'end' in value || 'relativeDateRange' in value) &&
    (Boolean(value.start) ||
      Boolean(value.end) ||
      Boolean(value.relativeDateRange))
  )
}

const isNumericRange = (value: Value): value is NumericRangeFilterValue => {
  if (value === undefined) return false
  if (value instanceof Array) return false
  if (typeof value === 'string') return false

  return (
    ('numericRangeFrom' in value || 'numericRangeTo' in value) &&
    (Boolean(value.numericRangeFrom) || Boolean(value.numericRangeTo))
  )
}

export const normalizeValue = <T extends Value>(v: T): T | undefined => {
  if (v === undefined) return v
  if (isDateRange(v)) return v
  if (isNumericRange(v)) return v
  if (v instanceof Array) return v.length === 0 ? undefined : v

  if (isSearchQuery(v)) {
    if (v.query === undefined) return undefined
    const trimmed = `${v.query}`.trim()
    return trimmed === '' ? undefined : ({ query: v.query } as T)
  }

  return undefined
}
