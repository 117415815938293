import type { StartEndValidation } from '../types'
import { convertTwentyFourHourTimeToSeconds } from './convertTwentyFourHourTimeToSeconds'

export const validateStartEnd = ({
  start,
  end,
}: {
  start?: TwentyFourHourTime
  end?: TwentyFourHourTime
}): StartEndValidation[] => {
  const errors: StartEndValidation[] = []

  if (!start) {
    errors.push('missing-start')
  }

  if (!end) {
    errors.push('missing-end')
  }

  if (start && end) {
    if (
      convertTwentyFourHourTimeToSeconds(start) >=
      convertTwentyFourHourTimeToSeconds(end)
    ) {
      errors.push('invalid-start-end')
    }
  }

  return errors
}
