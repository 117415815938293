import { faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import { faChevronDown, IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, ReactNode } from 'react'
import { twMerge } from '@lib/tailwind-merge'

type Variant = 'neutral' | 'warning' | 'success' | 'white'

const backgroundColor: Record<Variant, string> = {
  neutral: 'bg-neutral-100',
  warning: 'bg-warning-100',
  success: 'bg-success-100',
  white: 'bg-white-100',
}

const borderColor: Record<Variant, string> = {
  neutral: 'border-neutral-400',
  warning: 'border-warning-300',
  success: 'border-success-300',
  white: 'border-neutral-300',
}

const iconColor: Record<Variant, string> = {
  neutral: 'text-neutral-600',
  warning: 'text-warning-800',
  success: 'text-success-900',
  white: 'text-neutral-600',
}

type Props = {
  className?: string
  children: ReactNode
  darken?: boolean
  disabled?: boolean
  icon?: IconDefinition
  onClick: () => void
  onDoubleClick?: () => void
  variant?: Variant
} & ({ mode: 'dropdown'; isExpanded: boolean } | { mode: 'normal' })

export const CharmButton: FC<Props> = ({
  className,
  children,
  darken = false,
  disabled = false,
  icon,
  mode,
  onClick,
  onDoubleClick,
  variant = 'white',
  ...props
}) => {
  return (
    <button
      className={twMerge(
        'flex flex-row h-8 text-sm font-semibold border-none rounded-none text-neutral-700',
        className,
      )}
      disabled={disabled}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      {icon && (
        <div
          className={twMerge(
            'flex items-center pl-4 pr-3 border rounded-full rounded-r-none h-full',
            borderColor[variant],
            backgroundColor[variant],
            darken && 'bg-neutral-100 border-neutral-300',
          )}
        >
          <FontAwesomeIcon
            icon={icon}
            className={darken ? 'text-neutral-400' : iconColor[variant]}
          />
        </div>
      )}
      <div
        className={twMerge(
          'flex items-center pl-4 pr-4 border rounded-full border-neutral-300 h-full grow',
          icon && 'border-l-0 rounded-l-none pl-3',
          darken && 'text-neutral-400',
        )}
      >
        <span className="flex justify-center row grow">{children}</span>
        {mode === 'dropdown' && (
          <FontAwesomeIcon
            className={twMerge(
              'pl-3 text-neutral-500 fa-fw',
              darken && 'text-neutral-400',
            )}
            icon={
              'isExpanded' in props && props.isExpanded
                ? faChevronUp
                : faChevronDown
            }
          />
        )}
      </div>
    </button>
  )
}
