import { useAuth } from '@hooks/useAuth'
import { useEmployee } from '@hooks/useEmployee'
import { useUserId } from './useUserId'
import { useWeekDates } from '@hooks/useWeekDates'

export const useEmployeeName = () => {
  const { start } = useWeekDates()
  const userId = useUserId()
  const employeeQuery = useEmployee({ userId, startDateOverride: start })
  const { user } = useAuth()

  const isSelf = user?.id === userId
  const name = employeeQuery.data?.fullName ?? ''

  return { isSelf, name }
}
