import { noop } from 'utils/noop'
import { DateTime } from 'luxon'
import {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  useState,
} from 'react'
import { Input } from '../input'
import { useDateTimeToLocaleString as useDateTimeToLocaleStringImported } from 'hooks/useDateTimeWithLocale'

interface Props
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'onChange' | 'value'
  > {
  onChange: (value?: DateTime) => void
  timeZone?: string
  useDateTimeToLocaleString?: typeof useDateTimeToLocaleStringImported
  value?: DateTime
}

export const DateInput: FC<Props> = ({
  onBlur = noop,
  onChange,
  onFocus = noop,
  timeZone = 'America/Vancouver',
  useDateTimeToLocaleString = useDateTimeToLocaleStringImported,
  value,
  ...props
}) => {
  const [rawInput, setRawInput] = useState('')
  const [isActive, setIsActive] = useState(false)
  const toLocaleString = useDateTimeToLocaleString()(DateTime.DATE_SHORT)

  const getDisplayValue = () => {
    if (isActive) return rawInput
    if (!value?.isValid) return rawInput

    return toLocaleString(value)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const raw = event.currentTarget.value
    setRawInput(raw)

    const parseResult = DateTime.fromJSDate(new Date(raw)).setZone(timeZone, {
      keepLocalTime: true,
    })
    if (!parseResult.isValid) return onChange()

    onChange(parseResult)
  }

  const handleBlur: Props['onBlur'] = (event) => {
    setIsActive(false)
    onBlur(event)

    if (!value?.isValid) return setRawInput('')

    setRawInput(toLocaleString(value))
  }

  const handleFocus: Props['onFocus'] = (event) => {
    setIsActive(true)
    onFocus(event)

    if (!value?.isValid) return setRawInput('')

    setRawInput(toLocaleString(value))
  }

  return (
    <Input
      {...props}
      onChange={handleChange}
      value={getDisplayValue()}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  )
}
