import { Status } from 'types'
import { StatusCounts } from '../types'

export const getStatusCount = (
  timeCards: { status: Status }[],
): StatusCounts => {
  return timeCards.reduce<StatusCounts>(
    (acc, { status }) => {
      const statusCount = acc[status]
      return { ...acc, [status]: statusCount + 1 }
    },
    {
      [Status.Open]: 0,
      [Status.Approved]: 0,
      [Status.Submitted]: 0,
      [Status.Sent]: 0,
    },
  )
}
