import { useProjectIntegrations } from '@hooks/useProjectIntegrations'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { EntityPicker, EntityItem } from '../entity-picker'

interface Props {
  columnNameTranslationKey: TranslationKey
  onChange: (ids: Set<number>) => void
  selectedIds: Set<number>
}
export const ProjectIntegrationPicker: FC<Props> = ({
  columnNameTranslationKey,
  onChange,
  selectedIds,
}) => {
  const { t } = useTranslation()

  return (
    <EntityPicker
      columnNameTranslationKey={columnNameTranslationKey}
      filter={(projectIntegration, search) =>
        projectIntegration.name.toLowerCase().includes(search.toLowerCase()) &&
        !selectedIds.has(projectIntegration.id)
      }
      placeholder={t('common.search')}
      isSelected={(projectIntegration, selectedIds) =>
        selectedIds.has(projectIntegration.id)
      }
      renderNoItemSelected={() => (
        <span>{t('components.columnFilters.noneSelected')}</span>
      )}
      renderSearchItem={(projectIntegration) => (
        <EntityItem
          key={projectIntegration.id}
          onClick={() =>
            onChange(new Set(selectedIds).add(projectIntegration.id))
          }
          primaryText={projectIntegration.name}
        />
      )}
      renderSelectedItem={(projectIntegration) => (
        <EntityItem
          key={projectIntegration.id}
          onClick={() => {
            const s = new Set(selectedIds)
            s.delete(projectIntegration.id)
            onChange(s)
          }}
          primaryText={projectIntegration.name}
          removable={true}
        />
      )}
      selectedIds={selectedIds}
      useQuery={useProjectIntegrations}
    />
  )
}
