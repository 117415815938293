import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from '@lib/tailwind-merge'
import { EnvLabel } from 'components/indicators/env-label'
import { BrandLogo } from 'components/navigation/BrandLogo'

interface Props {
  children: ReactNode
  heading?: string
  subHeading?: string
}

export const Splash: FC<Props> = ({ children, heading, subHeading }) => {
  const { t } = useTranslation()

  const nonProdDeployedEnv =
    import.meta.env.PROD && import.meta.env.VITE_INFRA_ENVIRONMENT !== 'prod'

  const theme = import.meta.env.VITE_THEME

  return (
    <div
      className={twMerge(
        'flex flex-col items-center justify-center min-w-full min-h-full',
        nonProdDeployedEnv ? 'bg-neutral-500' : 'bg-neutral-900',
        theme,
      )}
    >
      <h1 className="font-logo text-white text-3xl">
        Time Logger {/* We don't translate this since it's the product name */}
      </h1>

      <h2 className="text-neutral-300 italic mt-1">{t('common.tagline')}</h2>

      {nonProdDeployedEnv && <EnvLabel className="mt-4" />}

      <div className="flex flex-col px-8 py-12 mt-7 text-center bg-white tems-center rounded-2xl w-[404px]">
        <h2 className="mb-2 text-lg font-semibold text-neutral-900">
          {heading}
        </h2>
        <p className="mb-12 text-sm text-neutral-700">{subHeading}</p>
        {children}
      </div>
      <BrandLogo className="w-60 mt-4" />
    </div>
  )
}
