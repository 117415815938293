import { dashedBorders } from '@utils/styles'
import { twMerge } from '@lib/tailwind-merge'

interface SideCellProps {
  className?: string
  children?: React.ReactNode
  rowIndex: number
  highlight?: boolean
  showBorder?: boolean
}

export function SideCell({
  className,
  children,
  rowIndex,
  highlight,
  showBorder,
}: SideCellProps) {
  const style = {
    gridRowStart: rowIndex,
  }

  return (
    <div
      className={twMerge(
        // Negative margin offsets SideCell width and right margin so TaskCell aligns with left edge of container
        'flex flex-row items-center -ml-3',
        dashedBorders,
        highlight && 'bg-neutral-100',
        showBorder && 'first:border-t border-t',
        className,
      )}
      style={style}
    >
      <div
        className={twMerge('h-full w-1 mr-2', highlight && 'bg-primary-600')}
      ></div>
      {children}
    </div>
  )
}
