import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { twMerge } from '@lib/tailwind-merge'

interface option {
  label: string
  icon: IconDefinition
  value: string
}

interface Props {
  options: option[]
  onChange: (value: string) => void
  selected: string
}

export const RadioButtons: FC<Props> = ({ options, onChange, selected }) => {
  return (
    <div className="inline-flex text-sm">
      {options.map(({ icon, label, value }, i) => {
        const isSelected = selected === value

        return (
          <button
            key={value}
            onClick={() => onChange(value)}
            className={twMerge(
              'px-3.5 py-2 border border-neutral-400 text-neutral-600 flex gap-1 items-center',
              isSelected && 'bg-neutral-600 text-white',
              i === 0 && 'rounded-l-full',
              i === options.length - 1 && 'rounded-r-full',
              i < options.length - 1 && 'border-r-0',
            )}
          >
            <FontAwesomeIcon icon={isSelected ? faCheck : icon} fixedWidth />
            {label}
          </button>
        )
      })}
    </div>
  )
}
