import { useQueryClient, useMutation } from '@tanstack/react-query'
import { timeCardKeys, entryKeys } from '@lib/keys'
import {
  createCorrectedTimeCard,
  deleteCorrectedTimeCard,
  updateCorrectedTimeCard,
  saveCorrectedTimeCard,
} from '@lib/api'
import { DateTime } from 'luxon'
import { useUserId } from './useUserId'
import { useWeekDates } from '@hooks/useWeekDates'
import { UpdateTimeCardMutationOptions } from './useTimeCardManagement'

export const useCreateCorrection = (date: DateTime) => {
  const queryClient = useQueryClient()
  const userId = useUserId()
  const { start, end } = useWeekDates()

  return useMutation(() => createCorrectedTimeCard({ userId, date }), {
    onSuccess: () => {
      void queryClient.invalidateQueries(
        timeCardKeys.search(userId, start, end),
      )

      void queryClient.invalidateQueries(entryKeys.search(userId, start, end))
    },
  })
}

export const useDeleteCorrection = () => {
  const queryClient = useQueryClient()
  const userId = useUserId()
  const { start, end } = useWeekDates()

  return useMutation(
    (timeCardId: number) => deleteCorrectedTimeCard({ userId, timeCardId }),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(
          timeCardKeys.search(userId, start, end),
        )

        void queryClient.invalidateQueries(entryKeys.search(userId, start, end))
      },
    },
  )
}

export const useUpdateCorrection = () => {
  const queryClient = useQueryClient()
  const userId = useUserId()
  const { start, end } = useWeekDates()

  return useMutation(
    // This shares an interface with useUpdateTimeCard to simplify for consumers
    (options: UpdateTimeCardMutationOptions) =>
      updateCorrectedTimeCard({ ...options, userId }),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(
          timeCardKeys.search(userId, start, end),
        )
      },
    },
  )
}

export const useSaveCorrection = () => {
  const queryClient = useQueryClient()
  const userId = useUserId()
  const { start, end } = useWeekDates()

  return useMutation(
    (timeCardId: number) => saveCorrectedTimeCard({ userId, timeCardId }),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(
          timeCardKeys.search(userId, start, end),
        )
      },
    },
  )
}
