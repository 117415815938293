import { useEffect, useState } from 'react'

export const useDebounce = <T>(val: T, ms = 300) => {
  const [state, setState] = useState(val)

  useEffect(() => {
    const id = setTimeout(() => setState(val), ms)

    return () => clearTimeout(id)
  }, [val, ms])

  return state
}
