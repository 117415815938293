import { trim } from '@utils/trim'

export const validateSearch = (
  value: string,
  message: string,
): string | undefined => {
  const length = trim(value).length
  if (length < 3 && length > 0) {
    return message
  }
}
