import { useMemo } from 'react'
import { useSettings } from './useSettings'
import { deriveLanguage, determineBrowserLanguage } from 'utils/language'

export const useLanguage = () => {
  const languageCode = useSettings().data?.languageCode ?? undefined

  return useMemo(
    () => deriveLanguage(languageCode) ?? determineBrowserLanguage(),
    [languageCode],
  )
}
