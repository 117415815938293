import { Button } from 'components/buttons/Button'
import { useSubmitAllTimeCards } from '@features/time-logging/hooks/useTimeCardManagement'
import { toast } from '@lib/toasts'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@lib/tooltip'

export const SubmitButton = () => {
  const { t } = useTranslation()
  const submitAllMutation = useSubmitAllTimeCards()

  const submitAll = async () => {
    try {
      const { totalSubmitted } = await submitAllMutation.mutateAsync()
      if (totalSubmitted === 0) {
        toast({
          title: t('features.timeLogging.noTimeCardsToSubmit'),
          variant: 'alert',
          content: t(
            'features.timeLogging.allValidTimeCardsHaveAlreadyBeenSubmitted',
          ),
        })
      } else {
        toast({
          title: t('features.timeLogging.timeCardsSubmitted'),
          variant: 'success',
          content: t('features.timeLogging.successfullySubmittedAll', {
            number: totalSubmitted,
            entities:
              totalSubmitted === 1
                ? t('common.timeCard')
                : t('common.timeCards'),
          }),
        })
      }
    } catch (e) {
      toast({
        title: t('features.timeLogging.unableToSubmitTimeCards'),
        variant: 'error',
      })
    }
  }

  return (
    <>
      <Button
        className="text-sm"
        onClick={() => void submitAll()}
        disabled={submitAllMutation.isLoading}
        data-tooltip-id="submitAllUnsubmitted"
      >
        {t('features.timeLogging.submitAll')}
      </Button>

      <Tooltip
        id="submitAllUnsubmitted"
        content={t('features.timeLogging.submitAllOpenAndValidTimeCards')}
        place="bottom"
        delayShow={500}
      />
    </>
  )
}
