import { FC, useMemo } from 'react'
import { Spinner } from 'components/loaders'
import { Header } from '../components/reports-list/Header'
import { Table } from '../components/reports-list/Table'
import { useSavedReports } from '../hooks/useSavedReports'
import { ReportTypeMap } from '../types'
import { TFunction, useTranslation } from 'react-i18next'

interface Props {
  type: keyof ReportTypeMap
}

const titleTranslationFor = (
  type: keyof ReportTypeMap,
): Parameters<TFunction<'translation', undefined>>[0] => {
  switch (type) {
    case 'time_log':
      return 'features.reporting.timeLog.timeLogReports'
    case 'audit_log':
      return 'features.reporting.auditLog.auditLogReports'
  }
}

const newReportPathFor = (type: keyof ReportTypeMap) => {
  switch (type) {
    case 'time_log':
      return '/reporting/time_log_reports/new'
    case 'audit_log':
      return '/reporting/audit_log_reports/new'
  }
}

export const ReportsList: FC<Props> = ({ type }) => {
  const { t } = useTranslation()
  const { data: reports } = useSavedReports()

  const reportsOfType = useMemo(() => {
    if (reports === undefined) return undefined

    return reports.filter((report) => report.type === type)
  }, [reports, type])

  return (
    <div className="flex flex-col flex-grow">
      <Header
        title={t(titleTranslationFor(type)) as string}
        newReportPath={newReportPathFor(type)}
      />

      {reportsOfType === undefined ? (
        <Spinner />
      ) : (
        <Table reports={reportsOfType} />
      )}
    </div>
  )
}
