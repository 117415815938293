import { coerceTo24Hour } from '../util'
import { Period } from '../types'

const castToPeriod = (periodStr?: string): Period | undefined => {
  if (periodStr === 'am') return 'am'
  if (periodStr === 'pm') return 'pm'

  return
}

const parse24Hour = (input: string) => {
  const [minOnesPlace, minTensPlace, ...reversedHoursDigits] =
    Array.from(input).reverse()
  const minute = Number([minTensPlace, minOnesPlace].join(''))
  const hour = Number(reversedHoursDigits.reverse().join(''))

  if (hour === 24 && minute === 0) return { hour: 0, minute: 0 }

  return { hour, minute }
}

const parseDigit = (input: string, { assumePm } = { assumePm: false }) => {
  if (!input.match(/^\d+$/)) return

  if (input.length === 3 || input.length === 4) return parse24Hour(input)

  const parsedHour = Number.parseInt(input)
  const hour =
    parsedHour < 12 && assumePm ? coerceTo24Hour(parsedHour, 'pm') : parsedHour

  return { hour, minute: 0 }
}

const parseShorthand = (input: string) => {
  const [_full, hourStr, _lastHourStr, periodStr] =
    /^((\d)+)\s*(am|pm)$/.exec(input) ?? []

  if (hourStr !== undefined && periodStr !== undefined) {
    const hour = Number.parseInt(hourStr)
    return { hour: coerceTo24Hour(hour, castToPeriod(periodStr)), minute: 0 }
  }
}

const hourMinutePeriodRegex = /^(\d{1,2})\s*:\s*(\d{2})\s*(am|pm){0,1}$/
const parseFull = (input: string) => {
  const [_, hourStr, minuteStr, periodStr] =
    hourMinutePeriodRegex.exec(input) ?? []
  if (hourStr !== undefined && minuteStr !== undefined) {
    const hour = Number.parseInt(hourStr)
    const minute = Number.parseInt(minuteStr)
    return { hour: coerceTo24Hour(hour, castToPeriod(periodStr)), minute }
  }
}

interface Result {
  hour: number
  minute: number
}

export const parse = (
  input: string,
  { assumePm } = { assumePm: false },
): Result => {
  const normalized = input.trim().toLowerCase()

  // interpret just numbers as 24 hours and 24-hour time
  const digitResult = parseDigit(normalized, { assumePm })
  if (digitResult) return digitResult

  // interpret "shorthand" (e.g. "9am")
  const shorthandResult = parseShorthand(normalized)
  if (shorthandResult) return shorthandResult

  const fullResult = parseFull(normalized)
  if (fullResult) return fullResult

  return { hour: NaN, minute: NaN }
}
