import { FC } from 'react'
import { Header } from '../components/Header'
import { useAdminApprovalGroups } from '@features/admin/hooks/useAdminApprovalGroups'
import { Table } from '../components/Table'
import { Spinner } from 'components/loaders'
import { Button } from 'components/buttons'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { useNewApprovalGroup } from '../hooks/useNewApprovalGroup'
import { useTranslation } from 'react-i18next'

export const ApprovalGroupsAdmin: FC = () => {
  const { data: approvalGroups } = useAdminApprovalGroups()
  const { newApprovalGroup } = useNewApprovalGroup()
  const { t } = useTranslation()

  return (
    <div className="flex flex-col flex-grow">
      <Header
        actionButton={
          <Button
            title={t('features.approvalGroupsAdmin.addGroup')}
            icon={faPlus}
            onClick={newApprovalGroup}
          >
            {t('features.approvalGroupsAdmin.addGroup')}
          </Button>
        }
      />

      {approvalGroups ? <Table approvalGroups={approvalGroups} /> : <Spinner />}
    </div>
  )
}
