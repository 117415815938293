import { convertTwentyFourHourTimeToSeconds } from './convertTwentyFourHourTimeToSeconds'

const convert = (item: {
  start: TwentyFourHourTime
  end: TwentyFourHourTime
}): { start: number; end: number } => ({
  start: convertTwentyFourHourTimeToSeconds(item.start),
  end: convertTwentyFourHourTimeToSeconds(item.end),
})

export const validateDoesNotOverlap = (
  breakTime: { start: TwentyFourHourTime; end: TwentyFourHourTime },
  breaks: { start: TwentyFourHourTime; end: TwentyFourHourTime }[],
): 'overlap'[] => {
  const { start, end } = convert(breakTime)
  const otherBreaks = breaks.map(convert).sort((a, b) => a.start - b.start)

  for (const other of otherBreaks) {
    if (start < other.start && end <= other.start) return []
    if (start >= other.end) continue

    return ['overlap']
  }

  return []
}
