import { FC } from 'react'
import { NewHeader as PageHeader } from 'components/page-header'
import { useTranslation } from 'react-i18next'
import { MenuButton } from 'components/buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'react-router-dom'

interface Props {
  favorite?: boolean
  onEdit?: () => void
  onFavoriteChange?: (isFavorite: boolean) => void
  onSave?: () => void
  onSaveAs?: (name: string) => void
  isPublic?: boolean
  saved?: boolean
  title: string
  newReportPath: string
}

export const Header: FC<Props> = ({ title, newReportPath }) => {
  const { t } = useTranslation()

  return (
    <PageHeader
      title={title}
      renderRightSlot={() => (
        <div className="flex flex-row items-center">
          <MenuButton
            className="ml-2"
            title={t('common.action')}
            menuItems={[
              {
                content: (
                  <Link to={newReportPath}>
                    <FontAwesomeIcon icon={faCirclePlus} className="mr-2" />
                    {t('features.reporting.createNew')}
                  </Link>
                ),
              },
            ]}
          >
            {t('common.action')}
          </MenuButton>
        </div>
      )}
    />
  )
}
