import { range } from '@utils/range'
import { Spinner } from 'components/loaders'
import { FC, Key, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'
import { InfiniteScrollListItem } from './InfiniteScrollListItem'

const SkeletonTrigger: FC<{
  disabled: boolean
  item: (key: Key) => ReactNode
  height: number
  hidden: boolean
  numOfItemsToRender: number
  onShow: () => void
}> = ({ disabled, item, height, hidden, numOfItemsToRender, onShow }) => {
  const { ref } = useInView({
    onChange: (inView) => {
      if (!inView) return
      if (hidden) return
      if (disabled) return

      onShow()
    },
  })

  const renderItems = () =>
    range(1, numOfItemsToRender).map((_, index) => item(index))

  if (hidden) return null
  if (disabled) return <div style={{ height }}>{renderItems()}</div>

  return (
    <div ref={ref} style={{ height }}>
      {renderItems()}
    </div>
  )
}

export interface Props<T> {
  items: T[]
  renderItem: (item: T, index: number) => ReactNode
  loadMore: () => void
  isFetching: boolean
  isInitiallyLoading: boolean
  expectedItemHeight: number
  totalItems: number
  skeletonItem: ReactNode
}

export const InfiniteScrollList = <T,>({
  items,
  renderItem,
  loadMore,
  isFetching,
  isInitiallyLoading,
  expectedItemHeight,
  totalItems,
  skeletonItem,
}: Props<T>) => {
  if (isInitiallyLoading) return <Spinner />

  return (
    <div className="relative w-full h-full">
      {items.length === 0 && (
        <div className="flex flex-row items-center h-10 text-sm italic font-medium text-neutral-500 mx-9">
          No items
        </div>
      )}
      {items.map((item, i) => (
        <InfiniteScrollListItem expectedItemHeight={expectedItemHeight} key={i}>
          {renderItem(item, i)}
        </InfiniteScrollListItem>
      ))}
      <SkeletonTrigger
        disabled={isFetching}
        item={(key) => (
          <InfiniteScrollListItem
            expectedItemHeight={expectedItemHeight}
            key={key}
          >
            {skeletonItem}
          </InfiniteScrollListItem>
        )}
        height={(totalItems - items.length) * expectedItemHeight}
        hidden={items.length >= totalItems && items.length !== 0}
        numOfItemsToRender={totalItems - items.length}
        onShow={loadMore}
      />
    </div>
  )
}
