import { coerceIntoArray } from './coerceIntoArray'

export function uniqueCountFromItems<T, R>(
  items: T[],
  accessor: (item: T) => R | R[],
) {
  return new Set(
    items.reduce<R[]>(
      (acc, item) => [...acc, ...coerceIntoArray(accessor(item))],
      [],
    ),
  ).size
}
