import { FC, useState } from 'react'
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  Row,
  Getter,
  ColumnFiltersState,
  ColumnDef,
} from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDownAZ,
  faArrowUpAZ,
  faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons'
import { faEdit } from '@fortawesome/pro-solid-svg-icons'
import { TableFilterField } from 'components/tables/TableFilterField'
import { IconButton } from 'components/buttons'
import { useEditApprovalGroup } from '../hooks/useEditApprovalGroup'
import { AutoApprovalToggle } from './AutoApprovalToggle'
import { useTranslation } from 'react-i18next'
import { DownloadCsvButton } from 'components/tables'
import { Tooltip } from 'react-tooltip'

interface Props {
  approvalGroups: AdminApprovalGroup[]
}

const columnHelper = createColumnHelper<AdminApprovalGroup>()

export const Table: FC<Props> = ({ approvalGroups }) => {
  const { t } = useTranslation()
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'name',
      desc: false,
    },
  ])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const { edit } = useEditApprovalGroup()

  const columns = [
    columnHelper.accessor('name', {
      header: t('features.approvalGroupsAdmin.groupName'),
      enableColumnFilter: true,
      filterFn: 'includesString',
      cell: ({ row, getValue }) => (
        <div className="flex items-center justify-between">
          {getValue()}{' '}
          <IconButton
            title={t('features.approvalGroupsAdmin.editApprovalGroup') ?? ''}
            icon={faEdit}
            className="invisible w-8 h-8 border group-hover/row:visible text-neutral-900 border-neutral-900 hover:bg-neutral-900 hover:text-white"
            onClick={() => edit(row.original)}
          />
        </div>
      ),
    }),
    {
      id: 'approvers',
      header: t('common.approvers'),
      accessorFn: (row: AdminApprovalGroup) =>
        row.approverUsers.map((user) => user.name).join(', '),
      enableColumnFilter: true,
      filterFn: 'includesString',
      cell: ({
        row,
        getValue,
      }: {
        row: Row<AdminApprovalGroup>
        getValue: Getter<string>
      }) => (
        <div className="flex items-center justify-between">
          {getValue()}
          <IconButton
            title={t('features.approvalGroupsAdmin.editApprovalGroup') ?? ''}
            icon={faEdit}
            className="invisible w-8 h-8 ml-auto border group-hover/row:visible text-neutral-900 border-neutral-900 hover:bg-neutral-900 hover:text-white"
            onClick={() => edit(row.original)}
          />
        </div>
      ),
    } as ColumnDef<AdminApprovalGroup>,
    columnHelper.accessor('employeeCount', {
      header: t('common.employees'),
    }),
    {
      id: 'defaultGroupManager',
      accessorFn: (group: AdminApprovalGroup) =>
        group.defaultForDirectReportsOfEmployee &&
        `${group.defaultForDirectReportsOfEmployee.name} ${group.defaultForDirectReportsOfEmployee.workdayWorkerId}`,
      header: () => (
        <span>
          {t('features.approvalGroupsAdmin.defaultGroupManager')}{' '}
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="text-neutral-400"
            data-tooltip-id="default-group-for-tooltip"
          />
          <Tooltip id="default-group-for-tooltip">
            {t('features.approvalGroupsAdmin.defaultGroupManagerTooltip')}
          </Tooltip>
        </span>
      ),
      cell: ({ row }) =>
        row.original.defaultForDirectReportsOfEmployee && (
          <div className="flex items-center gap-1">
            {row.original.defaultForDirectReportsOfEmployee.name}
            <small className="text-neutral-500">
              ({row.original.defaultForDirectReportsOfEmployee.workdayWorkerId})
            </small>
          </div>
        ),
      enableColumnFilter: true,
      filterFn: 'includesString',
    } as ColumnDef<AdminApprovalGroup>,
    {
      id: 'autoApproval',
      accessorFn: (group: AdminApprovalGroup) =>
        group.autoApproval ? 'Enabled' : 'Disabled',
      header: t('features.approvalGroupsAdmin.autoApproval'),
      cell: ({ row }: { row: Row<AdminApprovalGroup> }) => (
        <AutoApprovalToggle approvalGroup={row.original} />
      ),
      enableColumnFilter: true,
      filterFn: 'equalsString',
    } as ColumnDef<AdminApprovalGroup>,
  ]

  const table = useReactTable({
    data: approvalGroups,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    defaultColumn: {
      // Disable filtering by default
      enableColumnFilter: false,
    },
  })

  return (
    <div className="px-8 pt-6 flex flex-col flex-grow">
      <div className="flex justify-between mb-2">
        <div className="text-lg">
          {t('features.approvalGroupsAdmin.groupsCountAndTotal', {
            count: table.getRowModel().rows.length,
            total: approvalGroups.length,
          })}
        </div>

        <div>
          <DownloadCsvButton
            table={table}
            filename={t('common.approvalGroups')}
          />
        </div>
      </div>

      <div className="relative flex-grow">
        <div className="absolute top-0 bottom-0 left-0 right-0 overflow-auto">
          <table className="min-w-full text-sm">
            <thead className="sticky top-0 shadow z-20">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className="p-4 px-4 py-6 font-semibold text-left align-top border border-t-0 cursor-pointer bg-neutral-100 text-neutral-900 border-neutral-300 hover:bg-neutral-200 first-of-type:border-l-0 last-of-type:border-r-0"
                      onClick={header.column.getToggleSortingHandler()}
                      colSpan={header.colSpan}
                    >
                      <div className="flex justify-between">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}

                        {header.column.getIsSorted() && (
                          <FontAwesomeIcon
                            icon={
                              header.column.getIsSorted() === 'asc'
                                ? faArrowDownAZ
                                : faArrowUpAZ
                            }
                          />
                        )}
                      </div>
                      {header.column.getCanFilter() && (
                        <div className="max-w-sm">
                          <TableFilterField
                            table={table}
                            column={header.column}
                          />
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="hover:bg-neutral-100 group/row">
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="px-4 py-3 border border-neutral-300 first-of-type:border-l-0 last-of-type:border-r-0"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
