import { secondsToHHMM } from '@utils/time'
import { TimeDurationInput } from 'components/form/time-duration-input/TimeDurationInput'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonInput, FilterRenderer } from '../components'
import { renderNumericRange } from '@utils/renderNumericRange'

interface Props {
  columnNameTranslationKey: TranslationKey
  from?: number
  onChange: (value: { from?: number; to?: number }) => void
  to?: number
}

export const NumericRange: FC<Props> = ({
  columnNameTranslationKey,
  from,
  onChange,
  to,
}) => {
  const { t } = useTranslation()

  return (
    <FilterRenderer
      columnNameTranslationKey={columnNameTranslationKey}
      renderDropdown={() => (
        <div className="flex flex-row items-end">
          <TimeDurationInput
            className="min-w-[75px]"
            value={from}
            onChange={(from) => onChange({ from, to })}
          />
          <div className="mx-1 mb-2">-</div>
          <TimeDurationInput
            className="min-w-[75px]"
            value={to}
            onChange={(to) => onChange({ from, to })}
          />
        </div>
      )}
      renderTrigger={(clickHandler) => (
        <ButtonInput onClick={clickHandler}>
          {renderNumericRange(from, to, t, secondsToHHMM)}
        </ButtonInput>
      )}
    />
  )
}
