import {
  faCalendar,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons'
import { faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useOnClickOutside } from '@hooks/useOnClickOutside'
import { FC, ReactNode, useCallback, useRef, useState } from 'react'

interface Props {
  children: (renderProps: { menuHidden: boolean }) => ReactNode
  content: string
  nextButtonDisabled?: boolean
  nextButtonTitle: string
  onMenuHiddenChange?: (hidden: boolean) => void
  onNextButtonClick: () => void
  onPreviousButtonClick: () => void
  previousButtonDisabled?: boolean
  previousButtonTitle: string
  title?: string
  variant?: 'dark' | 'light'
}

export const DatePickerDropdown: FC<Props> = ({
  children,
  content,
  nextButtonDisabled = false,
  nextButtonTitle,
  onMenuHiddenChange,
  onNextButtonClick,
  onPreviousButtonClick,
  previousButtonDisabled = false,
  previousButtonTitle,
  title,
  variant = 'dark',
}) => {
  const [menuHidden, setMenuHidden] = useState(true)

  const close = useCallback(() => {
    if (menuHidden) return

    if (onMenuHiddenChange) onMenuHiddenChange(true)
    setMenuHidden(true)
  }, [onMenuHiddenChange, setMenuHidden, menuHidden])

  const open = () => {
    if (onMenuHiddenChange) onMenuHiddenChange(false)
    setMenuHidden(false)
  }

  const ref = useRef(null)

  useOnClickOutside(ref, close)

  if (variant === 'dark')
    return (
      <div className="relative text-neutral-900 grow" ref={ref}>
        <div className="flex items-center text-sm gap-4 3xl:text-base">
          <button
            className="relative text-white cursor-pointer bg-neutral-900 border-neutral-400 px-2 py-2 2xl:px-3 rounded border-[1.5px] flex flex-row items-center gap-1 3xl:gap-5 justify-between w-56 3xl:w-72"
            onClick={menuHidden ? open : close}
            title={title}
          >
            {content}
            <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
          </button>
          <div className="flex gap-3 2xl:gap-4 3xl:gap-6">
            <button
              disabled={previousButtonDisabled}
              onClick={onPreviousButtonClick}
              title={previousButtonTitle}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                className={
                  previousButtonDisabled
                    ? 'text-neutral-600'
                    : 'text-neutral-300'
                }
              />
            </button>
            <button
              disabled={nextButtonDisabled}
              onClick={onNextButtonClick}
              title={nextButtonTitle}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                className={
                  nextButtonDisabled ? 'text-neutral-600' : 'text-neutral-300'
                }
              />
            </button>
          </div>
        </div>
        {children({ menuHidden })}
      </div>
    )

  return (
    <div
      className="relative flex items-center h-8 text-sm border rounded-full text-neutral-900 border-neutral-300 flex-nowrap"
      ref={ref}
    >
      <button
        className="pl-4 pr-2"
        disabled={previousButtonDisabled}
        onClick={onPreviousButtonClick}
        title={previousButtonTitle}
      >
        <FontAwesomeIcon icon={faCaretLeft} />
      </button>
      <button
        className="flex-grow font-medium border-l border-r border-neutral-300 whitespace-nowrap px-2"
        onClick={menuHidden ? open : close}
        title={title}
      >
        <FontAwesomeIcon className="mr-2 text-neutral-600" icon={faCalendar} />
        {content}
      </button>
      <button
        className="pl-2 pr-4"
        disabled={nextButtonDisabled}
        onClick={onNextButtonClick}
        title={nextButtonTitle}
      >
        <FontAwesomeIcon icon={faCaretRight} />
      </button>
      {children({ menuHidden })}
    </div>
  )
}
