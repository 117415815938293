import { Button } from 'components/buttons/Button'
import { FC } from 'react'
import {
  faClipboardCheck,
  faCalendarXmark,
} from '@fortawesome/pro-solid-svg-icons'
import { SubmitButton } from './SubmitButton'
import { useTranslation } from 'react-i18next'
import { UserPicker } from './UserPicker'

export interface HeadingProps {
  onLeaveButtonClick: () => void
  onTaskButtonClick: () => void
}

export const Heading: FC<HeadingProps> = ({
  onLeaveButtonClick,
  onTaskButtonClick,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-row">
      <div className="flex flex-col">
        <UserPicker />

        <h1 className="text-2xl text-white">{t('common.timeCardsTitle')}</h1>
      </div>

      <div className="flex justify-end grow gap-4">
        <Button
          variant="outlined"
          className="justify-center w-12 text-neutral-300"
          onClick={onLeaveButtonClick}
          icon={faCalendarXmark}
          title={t('features.timeLogging.showLeaveAndTimeOffSummary')}
        >
          <span className="sr-only">
            {t('features.timeLogging.leaveSummary')}
          </span>
        </Button>

        <Button
          variant="outlined"
          className="justify-center w-12 text-neutral-300"
          onClick={onTaskButtonClick}
          icon={faClipboardCheck}
          title={t('features.timeLogging.selectTasks')}
        >
          <span className="sr-only">
            {t('features.timeLogging.selectTasksHeading')}
          </span>
        </Button>

        <SubmitButton />
      </div>
    </div>
  )
}
