import { FC, memo, useState, useEffect } from 'react'
import { ExploreColumn } from './ExploreColumn'
import { Tabs } from './Tabs'
import { TaskColumn } from './TaskColumn'

interface Props {
  disabledTaskIds: Set<number>
  search?: string
  searchError?: string
  integrationId: number
  onItemClick: (item: { label: string }) => void
  onSearchChange: (search: string) => void
  onTabChange: (tab: 'explore' | 'tasks') => void
  onTaskClick: (task: Task, checked: boolean) => void
  path: string[]
  projectId: number
  selectedTaskIds: Set<number>
  shown: boolean
  heading?: string
  displaySearch?: boolean
  onSearchToggle: (displayed: boolean) => void
  activeTab?: 'explore' | 'tasks'
}

export const NodeColumn: FC<Props> = memo(
  ({
    disabledTaskIds,
    search,
    searchError,
    integrationId,
    onItemClick,
    onSearchChange,
    onTabChange,
    onTaskClick,
    path,
    projectId,
    selectedTaskIds,
    shown,
    heading,
    displaySearch,
    onSearchToggle,
    activeTab = 'explore',
  }) => {
    const [exploreCount, setExploreCount] = useState<number | undefined>(
      undefined,
    )
    const [taskCount, setTaskCount] = useState<number | undefined>(undefined)
    const [consideredAutoSwitchToTaskTab, setConsideredAutoSwitchToTaskTab] =
      useState(false)

    // The path effectively defines the column,
    // so we can hook into path changes to effectively
    // identify when a "new" column is being shown.
    useEffect(() => {
      // When a new column is loaded, consider switching
      // to the Task tab automatically once the counts of each tab are loaded.
      setConsideredAutoSwitchToTaskTab(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(path)])

    // If we haven't already considered switching to the Task tab,
    // and counts for both tabs have loaded,
    // and the explore tab has no items but the task tab does have items,
    // switch to the task tab automatically.
    // If both counts load and the other conditions are not met,
    // update state to reflect that we considered and declined
    // so that we do not change tabs again until the column changes.
    useEffect(() => {
      if (!consideredAutoSwitchToTaskTab) {
        // If explore is 0 and task is loaded and has items, switch to the task tab
        if (exploreCount === 0 && taskCount !== undefined && taskCount > 0) {
          onTabChange('tasks')
        }

        // If both counts have loaded, update state to reflect that we considered
        // switching automatically
        if (exploreCount !== undefined && taskCount !== undefined) {
          setConsideredAutoSwitchToTaskTab(true)
        }
      }
    }, [consideredAutoSwitchToTaskTab, exploreCount, taskCount, onTabChange])

    return (
      <>
        <ExploreColumn
          integrationId={integrationId}
          onItemClick={onItemClick}
          onItemCountChange={setExploreCount}
          onSearchChange={onSearchChange}
          path={path}
          projectId={projectId}
          search={search}
          searchError={searchError}
          shown={shown && activeTab === 'explore'}
          heading={heading}
          displaySearch={displaySearch}
          onSearchToggle={onSearchToggle}
        >
          <Tabs
            activeTab="explore"
            exploreCount={exploreCount}
            onTabChange={onTabChange}
            taskCount={taskCount}
          />
        </ExploreColumn>
        <TaskColumn
          disabledTaskIds={disabledTaskIds}
          integrationId={integrationId}
          onItemCountChange={setTaskCount}
          onSearchChange={onSearchChange}
          onTaskClick={onTaskClick}
          path={path}
          projectId={projectId}
          selectedTaskIds={selectedTaskIds}
          search={search}
          searchError={searchError}
          shown={shown && activeTab === 'tasks'}
          heading={heading}
          displaySearch={displaySearch}
          onSearchToggle={onSearchToggle}
        >
          <Tabs
            activeTab="tasks"
            exploreCount={exploreCount}
            onTabChange={onTabChange}
            taskCount={taskCount}
          />
        </TaskColumn>
      </>
    )
  },
)

NodeColumn.displayName = 'NodeColumn'
