import { FC } from 'react'
import { coerceTo24Hour, convertTo12Hour } from '../../util'
import { Period } from '../../types'
import { Dial } from './Dial'

const twelveHours = Array.from({ length: 12 }, (_x, index) => index + 1)
const twentyFourHours = Array.from({ length: 24 }, (_x, index) => index)
const minutes = Array.from({ length: 12 }, (_x, index) => index * 5)

interface Props {
  hour: number
  minute: number
  mode?: '12' | '24'
  onChange: (hour: number | null, minute: number | null) => void
}

export const DialTimePicker: FC<Props> = ({
  hour,
  minute,
  mode = '12',
  onChange,
}) => {
  const getDisplayHour = () => {
    if (mode === '24') return hour

    return convertTo12Hour(hour)
  }

  const hours = mode === '12' ? twelveHours : twentyFourHours

  const currentPeriod = hour >= 12 ? 'pm' : 'am'

  const handleHourChange = (changedHour: number) => {
    if (mode === '24') return onChange(changedHour, minute)

    const convertedHour = coerceTo24Hour(changedHour, currentPeriod)
    onChange(convertedHour, minute)
  }

  const handlePeriodChange = (period: Period) => {
    if (period === 'am' && hour >= 12) return onChange(hour - 12, minute)
    if (period === 'pm' && hour < 12) return onChange(hour + 12, minute)

    onChange(hour, minute)
  }

  return (
    <div className="flex flex-row h-64 mt-2 text-sm">
      <Dial
        activeItem={getDisplayHour()}
        items={hours}
        onChange={handleHourChange}
        wrap={true}
      />
      <Dial
        activeItem={minute}
        items={minutes}
        onChange={(minute) => onChange(hour, minute)}
        wrap={true}
      />
      <Dial<Period>
        activeItem={currentPeriod}
        hidden={mode === '24'}
        items={['am', 'pm']}
        onChange={handlePeriodChange}
      />
    </div>
  )
}
