import { FC, ReactNode } from 'react'
import { twMerge } from '@lib/tailwind-merge'

interface Datum {
  value: ReactNode
  label: string
}

interface Props {
  data: Datum[]

  // NOTE: Design selection can be removed in favor of the 'new' design
  //       once the approvals page is moved over to our new header design
  design?: 'old' | 'new'
}

export const SelectionBar: FC<Props> = ({ data, design = 'old' }) => {
  const colors = {
    old: {
      background: 'bg-neutral-700',
      border: 'border border-neutral-600',
      value: 'text-white',
      label: 'text-neutral-300',
      divider: 'border-neutral-500',
    },
    new: {
      background: 'bg-neutral-200',
      border: null,
      value: 'text-neutral-900',
      label: 'text-neutral-600',
      divider: 'border-neutral-400',
    },
  }[design]

  return (
    <div
      data-testid="selection-bar"
      className={twMerge(
        'flex py-1.5 px-4 text-lg font-medium rounded-full leading-3',
        colors.value,
        colors.background,
        colors.border,
      )}
    >
      {data.map(({ value, label }, i) => (
        <div
          className={twMerge(
            'flex items-center gap-1',
            i > 0 && 'border-l pl-4 ml-4',
            colors.divider,
          )}
          key={i}
        >
          {value}
          <span className={twMerge('text-sm', colors.label)}>{label}</span>
        </div>
      ))}
    </div>
  )
}
