import { Tabs } from 'components/buttons'
import { FC, ReactNode, useState } from 'react'

/* modifier
<label className="flex flex-row items-center text-xs font-medium text-neutral-500">
  <Checkbox className="mr-1" /> Archived
</label>
*/

interface List {
  label: string
  component: ReactNode
}

interface Props {
  lists: readonly List[]
  title?: string
  modifier?: ReactNode
}

export const HeaderSelector: FC<Props> = ({ lists, title, modifier }) => {
  const tabs = lists.map((list) => ({
    id: list.label,
    label: list.label,
    component: list.component,
  }))
  const [activeTab, setActiveTab] = useState<typeof tabs[number]['id']>(
    tabs[0].id,
  )

  return (
    <div className="flex flex-col p-4 pb-0 bg-white drop-shadow-md rounded min-h-[250px] max-h-96 w-80">
      {(title || modifier) && (
        <div className="flex flex-row items-center mb-4">
          <span className="text-sm font-semibold text-neutral-900 grow">
            {title}
          </span>
          {modifier}
        </div>
      )}
      {lists.length > 1 && (
        <Tabs
          className="px-0 mb-3"
          activeTab={activeTab}
          onTabClick={(tabId) => setActiveTab(tabId)}
          tabs={tabs}
        />
      )}
      {tabs.map((tab) => {
        if (tab.id !== activeTab) return null

        return tab.component
      })}
    </div>
  )
}
