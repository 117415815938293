import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  useId,
  useMemo,
  ReactNode,
} from 'react'
import { twMerge } from '@lib/tailwind-merge'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spinner } from 'components/loaders'
import { AutoTextSize } from 'auto-text-size'
import { Tooltip } from 'react-tooltip'

export interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: 'default' | 'outlined' | 'subtle' | 'outlined-active'
  icon?: IconDefinition
  loading?: boolean
  autoTextSize?: boolean
  maxAutoFontSizePx?: number
  tooltip?: ReactNode
  tooltipProps?: Omit<React.ComponentProps<typeof Tooltip>, 'id'>
}

const subtleClassNames = `
  bg-transparent text-neutral-400 fill-neutral-400
`

const defaultClassNames = `
  bg-primary-600 text-white fill-white disabled:bg-primary-600 hover:bg-primary-700
`

const outlinedClassNames = `
  bg-transparent text-neutral-900 fill-neutral-900 disabled:bg-none border border-neutral-300 hover:opacity-90
`

const outlinedActiveClassNames = twMerge(
  outlinedClassNames,
  'text-primary-700 border-primary-300 bg-primary-100',
)

export const Button: FC<Props> = ({
  className: classNameOverride,
  children,
  disabled,
  variant = 'default',
  icon,
  loading = false,
  autoTextSize = false,
  maxAutoFontSizePx = 16,
  tooltip,
  tooltipProps,
  ...props
}) => {
  const tooltipId = useId()
  const theme = import.meta.env.VITE_THEME

  const variantClassNames = useMemo(() => {
    if (variant === 'outlined') return outlinedClassNames
    if (variant === 'outlined-active') return outlinedActiveClassNames
    if (variant === 'subtle') return subtleClassNames

    return defaultClassNames
  }, [variant])

  const themeClassNames = theme === 'netflix' ? 'px-4' : 'px-6'

  const className = twMerge(
    'flex flex-row items-center justify-center h-8 font-medium disabled:cursor-not-allowed disabled:opacity-40 gap-2',
    classNameOverride?.includes('rounded') ? '' : 'rounded-primary',
    themeClassNames,
    variantClassNames,
    classNameOverride,
  )

  return (
    <button
      {...props}
      disabled={disabled}
      className={className}
      data-tooltip-id={tooltipId}
    >
      {loading && <Spinner className="text-white" />}
      {autoTextSize ? (
        <AutoTextSize
          mode="oneline"
          className="my-auto"
          maxFontSizePx={maxAutoFontSizePx}
        >
          {children}
        </AutoTextSize>
      ) : (
        children
      )}
      {icon && <FontAwesomeIcon icon={icon} />}
      {tooltip && (
        <Tooltip id={tooltipId} {...tooltipProps}>
          {tooltip}
        </Tooltip>
      )}
    </button>
  )
}
