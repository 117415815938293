import { FC, ReactNode } from 'react'
import { useHolidaysOnDate } from '@hooks/useHolidays'
import { useAbsencesForDate } from '@hooks/useAbsences'
import { CellAnnotation, TimeStampCell } from 'components/tables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarStar,
  faCloudCheck,
} from '@fortawesome/pro-regular-svg-icons'
import { getApprovalStatus } from '@features/approvals/helpers/getApprovalStatus'
import { twMerge } from '@lib/tailwind-merge'
import { Status } from 'types'
import { ApprovalTooltip } from './ApprovalTooltip'
import { secondsToTimestamp } from '@utils/time'

interface Props {
  approval?: Omit<Approval, 'approvalDomain'>
  className?: string
  date: DateTime
  holidayCalendarId?: string
  userId: number
}

export const DayCell: FC<Props> = ({
  approval,
  className,
  date,
  holidayCalendarId,
  userId,
}) => {
  const holidayNames = useHolidaysOnDate(date, {
    holidayCalendarId,
  }).map(({ name }) => name)
  const leave = useAbsencesForDate(date, { userId })
  const status = approval ? getApprovalStatus(approval) : undefined

  const holidayAndLeaveData = new Map<ReactNode, ReactNode>()

  if (holidayNames.length > 0) {
    holidayAndLeaveData.set(
      <FontAwesomeIcon icon={faCalendarStar} />,
      holidayNames.join(', '),
    )
  }

  if (leave.length > 0) {
    leave.forEach((absence) => {
      holidayAndLeaveData.set(
        absence.type,
        absence.leave ? null : secondsToTimestamp(absence.duration),
      )
    })
  }

  return (
    <CellAnnotation
      className={twMerge(status === Status.Sent && 'opacity-60', className)}
      rightAnnotations={
        approval?.timeCard.sent_to_payroll && (
          <FontAwesomeIcon icon={faCloudCheck} size="sm" fixedWidth={true} />
        )
      }
      showLeaveIcon={leave.length > 0}
      showHolidayIcon={holidayNames.length > 0}
      tooltipContent={
        <ApprovalTooltip
          timeCardId={approval?.timeCard.id}
          extraData={holidayAndLeaveData}
        />
      }
    >
      {approval && (
        <TimeStampCell
          className={twMerge(
            status === Status.Approved && 'bg-success-100',
            status === Status.Submitted && 'bg-warning-100',
          )}
          seconds={approval.totalDomainSecondsLogged}
          subSeconds={approval.totalDomainSecondsTagged}
          zeroMode="dash"
        />
      )}
    </CellAnnotation>
  )
}
