import { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { noop } from '@utils/noop'
import { faRightFromBracket } from '@fortawesome/pro-regular-svg-icons'
import { MenuButton } from 'components/buttons/MenuButton'
import { useTranslation } from 'react-i18next'

interface Props {
  imageUrl?: string | null
  initial?: string
  onLogout?: () => void
}

const profileClassName = 'w-8 h-8 rounded-full'

const ProfileImage: FC<{ url?: string | null; initial?: string }> = ({
  url,
  initial,
}) => {
  if (url) return <img src={url} className={profileClassName} />

  return (
    <div
      className={`${profileClassName} bg-primary-700 text-neutral-50 font-extrabold flex items-center justify-center text-lg`}
    >
      {initial}
    </div>
  )
}

export const AccountInfo: FC<Props> = ({
  imageUrl,
  initial,
  onLogout: logout = noop,
}) => {
  const { t } = useTranslation()

  return (
    <MenuButton
      button={({ toggleMenuHidden, menuHidden }) => (
        <button
          onClick={toggleMenuHidden}
          className="flex items-center gap-2 h-full px-2 hover:bg-neutral-600"
        >
          <ProfileImage url={imageUrl} initial={initial} />
          <label className="sr-only">Account</label>
          <FontAwesomeIcon
            icon={menuHidden ? faChevronDown : faChevronUp}
            className="text-neutral-300"
          />
        </button>
      )}
      className="items-center h-full text-sm justify-items-end w-max"
      menuItems={[
        {
          content: (
            <>
              <FontAwesomeIcon
                icon={faRightFromBracket}
                className="mr-2 text-neutral-500"
              />
              {t('components.navigation.accountInfo.logOut')}
            </>
          ),
          onClick: logout,
        },
      ]}
    />
  )
}
