import { FC } from 'react'
import { EntityPicker, EntityItem } from '../entity-picker'
import { useApprovalGroups } from 'hooks/useApprovalGroups'
import { useTranslation } from 'react-i18next'

interface Props {
  columnNameTranslationKey: TranslationKey
  onChange: (ids: Set<number>) => void
  selectedIds: Set<number>
}
export const ApprovalGroupPicker: FC<Props> = ({
  columnNameTranslationKey,
  onChange,
  selectedIds,
}) => {
  const { t } = useTranslation()

  return (
    <EntityPicker
      className="w-72"
      columnNameTranslationKey={columnNameTranslationKey}
      filter={(approvalGroup, search) =>
        approvalGroup.name.toLowerCase().includes(search.toLowerCase())
      }
      placeholder={t('common.search')}
      isSelected={(approvalGroup, selectedIds) =>
        selectedIds.has(approvalGroup.id)
      }
      renderSearchItem={(approvalGroup) => (
        <EntityItem
          key={approvalGroup.id}
          onClick={() => onChange(new Set(selectedIds).add(approvalGroup.id))}
          primaryText={approvalGroup.name}
          disabled={selectedIds.has(approvalGroup.id)}
        />
      )}
      renderSelectedItem={(approvalGroup) => (
        <EntityItem
          key={approvalGroup.id}
          onClick={() => {
            const s = new Set(selectedIds)
            s.delete(approvalGroup.id)
            onChange(s)
          }}
          primaryText={approvalGroup.name}
          removable={true}
        />
      )}
      selectedIds={selectedIds}
      useQuery={useApprovalGroups}
    />
  )
}
