import { useCallback } from 'react'
import { useModal } from '@hooks/useModal'
import { RenameProjectForm } from '../components/RenameProjectForm'
import { useTranslation } from 'react-i18next'

export const useRenameProject = () => {
  const modal = useModal()
  const { t } = useTranslation()

  const renameProject = useCallback(
    (project: AdminProject) => {
      const id = modal.form({
        title: t('features.projectsAdmin.renameProject'),
        content: (
          <RenameProjectForm
            onCancel={() => modal.close(id)}
            onSave={() => modal.close(id)}
            project={project}
          />
        ),
      })
    },
    [modal, t],
  )

  return { renameProject }
}
