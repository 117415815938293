import { useProjects } from '@hooks/useProjects'
import { FC } from 'react'
import { EntityPicker, EntityItem } from '../entity-picker'

interface Props {
  columnNameTranslationKey: TranslationKey
  onChange: (ids: Set<number>) => void
  selectedIds: Set<number>
}
export const ProjectPicker: FC<Props> = ({
  columnNameTranslationKey,
  onChange,
  selectedIds,
}) => {
  return (
    <EntityPicker
      columnNameTranslationKey={columnNameTranslationKey}
      filter={(project, search) =>
        project.name.toLowerCase().includes(search.toLowerCase()) &&
        !selectedIds.has(project.id)
      }
      placeholder="Search Projects..."
      isSelected={(project, selectedIds) => selectedIds.has(project.id)}
      renderNoItemSelected={() => <span>None selected</span>}
      renderSearchItem={(project) => (
        <EntityItem
          key={project.id}
          onClick={() => onChange(new Set(selectedIds).add(project.id))}
          primaryText={project.name}
        />
      )}
      renderSelectedItem={(project) => (
        <EntityItem
          key={project.id}
          onClick={() => {
            const s = new Set(selectedIds)
            s.delete(project.id)
            onChange(s)
          }}
          primaryText={project.name}
          removable={true}
        />
      )}
      selectedIds={selectedIds}
      useQuery={useProjects}
    />
  )
}
