import { EnrichedAdminManagedGroup } from '../types'

export const hasErrors = (adminManagedGroup: EnrichedAdminManagedGroup) => {
  return Object.values(adminManagedGroup.weekTemplate.dayTemplates).some(
    (dayTemplate) => {
      if (dayTemplate.errors.length > 0) return true

      return dayTemplate.breaks.some((breakTemplate) => {
        return breakTemplate.errors.length > 0
      })
    },
  )
}
