import { ApprovalGroupPicker } from './ApprovalGroupPicker'
import { FC } from 'react'
import { Layout } from '../../components/Layout'
import { SectionHeader } from '../../components/SectionHeader'
import { UserPicker } from './UserPicker'
import { useTranslation } from 'react-i18next'

export const Members: FC = () => {
  const { t } = useTranslation()

  return (
    <Layout activeTab="members">
      <div className="flex flex-row flex-1">
        <SectionHeader
          className="w-1/3 min-w-[300px]"
          title={t('common.approvalGroups')}
          subtitle={t('features.adminManagedGroups.chooseApprovalGroups')}
        />
        <ApprovalGroupPicker />
      </div>
      <div className="flex flex-row flex-1">
        <SectionHeader
          className="w-1/3 min-w-[300px]"
          title={t('common.workers')}
          subtitle={t('features.adminManagedGroups.chooseWorkers')}
        />
        <UserPicker />
      </div>
    </Layout>
  )
}
