import { Reducer, useReducer, Dispatch } from 'react'
import { Status } from 'types'

export type ToggleStateAction =
  | {
      type: 'TOGGLE' | 'ENABLE_ONLY'
      status: Status
    }
  | { type: 'ENABLE_ALL' }

export type StatusToggleMap = StatusMap<boolean>
export type DispatchStatusChange = Dispatch<ToggleStateAction>

const allDisabled: StatusToggleMap = {
  [Status.Approved]: false,
  [Status.Open]: false,
  [Status.Sent]: false,
  [Status.Submitted]: false,
}

export const allEnabled: StatusToggleMap = {
  [Status.Approved]: true,
  [Status.Open]: true,
  [Status.Sent]: true,
  [Status.Submitted]: true,
}

const toggleState: Reducer<StatusToggleMap, ToggleStateAction> = (
  statusState,
  action,
) => {
  switch (action.type) {
    case 'TOGGLE':
      return {
        ...statusState,
        [action.status]: !statusState[action.status],
      }
    case 'ENABLE_ONLY':
      return { ...allDisabled, [action.status]: true }
    case 'ENABLE_ALL':
      return allEnabled
  }
}

export const useStatusToggle = () => {
  return useReducer(toggleState, allEnabled)
}
