import { faWarning, faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
  children: React.ReactNode
  variant: 'info' | 'warning'
}

const variantClasses = (variant: Props['variant']) => {
  switch (variant) {
    case 'info':
      return 'bg-neutral-50 border-neutral-200 text-neutral-500'
    case 'warning':
      return 'bg-warning-100 border-warning-200 text-warning-800'
  }
}

const variantIcon = (variant: Props['variant']) => {
  switch (variant) {
    case 'info':
      return faInfoCircle
    case 'warning':
      return faWarning
  }
}

export const InfoCard: FC<Props> = ({ variant, children }) => {
  return (
    <div
      className={twMerge(
        'rounded text-xs border p-2 flex items-center gap-2',
        variantClasses(variant),
      )}
    >
      <FontAwesomeIcon icon={variantIcon(variant)} />
      <p>{children}</p>
    </div>
  )
}
