import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Splash } from 'components/layout'
import { useTranslation } from 'react-i18next'

export const Logout: FC = () => {
  const { t } = useTranslation()

  return (
    <Splash
      heading={t('features.logout.logout')}
      subHeading={t('features.logout.youHaveSuccessfullyLoggedOut')}
    >
      <Link
        to="/login"
        className="py-3 text-sm font-medium text-center text-white rounded-primary bg-primary-600"
      >
        {t('features.logout.returnToLogin')}
      </Link>
    </Splash>
  )
}
