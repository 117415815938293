import { DateTime, DateTimeFormatOptions } from 'luxon'
import { useCallback, useMemo } from 'react'
import { useLanguage } from './useLanguage'

export const useDateTimeWithLocale = () => {
  const language = useLanguage()

  return useCallback((date: DateTime) => date.setLocale(language), [language])
}

export const useDateTimeToLocaleString = () => {
  const withLocale = useDateTimeWithLocale()

  return useCallback(
    (args: DateTimeFormatOptions) => (date: DateTime) => {
      return withLocale(date).toLocaleString(args)
    },
    [withLocale],
  )
}

export const useRenderLocaleWeekday = () => {
  const toLocaleString = useDateTimeToLocaleString()

  return useMemo(() => toLocaleString({ weekday: 'long' }), [toLocaleString])
}
