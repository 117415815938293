type UnwrapPaginated<T> = T extends Paginated<infer U> ? U : never

export const getItemsAndTotalCount = <
  T extends { data?: { pages: Paginated<unknown>[] } },
  U = UnwrapPaginated<NonNullable<T['data']>['pages'][number]>[],
>(
  result: T,
): {
  items: U
  totalItems: number
} => {
  const items = (result.data?.pages.flatMap((page) => page.data) ?? []) as U
  const totalItems = result.data?.pages.at(0)?.meta?.total ?? 0

  return { items, totalItems }
}
