import { FC, useCallback } from 'react'
import { useUpdateApprovalGroup } from '../hooks/useApprovalGroupsManagement'
import { useTranslation } from 'react-i18next'
import { toast } from '@lib/toasts'
import { ApprovalGroupForm } from './ApprovalGroupForm'

interface Props {
  approvalGroup: AdminApprovalGroup
  onCancel: () => void
  onSave: () => void
}

export const EditApprovalGroupForm: FC<Props> = ({
  onCancel,
  onSave,
  approvalGroup,
}) => {
  const { t } = useTranslation()
  const updateApprovalGroup = useUpdateApprovalGroup()

  const save = useCallback(
    async (groupName: string, approverUserIds: number[]) => {
      try {
        await updateApprovalGroup.mutateAsync({
          id: approvalGroup.id,
          name: groupName,
          approverUserIds: approverUserIds,
        })

        onSave()

        toast({
          title: t('features.approvalGroupsAdmin.approvalGroupSaved'),
          variant: 'success',
          content: t(
            'features.approvalGroupsAdmin.successfullySavedApprovalGroup',
            {
              name: groupName,
            },
          ),
        })
      } catch (error) {
        toast({
          title: t('features.approvalGroupsAdmin.unableToSaveApprovalGroup'),
          variant: 'error',
          content: t('features.approvalGroupsAdmin.failedToSaveApprovalGroup'),
        })
      }
    },
    [onSave, updateApprovalGroup, approvalGroup, t],
  )

  return (
    <ApprovalGroupForm
      approvalGroup={approvalGroup}
      save={save}
      isSaving={updateApprovalGroup.isLoading}
      cancel={onCancel}
    />
  )
}
