import { Tabs } from 'components/buttons'
import { useTranslation } from 'react-i18next'

export interface Props<
  T extends readonly ('my-tasks' | 'all-tasks' | 'non-project')[],
> {
  activeTab: T[number]
  onTabClick: (tab: T[number]) => void
  tabs: T
}

export const TabPicker = <
  T extends readonly ('my-tasks' | 'all-tasks' | 'non-project')[],
>({
  activeTab,
  onTabClick,
  tabs,
}: Props<T>) => {
  const { t } = useTranslation()

  const labelForTabs = {
    'my-tasks': t('features.timeLogging.myTasks'),
    'all-tasks': t('features.timeLogging.allTasks'),
    'non-project': t('common.nonProject'),
  } as const

  const tabsWithLabels = tabs.map((tab) => ({
    id: tab,
    label: labelForTabs[tab],
  }))

  return (
    <Tabs
      className="mt-6"
      tabs={tabsWithLabels}
      activeTab={activeTab}
      onTabClick={(tab) => onTabClick(tab as T[number])}
    />
  )
}
