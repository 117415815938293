import { FC, ReactNode } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from './AuthProvider'
import { ReactQueryProvider } from './ReactQueryProvider'
import { InhibitKeyPressOverrideProvider } from './InhibitKeyPressOverrideProvider'
import { Toaster } from 'react-hot-toast'

export const AppProvider: FC<{ children: ReactNode }> = ({ children }) => (
  <>
    <Toaster position="bottom-right" />
    <AuthProvider>
      <ReactQueryProvider>
        <InhibitKeyPressOverrideProvider>
          <Router>{children}</Router>
        </InhibitKeyPressOverrideProvider>
      </ReactQueryProvider>
    </AuthProvider>
  </>
)
