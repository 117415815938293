import { EntityPicker, EntityItem } from '@features/entity-picker'
import { useEmployees } from '@hooks/useEmployee'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useManagedGroupOutletContext } from '../ManagedGroup'

export const UserPicker = memo(() => {
  const {
    adminManagedGroup,
    handleEmployeeClick: handleClick,
    selectedWorkerIds: selectedIds,
    workers: { search, setSearch },
  } = useManagedGroupOutletContext()

  const { t } = useTranslation()

  return (
    <div className="flex flex-col">
      <p className="text-xs font-medium text-neutral-500 mb-2">
        {t('features.adminManagedGroups.workersIncludedInGroup', {
          count: selectedIds.size,
        })}
      </p>
      <EntityPicker
        filter={(employee, search) => {
          const s = search.toLowerCase()
          return employee.name.toLowerCase().includes(s)
        }}
        placeholder={t('common.search')}
        isSelected={(employee, selectedIds) =>
          selectedIds.has(employee.workdayWorkerId)
        }
        renderNoItemSelected={() => (
          <p className="text-xs text-neutral-500">
            {t('features.adminManagedGroups.noWorkersSelected')}
          </p>
        )}
        renderSearchItem={(employee) => (
          <EntityItem
            key={employee.workdayWorkerId}
            onClick={() => handleClick(employee, { selected: true })}
            primaryText={employee.name}
            disabled={
              selectedIds.has(employee.workdayWorkerId) ||
              (employee.adminManagedGroupId !== undefined &&
                employee.adminManagedGroupId !== adminManagedGroup.id)
            }
          />
        )}
        renderSelectedItem={(employee) => (
          <EntityItem
            key={employee.workdayWorkerId}
            onClick={() => handleClick(employee, { selected: false })}
            primaryText={employee.name}
            removable={true}
          />
        )}
        selectedIds={selectedIds}
        useState={() => [search, setSearch]}
        useQuery={useEmployees}
      />
    </div>
  )
})

UserPicker.displayName = 'UserPicker'
