import { FC, useId } from 'react'
import { Tooltip } from 'react-tooltip'

interface Props {
  path: string[]
}

export const ItemPath: FC<Props> = ({ path }) => {
  const id = useId()

  return (
    <div className="flex flex-row">
      {path.map((p) => (
        <span
          data-tooltip-id={id}
          key={p}
          className="text-xs border border-neutral-300 bg-neutral-150 font-medium text-neutral-900 mr-1 px-1 rounded-sm text-nowrap"
        >
          {p}
        </span>
      ))}
      <Tooltip
        id={id}
        content={path.join(' • ')}
        place="bottom"
        delayShow={200}
      />
    </div>
  )
}
