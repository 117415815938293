import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnchorHTMLAttributes, DetailedHTMLProps, FC } from 'react'

type ButtonProps = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & { icon: IconDefinition }

export const ButtonLink: FC<ButtonProps> = (props) => (
  <a
    className="flex flex-row items-center justify-center w-8 h-8 bg-white border rounded-primary border-primary-500 hover:opacity-90 text-primary-500"
    {...props}
  >
    <FontAwesomeIcon icon={props.icon} />
  </a>
)
