import { useRightClick } from '@hooks/useRightClick'
import { DetailedHTMLProps, FC, TdHTMLAttributes, useEffect } from 'react'
import { twMerge } from '@lib/tailwind-merge'

type Props = DetailedHTMLProps<
  TdHTMLAttributes<HTMLTableCellElement>,
  HTMLTableCellElement
> & {
  onRightClick?: (pos: MousePosition) => void
  selected?: boolean
  focused?: boolean
}

export const Td: FC<Props> = ({
  children,
  className,
  onRightClick,
  selected = false,
  focused = false,
  ...props
}) => {
  const ref = useRightClick<HTMLTableCellElement>({ onRightClick })

  // Scroll cell into view when focused
  useEffect(() => {
    if (focused)
      ref.current?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
      })
  }, [focused, ref])

  return (
    <td
      className={twMerge(
        'border-b border-r border-neutral-300 text-neutral-900 relative whitespace-nowrap',
        (props.onClick || onRightClick) && 'cursor-pointer',
        className,
      )}
      ref={ref}
      {...props}
    >
      {children}
      {selected && (
        <div className="absolute top-0 bottom-0 left-0 right-0 border opacity-50 bg-primary-300 border-primary-400"></div>
      )}
      {focused && (
        <div className="absolute top-[-1px] bottom-[-1px] left-[-1px] right-[-1px] border-2 border-primary-600"></div>
      )}
    </td>
  )
}
