import { getWeekStartAsSunday } from '@utils/dates'
import { DateTime, Interval } from 'luxon'
import { useMemo } from 'react'
import { useWeekDatesSearchParams } from './useWeekDatesSearchParams'

const getRange = (start: DateTime, end: DateTime) =>
  Interval.fromDateTimes(start, end)
    .splitBy({ day: 1 })
    .map((i) => i.start)

export const useWeekDates = (zone?: string) => {
  const { start: startParam, set } = useWeekDatesSearchParams(zone)

  return useMemo(() => {
    const start = getWeekStartAsSunday(
      startParam ?? DateTime.local().setZone(zone),
    )

    const end = start.plus({ days: 6 }).endOf('day')

    return { start, end, range: getRange(start, end), set }
  }, [startParam, set, zone])
}
