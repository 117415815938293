import { useTaggedTime } from '@hooks/useTaggedTime'
import { useFetchEntriesByTimeCard } from '@hooks/useEntries'
import { createTotalByTagRenderObj } from '@utils/taggedTime'
import { secondsToTimestamp } from '@utils/time'
import { TooltipTable } from 'components/indicators/tooltip-table'
import { Spinner } from 'components/loaders'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import { Project } from './Project'
import { TaskItem } from './types'

type ProjectItem = { name: string; tasks: TaskItem[] }
type TasksByProject = Record<number, ProjectItem>

interface Props {
  date: DateTime
  timeCardId: number
}

export const HourAllocationTab: FC<Props> = ({
  // date,
  timeCardId,
}) => {
  const { t } = useTranslation()
  const entriesQuery = useFetchEntriesByTimeCard(timeCardId)
  const taggedTime = useTaggedTime(timeCardId)
  const entries = entriesQuery.data ?? []

  if (entriesQuery.isLoading)
    return (
      <div className="flex flex-row items-center justify-center pt-4">
        <Spinner />
      </div>
    )

  const taggedRenderObj = createTotalByTagRenderObj(taggedTime.data ?? [])

  const tasksByProject = entries.reduce<TasksByProject>((acc, entry) => {
    const projectId = entry.task.project.id

    const foundProjecItem = acc[projectId] ?? {
      name: entry.task.project.name,
      tasks: [],
    }

    const projectItem: ProjectItem = {
      ...foundProjecItem,
      tasks: [
        ...foundProjecItem.tasks,
        {
          label: entry.task.displayPath.join(' • '),
          name: entry.task.name,
          secondsLogged: entry.duration,
        },
      ],
    }

    return { ...acc, [projectId]: projectItem }
  }, {})

  const total = entries.reduce((sum, { duration }) => sum + duration, 0)
  const taggedTotal =
    taggedTime.data?.reduce((sum, time) => sum + time.duration, 0) ?? 0

  return (
    <div className="flex flex-col px-8 py-4 pt-0">
      {/* TODO: Consider re-adding when we have date picker and remove date from info block */}
      {/* <h2 className="font-medium text-neutral-900 font-lg">
        {format(date, 'EEEE, LLL. d y')}
      </h2> */}
      {Object.values(tasksByProject)
        .sort((p1, p2) => (p1.name > p2.name ? 1 : -1))
        .map((project) => (
          <Project
            key={project.name}
            name={project.name}
            tasks={project.tasks}
          />
        ))}
      <div className="flex flex-row items-center justify-between py-1 mt-2 text-sm text-neutral-900">
        <span className="font-semibold">{t('common.total')}</span>
        <div className="flex flex-col text-right">
          <span className="font-semibold">{secondsToTimestamp(total)}</span>
          {taggedTotal > 0 && (
            <>
              <span
                data-tooltip-id="tagged-total"
                className="text-xs font-normal text-orange-800"
              >
                {secondsToTimestamp(taggedTotal)}
              </span>
              <Tooltip
                id="tagged-total"
                place="bottom"
                delayShow={1000}
                closeOnEsc={true}
              >
                <TooltipTable data={taggedRenderObj} />
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
