import { FC } from 'react'
import classNames from 'classnames'
import { Skeleton } from './Skeleton'

interface Props {
  subtle: boolean
  className?: string
  children?: React.ReactNode
}

const Row: FC<Props> = ({ subtle, children, className }) => {
  return (
    <div
      className={classNames(
        'flex flex-col justify-center py-3 text-sm border-neutral-300',
        className,
      )}
    >
      {subtle ? <Skeleton /> : children}
    </div>
  )
}

export default Row
