import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { twMerge } from '@lib/tailwind-merge'

interface Props {
  className?: string
  icon: IconDefinition
  onIconClick: () => void
  testId?: string
}

export const LogoControl: FC<Props> = ({
  className,
  icon,
  onIconClick,
  testId,
}) => (
  <div className={twMerge(['flex items-center gap-4', className])}>
    <button
      className="w-4"
      onClick={onIconClick}
      title="Open sidebar"
      data-testid={testId}
    >
      <FontAwesomeIcon icon={icon} className="text-white" />
    </button>

    <h1 className="text-neutral-100 whitespace-nowrap font-logo text-lg">
      Time Logger {/* We don't translate this since it's the product name */}
    </h1>
  </div>
)
