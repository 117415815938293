import { Toast } from 'components/notifications'
import { FC, ReactNode } from 'react'

interface Props {
  title: ReactNode
  content: ReactNode
}

export const SidebarError: FC<Props> = ({ title, content }) => (
  <Toast
    className="w-auto mx-8 mb-4 last:mb-0"
    dismissable={false}
    title={title}
    content={content}
    variant="error"
    shadow={false}
  />
)
