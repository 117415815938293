import { FC, useState, useMemo } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  ColumnFiltersState,
  ColumnDef,
} from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { FacilityWithWeeklyStatusTotals } from '../types'
import { ScrollableTable, Td, Th, Tr } from 'components/tables'
import { Interval } from 'luxon'
import { renderDateRange } from '@utils/renderDateRange'
import { useLanguage } from '@hooks/useLanguage'
import { useDateTimeWithLocale } from '@hooks/useDateTimeWithLocale'
import { TimeCardProgress } from './TimeCardProgress'
import { Status } from 'types'
import { twMerge } from '@lib/tailwind-merge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCloudExclamation,
  faSquareArrowUpRight,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'react-router-dom'
import { buildSearchParam as buildWeekDatesSearchParam } from '@hooks/useWeekDatesSearchParams'
import { serializeParams } from '../../submissions-admin/utils/paramSerialization'

interface Props {
  facilitiesWithWeeklyTimeCardStatuses: FacilityWithWeeklyStatusTotals[]
  selectedWeekStart: DateTime
  weeks: Interval[]
  payPeriods: PayPeriod[]
  displayPayPeriods: boolean
}

const getTimeCardStatusesForWeek = (
  facilityWithWeeklyTimeCardStatuses: FacilityWithWeeklyStatusTotals,
  date: DateTime,
) =>
  facilityWithWeeklyTimeCardStatuses.weeklyStatusTotals.find(
    ({ weekStartDate }) => weekStartDate.hasSame(date, 'day'),
  )

const buildTimeCardAdminUrl = ({
  weekStartDate,
  facilityId,
  status,
}: {
  weekStartDate: DateTime
  facilityId?: number
  status?: Status
}) =>
  `/admin/time-card-admin?${new URLSearchParams({
    ...serializeParams({
      facilityIds: new Set(facilityId ? [facilityId] : []),
      hiddenStatuses: new Set(
        status ? Object.values(Status).filter((s) => s !== status) : [],
      ),

      // If filtering on a status, filter on just workers with TCs with matching status
      filterEmployeesWithVisibleTimeCards: status ? 'atLeastOne' : undefined,
    }),
    ...Object.fromEntries(buildWeekDatesSearchParam(weekStartDate).entries()),
  }).toString()}`

// Finds pay periods in the given collection that intersect with the given interval
// returns the intersection and the index of the associated pay period
// in the provided payPeriods array.
const findPayPeriodIntersections = ({
  payPeriods,
  interval,
}: {
  payPeriods: PayPeriod[]
  interval: Interval
}) => {
  const intersections = []

  for (const [payPeriodIndex, payPeriod] of payPeriods.entries()) {
    const intersection = interval.intersection(
      Interval.fromDateTimes(
        payPeriod.startDate,
        payPeriod.endDate.endOf('day'),
      ),
    )

    if (intersection) intersections.push({ intersection, payPeriodIndex })
  }

  return intersections
}

export const Table: FC<Props> = ({
  facilitiesWithWeeklyTimeCardStatuses,
  selectedWeekStart,
  weeks,
  payPeriods,
  displayPayPeriods,
}) => {
  const { t } = useTranslation()
  const locale = useLanguage()
  const withLocale = useDateTimeWithLocale()

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'facility',
      desc: false,
    },
  ])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const renderWeekHeaderDate = (date: DateTime, selected: boolean) => (
    <div className="relative flex justify-center">
      <div
        className={twMerge(
          'text-2xs text-neutral-500 font-medium uppercase absolute -mt-3',
          selected && 'text-primary-700',
        )}
      >
        {withLocale(date).toLocaleString({
          weekday: 'short',
        })}
      </div>
      <div className="text-2xl font-light">{date.day}</div>
    </div>
  )

  const sortedPayPeriods = useMemo(
    () =>
      payPeriods.sort(
        (a, b) => a.startDate.toMillis() - b.startDate.toMillis(),
      ),
    [payPeriods],
  )

  const payPeriodsByFacility = useMemo(
    () =>
      sortedPayPeriods.reduce<Record<number, PayPeriod[]>>((acc, payPeriod) => {
        if (acc[payPeriod.facilityId] === undefined)
          acc[payPeriod.facilityId] = []

        acc[payPeriod.facilityId].push(payPeriod)

        return acc
      }, {}),
    [sortedPayPeriods],
  )

  const weekColumns = weeks.map(
    (weekInterval, i) =>
      ({
        id: `week-${i}`,
        enableSorting: false,
        header: () => {
          const isSelectedWeek = weekInterval.start.hasSame(
            selectedWeekStart,
            'day',
          )

          return (
            <div
              className={twMerge(
                'text-sm text-center w-full flex flex-col gap-1 px-4 py-3 ',
                isSelectedWeek &&
                  'bg-primary-100 text-primary-700 border-b-2 border-primary-700',
              )}
            >
              <div className="font-semibold">
                {renderDateRange(
                  weekInterval.start,
                  weekInterval.end.minus({ second: 1 }), // Interval end dates are exclusive
                  t,
                  locale,
                  { month: 'short' },
                )}
              </div>
              <div
                className={twMerge(
                  'flex justify-center text-neutral-900 pt-3 gap-1',
                  isSelectedWeek && 'text-primary-700',
                )}
              >
                {renderWeekHeaderDate(weekInterval.start, isSelectedWeek)}
                <div className="text-2xl font-light">&minus;</div>
                {renderWeekHeaderDate(
                  weekInterval.end.minus({ second: 1 }),
                  isSelectedWeek,
                )}
              </div>
            </div>
          )
        },
        accessorFn: (facilityWithWeeklyTimeCardStatuses) => {
          const facilityWeek = getTimeCardStatusesForWeek(
            facilityWithWeeklyTimeCardStatuses,
            weekInterval.start,
          )

          if (facilityWeek === undefined) return 0

          return Object.values(facilityWeek.timeCardStatusTotals).reduce(
            (sum, t) => sum + t,
            0,
          )
        },
        cell: ({ row }) => {
          const facilityWeek = getTimeCardStatusesForWeek(
            row.original,
            weekInterval.start,
          )

          if (facilityWeek === undefined) return null

          const hasIssues =
            facilityWeek.timeCardIssues.hasTimeLoggedToInactiveProject > 0 ||
            facilityWeek.timeCardIssues.hasTimeLoggedToUnlinkedProject > 0
          const hasSendErrors = facilityWeek.timeCardIssues.workdaySendError > 0

          // Find pay periods that intersect with the current week
          const payPeriodIntersections = findPayPeriodIntersections({
            payPeriods: payPeriodsByFacility[row.original.facility.id] || [],
            interval: weekInterval,
          })

          // We highlight all even pay periods,
          // so we find the first even intersectiong pay period
          // and highlight its intersection interval.
          // Note: We assume that a max of 2 pay periods can intersect
          //       a given week, since pay cycles are biweekly and monthly.
          const highlightedPayPeriodIntersection = payPeriodIntersections.find(
            ({ payPeriodIndex }) => payPeriodIndex % 2 === 0,
          )

          return (
            <div
              className={twMerge(
                'absolute inset-0',
                (hasIssues || hasSendErrors) &&
                  !displayPayPeriods &&
                  'bg-error-50',
                displayPayPeriods && 'bg-neutral-50',
              )}
            >
              {displayPayPeriods && highlightedPayPeriodIntersection && (
                <div
                  className="absolute top-0 bottom-0 bg-neutral-200"
                  style={{
                    left: `${
                      ((highlightedPayPeriodIntersection.intersection.start.toSeconds() -
                        weekInterval.start.toSeconds()) /
                        (7 * 24 * 60 * 60)) *
                      100
                    }%`,
                    right: `${
                      ((weekInterval.end.toSeconds() -
                        highlightedPayPeriodIntersection.intersection.end.toSeconds()) /
                        (7 * 24 * 60 * 60)) *
                      100
                    }%`,
                  }}
                ></div>
              )}
              <div
                className={
                  'block px-4 pt-1 pb-6 text-center group absolute inset-0'
                }
              >
                <div className="flex justify-center gap-2 mb-1 h-4 z-10">
                  {hasIssues && (
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="text-error-500"
                    />
                  )}
                  {hasSendErrors && (
                    <FontAwesomeIcon
                      icon={faCloudExclamation}
                      className="text-error-500"
                    />
                  )}

                  <Link
                    to={buildTimeCardAdminUrl({
                      facilityId: row.original.facility.id,
                      weekStartDate: weekInterval.start,
                    })}
                    className="text-neutral-700 invisible group-hover:visible absolute right-1"
                  >
                    <FontAwesomeIcon icon={faSquareArrowUpRight} />
                  </Link>
                </div>
                <TimeCardProgress
                  statusTotals={facilityWeek.timeCardStatusTotals}
                  simplifyWhenAllSent={!hasIssues && !hasSendErrors}
                  segmentLinkToBuilder={(status) =>
                    buildTimeCardAdminUrl({
                      facilityId: row.original.facility.id,
                      weekStartDate: weekInterval.start,
                      status,
                    })
                  }
                />
              </div>
            </div>
          )
        },
        footer: ({ table }) => {
          const totalsForWeek = table.getFilteredRowModel().rows.reduce(
            (totals, row) => {
              const facilityWeek = getTimeCardStatusesForWeek(
                row.original,
                weekInterval.start,
              )

              if (facilityWeek === undefined) return totals

              const { timeCardStatusTotals } = facilityWeek

              return {
                [Status.Open]:
                  totals[Status.Open] + timeCardStatusTotals[Status.Open],
                [Status.Submitted]:
                  totals[Status.Submitted] +
                  timeCardStatusTotals[Status.Submitted],
                [Status.Approved]:
                  totals[Status.Approved] +
                  timeCardStatusTotals[Status.Approved],
                [Status.Sent]:
                  totals[Status.Sent] + timeCardStatusTotals[Status.Sent],
              }
            },
            {
              [Status.Open]: 0,
              [Status.Submitted]: 0,
              [Status.Approved]: 0,
              [Status.Sent]: 0,
            },
          )

          const weekHasIssues = table.getFilteredRowModel().rows.some((row) => {
            const facilityWeek = getTimeCardStatusesForWeek(
              row.original,
              weekInterval.start,
            )

            if (facilityWeek === undefined) return false

            return Object.values(facilityWeek.timeCardIssues).some(
              (issue) => issue > 0,
            )
          })

          return (
            <div
              className={twMerge(
                'block px-4 py-6 pb-6 text-center group absolute inset-0',
              )}
            >
              <Link
                to={buildTimeCardAdminUrl({
                  weekStartDate: weekInterval.start,
                })}
                className="text-neutral-700 invisible group-hover:visible absolute right-1 top-1"
              >
                <FontAwesomeIcon icon={faSquareArrowUpRight} />
              </Link>

              <TimeCardProgress
                statusTotals={totalsForWeek}
                simplifyWhenAllSent={!weekHasIssues}
                segmentLinkToBuilder={(status) =>
                  buildTimeCardAdminUrl({
                    weekStartDate: weekInterval.start,
                    status,
                  })
                }
              />
            </div>
          )
        },
      } as ColumnDef<FacilityWithWeeklyStatusTotals, number>),
  )

  const columns = [
    {
      id: 'facility',
      header: t('common.facilityName'),
      accessorFn: ({ facility }) => facility.name,
      cell: ({ row, getValue }) => (
        <div className="px-4 py-3">
          <div className="font-semibold">{getValue()}</div>
          <div className="text-sm">
            {t(`payCycles.${row.original.facility.payCycle}`)}
          </div>
        </div>
      ),
    } as ColumnDef<FacilityWithWeeklyStatusTotals, string>,
    ...weekColumns,
  ]

  const table = useReactTable({
    data: facilitiesWithWeeklyTimeCardStatuses,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })

  return (
    <ScrollableTable
      header={table.getHeaderGroups().map((headerGroup) => (
        <Tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            return (
              <Th
                key={header.id}
                clickable={header.column.getCanSort()}
                label={flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
                // Week headers manage their own padding
                className={twMerge(header.id.match(/week-\d+/) && 'p-0')}
                onClick={header.column.getToggleSortingHandler()}
                sortDir={header.column.getIsSorted()}
              ></Th>
            )
          })}
        </Tr>
      ))}
      body={table.getRowModel().rows.map((row) => {
        return (
          <Tr key={row.id}>
            {row.getVisibleCells().map((cell) => {
              return (
                <Td key={cell.id} className="w-[10%]">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              )
            })}
          </Tr>
        )
      })}
      footer={table.getFooterGroups().map((footerGroup) => (
        <Tr key={footerGroup.id}>
          {footerGroup.headers.map((header) => (
            <td
              key={header.id}
              className="relative h-16 bottom-0 font-normal text-left border-t border-r bg-neutral-100 border-neutral-300"
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.footer,
                    header.getContext(),
                  )}
            </td>
          ))}
        </Tr>
      ))}
    />
  )
}
