import { FC, ReactNode, useState } from 'react'
import { Item as TItem } from '../types'
import { twMerge } from '@lib/tailwind-merge'
import { ItemPath } from './ItemPath'
import { IconButton } from 'components/buttons'
import { faSquareMinus } from '@fortawesome/pro-solid-svg-icons'
import {
  faChevronDown,
  faChevronUp,
  faSquarePlus,
} from '@fortawesome/pro-regular-svg-icons'
import { ActiveDiamond } from './ActiveDiamond'
import { Tooltip } from '@lib/tooltip'
import { useTranslation } from 'react-i18next'
import {
  isJiraTaskSourceDetails,
  isShotgridTaskSourceDetails,
} from '../helpers'

export interface Props {
  className?: string
  item: TItem
  onItemClick: (id: number, checked: boolean) => void
  path?: string[]
}

export const SkeletonItem = () => (
  <div className="h-11 grow my-2 mx-9 bg-neutral-100 animate-pulse"></div>
)

const ItemDetail = ({
  label,
  value,
}: {
  label: ReactNode
  value: ReactNode
}) => (
  <tr>
    <td className="px-2 text-xs text-neutral-500 whitespace-nowrap align-top">
      {label}
    </td>
    <td className="px-2 text-xs text-neutral-900 w-full">{value}</td>
  </tr>
)

export const Item: FC<Props> = ({ className, item, onItemClick, path }) => {
  const { t } = useTranslation()
  const [expanded, setExpaned] = useState(false)

  return (
    <div className={twMerge('flex flex-col', className)}>
      {path && <ItemPath path={path} />}
      <div
        className={twMerge(
          'flex flex-col p-1 my-1 text-sm rounded bg-neutral-150',
          item.checked && 'bg-primary-100',
        )}
      >
        <div className={twMerge('flex flex-row items-center')}>
          <label
            data-tooltip-id={`tooltip-${item.task.id}`}
            key={item.task.id}
            className={twMerge(
              'flex flex-grow flex-row items-center text-sm font-semibold cursor-pointer select-none',
              item.disabled && 'cursor-not-allowed opacity-50',
            )}
          >
            <IconButton
              id={`item-${item.task.id}`}
              className={twMerge(
                'hover:bg-primary-200 group w-8 h-8 text-neutral-500 mr-2',
                item.checked && 'text-primary-600',
              )}
              disabled={item.disabled}
              icon={item.checked ? faSquareMinus : faSquarePlus}
              iconProps={{
                className: 'group-hover:text-primary-800',
                size: 'lg',
              }}
              onClick={() => onItemClick(item.task.id, !item.checked)}
              circle={true}
            />
            {item.task.name}
          </label>
          {item.active !== undefined && (
            <ActiveDiamond active={item.active} className="mr-1" />
          )}
          <IconButton
            id={`item-${item.task.id}`}
            className="w-8 h-8 text-neutral-600 ml-auto hover:bg-neutral-300"
            icon={expanded ? faChevronUp : faChevronDown}
            onClick={() => setExpaned((expanded) => !expanded)}
          />
        </div>
        {expanded && (
          <table className="my-2 table-auto font-medium">
            <tbody>
              {isShotgridTaskSourceDetails(item.task.sourceDetails) && (
                <ItemDetail
                  label={t('features.taskPicker.shotgridId')}
                  value={item.task.sourceDetails.shotgridId}
                />
                // TODO: SG/Flow PT status
              )}

              {isJiraTaskSourceDetails(item.task.sourceDetails) && (
                <>
                  <ItemDetail
                    label={t('features.taskPicker.jiraId')}
                    value={item.task.sourceDetails.jiraId}
                  />
                  <ItemDetail
                    label={t('features.taskPicker.summary')}
                    value={item.task.sourceDetails.summary}
                  />
                </>
              )}
            </tbody>
          </table>
        )}
      </div>
      {item.disabled && (
        <Tooltip
          id={`tooltip-${item.task.id}`}
          place="bottom"
          content={t(
            'features.timeLogging.taskSelection.taskCannotBeRemovedWhenTimeIsAllocatedToIt',
          )}
        />
      )}
    </div>
  )
}
