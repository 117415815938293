import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'

interface Props {
  expanded: boolean
  onExpandChange: (expanded: boolean) => void
  projectName: string
  projectIntegrationCode?: string
}

export const AllocationTableHeader: FC<Props> = ({
  expanded,
  onExpandChange,
  projectName,
  projectIntegrationCode,
}) => {
  return (
    <h3
      className="inline-flex gap-2 items-center mb-1 text-sm tracking-wide uppercase cursor-pointer text-neutral-900 leading-4"
      onClick={() => onExpandChange(!expanded)}
    >
      <div className="bg-neutral-150 rounded-full w-6 h-6 flex items-center justify-center">
        <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronRight} />
      </div>

      <div className="inline-flex gap-1">
        <div className="font-semibold">{projectName}</div>
        {projectIntegrationCode && <div>({projectIntegrationCode})</div>}
      </div>
    </h3>
  )
}
