import { useState, useEffect, useMemo } from 'react'
import { useThrottle } from '@react-hook/throttle'
import { noop } from '@utils/noop'
import { useInhibitKeyPressOverride } from './useInhibitKeyPressOverride'

type Modifier = 'alt' | 'ctrl' | 'meta' | 'shift'

type Modifiers = {
  [modifier in Modifier]?: boolean
}

type Options<T> = {
  callback: (key: T) => void

  // Additional caller-specified logic to determine if event should be processed by this hook or not
  shouldProcessEvent: (e: KeyboardEvent) => boolean

  // Additional logic to determine if event should be attached or not
  // (this is useful since the hook itself cannot be conditionally invoked)
  shouldAttachEvent: () => boolean

  // If the default event should be prevented or not
  shouldPreventDefault: boolean
}

const defaultOptions = {
  callback: noop,
  shouldProcessEvent: () => true,
  shouldAttachEvent: () => true,
  shouldPreventDefault: true,
}

// modifiers is an optional object containing modifier states
// that must match when the specified targetKey is pressed
//    e.g. ('Tab', { shift: true }) means "shift+tab" (all other modifiers are ignored)
//         ('Tab', { shift: false }) means "tab without shift" (all other modifiers are ignored)
//         ('Tab') means "tab without regard for shift (or any other modifier)"
//         ('Meta') means "meta key is pressed"
const useKeyPress = function <T extends string>(
  targetKeys: T | readonly T[],
  modifiers: Modifiers = {},
  providedOptions: Partial<Options<T>> = {},
) {
  const options = useMemo<Options<T>>(
    () => ({
      ...defaultOptions,
      ...providedOptions,
    }),
    [providedOptions],
  )

  const targets = useMemo(() => {
    if (typeof targetKeys === 'string') return [targetKeys]

    return targetKeys
  }, [targetKeys])

  const [keyPressed, setKeyPressed] = useState<T | null>(null)
  const [repeat, setRepeat] = useThrottle<number>(0, 10, true)
  const { inhibited } = useInhibitKeyPressOverride()

  useEffect(() => {
    function modifiersMatch(e: KeyboardEvent): boolean {
      let modifier: Modifier

      for (modifier in modifiers) {
        if (e[`${modifier}Key`] != modifiers[modifier]) {
          return false
        }
      }

      return true
    }

    function shouldHandleEvent(e: KeyboardEvent): boolean {
      return (
        targets.includes(e.key as T) &&
        modifiersMatch(e) &&
        options.shouldProcessEvent(e) &&
        !inhibited
      )
    }

    function downHandler(e: KeyboardEvent) {
      if (shouldHandleEvent(e)) {
        if (options.shouldPreventDefault) e.preventDefault()
        setKeyPressed(e.key as T)

        if (e.repeat) {
          setRepeat((r) => r + 1)
        }
      }
    }

    function upHandler(e: KeyboardEvent) {
      if (shouldHandleEvent(e)) {
        if (options.shouldPreventDefault) e.preventDefault()
        setKeyPressed(null)
        setRepeat(0)
        options.callback(e.key as T)
      }
    }

    if (options.shouldAttachEvent()) {
      window.addEventListener('keydown', downHandler)
      window.addEventListener('keyup', upHandler)
    }

    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [targets, modifiers, setRepeat, inhibited, options])

  return { keyPressed, repeat }
}
export default useKeyPress
