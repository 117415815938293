import {
  DateRangeFilterValue,
  SearchQueryFilterValue,
  NumericValuesFilterValue,
  StringValuesFilterValue,
  StatusFilterValue,
  NumericRangeFilterValue,
} from '../types'

type AnyFilterValue =
  | DateRangeFilterValue
  | NumericValuesFilterValue
  | StringValuesFilterValue
  | StatusFilterValue
  | NumericRangeFilterValue
  | SearchQueryFilterValue

// Type guard functions to check the type of the values
export function isDateRangeFilter(
  value: AnyFilterValue,
): value is DateRangeFilterValue {
  return 'start' in value || 'end' in value || 'relativeDateRange' in value
}

export function isSearchQuery(
  value: AnyFilterValue,
): value is SearchQueryFilterValue {
  return 'query' in value
}

export function isValueFilter(
  value: AnyFilterValue,
): value is NumericValuesFilterValue | StringValuesFilterValue {
  return 'values' in value && value.values instanceof Array
}

export function isStatusFilter(
  value: AnyFilterValue,
): value is StatusFilterValue {
  return 'statuses' in value
}

export function isNumericRangeFilter(
  value: AnyFilterValue,
): value is NumericRangeFilterValue {
  return 'numericRangeFrom' in value || 'numericRangeTo' in value
}
