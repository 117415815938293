import { Button, MenuButton, Tabs } from 'components/buttons'
import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { useNameAdminManageGroupModal } from '../hooks/useNameAdminManagedGroupModal'
import { useManagedGroupOutletContext } from '../routes/ManagedGroup'

type Tab = 'members' | 'work-week-and-allocation'

interface Props {
  activeTab: Tab
  children?: ReactNode
  className?: string
}

export const Layout: FC<Props> = ({ activeTab, children, className }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    adminManagedGroup,
    //    delete: deleteGroup,
    duplicate,
    isLoading,
    setSubmitted,
    submitted,
    saveDisabled,
    save,
    updateName,
  } = useManagedGroupOutletContext()

  const getModalName = useNameAdminManageGroupModal()

  const handleSaveButtonClick = async () => {
    if (adminManagedGroup.id === 0) {
      setSubmitted(true)
      if (adminManagedGroup.isInvalid) return

      const name = await getModalName()
      await save(name, { navigateToTab: activeTab })
    } else {
      await save(adminManagedGroup.name)
    }
  }

  const rename = async () => {
    const name = await getModalName(adminManagedGroup.name)
    updateName(name)
  }

  const menuItems = [
    {
      content: 'Rename',
      onClick: () => void rename(),
      disabled: isLoading,
    },
    {
      content: 'Duplicate',
      onClick: () => void duplicate({ navigateToTab: activeTab }),
      disabled: isLoading,
    },
  ]

  // if (adminManagedGroup.id !== 0) {
  //   menuItems.push({
  //     content: 'Delete',
  //     onClick: () => void deleteGroup(),
  //     disabled: isLoading,
  //   })
  // }

  return (
    <div className={twMerge('flex flex-col grow', className)}>
      <div className="flex flex-col pt-4 bg-neutral-100">
        <div className="flex flex-row px-8 items-center">
          <h1
            className={twMerge(
              'text-2xl font-medium leading-6 text-neutral-900 mr-2 flex-grow',
              adminManagedGroup.id === 0 && 'italic',
            )}
          >
            {adminManagedGroup.name ||
              t('features.adminManagedGroups.untitled')}
          </h1>
          <MenuButton
            disabled={isLoading}
            button={({ disabled, toggleMenuHidden }) => (
              <Button
                className="mr-2"
                variant="outlined"
                disabled={disabled}
                onClick={toggleMenuHidden}
              >
                ...
              </Button>
            )}
            menuItems={menuItems}
          />
          <Button
            onClick={() => void handleSaveButtonClick()}
            disabled={saveDisabled}
          >
            {adminManagedGroup.id === 0 ? t('common.save') : t('common.update')}
          </Button>
        </div>
        <Tabs
          activeTab={activeTab}
          onTabClick={(tab: 'members' | 'work-week-and-allocation') =>
            navigate(`../${tab}`)
          }
          tabs={
            [
              {
                id: 'members',
                label: t('features.adminManagedGroups.members'),
              },
              {
                id: 'work-week-and-allocation',
                label: t('features.adminManagedGroups.workWeekAndAllocation'),
                error: submitted && adminManagedGroup.isInvalid,
              },
            ] as const
          }
        />
      </div>
      <div className="flex flex-col px-8 py-4 grow">{children}</div>
    </div>
  )
}
