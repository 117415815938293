import { FC, useMemo } from 'react'
import { NativeTimeInput } from 'components/form/native-time-input'
import { Checkbox } from 'components/form/checkbox'
import { useTranslation } from 'react-i18next'

interface Props {
  day: DayOfWeek
  value: DayOfWeekSettings | null
  onChange: (value: DayOfWeekSettings | null) => void
}

const DEFAULT_WORK_DAY = {
  startTime: '09:00',
  endTime: '18:00',
  breakStartTime: '12:30',
  breakEndTime: '13:30',
}

export const DayDefaultsRow: FC<Props> = ({ day, value, onChange }) => {
  const { t } = useTranslation()

  const enabled = useMemo(() => value !== null, [value])

  const valueWithDefaults = useMemo(
    () => ({
      ...DEFAULT_WORK_DAY,
      ...value,
    }),
    [value],
  )

  return (
    <tr>
      <td>
        <label className="flex items-center gap-2 cursor-pointer">
          <Checkbox
            checked={enabled}
            onChange={() => onChange(enabled ? null : DEFAULT_WORK_DAY)}
          />
          {t(`common.dayOfWeek.${day}.full`)}
        </label>
      </td>
      <td>
        <NativeTimeInput
          required
          disabled={!enabled}
          value={valueWithDefaults.startTime}
          onChange={(value) =>
            onChange({
              ...valueWithDefaults,
              startTime: value || DEFAULT_WORK_DAY.startTime,
            })
          }
        />
      </td>
      <td>
        <NativeTimeInput
          required
          disabled={!enabled}
          value={valueWithDefaults.endTime}
          onChange={(value) =>
            onChange({
              ...valueWithDefaults,
              endTime: value || DEFAULT_WORK_DAY.endTime,
            })
          }
        />
      </td>
      <td>
        <NativeTimeInput
          required
          disabled={!enabled}
          value={valueWithDefaults.breakStartTime}
          onChange={(value) =>
            onChange({
              ...valueWithDefaults,
              breakStartTime: value || DEFAULT_WORK_DAY.breakStartTime,
            })
          }
        />
      </td>
      <td>
        <NativeTimeInput
          required
          disabled={!enabled}
          value={valueWithDefaults.breakEndTime}
          onChange={(value) =>
            onChange({
              ...valueWithDefaults,
              breakEndTime: value || DEFAULT_WORK_DAY.breakEndTime,
            })
          }
        />
      </td>
    </tr>
  )
}
