import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, ReactNode } from 'react'
import { twMerge } from '@lib/tailwind-merge'

export interface Props {
  className?: string
  content?: ReactNode
  dismissable: boolean
  title: ReactNode
  onCloseButtonClick?: () => void
  onUndoClick?: () => void
  variant?: 'default' | 'success' | 'alert' | 'error'
  shadow?: boolean
}

export const Toast: FC<Props> = ({
  className,
  content,
  dismissable = true,
  title,
  onCloseButtonClick,
  onUndoClick,
  variant = 'default',
  shadow = true,
}) => {
  return (
    <div
      className={twMerge(
        'flex flex-row p-1 rounded w-[375px]',
        shadow && 'drop-shadow',
        variant === 'default'
          ? 'text-white bg-neutral-900'
          : 'text-neutral-900',
        variant === 'success' && 'bg-success-100',
        variant === 'alert' && 'bg-warning-100',
        variant === 'error' && 'bg-error-100',
        className,
      )}
    >
      <div
        className={twMerge(
          'w-1 rounded',
          variant === 'default' && 'bg-neutral-400',
          variant === 'success' && 'bg-success-500',
          variant === 'alert' && 'bg-warning-500',
          variant === 'error' && 'bg-error-500',
        )}
      >
        &nbsp;
      </div>
      <div className="flex flex-col px-4 py-2 grow">
        <div className="flex flex-row justify-between text-sm font-semibold">
          {title}
          {dismissable && (
            <button className="self-end contents" onClick={onCloseButtonClick}>
              <FontAwesomeIcon icon={faTimes} size="sm" />
            </button>
          )}
        </div>
        <span
          className={twMerge(
            'text-sm font-normal',
            variant === 'default' ? 'text-neutral-200' : 'text-neutral-700',
          )}
        >
          {content}
          {onUndoClick && (
            <span
              className="ml-1 underline cursor-pointer"
              onClick={onUndoClick}
            >
              Undo
            </span>
          )}
        </span>
      </div>
    </div>
  )
}
