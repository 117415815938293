import { deleteIntegrationProjectLink } from '@lib/api'
import { adminProjectsKeys, sourceProjectsKeys } from '@lib/keys'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useDeleteIntegrationProjectLink = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      projectId,
      integrationProjectLinkId,
    }: {
      projectId: number
      integrationProjectLinkId: number
    }) => deleteIntegrationProjectLink(projectId, integrationProjectLinkId),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(adminProjectsKeys.all)

        // source projects include a linked flag
        void queryClient.invalidateQueries(sourceProjectsKeys.all)
      },
    },
  )
}
