import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from '@lib/tailwind-merge'

interface Props {
  children?: ReactNode
  className?: string
  onClick: () => void
  selectedCount?: number
}

export const ButtonInput: FC<Props> = ({
  children,
  className,
  onClick,
  selectedCount = 0,
}) => {
  const { t } = useTranslation()

  return (
    <button
      className={twMerge(
        'h-8 px-3 text-xs bg-white border rounded border-neutral-400 whitespace-nowrap w-full text-left',
        className,
      )}
      onClick={onClick}
    >
      {children
        ? children
        : selectedCount === 0
        ? t('common.all')
        : t('common.selected', { count: selectedCount })}
    </button>
  )
}
