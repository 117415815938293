import { FC, useMemo, useState } from 'react'
import { NewHeader, WeekSelectorSubHeader } from 'components/page-header'
import { Table } from '../components/Table'
import { Spinner } from 'components/loaders'
import { useApprovals } from '@hooks/useApprovals'
import { useAdminProjects } from '@features/admin/hooks/useAdminProjects'
import { useAdminApprovalGroups } from '@features/admin/hooks/useAdminApprovalGroups'
import { useTranslation } from 'react-i18next'
import { useWeekDates } from '@hooks/useWeekDates'
import { DomainWithApprovals } from '../types'
import { MiniSilos } from '../components/MiniSilos'
import { Status } from 'types'
import { approvalStatus } from '../utils/approvalStatus'
import { useStatusToggle } from '@hooks/useStatusToggle'
import { SortingState, ColumnFiltersState } from '@tanstack/react-table'
import { Button } from 'components/buttons'

const defaultSortingState = [
  {
    id: 'name',
    desc: false,
  },
]

const defaultColumnFilterState: ColumnFiltersState = []

export const ApprovalsAdmin: FC = () => {
  const { start, end } = useWeekDates()
  const { data: approvals } = useApprovals(start, end)
  const { data: projects } = useAdminProjects()
  const { data: approvalGroups } = useAdminApprovalGroups()
  const {
    start: selectedWeek,
    end: selectedWeekEnd,
    set: setSelectedWeek,
  } = useWeekDates()
  const [showOnlyStatuses, changeShowOnlyStatus] = useStatusToggle()
  const [sorting, setSorting] = useState<SortingState>(defaultSortingState)
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    defaultColumnFilterState,
  )

  const { t } = useTranslation()

  const isLoading = useMemo(
    () => !approvals || !projects || !approvalGroups,
    [approvals, projects, approvalGroups],
  )

  const approvalDomains = useMemo(() => {
    if (!approvals || !projects || !approvalGroups) return []

    return approvals.reduce<DomainWithApprovals[]>((domains, approval) => {
      let domain = domains.find((d) => d.id === approval.domain.id)

      if (!domain) {
        const domainCollection: AdminApprovalDomain[] =
          approval.domain.type === 'project' ? projects : approvalGroups

        const approverUsers =
          domainCollection.find((d) => d.globalId === approval.domain.id)
            ?.approverUsers || []

        domain = { ...approval.domain, approverUsers, approvals: [] }
        domains.push(domain)
      }

      domain.approvals.push(approval)

      return domains
    }, [])
  }, [approvals, projects, approvalGroups])

  const statusCounts = useMemo(() => {
    const counts = {
      [Status.Open]: 0,
      [Status.Submitted]: 0,
      [Status.Approved]: 0,
    }

    if (approvals === undefined) return counts

    for (const approval of approvals) {
      counts[approvalStatus(approval)]++
    }

    return counts
  }, [approvals])

  const reset = () => {
    setColumnFilters(defaultColumnFilterState)
    setSorting(defaultSortingState)
    changeShowOnlyStatus({ type: 'ENABLE_ALL' })
  }

  return (
    <div className="flex flex-col flex-grow">
      <NewHeader title={t('features.navigationSidebar.approvalsAdmin')} />

      <WeekSelectorSubHeader
        selectedWeek={selectedWeek}
        onWeekSelect={setSelectedWeek}
      >
        {!isLoading && (
          <div className="border-r border-neutral-200 pr-2 mr-1">
            <MiniSilos
              changeStatus={changeShowOnlyStatus}
              statusCounts={statusCounts}
              showOnlyStatuses={showOnlyStatuses}
            />
          </div>
        )}

        <Button
          className="px-4 text-sm font-medium text-neutral-900"
          onClick={reset}
          variant="outlined"
        >
          {t('common.reset')}
        </Button>
      </WeekSelectorSubHeader>

      {isLoading ? (
        <Spinner />
      ) : (
        <Table
          approvalDomains={approvalDomains}
          weekStart={selectedWeek}
          weekEnd={selectedWeekEnd}
          showOnlyStatuses={showOnlyStatuses}
          sorting={sorting}
          onSortingChange={setSorting}
          columnFilters={columnFilters}
          onColumnFiltersChange={setColumnFilters}
        />
      )}
    </div>
  )
}
