import { NodeStackItem, NonNodeStackItem, StackItem } from '../types'

const root = (
  stack: StackItem[],
  selectedProject: { id: number; name: string },
): NonNodeStackItem => {
  const root = stack.at(0) as NonNodeStackItem

  if (!root)
    throw new Error('Unreachable.  The stack should always have a root')

  return {
    ...root,
    selected: { id: selectedProject.id, label: selectedProject.name },
  }
}

const node = (label?: string): NodeStackItem => ({
  column: 'node',
  selected: label ? { label } : undefined,
  activeTab: 'explore',
})

const projectIntegrations = (item?: {
  projectIntegration: { id: number; name: string }
}): StackItem => ({
  column: 'project-integrations',
  selected: item && {
    id: item.projectIntegration.id,
    label: item.projectIntegration.name,
  },
})

const fromProject = (project: Project, prev: StackItem[]): StackItem[] => {
  return [root(prev, project), projectIntegrations()] as StackItem[]
}

const fromSequence = (sequence: Sequence, prev: StackItem[]): StackItem[] => {
  return [
    root(prev, sequence.project),
    projectIntegrations(sequence),
    node('Sequences'),
    node(sequence.label),
    node(),
  ] as StackItem[]
}

const fromShot = (shot: Shot, prev: StackItem[]): StackItem[] => {
  return [
    root(prev, shot.project),
    projectIntegrations(shot),
    ...(shot.sequence ? [node('Sequences'), node(shot.sequence.label)] : []),
    node('Shots'),
    node(shot.label),
    node(),
  ]
}

const fromAsset = (asset: Asset, prev: StackItem[]): StackItem[] => {
  return [
    root(prev, asset.project),
    projectIntegrations(asset),
    node('Assets'),
    node(asset.label),
    node(),
  ]
}

export const createStackFromItem = (
  item: MultisearchResultItem,
  itemType: Exclude<keyof MultisearchResult, 'tasks'>,
  prev: StackItem[] = [{ column: 'root' }],
): StackItem[] => {
  return {
    projects: (i: typeof item) => fromProject(i as Project, prev),
    sequences: (i: typeof item) => fromSequence(i as Sequence, prev),
    shots: (i: typeof item) => fromShot(i as Shot, prev),
    assets: (i: typeof item) => fromAsset(i as Asset, prev),
  }[itemType](item)
}
