import { FC, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { DropdownMenu, DropdownMenuProps, Item } from './DropdownMenu'
import { useOnClickOutside } from '@hooks/useOnClickOutside'

type OnClose = () => void

interface OpenOptions {
  pos: MousePosition
  onClose?: OnClose
  item: Item
}

export interface ImperativeHandle {
  open: (options: OpenOptions) => void
}

const ImperativeContextMenu = forwardRef<ImperativeHandle>(
  (_props, handleRef) => {
    const [hidden, setHidden] = useState(true)
    const [pos, setPos] = useState<MousePosition>({
      x: 0,
      y: 0,
    })
    const [onClose, setOnClose] = useState<OnClose>(() => () => undefined)
    const [items, setItems] = useState<DropdownMenuProps['items']>([])
    const ref = useRef<HTMLDivElement>(null)

    const handleClose: OnClose = () => {
      setHidden(true)
      onClose()
    }

    useOnClickOutside(ref, handleClose)

    useImperativeHandle(handleRef, () => ({
      open: ({ pos, onClose, item }) => {
        setHidden(false)
        setPos(pos)
        onClose && setOnClose(onClose)
        setItems([item])
      },
    }))

    return (
      <DropdownMenu
        hidden={hidden}
        items={items}
        onItemClick={handleClose}
        preventEventPropagation={true}
        style={{ left: pos.x + 10, top: pos.y + 10 }}
        className="z-50 w-40"
        ref={ref}
      />
    )
  },
)

ImperativeContextMenu.displayName = 'ImperativeContextMenu'

interface ReactiveProps {
  items: DropdownMenuProps['items']
  onClose: () => void
  pos: MousePosition
}

const ReactiveContextMenu: FC<ReactiveProps> = ({ items, onClose, pos }) => {
  const ref = useRef(null)

  useOnClickOutside(ref, onClose)

  return (
    <DropdownMenu
      hidden={false}
      items={items}
      onItemClick={onClose}
      style={{ left: pos.x + 10, top: pos.y + 10 }}
      className="z-50 w-40"
      ref={ref}
    />
  )
}

type Props =
  | (ReactiveProps & { variant?: 'reactive' })
  | { variant: 'imperative' }

const ContextMenu = forwardRef<ImperativeHandle, Props>(
  ({ variant = 'reactive', ...props }, handleRef) => {
    return variant === 'reactive' ? (
      <ReactiveContextMenu {...(props as ReactiveProps)} />
    ) : (
      <ImperativeContextMenu ref={handleRef} />
    )
  },
)

ContextMenu.displayName = 'ContextMenu'

export { ContextMenu }
