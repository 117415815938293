import { multisearch, multisearchByType } from '@lib/api'
import { multiSearchKeys } from '@lib/keys'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

export const useMultisearch = (
  options: MultisearchOptions,
  { enabled }: { enabled: boolean },
) => {
  return useQuery(multiSearchKeys.search(options), () => multisearch(options), {
    enabled,
  })
}

export const useMultisearchByType = (
  options: MultisearchByTypeOptions,
  { enabled } = { enabled: true },
) => {
  return useInfiniteQuery({
    queryKey: multiSearchKeys.searchByType(options),
    queryFn: ({ pageParam }) =>
      multisearchByType({
        ...options,
        after: pageParam as string | undefined,
      }),
    getNextPageParam: (lastPage) => lastPage.meta?.nextPage,
    getPreviousPageParam: (lastPage) => lastPage.meta?.previousPage,
    enabled,
  })
}
