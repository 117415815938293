import { EnrichedAdminManagedGroup } from '../types'

export const unenrichAdminManagedGroup = (
  adminManagedGroup: EnrichedAdminManagedGroup,
): Omit<AdminManagedGroup, 'creator' | 'updatedAt' | 'createdAt'> => {
  return {
    ...adminManagedGroup,
    weekTemplate: {
      ...adminManagedGroup.weekTemplate,
      dayTemplates: Object.values(adminManagedGroup.weekTemplate.dayTemplates)
        .filter((dayTemplate) => dayTemplate.enabled)
        .map<DayTemplate>((dayTemplate) => {
          if (!dayTemplate.start)
            throw new Error(
              'Unreachable.  start is not defined, but needed to validated',
            )

          if (!dayTemplate.end)
            throw new Error(
              'Unreachable.  start is not defined, but needed to validated',
            )

          return dayTemplate as DayTemplate
        }),
    },
  }
}
