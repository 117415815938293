import { FC } from 'react'
import LogTime from './LogTime'
import TimeCard from './TimeCard'
import { useTimeCardByDate } from '@features/time-logging/hooks/useTimeCardManagement'
import { DateTime } from 'luxon'
import { isLeave } from '@utils/absence'

interface Props {
  date: DateTime
  numberLabel: number
  absences: Absence[]
  readOnly: boolean
  lock?: DayLock
}

export const Day: FC<Props> = ({
  date,
  numberLabel,
  absences,
  readOnly,
  lock,
}) => {
  const timeCard = useTimeCardByDate(date, { returnNullTimeCard: true })

  const totalTimeOff = absences.reduce(
    (total, absence) => (isLeave(absence) ? total : total + absence.duration),
    0,
  )

  // Time Cards cannot be created when in read-only mode,
  // cannot be created in the future,
  // cannot be created on days where the employee
  // has taken time off for the full day (8 hours) or is on leave,
  // and cannot be created on days that are fully locked
  const canCreateTimeCard =
    !readOnly &&
    date <= DateTime.now() &&
    totalTimeOff < 8 * 60 ** 2 &&
    !absences.some(isLeave) &&
    lock?.type !== 'full'

  if (timeCard.id === 0) {
    return canCreateTimeCard ? <LogTime date={date} className="grow" /> : null
  }

  return (
    <TimeCard
      numberLabel={numberLabel}
      timeCard={timeCard}
      className="grow"
      totalTimeOffInSeconds={totalTimeOff}
      hasLeave={absences.some(isLeave)}
      readonly={readOnly}
      lock={lock}
    />
  )
}
