import { useDeleteReport as useDeleteReportAPI } from './useSavedReports'
import { useModal } from '@hooks/useModal'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SavedReport } from '../types'
import { toast } from '@lib/toasts'

export const useDeleteReport = () => {
  const { t } = useTranslation()
  const modal = useModal()
  const navigate = useNavigate()

  const deleteReportAPI = useDeleteReportAPI()

  const deleteReport = (savedReport: SavedReport) => {
    modal.confirm({
      title: t('features.reporting.confirmReportDeletion'),
      content: (
        <>
          <p className="mt-2 mb-4 text-xl font-semibold">{savedReport.name}</p>
          <p>{t('features.reporting.areYouSureYouWantToDeleteThisReport')}</p>
        </>
      ),
      onConfirmAsync: async () => {
        try {
          await deleteReportAPI.mutateAsync(savedReport.id)

          navigate(`/reporting/${savedReport.type}_reports/`)

          toast({
            title: t('features.reporting.reportDeleted'),
            variant: 'alert',
            content: t('features.reporting.successfullyDeletedReport'),
          })
        } catch (e) {
          toast({
            title: t('features.reporting.unableToDeleteReport'),
            variant: 'error',
            content: t('features.reporting.failedToDeleteReport'),
          })
        }
      },
      confirmText: t('common.delete'),
      primaryButtonClassName: 'bg-error-500 hover:bg-error-700',
    })
  }

  return { deleteReport }
}
