import { DetailedHTMLProps, FC, SelectHTMLAttributes } from 'react'
import { twMerge } from '@lib/tailwind-merge'

export const Select: FC<
  DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>
> = ({ className, ...props }) => (
  <select
    className={twMerge(
      'w-full p-2 text-sm border border-solid rounded border-neutral-400',
      className,
    )}
    {...props}
  />
)
