import { FunctionComponent, useMemo } from 'react'

const STROKE_THICKNESS = 3
const VIEW_PORT_HEIGHT = 16
const RADIUS = VIEW_PORT_HEIGHT / 2 - STROKE_THICKNESS / 2

const { PI, sin, cos } = Math

interface Props {
  className?: string
  hidden?: boolean
  percentGreen?: number
  percentRed?: number
  percentYellow?: number
}

const grayPath = `
  M 0 ${RADIUS} 
  A ${RADIUS} ${RADIUS} 0 1 0 -0.0001 ${RADIUS} 
`

const calculatePath = (percentFill: number) => {
  if (percentFill < 0) return ''
  if (percentFill >= 100)
    return `
      M 0 ${RADIUS}
      A ${RADIUS} ${RADIUS} 0 1 0 -0.0001 ${RADIUS}
    `
  const sweepShort = percentFill % 100 < 50

  // convert from 100 percent to radian length.
  const radians = 2 * PI * (percentFill / 100)

  // translate 90 degrees to move start point
  const translated = radians - PI / 2

  const unitCircleX = cos(translated)
  const unitCircleY = sin(translated)
  const x = unitCircleX * RADIUS
  const y = unitCircleY * RADIUS * -1

  return `
    M 0 ${RADIUS}
    A ${RADIUS} ${RADIUS} 0 ${sweepShort ? 0 : 1} 0 ${x} ${y}
  `
}

export const CircleProgress: FunctionComponent<Props> = ({
  className = '',
  hidden = false,
  percentGreen = 0,
  percentRed = 0,
  percentYellow = 0,
}) => {
  const greenPath = useMemo(() => calculatePath(percentGreen), [percentGreen])
  const redPath = useMemo(() => calculatePath(percentRed), [percentRed])
  const yellowPath = useMemo(
    () => calculatePath(percentYellow),
    [percentYellow],
  )

  if (hidden) return null

  return (
    <div className={`block ${className}`}>
      <svg
        width="100%"
        height="100%"
        viewBox={`${(VIEW_PORT_HEIGHT / 2) * -1} ${
          (VIEW_PORT_HEIGHT / 2) * -1
        } ${VIEW_PORT_HEIGHT} ${VIEW_PORT_HEIGHT}`}
      >
        <path
          d={grayPath}
          className="stroke-neutral-300"
          fill="transparent"
          strokeLinecap="round"
          strokeWidth={STROKE_THICKNESS}
        />
        {
          percentGreen &&
          <path
            className="stroke-success-500"
            d={greenPath}
            fill="transparent"
            strokeLinecap="round"
            strokeWidth={STROKE_THICKNESS}
          />
        }
        {
          percentYellow &&
          <path
            className="stroke-crushed-orange"
            d={yellowPath}
            fill="transparent"
            strokeLinecap="round"
            strokeWidth={STROKE_THICKNESS}
          />
        }

        {
          percentRed &&
          <path
            className="stroke-red"
            d={redPath}
            fill="transparent"
            strokeLinecap="round"
            strokeWidth={STROKE_THICKNESS}
          />
        }
      </svg>
    </div>
  )
}
