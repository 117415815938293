import { getWeekStartAsSunday } from '@utils/dates'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

const WEEK_START_KEY = 'week-start'

export const buildSearchParam = (
  date: DateTime,
  params = new URLSearchParams(),
) => {
  params.set(WEEK_START_KEY, getWeekStartAsSunday(date).toISODate())
  return params
}

const parseDate = (
  param: string | null,
  zone?: string,
): DateTime | undefined => {
  if (param === null) return undefined

  const parsed = DateTime.fromISO(param, { zone })

  return parsed.invalidReason === null ? parsed : undefined
}

export const useWeekDatesSearchParams = (zone?: string) => {
  const [searchParams, setSearchParams] = useSearchParams()

  return useMemo(() => {
    const start = parseDate(searchParams.get(WEEK_START_KEY), zone)

    const set = (start: DateTime) => {
      setSearchParams((params) => buildSearchParam(start, params))
    }

    return { start, set }
  }, [searchParams, setSearchParams, zone])
}
