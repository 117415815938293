import { FC } from 'react'
import { twMerge } from '@lib/tailwind-merge'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  active: boolean
  label: string
  onClick: (path: string) => void
  path: string
  nested?: boolean
  icon?: IconDefinition
}

export const ChildItem: FC<Props> = ({
  active,
  label,
  onClick,
  path,
  nested,
  icon,
}) => {
  const baseClass =
    'flex flex-row items-center text-sm cursor-pointer text-neutral-300 hover:text-white h-11 font-medium hover:bg-neutral-700 border-l-2 border-transparent gap-2'

  return (
    <li>
      <a
        href={path}
        className={twMerge([
          baseClass,
          active ? 'border-l-2 border-primary-600 bg-neutral-700' : undefined,
          nested ? 'pl-10' : 'pl-4',
        ])}
        onClick={(event) => {
          event.preventDefault()
          onClick(path)
        }}
      >
        {icon && <FontAwesomeIcon icon={icon} className="text-neutral-400" />}
        {label}
      </a>
    </li>
  )
}
