import { faCheckDouble, faRotateLeft } from '@fortawesome/pro-regular-svg-icons'
import { MenuButton } from 'components/buttons'
import { NewHeader } from 'components/page-header'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ApprovalSelectionBar } from './ApprovalSelectionBar'

interface Props {
  disableApproveSelected: boolean
  disableUnapproveSelected: boolean
  onApproveSelected: () => void
  onUnapproveSelected: () => void
  selectedApprovals: Approval[]
  title: string
}

export const Header: FC<Props> = ({
  disableApproveSelected,
  disableUnapproveSelected,
  onApproveSelected,
  onUnapproveSelected,
  selectedApprovals,
  title,
}) => {
  const { t } = useTranslation()

  return (
    <NewHeader
      title={title}
      renderRightSlot={() => (
        <div className="flex gap-4">
          <ApprovalSelectionBar selectedApprovals={selectedApprovals} />
          <MenuButton
            className="z-30"
            menuItems={[
              {
                disabled: disableApproveSelected,
                icon: faCheckDouble,
                label: t('features.approvals.approveSelected'),
                onClick: onApproveSelected,
              },
              {
                disabled: disableUnapproveSelected,
                icon: faRotateLeft,
                label: t('features.approvals.unapproveSelected'),
                onClick: onUnapproveSelected,
              },
            ]}
          >
            Action
          </MenuButton>
        </div>
      )}
    />
  )
}
