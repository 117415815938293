import { FC } from 'react'
import { ButtonGroup } from 'components/buttons'
import { addItemsToSet, removeItemsFromSet, useSet } from '@hooks/useSet'
import { FilterAlertType, FilterAlertTypeCount } from '../types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCloudExclamation,
  faCloudQuestion,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'

interface Props {
  className?: string
  counts: FilterAlertTypeCount
  onChange: (selected: Props['selected']) => void
  selected: Set<FilterAlertType>
}

export const AlertButtons: FC<Props> = ({
  className,
  counts,
  onChange,
  selected: selectedIssues,
}) => {
  const { t } = useTranslation()

  const generateProps = (type: FilterAlertType) => {
    const selected = selectedIssues.has(type)

    const handleClick = () => {
      const performOperationWith = selected ? removeItemsFromSet : addItemsToSet
      const updatedSelected = performOperationWith(type)(selectedIssues)
      onChange(updatedSelected)
    }

    return {
      count: counts[type],
      onClick: handleClick,
      selected,
    }
  }

  const buttons = [
    {
      children: (
        <FontAwesomeIcon icon={faCloudQuestion} size="sm" fixedWidth={true} />
      ),
      tooltip: t('features.admin.workdaySendStatuses.pending'),
      ...generateProps('pending'),
    },
    {
      children: (
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          size="sm"
          className="text-error-500"
          fixedWidth={true}
        />
      ),
      tooltip: t('features.admin.timeCardIssues'),
      ...generateProps('issues'),
    },
    {
      children: (
        <FontAwesomeIcon
          icon={faCloudExclamation}
          size="sm"
          className="text-error-500"
          fixedWidth={true}
        />
      ),
      tooltip: t('features.admin.workdaySendStatuses.error'),
      ...generateProps('workdayError'),
    },
  ].filter((button) => button.count > 0)

  return <ButtonGroup buttons={buttons} className={className} />
}

export const useFilterRowByIssueType = useSet<FilterAlertType>
