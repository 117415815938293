import { padDigit } from './padDigit'
import { DateTime } from 'luxon'

const getMidnightInTimeZone = (
  year: number,
  month: number,
  day: number,
  timeZone: string,
) =>
  DateTime.local()
    .setZone(timeZone)
    .set({ year, month, day, hour: 0, minute: 0, second: 0, millisecond: 0 })

export const getElapsedSecondsFromMidnight = (
  clockHour: number,
  clockMinute: number,
  date: DateTime,
  timeZone: string,
  { assumeMidnightIsEndOfDay } = { assumeMidnightIsEndOfDay: false },
) => {
  const year = date.get('year')
  const month = date.get('month')
  const day = date.get('day')

  const midnight = getMidnightInTimeZone(year, month, day, timeZone)

  let hour = clockHour
  let minute = clockMinute

  if (clockHour === 0 && clockMinute === 0 && assumeMidnightIsEndOfDay) {
    hour = 24
    minute = 0
  }

  const clock = DateTime.local().setZone(timeZone).set({
    year,
    month,
    day,
    hour,
    minute,
    second: 0,
    millisecond: 0,
  })

  return clock.diff(midnight, 'second').toMillis() / 1000
}

export const convertSecondsFromMidnightIntoDateTime = (
  seconds: number,
  dateTime: DateTime,
  timeZone: string,
): DateTime => {
  const year = dateTime.get('year')
  const month = dateTime.get('month')
  const day = dateTime.get('day')

  const midnight = getMidnightInTimeZone(year, month, day, timeZone)

  return midnight.plus({ seconds })
}

export const getClockFromElapsedSecondsFromMidnight = (
  seconds: number,
  referenceDate: DateTime,
  timeZone: string,
) => {
  const dateTime = convertSecondsFromMidnightIntoDateTime(
    seconds,
    referenceDate,
    timeZone,
  )

  return { hour: dateTime.get('hour'), minute: dateTime.get('minute') }
}

export const hoursFromSeconds = (seconds: number) =>
  Math.floor(seconds / 60 ** 2)

export const minutesFromSeconds = (
  seconds: number,
  options: { round: 'floor' | 'ceil' } = { round: 'floor' },
) => {
  const minutes = (seconds % 60 ** 2) / 60

  if (options.round === 'floor') return Math.floor(minutes)
  if (options.round === 'ceil') return Math.ceil(minutes)

  return minutes
}

export const hoursMinutesToSeconds = (
  hours: number | null,
  minutes: number | null,
): number | null => {
  if (hours === null && minutes === null) return null

  let seconds = 0
  seconds += (hours ?? 0) * 60 * 60
  seconds += (minutes ?? 0) * 60
  return seconds
}

export const secondsToHHMM = (
  seconds: number,
  options?: { language?: string },
) => {
  const hours = hoursFromSeconds(seconds)
  const mins = minutesFromSeconds(seconds, { round: 'ceil' })

  const labels = ['h', 'm']

  return [hours, mins]
    .reduce<string[]>((a, p, i) => {
      // Only include hours/minutes that are > 0
      if (p > 0) {
        // Add appropriate label for part
        const formatted = options?.language
          ? p.toLocaleString(options.language)
          : p
        a.push(`${formatted}${labels[i]}`)
      }

      return a
    }, [])
    .join(' ')
}

export const secondsToTimestamp = (
  seconds: number,
  options?: { language?: string },
) => {
  const hours = hoursFromSeconds(seconds)
  const minutes = minutesFromSeconds(seconds)

  const minutesPart = minutes < 10 ? `0${minutes}` : minutes

  const hoursString = options?.language
    ? hours.toLocaleString(options.language)
    : hours

  return `${hoursString}:${minutesPart}`
}

const secondsTo24HourTimeOfDay = (
  seconds: number,
  referenceDate: DateTime,
  timeZone: string,
) => {
  const { hour, minute } = getClockFromElapsedSecondsFromMidnight(
    seconds,
    referenceDate,
    timeZone,
  )

  return `${hour}:${padDigit(minute)}`
}

const secondsTo12HourTimeOfDay = (
  seconds: number,
  referenceDate: DateTime,
  timeZone: string,
) => {
  const { hour, minute } = getClockFromElapsedSecondsFromMidnight(
    seconds,
    referenceDate,
    timeZone,
  )

  const calcHours = (hours: number) => {
    if (hours > 12) return hours - 12
    if (hours > 0) return hours
    if (hours === 0) return 12

    return ''
  }

  const calcMinutes = (minutes: number) => {
    if (minutes == 0) return ''
    if (minutes < 10) return `:0${minutes}`
    return `:${minutes}`
  }
  const amOrPm = hour < 12 ? 'am' : 'pm'

  return `${calcHours(hour)}${calcMinutes(minute)}${amOrPm}`
}

interface ClockOptions {
  clock: '12' | '24'
}

export const secondsToTimeOfDay = (
  seconds: number,
  referenceDate: DateTime,
  timeZone: string,
  options: ClockOptions = { clock: '12' },
) => {
  return options.clock === '12'
    ? secondsTo12HourTimeOfDay(seconds, referenceDate, timeZone)
    : secondsTo24HourTimeOfDay(seconds, referenceDate, timeZone)
}

export const rangeInTimeOfDay = (
  startAt: number,
  endAt: number,
  referenceDate: DateTime,
  timeZone: string,
  options: ClockOptions = { clock: '12' },
) => {
  const start = secondsToTimeOfDay(startAt, referenceDate, timeZone, options)
  const end = secondsToTimeOfDay(endAt, referenceDate, timeZone, options)

  if (startAt < 0 || startAt > 24 * 60 ** 2) return ''
  if (endAt < 0 || endAt > 24 * 60 ** 2) return ''

  return `${start} – ${end}`
}
