import { useMemo, useState } from 'react'
import { Sidebar } from 'components/sidebar'
import { useTranslation } from 'react-i18next'
import {
  ColumnDraggableData,
  ColumnDroppableData,
  ReportTypeMap,
} from '../../../types'
import { columnInfoFor } from '../../../lib/infoByColumnId'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { Columns } from './Columns'
import { DeselectedList } from './DeselectedList'
import { DroppableSlot } from './DroppableSlot'
import { coalesceContiguousColumnParents } from '../../../utils/coalesceContiguousColumnParents'
import { SearchField } from 'components/form/search-field'
import { filterColumnGroupsBySearchQuery } from '../../../utils/filterColumnGroupsBySearchQuery'

type ReportTypeProps<RT extends keyof ReportTypeMap> = {
  reportType: RT
  selectedColumnIds: ReportTypeMap[RT]['columnId'][]
  onColumnRemoved: (columnId: ReportTypeMap[RT]['columnId']) => void
  onColumnAdded: (columnId: ReportTypeMap[RT]['columnId']) => void
  onColumnOrderChange: (columnIds: ReportTypeMap[RT]['columnId'][]) => void
}

type Props = {
  visible: boolean
  onClose: () => void
} & ReportTypeProps<keyof ReportTypeMap>

export function ColumnsSidebar<RT extends keyof ReportTypeMap>({
  visible,
  onClose,
  selectedColumnIds,
  onColumnRemoved,
  onColumnAdded,
  onColumnOrderChange,
  reportType,
}: Props) {
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState('')

  const deselectedColumnIds = useMemo(
    () =>
      (
        Object.keys(
          columnInfoFor(reportType),
        ) as ReportTypeMap[RT]['columnId'][]
      ).filter((columnId) => !selectedColumnIds.includes(columnId)),
    [selectedColumnIds, reportType],
  )

  const filteredSelectedColumnGroups = useMemo(() => {
    const groups = coalesceContiguousColumnParents(selectedColumnIds)
    return filterColumnGroupsBySearchQuery({
      reportType,
      groups,
      searchQuery,
      t,
    })
  }, [selectedColumnIds, searchQuery, t, reportType])

  // We support several scenarios here:
  //   - Repositioning a single column by dragging it to a new position
  //   - Repositioning multiple columns by dragging a group
  //     (we always work on the flat selected column ID collection and then re-group)
  //   - Dragging a column or group from the deselected list to the selected list (i.e. adding it)
  //   - Dragging a column or group from the selected list to the deselected list (i.e. removing it)
  const handleDragEnd = (event: DragEndEvent) => {
    // No collision, nothing to do
    if (event.over === null) return

    // Can't figure out how to specify a type for our draggable/droppable Data,
    // so we unfortunately have to assert it here
    const overData = event.over.data.current as ColumnDroppableData
    const activeData = event.active.data.current as ColumnDraggableData

    const columnsWithoutDraggedColumn = [...selectedColumnIds]

    const draggedColumns =
      activeData.type === 'group' ? activeData.columns : [activeData.columnId]

    // Remove dragged columns from selected columns list
    // Note that the dragged columns may not be in the selected columns list,
    // since they can be dragged directly from the deselected list
    if (columnsWithoutDraggedColumn.indexOf(draggedColumns[0]) > -1) {
      columnsWithoutDraggedColumn.splice(
        columnsWithoutDraggedColumn.indexOf(draggedColumns[0]),
        draggedColumns.length,
      )
    }

    // Insert dragged column back into selected columns list in new position,
    // unless it was dropped over the deselected list, in which case
    // we do not want to re-insert it since we want to remove it.
    if (overData.type !== 'deselected-list') {
      // Find index of column over which the dragged column was dropped
      const newPos =
        overData.type === 'selected-end'
          ? columnsWithoutDraggedColumn.length // Insert column at end of list
          : columnsWithoutDraggedColumn.indexOf(
              overData.type === 'group'
                ? overData.columns[0]
                : overData.columnId,
            )

      // Insert dragged columns just before dropped column
      columnsWithoutDraggedColumn.splice(newPos, 0, ...draggedColumns)
    }

    onColumnOrderChange(columnsWithoutDraggedColumn)
  }

  return (
    <Sidebar
      onClose={onClose}
      hidden={!visible}
      title={t('features.reporting.customizeColumns')}
      mode="block"
    >
      <div className="px-8 my-2">
        <SearchField
          onChange={setSearchQuery}
          value={searchQuery}
          placeholder={t('common.search')}
        />
      </div>

      <DndContext
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
      >
        <div className="px-8 overflow-auto text-sm">
          <Columns
            columnGroups={filteredSelectedColumnGroups}
            onColumnSelectDeselect={onColumnRemoved}
            selected={true}
            droppable={true}
            draggingEnabled={searchQuery === ''}
            reportType={reportType}
          />

          {/* Spacer to allow columns to be placed at the end of the list */}
          <DroppableSlot data={{ type: 'selected-end' }}>
            <div className="h-4"></div>
          </DroppableSlot>

          {deselectedColumnIds.length > 0 && (
            <DeselectedList
              columnIds={deselectedColumnIds}
              onColumnAdded={onColumnAdded}
              searchQuery={searchQuery}
              draggingEnabled={searchQuery === ''}
              reportType={reportType}
            />
          )}
        </div>
      </DndContext>
    </Sidebar>
  )
}
