import { useSet } from '@hooks/useSet'
import { noop } from '@utils/noop'
import { createContext, FC, ReactNode, useMemo } from 'react'

interface Properties {
  inhibited: boolean
}

interface Methods {
  registerMenuOpened: (name: string) => void
  registerMenuClosed: (name: string) => void
}

type InhibitKeyPressOverrideContext = Properties & Methods

const initialContext: InhibitKeyPressOverrideContext = {
  inhibited: false,
  registerMenuOpened: noop,
  registerMenuClosed: noop,
}

export const InhibitKeyPressOverrideContext = createContext(initialContext)

export const InhibitKeyPressOverrideProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const {
    set: openMenus,
    add: registerMenuOpened,
    remove: registerMenuClosed,
  } = useSet<string>()

  const inhibited = useMemo(() => openMenus.size > 0, [openMenus])

  const value = useMemo(
    () => ({
      registerMenuClosed,
      registerMenuOpened,
      inhibited,
    }),
    [registerMenuClosed, registerMenuOpened, inhibited],
  )

  return (
    <InhibitKeyPressOverrideContext.Provider value={value}>
      {children}
    </InhibitKeyPressOverrideContext.Provider>
  )
}
