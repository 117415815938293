import { useDateTimeToLocaleString } from '@hooks/useDateTimeWithLocale'
import { initialCapitalize } from '@utils/initialCapitalize'
import { Field } from 'components/form/field'
import { HorizontalScroll } from 'components/form/horizontal-scroll'
import { Select } from 'components/form/select'
import { DateTime } from 'luxon'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Item {
  date: DateTime
  heading: string
  content: string
}

const Item: FC<Item> = ({ date, heading, content }) => {
  const toLocaleString = useDateTimeToLocaleString()
  const localizeMonth = toLocaleString({ month: 'short' })
  const localizeDay = toLocaleString({ day: 'numeric' })

  return (
    <li className="flex flex-row items-center py-3 border-b border-neutral-200 space-x-4 last:border-b-0">
      <div className="flex flex-col items-center justify-center w-12 h-12 border rounded border-neutral-400 text-neutral-900">
        <span className="text-sm leading-none">{localizeMonth(date)}</span>
        <span className="p-0 m-0 text-2xl font-medium leading-none">
          {localizeDay(date)}
        </span>
      </div>
      <div className="flex flex-col gap-0.5">
        <span className="text-xs font-medium uppercase text-neutral-700">
          {heading}
        </span>
        <span>{content}</span>
      </div>
    </li>
  )
}

interface Props {
  types: string[]
  onLeaveTypeChange: (leaveType: string) => void
  onYearChange: (year: number) => void
  selectedLeaveType: string
  selectedYear: number
  years: number[]
  items: Item[]
}

export const Leave: FC<Props> = ({
  types,
  onLeaveTypeChange,
  onYearChange,
  selectedLeaveType,
  selectedYear,
  years,
  items,
}) => {
  const { t } = useTranslation()
  const selectedYearIndex = years.indexOf(selectedYear)
  const previousYear = years[selectedYearIndex - 1]
  const nextYear = years[selectedYearIndex + 1]

  return (
    <div className="flex flex-col p-8 pb-0 overflow-hidden">
      <div className="flex flex-row space-x-2">
        <Field
          htmlFor="select-leave-type"
          label={t('components.leave.type')}
          className="basis-2/3"
        >
          {(id) => (
            <Select
              id={id}
              value={selectedLeaveType}
              onChange={(event) => onLeaveTypeChange(event.currentTarget.value)}
            >
              {types.map((leaveType) => (
                <option
                  key={leaveType}
                  value={leaveType}
                  label={initialCapitalize(leaveType)}
                />
              ))}
            </Select>
          )}
        </Field>
        <Field
          htmlFor="select-leave-type"
          label={t('common.year')}
          className="basis-1/3"
        >
          {() => (
            <HorizontalScroll
              disableNext={nextYear === undefined}
              disablePrevious={previousYear === undefined}
              value={selectedYear}
              onNext={() => onYearChange(nextYear)}
              onPrevious={() => onYearChange(previousYear)}
            />
          )}
        </Field>
      </div>
      <ul className="flex flex-col overflow-auto">
        {items.length === 0 ? (
          <span className="flex self-center text-sm italic text-center text-neutral-700">
            {t('components.leave.noLeaveFoundInYear', { year: selectedYear })}
          </span>
        ) : null}
        {items.map((item, index) => (
          <Item key={`${item.date.toString()}-${index}`} {...item} />
        ))}
      </ul>
    </div>
  )
}
