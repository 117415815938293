import {
  searchAdminLocks,
  upsertDayLocks,
  deleteDayLocks,
  DayLockPayload,
} from '@lib/api'
import { dayLockKeys } from '@lib/keys'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'

export const useSearchDayLocks = (startDate: DateTime, endDate: DateTime) => {
  return useQuery(dayLockKeys.searchAll(startDate, endDate), () =>
    searchAdminLocks(startDate, endDate),
  )
}

export const useUpsertDayLocks = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ days, type }: { days: DayLockPayload[]; type: DayLockType }) =>
      upsertDayLocks(days, type),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(dayLockKeys.all)
      },
    },
  )
}

export const useDeleteDayLocks = () => {
  const queryClient = useQueryClient()

  return useMutation((days: DayLockPayload[]) => deleteDayLocks(days), {
    onSuccess: () => {
      void queryClient.invalidateQueries(dayLockKeys.all)
    },
  })
}
