import { FC, useState } from 'react'
import { Label } from 'components/form/label'
import { Input } from 'components/form/input'
import { TextArea } from 'components/form/textarea'
import { ModalForm } from 'components/notifications'
import { useTranslation } from 'react-i18next'
import { SavedReport } from '../types'
import { RadioButtons } from 'components/buttons/RadioButtons'
import { faLock, faGlobe } from '@fortawesome/pro-regular-svg-icons'

interface Props {
  savedReport?: SavedReport
  cancel: () => void
  save: (
    reportName: string,
    reportDescription: string,
    isPublic: boolean,
  ) => void
  isSaving: boolean
}

export const ReportForm: FC<Props> = ({
  savedReport,
  cancel,
  save,
  isSaving,
}) => {
  const [reportName, setReportName] = useState<string>(savedReport?.name ?? '')
  const [reportDescription, setReportDescription] = useState<string>(
    savedReport?.description ?? '',
  )
  const [isPublic, setIsPublic] = useState<boolean>(
    savedReport?.isPublic ?? false,
  )

  const { t } = useTranslation()

  const formFields = (
    <>
      <div>
        <Label>{t('features.reporting.reportName')}</Label>
        <Input
          value={reportName}
          className="bg-neutral-50"
          onChange={(event) => setReportName(event.target.value)}
          required
        />
      </div>

      <div>
        <Label>{t('features.reporting.descriptionOptional')}</Label>
        <TextArea
          value={reportDescription}
          placeholder={t('features.reporting.enterShortDescription')}
          className="bg-neutral-50 placeholder:text-neutral-500"
          onChange={(event) => setReportDescription(event.target.value)}
        />
      </div>

      <div>
        <RadioButtons
          options={[
            {
              label: t('features.reporting.private'),
              icon: faLock,
              value: 'private',
            },
            {
              label: t('features.reporting.public'),
              icon: faGlobe,
              value: 'public',
            },
          ]}
          onChange={(value) => setIsPublic(value === 'public')}
          selected={isPublic ? 'public' : 'private'}
        />
        <p className="text-neutral-500 text-xs my-2">
          {t('features.reporting.visibilityDescription')}
        </p>
      </div>
    </>
  )

  return (
    <ModalForm
      fields={formFields}
      onCancel={cancel}
      onSave={() => save(reportName, reportDescription, isPublic)}
      isSaving={isSaving}
      className="w-[400px]"
      saveButtonText={
        savedReport ? t('common.save') : t('features.reporting.createReport')
      }
    />
  )
}
