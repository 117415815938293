import { FC, useId } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'

interface SectionProps {
  label: string
  tooltip?: string
}

const Section: FC<SectionProps> = ({ label, tooltip }) => {
  const tooltipId = useId()

  return (
    <div className="py-3 border-t border-dashed border-neutral-300 first:border-t-0">
      <div className="text-sm text-neutral-900 font-semibold">
        <span data-tooltip-id={tooltipId}>{label}</span>

        {tooltip && (
          <Tooltip
            id={tooltipId}
            content={tooltip}
            delayShow={500}
            place="right"
          />
        )}
      </div>
    </div>
  )
}

export const WorkedAndAllocated = () => {
  const { t } = useTranslation()

  return (
    // NOTE: mt-8 aligns dotted line with time cards (32px)
    <div className="grid-in-nav mt-8">
      <Section
        label={t('features.timeLogging.worked')}
        tooltip={t('features.timeLogging.workedTooltip')}
      />
      <Section label={t('features.timeLogging.allocated')} />
    </div>
  )
}
