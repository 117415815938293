import { FC, useCallback } from 'react'
import { useCreateApprovalGroup } from '../hooks/useApprovalGroupsManagement'
import { useTranslation } from 'react-i18next'
import { toast } from '@lib/toasts'
import { ApprovalGroupForm } from './ApprovalGroupForm'

interface Props {
  onCancel: () => void
  onSave: () => void
}

export const NewApprovalGroupForm: FC<Props> = ({ onCancel, onSave }) => {
  const { t } = useTranslation()
  const createApprovalGroup = useCreateApprovalGroup()

  const save = useCallback(
    async (groupName: string, approverUserIds: number[]) => {
      try {
        await createApprovalGroup.mutateAsync({
          name: groupName,
          approverUserIds: approverUserIds,
        })

        onSave()

        toast({
          title: t('features.approvalGroupsAdmin.approvalGroupCreated'),
          variant: 'success',
          content: t(
            'features.approvalGroupsAdmin.successfullyCreatedApprovalGroup',
            {
              name: groupName,
            },
          ),
        })
      } catch (error) {
        toast({
          title: t('features.approvalGroupsAdmin.unableToCreateApprovalGroup'),
          variant: 'error',
          content: t(
            'features.approvalGroupsAdmin.failedToCreateApprovalGroup',
          ),
        })
      }
    },
    [onSave, createApprovalGroup, t],
  )

  return (
    <ApprovalGroupForm
      save={save}
      isSaving={createApprovalGroup.isLoading}
      cancel={onCancel}
    />
  )
}
