import { DateTime, Interval } from 'luxon'

export const generateCalendar = (
  fromDateTime: DateTime,
): Array<Array<DateTime>> => {
  /**
   * There's a slight amount of gynmastics required to navigate around the fact
   * that luxon considers the start of the week to be Monday
   *
   * If it's desired that Monday should be the start of the week, simplifying
   * this logic should be trivial
   *
   * - AF 4/5/23
   */
  const startOfFirstWeek = fromDateTime
    .startOf('month')
    .startOf('week')
    .minus({ day: 1 }) // weeks start on Monday for Luxon

  const lastDayOfMonth = fromDateTime.endOf('month')

  const lastDayOfLastWeekOfMonth =
    lastDayOfMonth.get('weekday') === 7 // weekday: 0 = Monday, 7 = Sunday
      ? lastDayOfMonth.plus({ days: 6 })
      : lastDayOfMonth.endOf('week').minus({ day: 1 })

  return Interval.fromDateTimes(startOfFirstWeek, lastDayOfLastWeekOfMonth)
    .splitBy({ week: 1 })
    .map((week) =>
      week.splitBy({ day: 1 }).map((dayInterval) => dayInterval.start),
    )
}
