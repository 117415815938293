import { useQueryClient, useMutation } from '@tanstack/react-query'
import { breakKeys, timeCardKeys } from '@lib/keys'
import { deleteBreak, postBreak, updateBreak } from '@lib/api'
import { useUserId } from './useUserId'
import { useWeekDates } from '@hooks/useWeekDates'

export const useAddBreak = () => {
  const queryClient = useQueryClient()
  const userId = useUserId()
  const { start, end } = useWeekDates()

  return useMutation(postBreak, {
    onSuccess: () => {
      void queryClient.invalidateQueries(breakKeys.all)
      void queryClient.invalidateQueries(
        timeCardKeys.search(userId, start, end),
      )
    },
  })
}

export const useUpdateBreak = () => {
  const queryClient = useQueryClient()
  const userId = useUserId()
  const { start, end } = useWeekDates()

  return useMutation(updateBreak, {
    onSuccess: () => {
      void queryClient.invalidateQueries(breakKeys.all)
      void queryClient.invalidateQueries(
        timeCardKeys.search(userId, start, end),
      )
    },
  })
}

export const useDeleteBreak = () => {
  const queryClient = useQueryClient()
  const userId = useUserId()
  const { start, end } = useWeekDates()

  return useMutation(deleteBreak, {
    onSuccess: () => {
      void queryClient.invalidateQueries(breakKeys.all)
      void queryClient.invalidateQueries(
        timeCardKeys.search(userId, start, end),
      )
    },
  })
}
