import { secondsToHHMM } from '@utils/time'
import classNames from 'classnames'
import { Box } from 'components/containers/box'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from '@lib/tailwind-merge'

const STROKE_THICKNESS = 10
const VIEW_PORT_HEIGHT = 100
const RADIUS = VIEW_PORT_HEIGHT / 2 - STROKE_THICKNESS / 2
const MAX_RADIAN_LENGTH = (4 * Math.PI) / 3

const computePercentage = (numerator: number, denominator: number) => {
  if (denominator === 0) return 0

  const percentage = (numerator / denominator) * 100
  return Math.max(percentage, 0)
}

const computeRadianLength = (percent: number): [number, number] => {
  if (percent <= 0) return [0, 0]

  const calculatedLength = (MAX_RADIAN_LENGTH * percent) / 100
  const fullLength = Math.max(
    Math.min(calculatedLength, MAX_RADIAN_LENGTH),
    0.3,
  )
  const gappedLength = Math.max(fullLength - 0.3, 0.01)
  return [fullLength, gappedLength]
}

const computePoint = (radians: number) => {
  // Translated 7 * PI / 6 from 0,0 on unit Circle
  const translated = radians - (7 * Math.PI) / 6

  const unitCircleX = Math.cos(translated)
  const unitCircleY = Math.sin(translated)

  const x = unitCircleX * RADIUS
  const y = unitCircleY * RADIUS

  return [x, y]
}

const generatePath = (radians: number, offsetRadians = 0) => {
  if (radians <= 0) return ''

  const maxRadians = Math.min(radians, MAX_RADIAN_LENGTH - offsetRadians)

  const isLargeArc = maxRadians >= Math.PI
  const [startX, startY] = computePoint(offsetRadians)
  const [endX, endY] = computePoint(offsetRadians + maxRadians)

  return `
    M ${startX} ${startY}
    A ${RADIUS} ${RADIUS} 0 ${isLargeArc ? 1 : 0} 1 ${endX} ${endY}
  `
}

const generatePaths = ({
  numHolidaySeconds,
  numLeaveSeconds,
  numWorkSeconds,
  totalHours,
}: {
  numHolidaySeconds: number
  numLeaveSeconds: number
  numWorkSeconds: number
  totalHours: number
}) => {
  const combinedSeconds = Math.max(
    numHolidaySeconds + numLeaveSeconds + numWorkSeconds,
    totalHours * 60 ** 2,
  )
  const percentLeave = computePercentage(numLeaveSeconds, combinedSeconds)
  const percentHoliday = computePercentage(numHolidaySeconds, combinedSeconds)
  const percentWork = computePercentage(numWorkSeconds, combinedSeconds)

  const [fullLeaveRadians, gappedLeaveRadians] =
    computeRadianLength(percentLeave)
  const [fullHolidayRadians, gappedHolidayRadians] =
    computeRadianLength(percentHoliday)
  const [fullWorkRadians] = computeRadianLength(percentWork)

  const generateLeavePath = () => {
    if (percentHoliday > 0) return generatePath(gappedLeaveRadians)
    if (percentWork > 0) return generatePath(gappedLeaveRadians)

    return generatePath(fullLeaveRadians)
  }

  const generateHolidayPath = () => {
    if (percentWork > 0)
      return generatePath(gappedHolidayRadians, fullLeaveRadians)

    return generatePath(fullHolidayRadians, fullLeaveRadians)
  }

  const leavePath = generateLeavePath()
  const holidayPath = generateHolidayPath()
  const workPath = generatePath(
    fullWorkRadians,
    fullLeaveRadians + fullHolidayRadians,
  )
  const backgroundPath = generatePath(
    MAX_RADIAN_LENGTH - fullLeaveRadians - fullHolidayRadians,
    fullLeaveRadians + fullHolidayRadians,
  )

  return { backgroundPath, leavePath, holidayPath, workPath }
}

interface Props {
  className?: string
  numHolidaySeconds: number
  numLeaveSeconds: number
  numWorkSeconds: number
  totalHours: number
  loading?: boolean
}

export const WeeklyProgressGauge: FC<Props> = ({
  className,
  numHolidaySeconds,
  numLeaveSeconds,
  numWorkSeconds,
  totalHours,
  loading = false,
}) => {
  const { t } = useTranslation()

  const { backgroundPath, leavePath, holidayPath, workPath } = useMemo(
    () =>
      generatePaths({
        numHolidaySeconds,
        numLeaveSeconds,
        numWorkSeconds,
        totalHours,
      }),
    [numHolidaySeconds, numLeaveSeconds, numWorkSeconds, totalHours],
  )

  const renderSummedHours = () => {
    const totalAllocatedSeconds =
      numHolidaySeconds + numLeaveSeconds + numWorkSeconds

    if (totalAllocatedSeconds <= 0) return '0h'

    return secondsToHHMM(totalAllocatedSeconds)
  }

  return (
    <Box
      className={twMerge(
        'px-5 w-full text-neutral-500 pb-2 max-w-[150px]',
        classNames(className, { 'fa-fade': loading }),
      )}
    >
      <span className="mt-3 mb-5 text-xs font-semibold text-center uppercase">
        {t('components.weeklyProgressGauge.weeklyTotal')}
      </span>
      <div className={'relative block mx-auto'}>
        <svg
          width="100%"
          height="100%"
          viewBox={`${(VIEW_PORT_HEIGHT / 2) * -1} ${
            (VIEW_PORT_HEIGHT / 2) * -1
          } ${VIEW_PORT_HEIGHT} ${VIEW_PORT_HEIGHT}`}
        >
          <path
            className="stroke-neutral-200"
            strokeWidth={STROKE_THICKNESS}
            fill="transparent"
            d={backgroundPath}
            strokeLinecap="round"
          />
          <path
            className="stroke-success-300"
            strokeWidth={STROKE_THICKNESS}
            fill="transparent"
            d={leavePath}
            strokeLinecap="round"
          />
          <path
            className="stroke-success-500"
            strokeWidth={STROKE_THICKNESS}
            fill="transparent"
            d={holidayPath}
            strokeLinecap="round"
          />
          <path
            className="stroke-success-700"
            strokeWidth={STROKE_THICKNESS}
            fill="transparent"
            d={workPath}
            strokeLinecap="round"
          />
        </svg>
        <span className="absolute w-full font-medium text-center top-[36%] text-neutral-900">
          {renderSummedHours()}
        </span>
        <span className="absolute w-full text-xs font-medium text-center top-[60%] text-neutral-400">
          {t('components.weeklyProgressGauge.hours', { count: totalHours })}
        </span>
      </div>
    </Box>
  )
}
