import { FC } from 'react'
import { Status } from 'types'
import { MiniSilo } from './MiniSilo'
import { StatusToggleMap, DispatchStatusChange } from 'hooks/useStatusToggle'

interface Props {
  statusCounts: Omit<StatusMap<number>, Status.Sent>
  changeStatus: DispatchStatusChange
  showOnlyStatuses: StatusToggleMap
}

export const MiniSilos: FC<Props> = ({
  statusCounts,
  changeStatus,
  showOnlyStatuses,
}) => {
  return (
    <div className="flex gap-2">
      <MiniSilo
        onDoubleClick={(status) =>
          changeStatus({ type: 'ENABLE_ONLY', status })
        }
        onClick={(status) => changeStatus({ type: 'TOGGLE', status })}
        status={Status.Open}
        count={statusCounts[Status.Open]}
        enabled={showOnlyStatuses[Status.Open]}
      />
      <MiniSilo
        onDoubleClick={(status) =>
          changeStatus({ type: 'ENABLE_ONLY', status })
        }
        onClick={(status) => changeStatus({ type: 'TOGGLE', status })}
        status={Status.Submitted}
        count={statusCounts[Status.Submitted]}
        enabled={showOnlyStatuses[Status.Submitted]}
      />
      <MiniSilo
        onDoubleClick={(status) =>
          changeStatus({ type: 'ENABLE_ONLY', status })
        }
        onClick={(status) => changeStatus({ type: 'TOGGLE', status })}
        status={Status.Approved}
        count={statusCounts[Status.Approved]}
        total={Object.values(statusCounts).reduce(
          (sum, count) => sum + count,
          0,
        )}
        enabled={showOnlyStatuses[Status.Approved]}
      />
    </div>
  )
}
