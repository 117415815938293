import { Calculation } from '../types'
import { convertTwentyFourHourTimeToSeconds } from './convertTwentyFourHourTimeToSeconds'

const computeTwentyFourHourTimeDiff = (
  start: TwentyFourHourTime,
  end: TwentyFourHourTime,
) => {
  return Math.max(
    convertTwentyFourHourTimeToSeconds(end) -
      convertTwentyFourHourTimeToSeconds(start),
    0,
  )
}

const computeAllocationTotal = (dayTemplate: {
  entries: { duration: number }[]
}) => {
  return dayTemplate.entries.reduce((acc, entry) => {
    return acc + entry.duration
  }, 0)
}

const computeWorkdayTotal = (dayTemplate: {
  start?: TwentyFourHourTime
  end?: TwentyFourHourTime
  breaks: { start?: TwentyFourHourTime; end?: TwentyFourHourTime }[]
}) => {
  if (!dayTemplate.start || !dayTemplate.end) return 0

  const totalDay = computeTwentyFourHourTimeDiff(
    dayTemplate.start,
    dayTemplate.end,
  )

  const totalBreaks = dayTemplate.breaks.reduce((acc, { start, end }) => {
    if (!start || !end) return acc

    return acc + computeTwentyFourHourTimeDiff(start, end)
  }, 0)

  return totalDay - totalBreaks
}

export const computeTotals = (dayTemplate: {
  start?: TwentyFourHourTime
  end?: TwentyFourHourTime
  breaks: { start?: TwentyFourHourTime; end?: TwentyFourHourTime }[]
  entries: { duration: number }[]
}): Calculation => {
  const workWeek = computeWorkdayTotal(dayTemplate)
  const allocation = computeAllocationTotal(dayTemplate)
  const percentage = Math.round((allocation / workWeek) * 100)

  return {
    workWeek,
    allocation,
    percentage:
      isNaN(percentage) || percentage === Infinity || percentage <= 0
        ? 0
        : percentage,
  }
}
