import { useDebouncedState } from '@hooks/useDebouncedState'
import { useMemo } from 'react'
import { convertToApiFilter } from '../utils/convertToApiFilter'
import { ReportTypeMap } from '../types'

export const useFilter = <RT extends keyof ReportTypeMap>(
  initialFilters: ReportTypeMap[RT]['filters'],
) => {
  const { state, debounced, setState } =
    useDebouncedState<ReportTypeMap[RT]['filters']>(initialFilters)

  const filterParams = useMemo(() => convertToApiFilter(debounced), [debounced])

  return {
    filter: state,
    filterParams,
    setFilter: setState,
    clearFilter: () => setState({}),
  }
}
