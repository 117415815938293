import { useCallback } from 'react'
import { useModal } from '@hooks/useModal'
import { LinkWorkdayProjectForm } from '../components/LinkWorkdayProjectForm'
import { useTranslation } from 'react-i18next'

export const useLinkWorkdayProject = () => {
  const modal = useModal()
  const { t } = useTranslation()

  const linkWorkdayProject = useCallback(
    (project: AdminProject) => {
      const id = modal.form({
        title: t('features.projectsAdmin.linkProjectTitle', {
          integrationName: 'Workday',
          projectName: project.name,
        }),
        content: (
          <LinkWorkdayProjectForm
            onCancel={() => modal.close(id)}
            onSave={() => modal.close(id)}
            project={project}
          />
        ),
      })
    },
    [modal, t],
  )

  return { linkWorkdayProject }
}
