import {
  updateApprovalGroup,
  createApprovalGroup,
  toggleApprovalGroupAutoApproval,
} from '@lib/api'
import { approvalGroupsKeys, adminApprovalGroupsKeys } from '@lib/keys'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useUpdateApprovalGroup = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      id,
      name,
      approverUserIds,
    }: {
      id: number
      name: string
      approverUserIds: number[]
    }) => updateApprovalGroup(id, name, approverUserIds),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(adminApprovalGroupsKeys.all)
        void queryClient.invalidateQueries(approvalGroupsKeys.all)
      },
    },
  )
}

export const useCreateApprovalGroup = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ name, approverUserIds }: { name: string; approverUserIds: number[] }) =>
      createApprovalGroup(name, approverUserIds),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(adminApprovalGroupsKeys.all)
        void queryClient.invalidateQueries(approvalGroupsKeys.all)
      },
    },
  )
}

export const useToggleAutoApproval = () => {
  const queryClient = useQueryClient()

  return useMutation((id: number) => toggleApprovalGroupAutoApproval(id), {
    onSuccess: () => {
      void queryClient.invalidateQueries(adminApprovalGroupsKeys.all)
    },
  })
}
