import {
  faArrowDownTriangleSquare,
  faArrowUpTriangleSquare,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox } from 'components/form/checkbox'
import { DetailedHTMLProps, FC, ReactNode, ThHTMLAttributes } from 'react'
import { twMerge } from '@lib/tailwind-merge'

type SelectionState = boolean | 'indeterminate'

type Props = Omit<
  DetailedHTMLProps<
    ThHTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
  >,
  'onSelect'
> & {
  clickable?: boolean
  label: ReactNode
  onSelect?: (selected: SelectionState) => void
  selectable?: boolean
  selected?: SelectionState
  sortDir?: 'asc' | 'desc' | false
  wrapperClassName?: string
}

export const Th: FC<Props> = ({
  className,
  wrapperClassName,
  clickable = true,
  label,
  onSelect = () => undefined,
  selectable = false,
  selected,
  sortDir,
  ...props
}) => {
  return (
    <th
      className={twMerge(
        'p-0 px-4 py-3 font-semibold text-left border-t border-b border-r bg-neutral-100 text-neutral-900 border-neutral-300 group align-top',
        clickable && 'cursor-pointer',
        (sortDir === 'asc' || sortDir === 'desc') && 'bg-neutral-200',
        className,
      )}
      {...props}
    >
      <div className={twMerge('flex flex-row h-full', wrapperClassName)}>
        {selectable && (
          <Checkbox
            className="mr-2"
            checked={selected === true}
            indeterminate={selected === 'indeterminate'}
            onClick={(event) => event.stopPropagation()}
            onChange={() => onSelect(!selected)}
          />
        )}
        <div className="flex flex-col grow">
          <div className="flex flex-row flex-grow">
            {typeof label === 'string' ? (
              <span className="mb-2">{label}</span>
            ) : (
              label
            )}
            {sortDir !== undefined && sortDir !== false && (
              <FontAwesomeIcon
                className="ml-auto text-neutral-600"
                icon={
                  sortDir === 'asc'
                    ? faArrowDownTriangleSquare
                    : faArrowUpTriangleSquare
                }
              />
            )}
          </div>
          {props.children}
        </div>
      </div>
    </th>
  )
}
