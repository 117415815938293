import React, { Dispatch, ReactNode, SetStateAction } from 'react'
import { ReportTypeMap } from '../../types'
import { infoByColumnId } from '../../lib/infoByColumnId'
import { useTranslation } from 'react-i18next'
import { coalesceContiguousColumnParents } from '../../utils/coalesceContiguousColumnParents'
import { labelKeyByColumnGroupId } from '../../lib/labelKeyByColumnGroupId'
import {
  faArrowDownTriangleSquare,
  faArrowUpTriangleSquare,
  faFilter,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Sorted } from '../../hooks/useSort'
import { twMerge } from '@lib/tailwind-merge'
import { Spinner } from 'components/loaders'
import { useDateTimeToLocaleString } from '@hooks/useDateTimeWithLocale'
import { formattedColumnTotal } from '@features/reporting/utils/formattedColumnTotal'
import { useLanguage } from '@hooks/useLanguage'

type Props<RT extends keyof ReportTypeMap> = {
  pending: boolean
  showFilters: boolean
  sorted?: Sorted
  reportType: RT
  columnIds: ReportTypeMap[RT]['columnId'][]
  filter: ReportTypeMap[RT]['filters']
  showTotalRow: boolean
  onHeaderClick: (columnId: ReportTypeMap[RT]['columnId']) => void
  setFilter: Dispatch<SetStateAction<ReportTypeMap[RT]['filters']>>
  page?: ReportTypeMap[RT]['page']
}

export const Table = React.memo(function Table<RT extends keyof ReportTypeMap>({
  columnIds,
  filter,
  onHeaderClick,
  page,
  pending,
  setFilter,
  showFilters,
  sorted,
  reportType,
  showTotalRow,
}: Props<RT>) {
  const { t } = useTranslation()
  const language = useLanguage()
  const toLocaleString = useDateTimeToLocaleString()
  const coalescedColumnIds = coalesceContiguousColumnParents(columnIds)

  return (
    <div className="relative flex-grow">
      <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col overflow-auto">
        <div className="flex-grow">
          <table className="text-sm border-separate border-spacing-0">
            {/* z-20 to ensure headings stack above expanded cells when hovered (z-10) */}
            <thead className="shadow sticky top-0 z-20">
              <tr>
                {coalescedColumnIds.map((group) => (
                  <th
                    key={`${group.id}-${group.columns.toString()}`}
                    colSpan={group.columns.length}
                    className="h-10 px-4 font-semibold text-left border-b border-r last-of-type:border-r-0 cursor-pointer bg-neutral-100 text-neutral-900 border-neutral-300"
                  >
                    <div className="flex flex-row items-center self-center justify-center flex-grow whitespace-nowrap">
                      {t(
                        labelKeyByColumnGroupId({
                          reportType,
                          groupId: group.id,
                        }),
                      )}
                    </div>
                  </th>
                ))}
              </tr>
              <tr>
                {coalescedColumnIds
                  .flatMap((group) => group.columns)
                  .map((columnId) => {
                    const { filterKey, labelKey, ...info } = infoByColumnId({
                      reportType,
                      id: columnId,
                    })
                    const filterValue = filter[filterKey]

                    /**
                     * This heaping pile of trash code here makes me a little sick,
                     * and I think it might actually be a small bug with TS.
                     *
                     * Here's a playground that recreates the problem:
                     * https://tinyurl.com/3avzs8hj
                     *
                     * Without this horrible, albeit limited in scope, cast, the
                     * `value` property of renderFilter was coming back as `undefined`
                     * since, for some reason, the types were being intersected as
                     * opposed to unioned.
                     *
                     * - AF 10/19/23
                     */
                    const renderFilter = info.renderFilter as (options: {
                      value: unknown
                      onChange: (v: unknown) => void
                    }) => ReactNode

                    const isSorted = sorted?.column === columnId

                    return (
                      <th
                        key={columnId}
                        className={twMerge(
                          'px-4 py-3 font-semibold text-left border-b border-r last-of-type:border-r-0 cursor-pointer top-10 bg-neutral-100 text-neutral-900 border-neutral-300',
                          isSorted && 'bg-neutral-200',
                        )}
                        onClick={() => onHeaderClick(columnId)}
                      >
                        <div className="flex flex-row items-center w-40">
                          <span className="flex-grow text-sm font-semibold truncate">
                            <>{t(labelKey)}</>
                          </span>
                          {filterValue && (
                            <FontAwesomeIcon
                              className="ml-2 text-neutral-600"
                              icon={faFilter}
                            />
                          )}
                          {isSorted && (
                            <FontAwesomeIcon
                              className="ml-2 text-neutral-600"
                              icon={
                                sorted.direction === 'asc'
                                  ? faArrowDownTriangleSquare
                                  : faArrowUpTriangleSquare
                              }
                            />
                          )}
                        </div>

                        {showFilters && (
                          <div className="mt-2">
                            {renderFilter({
                              value: filterValue,
                              onChange: (value) =>
                                setFilter((f) => ({
                                  ...f,
                                  [filterKey]: value,
                                })),
                            })}
                          </div>
                        )}
                      </th>
                    )
                  })}
              </tr>
              {showTotalRow && (
                <tr>
                  {coalescedColumnIds
                    .flatMap((group) => group.columns)
                    .map((columnId) => {
                      const { accessorKey } = infoByColumnId({
                        reportType,
                        id: columnId,
                      })

                      return (
                        <th
                          key={columnId}
                          className="py-5 px-4 text-left bg-neutral-100 border-r border-b border-dashed border-neutral-300 last-of-type:border-r-0 text-sm text-neutral-600"
                        >
                          {page?.meta.columnTotals?.[accessorKey] &&
                            formattedColumnTotal(
                              reportType,
                              columnId,
                              page.meta.columnTotals[accessorKey],
                              language,
                            )}
                        </th>
                      )
                    })}
                </tr>
              )}
            </thead>
            <tbody>
              {/* fixed positioning is a little smelly imo, but it leads to a nice UX - AF 11/28/23 */}
              {pending && (
                <tr>
                  <td colSpan={coalescedColumnIds.length}>
                    <Spinner className="fixed left-[50%] top-[325px]" />
                  </td>
                </tr>
              )}
              {page?.data.map((row) => (
                <tr key={row.id}>
                  {coalescedColumnIds.flatMap((group) =>
                    group.columns.map((columnId) => {
                      const value = infoByColumnId({
                        reportType,
                        id: columnId,
                      }).accessor(row, {
                        t,
                        toLocaleString,
                      })

                      return (
                        <td
                          key={columnId}
                          className={twMerge(
                            'px-4 py-3 border-b border-r last-of-type:border-r-0 border-neutral-300 hover:relative',
                          )}
                        >
                          {/* These hover classes and the hover:relative on the <td> make the cell contents visible on hover
                              Can be removed once column widths are configurable
                              z-10 to stay below thead (z-20)
                          */}
                          <div className="w-40 text-ellipsis overflow-hidden whitespace-nowrap bg-white hover:w-auto hover:absolute hover:top-0 hover:left-0 hover:py-3 hover:px-4 z-10">
                            {value}
                          </div>
                        </td>
                      )
                    }),
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
})
