import { DateTime } from 'luxon'
import {
  SavedReport,
  DateRangeFilterValue,
  ReportTypeMap,
  SavedReportTypeSpecific,
} from '@features/reporting/types'

type SerializedDateRangeFilterValue = {
  start?: string
  end?: string
}

type FiltersWithSerializedDateTimes<RT extends keyof ReportTypeMap> = Omit<
  ReportTypeMap[RT]['filters'],
  KeysMatching<ReportTypeMap[RT]['filters'], DateRangeFilterValue | undefined>
> & {
  [key in KeysMatching<
    ReportTypeMap[RT]['filters'],
    DateRangeFilterValue | undefined
  >]?: SerializedDateRangeFilterValue
}

export type SavedReportWithSerializedDateTimes<RT extends keyof ReportTypeMap> =
  Omit<SavedReport, keyof SavedReportTypeSpecific<RT>> &
    Omit<SavedReportTypeSpecific<RT>, 'filters'> & {
      filters: FiltersWithSerializedDateTimes<RT>
    }

type SavedReportOfType<RT extends keyof ReportTypeMap> = Omit<
  SavedReport,
  keyof SavedReportTypeSpecific<RT>
> &
  SavedReportTypeSpecific<RT>

export function isSerializedDateRangeFilter(
  value: object,
): value is SerializedDateRangeFilterValue {
  return 'start' in value || 'end' in value
}

// Deserializes all date filter start/end value strings, replacing with DateTime objects
export const savedReportFilterDeserializer = <RT extends keyof ReportTypeMap>(
  report: SavedReportWithSerializedDateTimes<RT>,
): SavedReportOfType<RT> => {
  const newFilters = { ...report.filters }

  for (const key of Object.keys(
    report.filters,
  ) as (keyof SavedReportWithSerializedDateTimes<RT>['filters'])[]) {
    const originalFilter = report.filters[key]

    if (originalFilter && isSerializedDateRangeFilter(originalFilter)) {
      const newFilter = { ...originalFilter }

      // TODO: Fix typing here — can't seem to get it right. —JR
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newFilters[key] = newFilter

      if (newFilter.start) {
        ;(newFilter as DateRangeFilterValue).start = DateTime.fromISO(
          newFilter.start,
        )
      }

      if (newFilter.end) {
        ;(newFilter as DateRangeFilterValue).end = DateTime.fromISO(
          newFilter.end,
        )
      }
    }
  }

  return { ...report, filters: newFilters }
}
