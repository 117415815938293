import { DateTime } from 'luxon'

export const dateRangeForRelativeValue = (
  value: RelativeDateRangeValue,
): { startDate: DateTime; endDate: DateTime } => {
  const today = DateTime.now().startOf('day')

  switch (value) {
    case 'today':
      return { startDate: today, endDate: today.endOf('day') }
    case 'thisWeek':
      return {
        // NOTE: Subtracting a day because luxon uses Mon -> Sun weeks
        startDate: today.startOf('week').minus({ day: 1 }),
        endDate: today.endOf('week').minus({ day: 1 }).endOf('day'),
      }
    case 'lastWeek':
      return {
        startDate: today.minus({ weeks: 1 }).startOf('week').minus({ day: 1 }),
        endDate: today
          .minus({ weeks: 1 })
          .endOf('week')
          .minus({ day: 1 })
          .endOf('day'),
      }
    case 'thisMonth':
      return {
        startDate: today.startOf('month'),
        endDate: today.endOf('month').endOf('day'),
      }
    case 'lastMonth':
      return {
        startDate: today.minus({ months: 1 }).startOf('month'),
        endDate: today.minus({ months: 1 }).endOf('month').endOf('day'),
      }
    case 'thisYear':
      return {
        startDate: today.startOf('year'),
        endDate: today.endOf('year').endOf('day'),
      }
    case 'weekToDate':
      return {
        // NOTE: Subtracting a day because luxon uses Mon -> Sun weeks
        startDate: today.startOf('week').minus({ day: 1 }),
        endDate: today.endOf('day'),
      }
    case 'monthToDate':
      return {
        startDate: today.startOf('month'),
        endDate: today.endOf('day'),
      }
    case 'yearToDate':
      return {
        startDate: today.startOf('year'),
        endDate: today.endOf('day'),
      }
  }
}
