import { Login } from '@features/login'
import { Logout } from '@features/logout'

export const unauthenticatedRoutes = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/logout',
    element: <Logout />,
  },
]
