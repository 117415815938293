import { FC, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Status } from 'types'
import { StatusSegment } from 'components/indicators/status-segment'
import { faCloudCheck } from '@fortawesome/pro-regular-svg-icons'
import { useLanguage } from '@hooks/useLanguage'

interface Props {
  statusTotals: StatusMap<number>
  simplifyWhenAllSent: boolean
  onClick?: (status: Status, e: React.MouseEvent) => void
  segmentLinkToBuilder?: (status: Status) => string
}

export const TimeCardProgress: FC<Props> = ({
  statusTotals,
  simplifyWhenAllSent,
  onClick,
  segmentLinkToBuilder,
}) => {
  const locale = useLanguage()

  const total = useMemo(
    () => Object.values(statusTotals).reduce((a, b) => a + b, 0),
    [statusTotals],
  )

  if (
    simplifyWhenAllSent &&
    statusTotals[Status.Open] === 0 &&
    statusTotals[Status.Submitted] === 0 &&
    statusTotals[Status.Approved] === 0 &&
    statusTotals[Status.Sent] > 0
  ) {
    return (
      <div className="flex justify-center items-center gap-1 text-xs font-medium text-neutral-600">
        <FontAwesomeIcon icon={faCloudCheck} className={'text-neutral-400'} />
        {statusTotals[Status.Sent].toLocaleString(locale)}
      </div>
    )
  }

  return (
    <div className="flex w-full gap-0.5 text-xs font-medium bg-white">
      {Object.values(Status).map((status) => (
        <StatusSegment
          key={status}
          status={status}
          value={statusTotals[status]}
          total={total}
          onClick={onClick}
          linkTo={segmentLinkToBuilder?.(status)}
        />
      ))}
    </div>
  )
}
