import { Checkbox } from 'components/form/checkbox/Checkbox'
import { Radio } from 'components/form/radio/Radio'
import { FC } from 'react'

export const Selection: FC<{
  checked: boolean
  disabled: boolean
  label: string
  onChange: (checked: boolean) => void
  type?: 'radio' | 'checkbox'
}> = ({ checked, disabled, onChange, label, type = 'radio' }) => {
  const SelectionComponent = type === 'radio' ? Radio : Checkbox

  return (
    <label className="flex flex-row mr-4 text-sm text-neutral-900">
      <SelectionComponent
        checked={checked}
        disabled={disabled}
        className="mr-2"
        onChange={(event) => onChange(event.currentTarget.checked)}
      />
      {label}
    </label>
  )
}
