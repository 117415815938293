import { useOnClickOutside } from '@hooks/useOnClickOutside'
import { noop } from '@utils/noop'
import { InfiniteScrollList } from 'components/containers/infinite-scroll-list'
import { ReactNode, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from '@lib/tailwind-merge'
import { Label } from '../label'
import { SearchField } from '../search-field'

interface Props<T> {
  className?: string
  searchFieldClassName?: string
  searchFieldWrapperClassName?: string
  expectedItemHeight: number
  items: T[]
  isFetching?: boolean
  isInitiallyLoading?: boolean
  label?: string
  onNextPage?: () => void
  onSearch: (value: string) => void
  placeholder: string
  renderItem: (item: T, clickHandler: () => void) => ReactNode
  totalItems: number
  value: string
  variant?: 'floating' | 'fixed-below'
  autoFocusSearchField?: boolean
}

export const SearchSelection = <T,>({
  className,
  searchFieldClassName,
  searchFieldWrapperClassName,
  expectedItemHeight,
  items,
  isFetching = false,
  isInitiallyLoading = false,
  label,
  onNextPage: getNextPage = noop,
  onSearch,
  placeholder,
  renderItem,
  totalItems,
  value,
  variant = 'floating',
  autoFocusSearchField,
}: Props<T>) => {
  const { t } = useTranslation()
  const ref = useRef(null)
  const [showPicker, setShowPicker] = useState(variant === 'fixed-below')

  useOnClickOutside(ref, () => {
    if (variant === 'fixed-below') return

    setShowPicker(false)
  })

  return (
    <div
      className={twMerge(
        'flex flex-col',
        variant === 'fixed-below' && 'overflow-hidden',
        className,
      )}
      ref={ref}
    >
      {label && <Label className="mb-2">{label}</Label>}
      <SearchField
        className={searchFieldWrapperClassName}
        searchFieldClassName={searchFieldClassName}
        value={value}
        hidePicker={!showPicker}
        onChange={onSearch}
        onFocus={() => setShowPicker(true)}
        autoFocus={autoFocusSearchField}
        placeholder={placeholder}
        pickerVariant={variant}
        picker={({ hidden }) => {
          if (hidden) return null

          return (
            <>
              <InfiniteScrollList
                expectedItemHeight={expectedItemHeight}
                items={items}
                isFetching={isFetching}
                isInitiallyLoading={isInitiallyLoading}
                loadMore={getNextPage}
                renderItem={(item) =>
                  renderItem(item, () => setShowPicker(false))
                }
                skeletonItem={
                  <div className="h-4 mt-2 grow bg-neutral-100 animate-pulse"></div>
                }
                totalItems={totalItems}
              />
              {!isFetching && !isInitiallyLoading && items.length === 0 && (
                <span>
                  {t('components.searchSelection.noResultsFor', {
                    search: value,
                  })}
                </span>
              )}
            </>
          )
        }}
      />
    </div>
  )
}
