import { FC, ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { twMerge } from '@lib/tailwind-merge'

interface Props {
  color: string
  icon?: IconDefinition
  className?: string
  borderShade?: number
  children: ReactNode
}

export const Label: FC<Props> = ({
  color,
  icon,
  className,
  children,
  borderShade = 300,
}) => {
  return (
    <div
      className={twMerge(
        `rounded text-xs font-semibold flex items-center justify-center gap-2 px-4 h-6 overflow-hidden bg-${color}-100 border border-${color}-${borderShade} text-${color}-800 uppercase`,
        className,
      )}
    >
      {icon && <FontAwesomeIcon icon={icon} />}

      {children}
    </div>
  )
}
