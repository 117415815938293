import { FC, ReactNode } from 'react'
import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { padDigit } from '@utils/padDigit'

const withLightening = (icon: IconDefinition) => {
  const IconWithLightening = ({
    lightened,
    ...props
  }: { lightened: boolean } & Omit<FontAwesomeIconProps, 'icon'>) => (
    <FontAwesomeIcon
      {...props}
      icon={icon}
      className={`${props.className ?? ''} ${
        lightened ? 'text-neutral-200' : 'text-neutral-400'
      }`}
    />
  )

  return IconWithLightening
}

const ChevronUp = withLightening(faChevronUp)
const ChevronDown = withLightening(faChevronDown)

interface CellProps {
  children?: ReactNode
  disabled?: boolean
  onClick: () => void
  selected?: boolean
}

const Cell: FC<CellProps> = ({
  children,
  disabled,
  onClick,
  selected = false,
}) => {
  const pointerClassName =
    children !== undefined && !disabled ? 'cursor-pointer' : ''
  const selectedClassName = selected ? 'bg-primary-600 text-white' : ''
  const hoverColor =
    pointerClassName === 'cursor-pointer' && !selected
      ? 'hover:text-primary-600'
      : ''

  return (
    <button
      type="button"
      className={`flex flex-row items-center justify-center flex-1 select-none ${pointerClassName} ${selectedClassName} ${hoverColor}`}
      onClick={() => {
        if (disabled) return

        onClick()
      }}
    >
      {children}
    </button>
  )
}

interface CellWithValue<T> {
  onClick: (i: T) => void
  selected?: boolean
  value?: T
}

const CellWithValue = <T extends number | string>({
  onClick: click,
  selected,
  value,
}: CellWithValue<T>) => {
  const handleClick = () => {
    if (value === undefined) return

    click(value)
  }

  return (
    <Cell onClick={handleClick} selected={selected}>
      {typeof value === 'number' ? padDigit(value) : value}
    </Cell>
  )
}
const spliceInto = <I extends string | number>(
  items: NonNullable<I>[],
  item: I,
): { items: NonNullable<I>[]; index: number } => {
  let hasSpliced = false
  let spliceIndex = Math.floor(items.length / 2)
  const newItems: NonNullable<I>[] = []

  for (let index = 0; index < items.length; index++) {
    const currentItem = items[index]

    if (currentItem === item) {
      spliceIndex = index
      hasSpliced = true
    } else if (currentItem > item && !hasSpliced) {
      spliceIndex = index
      newItems.push(item)
      hasSpliced = true
    }

    newItems.push(currentItem)
  }

  if (item !== null && !hasSpliced) {
    newItems.push(item)
    spliceIndex = newItems.length - 1
  }

  return { items: newItems, index: spliceIndex }
}

interface Props<T> {
  activeItem: T
  hidden?: boolean
  items: NonNullable<T>[]
  onChange: (item: NonNullable<T>) => void
  wrap?: boolean
}

export const Dial = <T extends string | number>({
  activeItem,
  hidden = false,
  items,
  onChange,
  wrap = false,
}: Props<T>) => {
  if (hidden) return null

  const { items: spliced, index } = spliceInto(items, activeItem)

  const itemIsAtEnd = spliced.length - 1 === index
  const itemIsAtBeginning = index === 0

  const getValue = (positionFromIndex: number): T => {
    if (!wrap) return spliced[index + positionFromIndex]

    const indexOfInterest = index + positionFromIndex
    if (indexOfInterest > spliced.length - 1)
      return spliced.at(positionFromIndex - 1) as NonNullable<T>

    return spliced.at(indexOfInterest) as NonNullable<T>
  }

  return (
    <div className="flex flex-col flex-1 hover:bg-neutral-100">
      <Cell
        onClick={() => onChange(getValue(-1))}
        disabled={!wrap && itemIsAtBeginning}
      >
        <ChevronUp lightened={!wrap && itemIsAtBeginning} />
      </Cell>
      <CellWithValue onClick={onChange} value={getValue(-2)} />
      <CellWithValue onClick={onChange} value={getValue(-1)} />
      <CellWithValue selected={true} onClick={onChange} value={getValue(0)} />
      <CellWithValue onClick={onChange} value={getValue(+1)} />
      <CellWithValue onClick={onChange} value={getValue(+2)} />
      <Cell
        onClick={() => onChange(getValue(1))}
        disabled={!wrap && itemIsAtEnd}
      >
        <ChevronDown lightened={!wrap && itemIsAtEnd} />
      </Cell>
    </div>
  )
}
