import { faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons'
import { coerceIntoArray } from '@utils/coerceIntoArray'
import { Sidebar } from 'components/sidebar'
import { FC, ReactNode } from 'react'
import { twMerge } from '@lib/tailwind-merge'
import { ButtonLink } from './ButtonLink'

type InfoSection = Record<string, ReactNode>
type InfoSections = InfoSection[]

interface Props {
  children: ReactNode
  contentSlot?: ReactNode
  email?: string
  department?: string | null
  fullName: string
  hidden: boolean
  info?: InfoSection | InfoSections
  onClose: () => void
  type: string
  phoneNumber?: string
  profileImageUrl?: string
  title?: string
  blockBreakpoint?: '2xl' | '3xl'
}

export const EmployeeSidebar: FC<Props> = ({
  children,
  contentSlot,
  email,
  department,
  fullName,
  info: information,
  phoneNumber,
  hidden,
  onClose,
  type,
  profileImageUrl,
  title,
  blockBreakpoint,
}) => {
  return (
    <Sidebar
      className="overflow-auto"
      hidden={hidden}
      onClose={onClose}
      mode="block"
      blockBreakpoint={blockBreakpoint}
    >
      <div className="flex flex-col p-4 pt-0">
        {profileImageUrl && (
          <img
            src={profileImageUrl}
            className="self-center w-[72px] h-[72px] rounded-full mb-4"
          />
        )}
        <h1 className="text-lg font-semibold text-center text-neutral-900">
          {fullName}
        </h1>
        <span className="mb-4 text-sm text-center text-neutral-600">
          {[title, department, type].filter(Boolean).join(' • ')}
        </span>
        <div className="flex flex-row justify-center mb-2 space-x-3">
          {phoneNumber && (
            <ButtonLink icon={faPhone} href={`tel:${phoneNumber}`} />
          )}
          {email && <ButtonLink icon={faEnvelope} href={`mailto:${email}`} />}
        </div>
      </div>
      <div className="mb-4">{contentSlot}</div>
      <div className="table">
        {coerceIntoArray(information).map((info, index) => {
          return Object.entries(info).map(([key, value], i, arr) => {
            const isFirst = i === 0
            const isLast = i === arr.length - 1

            return (
              <div className="table-row" key={`${key}-${index}`}>
                <div
                  className={twMerge(
                    'table-cell py-2 text-sm text-left text-neutral-600 pl-8',
                    isFirst && 'pt-6 border-t border-t-neutral-300',
                    isLast && 'pb-6',
                  )}
                >
                  {key}
                </div>
                <div
                  className={twMerge(
                    'table-cell py-2 text-sm font-medium text-left pr-8',
                    isFirst && 'pt-6 border-t border-t-neutral-300',
                    isLast && 'pb-6',
                  )}
                >
                  {value}
                </div>
              </div>
            )
          })
        })}
      </div>
      {children}
    </Sidebar>
  )
}
