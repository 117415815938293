import { TFunction } from 'react-i18next'
import { Status } from 'types'
import { StatusBadge } from 'components/indicators/status-badge'
import { faArrowRightFromBracket } from '@fortawesome/pro-solid-svg-icons'
import { Label } from 'components/indicators/label'

const getNameForEvent = (
  event: BaseAuditEvent,
  t: TFunction<'translation'>,
) => {
  switch (event.user) {
    case 'system':
      return t('common.system')
    case 'unknown':
      return t('common.unknown')
    default:
      return event.user.name
  }
}

const getBaseTimelineItemForEvent = (
  event: AuditEvent,
  t: TFunction<'translation'>,
): Pick<TimelineItem, 'date' | 'subtext'> => ({
  date: event.timestamp,
  subtext: t('features.eventLogs.byName', {
    name: getNameForEvent(event, t),
  }),
})

export const getTimelineItemForEvent = (
  event: AuditEvent,
  t: TFunction<'translation'>,
): TimelineItem => {
  const baseItem = getBaseTimelineItemForEvent(event, t)

  switch (event.type) {
    case 'time_card_created':
      return {
        ...baseItem,
        title: <StatusBadge status={Status.Open} borderShade={400} />,
      }
    case 'time_card_destroyed':
      return {
        ...baseItem,
        title: (
          <span className="text-xs font-semibold">
            {t('features.eventLogs.timeCardDeleted')}
          </span>
        ),
      }
    case 'time_card_submitted':
      return {
        ...baseItem,
        title: <StatusBadge status={Status.Submitted} />,
      }
    case 'time_card_unsubmitted':
      return {
        ...baseItem,
        title: (
          <Label color="neutral" icon={faArrowRightFromBracket}>
            {t('features.eventLogs.timeCardUnsubmitted')}
          </Label>
        ),
      }
    case 'time_card_sent':
      return {
        ...baseItem,
        title: <StatusBadge status={Status.Sent} />,
      }
    case 'approval_approved':
      return {
        ...baseItem,
        title: <StatusBadge status={Status.Approved} />,
        content: <span className="text-sm">{event.details.domain.name}</span>,
      }
    case 'approval_unapproved':
      return {
        ...baseItem,
        title: (
          <Label color="neutral">{t('features.eventLogs.unapproved')}</Label>
        ),
        content: <span className="text-sm">{event.details.domain.name}</span>,
      }
    case 'note_added':
      return {
        ...baseItem,
        title: (
          <span className="text-xs font-semibold">
            {t('features.eventLogs.noteAdded')}
          </span>
        ),
        content: <p className="text-sm">&ldquo;{event.details.body}&rdquo;</p>,
      }
    case 'note_updated':
      return {
        ...baseItem,
        title: (
          <span className="text-xs font-semibold">
            {t('features.eventLogs.noteUpdated')}
          </span>
        ),
        content: <p className="text-sm">&ldquo;{event.details.body}&rdquo;</p>,
      }

    case 'note_removed':
      return {
        ...baseItem,
        title: (
          <span className="text-xs font-semibold">
            {t('features.eventLogs.noteRemoved')}
          </span>
        ),
      }
    case 'correction_created':
      return {
        ...baseItem,
        title: (
          <span className="text-xs font-semibold">
            {t('features.timeLogging.corrected')}
          </span>
        ),
      }
    default:
      return {
        ...baseItem,
        title: (
          <span className="text-xs font-semibold">
            {t('features.eventLogs.unknownEvent')}
          </span>
        ),
      }
  }
}
