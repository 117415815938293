import { TFunction } from 'react-i18next'

export const renderNumericRange = (
  from: number | undefined,
  to: number | undefined,
  t: TFunction<'translation', undefined>,
  formatter: (value: number) => string,
) => {
  if (from !== undefined && to !== undefined)
    return `${formatter(from)} - ${formatter(to)}`
  if (from) return t('common.plus', { number: formatter(from) })
  if (to) return t('common.upTo', { value: formatter(to) })

  return t('common.all')
}
