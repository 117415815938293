import { FC, useMemo, useState } from 'react'
import { NewHeader, WeekSelectorSubHeader } from 'components/page-header'
import { useTranslation } from 'react-i18next'
import { Table } from '../components/Table'
import { Spinner } from 'components/loaders'
import { useWeekDates } from '@hooks/useWeekDates'
import { useFacilities } from '@hooks/useFacilities'
import { useWeeklyFacilityTimeCardStatusTotals } from '../hooks/useWeeklyFacilityTimeCardStatusTotals'
import { FacilityWithWeeklyStatusTotals } from '../types'
import { Interval } from 'luxon'
import { usePayPeriods } from '@hooks/usePayPeriods'
import { Button } from 'components/buttons'
import { faCalendarRange } from '@fortawesome/pro-regular-svg-icons'

export const FacilitiesAdmin: FC = () => {
  const { t } = useTranslation()
  const {
    start: selectedWeekStart,
    end: selectedWeekEnd,
    set: setSelectedWeekStart,
  } = useWeekDates()
  const [displayPayPeriods, setDisplayPayPeriods] = useState(false)

  const { windowStartDate, windowEndDate } = useMemo(
    () => ({
      windowStartDate: selectedWeekStart.minus({ week: 4 }),
      windowEndDate: selectedWeekEnd.plus({ week: 1 }),
    }),
    [selectedWeekStart, selectedWeekEnd],
  )
  const { data: facilities } = useFacilities()
  const { data: payPeriods } = usePayPeriods({
    startDate: windowStartDate,
    endDate: windowEndDate,
  })

  const weeks = useMemo(
    () =>
      Interval.fromDateTimes(windowStartDate, windowEndDate).splitBy({
        weeks: 1,
      }),
    [windowStartDate, windowEndDate],
  )

  const weeklyTotalResults = useWeeklyFacilityTimeCardStatusTotals({
    weeks,
  })

  // Group returned time card statuses for each week into
  // FacilityWithWeeklyStatusTotals shape (array of Facilities with
  // time card status totals for each week — ie the shape of the table)
  const facilitiesWithWeeklyTimeCardStatuses = useMemo(() => {
    if (facilities === undefined) return []

    return weeklyTotalResults.reduce<FacilityWithWeeklyStatusTotals[]>(
      (acc, { data }, i) => {
        if (data === undefined) return acc

        for (const {
          facilityId,
          timeCardStatusTotals,
          timeCardIssues,
        } of data) {
          let obj = acc.find(({ facility }) => facility.id === facilityId)

          // Facility doesn't exist yet in the array,
          // find it from the data returned by the Facilities query
          if (obj === undefined) {
            const facility = facilities.find(({ id }) => id === facilityId)

            if (facility === undefined)
              throw new Error(
                `Facility ${facilityId} not returned by /facilities`,
              )

            obj = { facility, weeklyStatusTotals: [] }
            acc.push(obj)
          }

          obj.weeklyStatusTotals.push({
            // useQueries guarantees that the data is in the same order as the queries,
            // so we can use the index of the result to match to the requested week.
            weekStartDate: weeks[i].start,
            timeCardStatusTotals,
            timeCardIssues,
          })
        }

        return acc
      },
      [],
    )
  }, [facilities, weeklyTotalResults, weeks])

  const isLoading = useMemo(
    () =>
      facilities === undefined ||
      weeklyTotalResults.some(({ data }) => data === undefined) ||
      payPeriods === undefined,
    [facilities, weeklyTotalResults, payPeriods],
  )

  return (
    <div className="flex flex-col flex-grow">
      <NewHeader title={t('common.facilities')} />

      <WeekSelectorSubHeader
        onWeekSelect={setSelectedWeekStart}
        selectedWeek={selectedWeekStart}
      >
        <Button
          icon={faCalendarRange}
          variant={displayPayPeriods ? 'outlined-active' : 'outlined'}
          className="px-4 cursor-pointer"
          onClick={() => setDisplayPayPeriods((displayed) => !displayed)}
        />
      </WeekSelectorSubHeader>

      {isLoading ? (
        <Spinner />
      ) : (
        <Table
          facilitiesWithWeeklyTimeCardStatuses={
            facilitiesWithWeeklyTimeCardStatuses
          }
          selectedWeekStart={selectedWeekStart}
          weeks={weeks}
          payPeriods={payPeriods ?? []}
          displayPayPeriods={displayPayPeriods}
        />
      )}
    </div>
  )
}
