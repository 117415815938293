import { useCallback, useMemo } from 'react'
import { FilterAlertType, WorkerAndTimeCards } from '../types'
import { WorkdaySendStatus } from 'types'
import { FilterWorkersWithVisibleItems } from 'components/buttons'

// Returns true if the provided AdminTimeCard
// matches any of the provided FilterAlertType,
// false otherwise.
const timeCardMatchesAnyAlertType = (
  timeCard: AdminTimeCard,
  types: Set<FilterAlertType>,
) => {
  if (types.has('issues') && timeCard.issues.length > 0) return true
  if (
    types.has('pending') &&
    timeCard.workdaySendStatus === WorkdaySendStatus.Pending
  )
    return true
  if (
    types.has('workdayError') &&
    timeCard.workdaySendStatus === WorkdaySendStatus.Error
  )
    return true

  return false
}

// Returns true if the provided worker should be included
type WorkerFilterFn = (worker: TWorker) => boolean

// Returns true if the provided time card should be included
type TimeCardFilterFn = (timeCard: AdminTimeCard) => boolean

// Returns true if the provided worker and time cards should be included
// If false is returned, the entire worker is excluded
type WorkerAndTimeCardsFilterFn = (
  worker: TWorker,
  timeCards: AdminTimeCard[],
) => boolean

interface Options {
  hiddenDays: Set<number>
  filteredAlertTypes: Set<FilterAlertType>
  hiddenStatuses: Set<Status>
  filterEmployeesWithVisibleTimeCards: FilterWorkersWithVisibleItems
  employeeNameFilter: string
  filteredFacilityIds: Set<number>
  filteredApprovalGroupIds: Set<number>
}

interface FilterMap {
  // Filters that just care about the worker
  worker: {
    [key: string]: WorkerFilterFn
  }

  // Filters that just care about the time card
  timeCard: {
    [key: string]: TimeCardFilterFn
  }

  // Filters that care about the combined worker and time card collection
  workerAndTimeCards: {
    [key: string]: WorkerAndTimeCardsFilterFn
  }
}

export const useFiltering = ({
  hiddenDays,
  filteredAlertTypes,
  hiddenStatuses,
  filterEmployeesWithVisibleTimeCards,
  employeeNameFilter,
  filteredFacilityIds,
  filteredApprovalGroupIds,
}: Options) => {
  const dayFilter = useCallback<TimeCardFilterFn>(
    (timeCard: AdminTimeCard) => {
      if (hiddenDays.size === 0) return true

      return !hiddenDays.has(timeCard.date.weekday)
    },
    [hiddenDays],
  )

  const alertFilter = useCallback<TimeCardFilterFn>(
    (timeCard: AdminTimeCard) => {
      if (filteredAlertTypes.size === 0) return true

      return timeCardMatchesAnyAlertType(timeCard, filteredAlertTypes)
    },
    [filteredAlertTypes],
  )

  const statusFilter = useCallback<TimeCardFilterFn>(
    (timeCard: AdminTimeCard) => {
      if (hiddenStatuses.size === 0) return true

      return !hiddenStatuses.has(timeCard.status)
    },
    [hiddenStatuses],
  )

  const visibleTimeCardFilter = useCallback<WorkerAndTimeCardsFilterFn>(
    (_worker: TWorker, timeCards: AdminTimeCard[]) => {
      return {
        all: true,
        atLeastOne: timeCards.length > 0,
        none: timeCards.length === 0,
      }[filterEmployeesWithVisibleTimeCards]
    },
    [filterEmployeesWithVisibleTimeCards],
  )

  const workerAttributeFilter = useCallback<WorkerFilterFn>(
    (worker: TWorker) => {
      const normalizedSearch = employeeNameFilter.trim().toLowerCase()

      const notFilteringNames = normalizedSearch === ''
      const notFilteringFacilities = filteredFacilityIds.size === 0
      const notFilteringApprovalGroups = filteredApprovalGroupIds.size === 0

      // Optimization for when no filters are applied
      if (
        notFilteringNames &&
        notFilteringFacilities &&
        notFilteringApprovalGroups
      )
        return true

      return (
        (notFilteringNames ||
          worker.fullName.toLowerCase().includes(normalizedSearch)) &&
        (notFilteringFacilities ||
          filteredFacilityIds.has(worker.facility.id)) &&
        (notFilteringApprovalGroups ||
          filteredApprovalGroupIds.has(worker.approvalGroup.id))
      )
    },
    [employeeNameFilter, filteredFacilityIds, filteredApprovalGroupIds],
  )

  const filters = useMemo<FilterMap>(
    () => ({
      worker: { workerAttributeFilter },
      timeCard: { dayFilter, alertFilter, statusFilter },
      workerAndTimeCards: { visibleTimeCardFilter },
    }),
    [
      dayFilter,
      alertFilter,
      statusFilter,
      visibleTimeCardFilter,
      workerAttributeFilter,
    ],
  )

  const applyFilters = useCallback(
    (workerAndTimeCards: WorkerAndTimeCards[], filters: FilterMap) =>
      workerAndTimeCards.reduce<WorkerAndTimeCards[]>(
        (acc, { worker, timeCards }) => {
          // Filter workers first, no need to proceed if the worker is excluded
          const includeWorker = Object.values(filters.worker).every((filter) =>
            filter(worker),
          )

          if (includeWorker) {
            // Filter Time Cards next
            const filteredTimeCards = timeCards.filter((timeCard) => {
              return Object.values(filters.timeCard).every((filter) =>
                filter(timeCard),
              )
            })

            // Determine if the worker should be included based on the new filtered Time Cards
            const includeWorkerAndTimeCards = Object.values(
              filters.workerAndTimeCards,
            ).every((filter) => filter(worker, filteredTimeCards))

            if (includeWorkerAndTimeCards) {
              acc.push({
                worker,
                timeCards: filteredTimeCards,
              })
            }
          }

          return acc
        },
        [],
      ),
    [],
  )

  // Given a filter key, return a FilterMap without that filter
  // We assume here that filter keys never clash, even across types
  // (i.e. a worker/timeCard/workerAndTimeCards filters never share a key)
  const filtersWithout = useCallback(
    (filterKey: string) => {
      const {
        worker: { [filterKey]: _excludedWorkersFilter, ...worker },
        timeCard: { [filterKey]: _excludedTimeCardsFilter, ...timeCard },
        workerAndTimeCards: {
          [filterKey]: _excludedWorkerAndTimeCardsFilter,
          ...workerAndTimeCards
        },
      } = filters

      return { worker, timeCard, workerAndTimeCards }
    },
    [filters],
  )

  return { filters, applyFilters, filtersWithout }
}
