import { sendTimeCardsToPayroll } from '@lib/api'
import { timeCardKeys } from '@lib/keys'
import { useQueryClient, useMutation } from '@tanstack/react-query'

export const useSendToPayroll = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (timeCardIds: number[]) => sendTimeCardsToPayroll(timeCardIds),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(timeCardKeys.all)
      },
    },
  )
}
