import { useAuth } from '@hooks/useAuth'
import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from '@react-oauth/google'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

export const LoginWithGoogle: FC = () => {
  const { login } = useAuth()
  const navigate = useNavigate()

  const handleSuccess = async (response: CredentialResponse) => {
    if (response.credential) {
      await login(response.credential)
      navigate('/time-logger')
    } else {
      // TODO - verify that we want this kind of sentry log
      Sentry.captureMessage(
        `Unable to login.  Sign in response did not contain token.  Response: ${JSON.stringify(
          response,
        )}`,
      )
    }
  }

  const handleError = () => {
    Sentry.captureMessage(
      'Unable to log in. Unable to authenticate with google',
    )
  }

  return (
    <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
      <div className="mx-auto flex justify-center">
        <GoogleLogin
          onSuccess={(response) => void handleSuccess(response)}
          onError={handleError}
        />
      </div>
    </GoogleOAuthProvider>
  )
}
