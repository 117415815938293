import { FC } from 'react'
import { Annotation } from './Annotation'

interface Props {
  name: string
  variant: 'floating' | 'attachment'
}

export const Holiday: FC<Props> = ({ name, variant }) => {
  return (
    <Annotation position={variant} type="holiday">
      <span className="font-semibold">{name}</span>
    </Annotation>
  )
}
