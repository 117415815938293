import { twMerge } from '@lib/tailwind-merge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons'
import classNames from 'classnames'

interface Props<T extends string | number> {
  className?: string
  disabled?: boolean
  disableNext?: boolean
  disablePrevious?: boolean
  onNext: () => void
  onPrevious: () => void
  value: T
}

export const HorizontalScroll = <T extends string | number>({
  className,
  disabled,
  disableNext,
  disablePrevious,
  onNext,
  onPrevious,
  value,
}: Props<T>) => {
  const isPreviousDisabled = disablePrevious || disabled
  const isNextDisabled = disableNext || disabled

  return (
    <span
      className={twMerge(
        'flex flex-row w-full p-2 text-sm border-solid rounded border-[1px] border-neutral-400',
        className,
      )}
    >
      {value}
      <div className="flex flex-row items-center justify-center ml-auto space-x-2">
        <button onClick={onPrevious} disabled={isPreviousDisabled}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={classNames({ 'text-neutral-300': isPreviousDisabled })}
          />
        </button>
        <button onClick={onNext} disabled={isNextDisabled}>
          <FontAwesomeIcon
            icon={faChevronRight}
            className={classNames({ 'text-neutral-300': isNextDisabled })}
          />
        </button>
      </div>
    </span>
  )
}
