import { RefObject, useEffect, useRef } from 'react'

interface Options<T extends HTMLElement> {
  ref?: RefObject<T>
  onRightClick?: (pos: MousePosition) => void
}

export const useRightClick = <T extends HTMLElement>({
  ref: injectedRef,
  onRightClick,
}: Options<T>) => {
  const ref = useRef<T>(null)

  useEffect(() => {
    if (!onRightClick) return

    const r = injectedRef?.current ?? ref.current
    const respondToRightClick = (event: MouseEvent) => {
      event.preventDefault()
      onRightClick({
        x: event.pageX,
        y: event.pageY,
      })
    }

    r?.addEventListener('contextmenu', respondToRightClick)

    return () => r?.removeEventListener('contextmenu', respondToRightClick)
  }, [onRightClick]) // eslint-disable-line react-hooks/exhaustive-deps

  return injectedRef ?? ref
}
