import { abbreviateDayOfWeek } from '@utils/abbreviateDayOfWeek'
import {
  extractSortedProjectsAndProjectIntegrations,
  groupTaskByProjectAndIntegrationId,
  taskGroupKey,
} from '@utils/groupTaskbyProjectAndIntegrationId'
import {
  EmptyAllocationBanner,
  LeftHeader,
  SideColumn,
  SummaryCell,
  SummaryRow,
} from 'components/time-entry-table'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { daysOfWeek } from '../../../utils/daysOfWeek'
import { useManagedGroupOutletContext } from '../../ManagedGroup'
import { AllocationTable } from './AllocationTable'

export const AllocationTables: FC = memo(() => {
  const { t } = useTranslation()
  const { adminManagedGroup, totals } = useManagedGroupOutletContext()

  const adminProject = {
    id: 0,
    name: t('common.nonProject'),
    isAdminProject: true,
  }

  const tasks = adminManagedGroup.weekTemplate.taskSelections

  const grouped = groupTaskByProjectAndIntegrationId(tasks)
  const projectsAndIntegrations = extractSortedProjectsAndProjectIntegrations(
    grouped,
    adminProject,
  )

  if (projectsAndIntegrations.length === 0)
    return (
      <EmptyAllocationBanner
        body={t('features.adminManagedGroups.useTaskSidebarToAddTasks')}
      />
    )

  return (
    <>
      {projectsAndIntegrations.map(({ project, projectIntegration }) => {
        const tasks = grouped[taskGroupKey({ project, projectIntegration })]

        return (
          <AllocationTable
            key={taskGroupKey({ project, projectIntegration })}
            projectName={project.name}
            projectIntegrationCode={projectIntegration?.code}
            tasks={tasks}
          />
        )
      })}
      <SummaryRow>
        <SideColumn side="left">
          <LeftHeader
            highlight={false}
            labels={[t('features.adminManagedGroups.totalAllocatedHours')]}
            rowIndex={1}
          ></LeftHeader>
        </SideColumn>
        {daysOfWeek.map((day, i) => (
          <SummaryCell
            key={day}
            gridClass={`grid-in-${abbreviateDayOfWeek(day)}`}
            total={
              adminManagedGroup.weekTemplate.dayTemplates[day].totals.allocation
            }
            skipBorder={daysOfWeek.length == i + 1}
          />
        ))}
        <SummaryCell
          gridClass="grid-in-totalByTask"
          total={totals.allocation}
          skipBorder={true}
        />
      </SummaryRow>
    </>
  )
})

AllocationTables.displayName = 'AllocationTables'
