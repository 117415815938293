import { FC, ReactNode } from 'react'

export const Section: FC<{ children: ReactNode; heading?: string }> = ({
  children,
  heading,
}) => {
  return (
    <section className="pt-8 pb-4">
      <h1 className="ml-4 text-xs font-semibold uppercase text-neutral-400">
        {heading}
      </h1>
      <ul>{children}</ul>
    </section>
  )
}
