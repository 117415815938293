import { useState } from 'react'
import { Sidebar } from 'components/sidebar'
import { TabPicker } from './components/TabPicker'
import { MyTasks } from './tabs/MyTasks'
import NonProject from './tabs/NonProject'
import { AllTasks } from './tabs/all-tasks'

interface Props<
  T extends readonly ('my-tasks' | 'all-tasks' | 'non-project')[],
> {
  disabledTaskIds: Set<number>
  hidden: boolean
  onClose: () => void
  onTaskClick: (task: SelectedTask, checked: boolean) => void
  selectedTaskIds: Set<number>
  tabs: T
  title: string
  subtitle: string
}

export const TaskPicker = <
  T extends readonly ('my-tasks' | 'all-tasks' | 'non-project')[],
>({
  disabledTaskIds,
  hidden,
  onClose,
  onTaskClick,
  selectedTaskIds,
  subtitle,
  tabs,
  title,
}: Props<T>) => {
  const [selectedTab, setSelectedTab] = useState<T[number]>(tabs[0])

  return (
    <Sidebar
      hidden={hidden}
      onClose={onClose}
      subTitle={subtitle}
      title={title}
    >
      <TabPicker
        activeTab={selectedTab}
        onTabClick={setSelectedTab}
        tabs={tabs}
      />
      <div className="flex-col h-full pt-4 overflow-auto">
        {tabs.includes('my-tasks') && (
          <MyTasks
            disabledTaskIds={disabledTaskIds}
            hidden={selectedTab !== 'my-tasks'}
            onTaskClick={onTaskClick}
            selectedTaskIds={selectedTaskIds}
          />
        )}
        {tabs.includes('all-tasks') && (
          <AllTasks
            disabledTaskIds={disabledTaskIds}
            hidden={selectedTab !== 'all-tasks'}
            onTaskClick={onTaskClick}
            selectedTaskIds={selectedTaskIds}
          />
        )}
        {tabs.includes('non-project') && (
          <NonProject
            disabledTaskIds={disabledTaskIds}
            hidden={selectedTab !== 'non-project'}
            onTaskClick={onTaskClick}
            selectedTaskIds={selectedTaskIds}
          />
        )}
      </div>
    </Sidebar>
  )
}
