import { initialCapitalize } from '@utils/initialCapitalize'

interface Props {
  className?: string
}

export const BrandLogo = ({ className }: Props) => {
  const theme = import.meta.env.VITE_THEME
  const assetsBaseUrl = `${import.meta.env.VITE_ASSETS_BASE_URL}/`
  const logoUrl = new URL('logo.svg', assetsBaseUrl)
    .href
  const logoAlt = `${initialCapitalize(theme)} Logo`

  return (
    <img
      src={logoUrl}
      alt={logoAlt}
      className={`${className ? className : ''}`}
    />
  )
}
