import { FC } from 'react'
import { EntityPicker, EntityItem } from '../entity-picker'
import { useShotgridEntitySchemas } from '@hooks/useShotgridEntitySchemas'
import { useTranslation } from 'react-i18next'

interface Props {
  columnNameTranslationKey: TranslationKey
  onChange: (ids: Set<string>) => void
  selected: Set<string>
}
export const LinkedEntityPicker: FC<Props> = ({
  columnNameTranslationKey,
  onChange,
  selected,
}) => {
  const { t } = useTranslation()

  return (
    <EntityPicker
      className="w-96"
      columnNameTranslationKey={columnNameTranslationKey}
      filter={(schema, search) => {
        const s = search.toLowerCase()

        return (
          schema.entityType.toLowerCase().includes(s) ||
          schema.name.toLowerCase().includes(s)
        )
      }}
      placeholder={t('common.search')}
      isSelected={(schema, selectedIds) => selectedIds.has(schema.entityType)}
      renderSearchItem={(schema) => (
        <EntityItem
          key={schema.entityType}
          onClick={() => onChange(new Set(selected).add(schema.entityType))}
          primaryText={schema.name}
          disabled={selected.has(schema.entityType)}
        />
      )}
      renderSelectedItem={(schema) => (
        <EntityItem
          key={schema.entityType}
          onClick={() => {
            const s = new Set(selected)
            s.delete(schema.entityType)
            onChange(s)
          }}
          primaryText={schema.name}
          removable={true}
        />
      )}
      selectedIds={selected}
      useQuery={useShotgridEntitySchemas}
    />
  )
}
