import { faGlobe, faLock } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const ReportStatus: FC<{ status: 'public' | 'private' }> = ({
  status,
}) => {
  const { t } = useTranslation()

  return (
    <span className="flex flex-row items-center text-xs font-medium text-neutral-600">
      <FontAwesomeIcon
        icon={status === 'public' ? faGlobe : faLock}
        className="mr-2"
      />
      {status === 'public'
        ? t('features.reporting.public')
        : t('features.reporting.private')}
    </span>
  )
}
