import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { adminManagedGroupsKeys } from '@lib/keys'
import {
  createAdminManagedGroup,
  // deleteAdminManagedGroup,
  getAdminManagedGroup,
  getAdminManagedGroups,
  updateAdminManagedGroup,
} from '@lib/api'

export const useAdminManagedGroups = () => {
  return useQuery(adminManagedGroupsKeys.all, getAdminManagedGroups)
}

export const useAdminManagedGroup = (
  id: number,
  {
    enabled,
    onSuccess,
    refetchOnWindowFocus,
  }: {
    enabled: boolean
    onSuccess?: (group: AdminManagedGroup) => void
    refetchOnWindowFocus?: boolean
  } = {
    enabled: true,
  },
) => {
  return useQuery(
    adminManagedGroupsKeys.get(id),
    () => getAdminManagedGroup(id),
    { enabled, onSuccess, refetchOnWindowFocus },
  )
}

export const useCreateAdminManagedGroup = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      adminManagedGroup,
    }: {
      adminManagedGroup: Omit<
        AdminManagedGroup,
        'creator' | 'updatedAt' | 'createdAt'
      >
    }) => {
      const { id, ...rest } = adminManagedGroup
      return createAdminManagedGroup(rest)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(adminManagedGroupsKeys.all)
      },
    },
  )
}

export const useUpdateAdminManagedGroup = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      id,
      adminManagedGroup,
    }: {
      id: number
      adminManagedGroup: Omit<
        AdminManagedGroup,
        'creator' | 'createdAt' | 'updatedAt'
      >
    }) => {
      return updateAdminManagedGroup(id, adminManagedGroup)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(adminManagedGroupsKeys.all)
      },
    },
  )
}

// export const useDeleteAdminManagedGroup = () => {
//   const queryClient = useQueryClient()

//   return useMutation(
//     ({ id }: { id: number }) => {
//       return deleteAdminManagedGroup(id)
//     },
//     {
//       onSuccess: async () => {
//         await queryClient.invalidateQueries(adminManagedGroupsKeys.all)
//       },
//     },
//   )
// }
