import { useFacilities } from '@hooks/useFacilities'
import { FC } from 'react'
import { ValuePicker } from '../value-picker'

interface Props {
  columnNameTranslationKey: TranslationKey
  onChange: (ids: Set<number>) => void
  selectedIds: Set<number>
}
export const FacilityPicker: FC<Props> = ({
  columnNameTranslationKey,
  onChange,
  selectedIds,
}) => {
  const { data: facilities = [] } = useFacilities()

  return (
    <ValuePicker
      accessor={(facility) => facility.id}
      columnNameTranslationKey={columnNameTranslationKey}
      onChange={onChange}
      renderLabel={(facility) => facility.name}
      selected={selectedIds}
      values={facilities}
    />
  )
}
