import { useEntries } from '@hooks/useEntries'
import { useMemo } from 'react'
import { useUserId } from './useUserId'
import { DateTime } from 'luxon'

export const useEntriesSumByTimeCard = (timeCardId: number | undefined) => {
  const userId = useUserId()
  const entriesQuery = useEntries({ userId })

  return useMemo(() => {
    if (entriesQuery.data === undefined) return 0

    return entriesQuery.data.reduce((sum, entry) => {
      if (entry.timeCard.id !== timeCardId) return sum

      return sum + entry.duration
    }, 0)
  }, [entriesQuery.data, timeCardId])
}

export const useEntriesSumByWeek = (start: DateTime, end: DateTime) => {
  const userId = useUserId()
  const entriesQuery = useEntries({ start, end, userId })

  return useMemo(() => {
    if (entriesQuery.data === undefined) return 0

    return entriesQuery.data
      .filter((entry) => entry.date <= DateTime.now())
      .reduce((sum, entry) => sum + entry.duration, 0)
  }, [entriesQuery.data])
}

export const useEntriesSumByDate = (dates: DateTime[]) => {
  const userId = useUserId()
  const entriesQuery = useEntries({ userId })

  return useMemo(() => {
    if (entriesQuery.data === undefined) return 0

    return entriesQuery.data
      .filter((entry) => {
        if (entry.date > DateTime.now()) return false

        return dates.some((date) => date.hasSame(entry.date, 'day'))
      })
      .reduce((sum, entry) => sum + entry.duration, 0)
  }, [entriesQuery.data, dates])
}

export const useEntriesSumByTask = (taskId: number) => {
  const userId = useUserId()
  const entriesQuery = useEntries({ userId })

  return useMemo(() => {
    if (entriesQuery.data === undefined) return 0

    return entriesQuery.data.reduce((sum, entry) => {
      if (entry.task.id !== taskId) return sum

      return sum + entry.duration
    }, 0)
  }, [entriesQuery.data, taskId])
}

export const useEntryByTimeCardAndTask = (
  timeCardId: number,
  taskId: number,
) => {
  const userId = useUserId()
  const entriesQuery = useEntries({ userId })

  return useMemo(() => {
    if (entriesQuery.data === undefined) return

    return entriesQuery.data.find(
      (entry) => entry.task.id === taskId && entry.timeCard.id === timeCardId,
    )
  }, [entriesQuery.data, timeCardId, taskId])
}
