import { FC } from 'react'
import { InfiniteScrollList } from 'components/containers/infinite-scroll-list'
import { NavItem, NavItemSkeleton } from '../../../components/NavItem'
import { useTranslation } from 'react-i18next'
import { useProjects } from '@hooks/useProjects'
import { ColumnHeading } from '../ColumnHeading'

interface Props {
  hidden: boolean
  onProjectClick: (project: Project) => void
  search?: string
}

export const Projects: FC<Props> = ({ hidden, onProjectClick }) => {
  const { t } = useTranslation()
  const projectsResult = useProjects()

  const projects = projectsResult.data ?? []
  const totalProjects = projects.length

  if (hidden) return null

  return (
    <>
      <ColumnHeading
        heading={t('common.projects')}
        className="mt-4 mb-2 mx-9"
      />
      <InfiniteScrollList
        totalItems={totalProjects}
        items={projects.map((project) => ({
          label: project.name,
          id: project.id,
          onClick: () => onProjectClick(project),
        }))}
        loadMore={() => undefined}
        isInitiallyLoading={projectsResult.isLoading}
        isFetching={projectsResult.isFetching || projectsResult.isLoading}
        renderItem={(item) => (
          <NavItem
            key={`${item.id ?? ''}=${item.label}`}
            label={item.label}
            onClick={item.onClick}
            showImage={false}
            skip={false}
          />
        )}
        expectedItemHeight={40}
        skeletonItem={<NavItemSkeleton />}
      />
    </>
  )
}
