import {
  faUserMinus,
  faUsers,
  faUserClock,
} from '@fortawesome/pro-regular-svg-icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonGroup } from './ButtonGroup'

export type FilterWorkersWithVisibleItems = 'all' | 'atLeastOne' | 'none'

export type FilterWorkerssWithVisibleItemsCounts = {
  [value in FilterWorkersWithVisibleItems]: number
}

interface Props {
  className?: string
  counts: FilterWorkerssWithVisibleItemsCounts
  onChange: (value: FilterWorkersWithVisibleItems) => void
  value: FilterWorkersWithVisibleItems
  itemType?: 'timeCard' | 'approval'
  loading?: boolean
}

export const FilterWorkerWithVisibleItemsButtonGroup: FC<Props> = ({
  className,
  counts,
  onChange,
  value,
  itemType = 'timeCard',
  loading,
}) => {
  const { t } = useTranslation()

  return (
    <ButtonGroup
      buttons={[
        {
          icon: faUsers,
          count: counts.all,
          onClick: () => onChange('all'),
          selected: value === 'all',
          tooltip: t('common.allWorkers'),
          loading,
        },
        {
          icon: faUserClock,
          count: counts.atLeastOne,
          onClick: () => onChange('atLeastOne'),
          selected: value === 'atLeastOne',
          tooltip: t(
            itemType === 'timeCard'
              ? 'common.workersWithVisibleTimeCards'
              : 'common.workersWithVisibleApprovals',
          ),
          loading,
        },
        {
          icon: faUserMinus,
          count: counts.none,
          onClick: () => onChange('none'),
          selected: value === 'none',
          tooltip: t(
            itemType === 'timeCard'
              ? 'common.workersWithoutVisibleTimeCards'
              : 'common.workersWithoutVisibleApprovals',
          ),
          loading,
        },
      ]}
      className={className}
    />
  )
}
