import { FC } from 'react'
import Row from './Row'
import { Total } from './Total'

interface Props {
  subtle: boolean
  totalInSeconds: number
  className?: string
  children?: React.ReactNode
}

export const TotalRow: FC<Props> = ({
  subtle,
  totalInSeconds,
  children,
  className,
}) => {
  return (
    <Row subtle={subtle} className={className}>
      <div className="relative flex flex-row items-center text-center">
        {children}
        <Total seconds={totalInSeconds} />
      </div>
    </Row>
  )
}
