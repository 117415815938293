import { DetailedHTMLProps, FC, LabelHTMLAttributes } from 'react'
import { twMerge } from '@lib/tailwind-merge'

export const Label: FC<
  DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>
> = ({ className, ...props }) => (
  <label
    {...props}
    className={twMerge(
      'flex flex-1 mb-1 text-xs font-medium text-neutral-400',
      className,
    )}
  />
)
