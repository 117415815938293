import { twMerge } from '@lib/tailwind-merge'
import { statusColors } from '@utils/statuses'
import { useLanguage } from '@hooks/useLanguage'
import { Link } from 'react-router-dom'
import { AutoTextSize } from 'auto-text-size'

export const StatusSegment = ({
  status,
  value,
  total,
  onClick,
  linkTo,
}: {
  status: Status
  value: number
  total: number
  onClick?: (status: Status, e: React.MouseEvent) => void
  linkTo?: string
}) => {
  const locale = useLanguage()

  if (value === 0) return null

  const props = {
    className: twMerge(
      `text-center border bg-${statusColors[status]}-200 border-${statusColors[status]}-500 min-w-[1rem] first-of-type:rounded-l last-of-type:rounded-r text-${statusColors[status]}-800 px-1 flex justify-center`,
      (onClick || linkTo) && 'cursor-pointer hover:shadow',
    ),
    style: { width: `${(value / total) * 100}%` },
    onClick: (e: React.MouseEvent) =>
      onClick !== undefined && onClick(status, e),
  }

  const textValue = (
    <AutoTextSize mode="oneline" maxFontSizePx={12}>
      {value.toLocaleString(locale)}
    </AutoTextSize>
  )

  if (linkTo) {
    return (
      <Link to={linkTo} {...props}>
        {textValue}
      </Link>
    )
  } else {
    return <div {...props}>{textValue}</div>
  }
}
