import { ReportTypeMap } from '@features/reporting/types'
import { useQuery } from '@tanstack/react-query'
import { getReportPage, GetReportPageOptions } from '../lib/api'
import { reportDataKeys } from '../lib/keys'

export const useReportPage = <RT extends keyof ReportTypeMap>(
  reportType: RT,
  options: GetReportPageOptions<RT> = {},
) => {
  /**
   * If we'd like, we can make use of `keepPreviousData` to prevent "jank" from
   * happening when `isLoading` and `success` are quickly changed.
   *
   * - AF 10/12/23
   */
  return useQuery(reportDataKeys(reportType).getPage(options), () =>
    getReportPage(reportType, options),
  )
}
