import { FC } from 'react'
import { Spinner } from 'components/loaders'
import { useParams } from 'react-router-dom'
import { Report } from '../components/report-content/Report'
import { useSavedReport } from '../hooks/useSavedReports'
import { SavedReport } from '../types'

interface Props {
  type: SavedReport['type']
}

export const ViewReport: FC<Props> = ({ type }) => {
  const { reportId } = useParams()
  const { data: savedReport } = useSavedReport(reportId ? Number(reportId) : 0)

  if (reportId !== undefined && savedReport === undefined) {
    return <Spinner />
  }

  return <Report savedReport={savedReport} type={type} />
}
