import { FC } from 'react'
import { NewHeader as PageHeader } from 'components/page-header'
import { useTranslation } from 'react-i18next'
import { MenuButton } from 'components/buttons'
import { ReportStatus } from '../ReportStatus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPenToSquare,
  faTrashCan,
  faFloppyDisks,
  faFloppyDisk,
} from '@fortawesome/pro-regular-svg-icons'

interface Props {
  favorite?: boolean
  title: string
  italicizeTitle?: boolean
  onEdit: () => void
  onFavoriteChange?: (isFavorite: boolean) => void
  onSave: () => void
  onSaveAs: () => void
  onDelete: () => void
  isPublic: boolean
  dirty: boolean
  canSave: boolean
  canEdit: boolean
}

export const Header: FC<Props> = ({
  title,
  italicizeTitle,
  dirty,
  isPublic,
  onSave,
  onSaveAs,
  onEdit,
  onDelete,
  canSave,
  canEdit,
}) => {
  const { t } = useTranslation()

  return (
    <PageHeader
      title={title}
      italicizeTitle={italicizeTitle}
      renderTitleSlot={() => {
        if (dirty)
          return (
            <span className="px-1 text-xs rounded bg-neutral-300 text-neutral-900">
              Unsaved
            </span>
          )

        /*
        return (
          <IconButton
            className={twMerge(
              favorite ? 'text-warning-500' : 'text-neutral-900',
            )}
            icon={favorite ? faStar : faStarOutlined}
            onClick={() => onFavoriteChange(!favorite)}
          />
        )
        */
      }}
      renderRightSlot={() => {
        return (
          <>
            <ReportStatus status={isPublic ? 'public' : 'private'} />
            <MenuButton
              className="ml-2"
              title={t('common.action')}
              menuItems={[
                {
                  content: (
                    <>
                      <FontAwesomeIcon icon={faFloppyDisk} className="mr-2" />
                      {t('common.save')}
                    </>
                  ),
                  onClick: onSave,
                  disabled: !canSave || !dirty,
                },
                {
                  content: (
                    <>
                      <FontAwesomeIcon icon={faFloppyDisks} className="mr-2" />
                      {t('common.saveAs')}
                    </>
                  ),
                  onClick: onSaveAs,
                },
                {
                  content: (
                    <>
                      <FontAwesomeIcon icon={faPenToSquare} className="mr-2" />
                      {t('common.edit')}
                    </>
                  ),
                  onClick: onEdit,
                  disabled: !canEdit,
                },
                {
                  content: (
                    <>
                      <FontAwesomeIcon icon={faTrashCan} className="mr-2" />
                      {t('common.delete')}
                    </>
                  ),
                  onClick: onDelete,
                  disabled: !canEdit,
                },
                // {
                //   content: (
                //     <>
                //       <FontAwesomeIcon icon={faPenToSquare} className="mr-2" />
                //       {t('common.saveAs')}
                //     </>
                //   ),
                //   onClick: handleSaveAsClick,
                //   disabled: !isPublic,
                // },
                // TODO: Share link
              ]}
            >
              {t('common.action')}
            </MenuButton>
          </>
        )
      }}
    />
  )
}
