import { Table, Column } from '@tanstack/react-table'
import { FC } from 'react'
import { TableTextFilterField } from './TableTextFilterField'
import { TableSelectFilterField } from './TableSelectFilterField'
import { useTranslation } from 'react-i18next'

interface Props {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  table: Table<any>
  column: Column<any>
  /* eslint-enable */
}

export const TableFilterField: FC<Props> = ({ table: _table, column }) => {
  const { t } = useTranslation()

  switch (column.columnDef.filterFn) {
    case 'includesString':
      // TODO: Placeholder should be specified on column def/keyed to column ID if we ever add multiple text-search columns
      return (
        <TableTextFilterField
          placeholder={t('components.tables.tableFilterField.search')}
          column={column}
        />
      )
    case 'equalsString': {
      const values: string[] = [
        ...new Set(
          column
            .getFacetedRowModel()
            .rows.map((r) => r.getValue<string | undefined | null>(column.id))
            .filter((v) => v) // Remove undefined/null
            .sort() as string[],
        ),
      ]

      return <TableSelectFilterField values={values} column={column} />
    }
  }

  return <></>
}
