import { FC, useState, memo } from 'react'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { RootColumn } from './root'
import { StackItem, NodeStackItem, Search } from './types'
import { Crumb } from '../../types'
import { useTranslation } from 'react-i18next'
import { NodeColumn } from './node-column/NodeColumn'
import { ProjectIntegrationsColumn } from './ProjectIntegrationsColumn'
import { getNonNodeStackItem } from './utils/getNonNodeStackItem'
import { validateSearch } from './utils/validateSearch'
import { createStackFromItem } from './utils/createStackFromItem'

interface Props {
  disabledTaskIds: Set<number>
  hidden: boolean
  onTaskClick: (task: SelectedTask, checked: boolean) => void
  selectedTaskIds: Set<number>
}

const initialStack: StackItem[] = [{ column: 'root' }]

export const AllTasks: FC<Props> = memo(
  ({ disabledTaskIds, hidden, onTaskClick, selectedTaskIds }) => {
    const { t } = useTranslation()
    const [stack, setStack] = useState<StackItem[]>(initialStack)

    const clearStack = () => setStack((s) => s.slice(0, 1))

    const pushToStack = (item: StackItem, { replace } = { replace: false }) =>
      setStack((s) => (replace ? [...s.slice(0, -1), item] : [...s, item]))

    const setActiveNodeColumnTab = (activeTab: NodeStackItem['activeTab']) => {
      const topOfStack = peepStack()

      if (topOfStack.column !== 'node') return

      pushToStack(
        {
          ...topOfStack,
          activeTab,
        },
        { replace: true },
      )
    }

    const navigateToNodeColumn = () => {
      pushToStack({
        column: 'node',
        activeTab: 'explore',
      })
    }

    const setSelectedItem = (
      item: { id: number; label: string } | { label: string },
    ) => {
      const topOfStack = peepStack()

      if ('id' in item && topOfStack.column !== 'node') {
        pushToStack({ ...topOfStack, selected: item }, { replace: true })
      } else if (topOfStack.column === 'node') {
        pushToStack(
          { ...topOfStack, column: 'node', selected: item },
          { replace: true },
        )
      } else {
        throw new Error('Unreachable. Mismatched item being pushed to stack')
      }
    }

    const setSearch = (value: string) => {
      const top = peepStack()
      const search: Search = { value }

      if (top.column === 'root') {
        search.error = validateSearch(
          value,
          t(
            'features.timeLogging.taskSelection.enterCharacterMinimumToBeginSearch',
          ),
        )
      }

      pushToStack({ ...top, search }, { replace: true })
    }

    const setNodeDisplaySearch = (displaySearch: boolean) => {
      const top = peepStack()

      pushToStack({ ...top, displaySearch }, { replace: true })
    }

    const peepStack = () => {
      const top = stack.at(-1)

      if (top) return top

      throw new Error(
        'Unreachable.  Stack should always have at least one element',
      )
    }

    const crumbs = stack.reduce<Crumb[]>((acc, stackItem, index, stack) => {
      if (stackItem.selected?.label === undefined) return acc // anything without a label (the active item) shouldn't be displayed

      const setLocation = () => {
        const nextStackItem = stack.at(index + 1)

        nextStackItem &&
          setStack([
            ...stack.slice(0, index + 1),
            { ...nextStackItem, selected: undefined }, // Clear selection
          ])
      }

      const onClick = index + 1 === stack.length - 1 ? undefined : setLocation

      return [...acc, { label: stackItem.selected.label, onClick }]
    }, [])

    const activeStackItem = peepStack()
    const activeColumn = activeStackItem.column

    const projectId = getNonNodeStackItem(stack, 'root')?.selected?.id
    const integrationId = getNonNodeStackItem(stack, 'project-integrations')
      ?.selected?.id

    const path = stack.reduce<string[]>((acc, s) => {
      if (s.column !== 'node') return acc
      if (!s.selected?.label) return acc

      return [...acc, s.selected.label]
    }, [])

    const handleMultisearchItemClick = (
      item: MultisearchResultItem,
      type: Exclude<keyof MultisearchResult, 'tasks'>,
    ) => {
      setStack((stack) => createStackFromItem(item, type, stack))
    }

    if (hidden) return null

    return (
      <div className="flex flex-col h-full">
        <Breadcrumbs
          hidden={activeColumn === 'root'}
          crumbs={crumbs}
          onHomeClick={clearStack}
        />
        {activeStackItem.column !== 'node' && (
          <RootColumn
            disabledTaskIds={disabledTaskIds}
            onProjectClick={(project) => {
              setSelectedItem({ id: project.id, label: project.name })
              pushToStack({ column: 'project-integrations' })
            }}
            onSearchChange={setSearch}
            onTaskClick={onTaskClick}
            onMultisearchItemClick={handleMultisearchItemClick}
            search={activeStackItem.search?.value}
            searchError={activeStackItem.search?.error}
            selectedTaskIds={selectedTaskIds}
            shown={activeColumn === 'root' || activeColumn === 'project'}
          />
        )}
        {projectId && activeStackItem.column !== 'node' && (
          <ProjectIntegrationsColumn
            heading={crumbs.at(-1)?.label}
            projectId={projectId}
            onItemClick={(projectIntegration) => {
              setSelectedItem({
                id: projectIntegration.id,
                label: projectIntegration.name,
              })
              navigateToNodeColumn()
            }}
            shown={activeColumn === 'project-integrations'}
            displaySearch={activeStackItem.displaySearch}
            onSearchToggle={setNodeDisplaySearch}
            onSearchChange={setSearch}
            searchValue={activeStackItem.search?.value}
          />
        )}
        {projectId && integrationId && activeStackItem.column === 'node' && (
          <NodeColumn
            heading={crumbs.at(-1)?.label}
            disabledTaskIds={disabledTaskIds}
            search={activeStackItem.search?.value}
            searchError={activeStackItem.search?.error}
            integrationId={integrationId}
            onItemClick={(item) => {
              setSelectedItem(item)
              navigateToNodeColumn()
            }}
            displaySearch={activeStackItem.displaySearch}
            onSearchToggle={setNodeDisplaySearch}
            onSearchChange={setSearch}
            onTabChange={setActiveNodeColumnTab}
            onTaskClick={onTaskClick}
            path={path}
            projectId={projectId}
            selectedTaskIds={selectedTaskIds}
            shown={activeColumn === 'node'}
            activeTab={
              activeStackItem?.column === 'node'
                ? activeStackItem?.activeTab
                : undefined
            }
          />
        )}
      </div>
    )
  },
)

AllTasks.displayName = 'AllTasks'
