import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { taskKeys } from '@lib/keys'
import { useUserId } from './useUserId'
import {
  deselectTask,
  getSelectedTasks,
  getAssignedTasks,
  selectTask,
  getNonProjectTasks,
} from '@lib/api'
import { useWeekDates } from '@hooks/useWeekDates'

export const useAssignedTasksQuery = ({ enabled } = { enabled: true }) => {
  const userId = useUserId()

  return useQuery(taskKeys.assigned(userId), () => getAssignedTasks(userId), {
    enabled,
  })
}

export const useSelectedTasks = () => {
  const userId = useUserId()
  const { start, end } = useWeekDates()

  return useQuery(taskKeys.selected(userId, start, end), () =>
    getSelectedTasks(userId, start, end),
  )
}

export const useSelectTask = () => {
  const userId = useUserId()
  const { start, end } = useWeekDates()
  const queryClient = useQueryClient()

  return useMutation(
    (taskId: number) => {
      return selectTask(userId, start, taskId)
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(
          taskKeys.selected(userId, start, end),
        )
        void queryClient.invalidateQueries(taskKeys.nonProject)
      },
    },
  )
}

export const useDeselectTask = () => {
  const userId = useUserId()
  const { start, end } = useWeekDates()
  const queryClient = useQueryClient()

  return useMutation(
    (taskId: number) => {
      return deselectTask(userId, start, taskId)
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(
          taskKeys.selected(userId, start, end),
        )
        void queryClient.invalidateQueries(taskKeys.nonProject)
      },
    },
  )
}

export const useNonProjectTasks = ({ enabled } = { enabled: true }) => {
  return useQuery(taskKeys.nonProject, getNonProjectTasks, { enabled })
}
