import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons'
import classNames from 'classnames'
import { useAddTimeCard } from '@features/time-logging/hooks/useAddTimeCard'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'components/loaders'

interface Props {
  date: DateTime
  className?: string
}

function LogTime(props: Props) {
  const addTimeCardMutation = useAddTimeCard(props.date)
  const { t } = useTranslation()

  return (
    <div
      data-testid="log-time"
      className={classNames(
        'flex justify-center items-start mt-2',
        props.className,
      )}
    >
      <button
        className="flex gap-2 items-center justify-center text-xs uppercase text-[#8D99A8] cursor-pointer"
        onClick={addTimeCardMutation.mutate}
        disabled={addTimeCardMutation.isLoading}
      >
        {addTimeCardMutation.isLoading ? (
          <Spinner />
        ) : (
          <FontAwesomeIcon icon={faCirclePlus} className="text-sm" />
        )}

        {t('features.timeLogging.logTime')}
      </button>
    </div>
  )
}

export default LogTime
