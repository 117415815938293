import { createIntegrationProjectLink } from '@lib/api'
import { adminProjectsKeys, sourceProjectsKeys } from '@lib/keys'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useCreateIntegrationProjectLink = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      projectId,
      projectIntegrationId,
      sourceProjectId,
    }: {
      projectId: number
      projectIntegrationId: number
      sourceProjectId: number
    }) =>
      createIntegrationProjectLink(
        projectId,
        projectIntegrationId,
        sourceProjectId,
      ),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(adminProjectsKeys.all)

        // source projects include a linked flag
        void queryClient.invalidateQueries(sourceProjectsKeys.all)
      },
    },
  )
}
