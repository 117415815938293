import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Annotation } from './Annotation'

interface Props {
  onClick: () => void
  variant: 'floating' | 'attachment'
}

export const Leave: FC<Props> = ({ onClick, variant }) => {
  const { t } = useTranslation()

  return (
    <Annotation position={variant} type="timeOff">
      <span onClick={onClick} className="cursor-pointer">
        {t('features.timeLogging.leave')}
      </span>
    </Annotation>
  )
}
