import { Button } from 'components/buttons/Button'
import { Header, Heading } from 'components/page-header'
import { FC, ReactNode } from 'react'
import { Navigation } from './Navigation'
import { useTranslation } from 'react-i18next'

interface Props {
  children: ReactNode
  onSaveButtonClick: () => void
  saveButtonDisabled: boolean
  subtitle: string
  title: string
}

export const Layout: FC<Props> = ({
  children,
  onSaveButtonClick,
  saveButtonDisabled,
  subtitle,
  title,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col flex-grow">
      <Header className="flex flex-row pb-2">
        <div className="flex flex-col flex-grow">
          <Heading
            breadcrumbs={[t('features.settings.system')]}
            title={t('features.settings.settings')}
          />
          <Navigation />
        </div>
        <Button onClick={onSaveButtonClick} disabled={saveButtonDisabled}>
          {t('common.save')}
        </Button>
      </Header>
      <div className="flex flex-col p-8 border-b bg-neutral-50 border-neutral-200">
        <h3 className="text-2xl font-medium text-neutral-900">{title}</h3>
        <p className="text-sm text-neutral-600">{subtitle}</p>
      </div>
      <div className="flex flex-col p-8">{children}</div>
    </div>
  )
}
