import { useCallback } from 'react'
import { useModal } from '@hooks/useModal'
import { NewApprovalGroupForm } from '../components/NewApprovalGroupForm'
import { useTranslation } from 'react-i18next'

export const useNewApprovalGroup = () => {
  const modal = useModal()
  const { t } = useTranslation()

  const newApprovalGroup = useCallback(() => {
    const id = modal.form({
      title: t('features.approvalGroupsAdmin.newApprovalGroup'),
      content: (
        <NewApprovalGroupForm
          onCancel={() => modal.close(id)}
          onSave={() => modal.close(id)}
        />
      ),
    })
  }, [modal, t])

  return { newApprovalGroup }
}
