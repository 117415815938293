const adminProjectId = 0

export const taskGroupKey = ({
  project,
  projectIntegration,
}: {
  project: Project
  projectIntegration?: ProjectIntegration
}) => {
  const groupedProjectId = project.isAdminProject ? adminProjectId : project.id

  return `${groupedProjectId}-${projectIntegration?.id ?? 0}`
}

const taskSortString = (task: SelectedTask) =>
  [...task.displayPath, task.name].join(' ')

export const groupTaskByProjectAndIntegrationId = (
  tasks: SelectedTask[],
): Record<string, SelectedTask[]> => {
  return tasks.reduce((acc, task) => {
    const groupKey = taskGroupKey(task)

    return {
      ...acc,
      // Group tasks by project ID and Project Integration ID,
      // consolidating all admin project into special
      // client-only non-project project (adminProject)
      // (still broken down by Project Integration)
      // Sorted alphabetically by display path (e.g. for SG tasks: linked entity name and pipeline step) + task name
      [groupKey]: [...(acc[groupKey] ?? []), task].sort((a, b) =>
        taskSortString(a).localeCompare(taskSortString(b)),
      ),
    }
  }, {} as ReturnType<typeof groupTaskByProjectAndIntegrationId>)
}

export const extractSortedProjectsAndProjectIntegrations = (
  groupedTasks: ReturnType<typeof groupTaskByProjectAndIntegrationId>,
  adminProject: Project,
): { project: Project; projectIntegration?: ProjectIntegration }[] => {
  return Object.keys(groupedTasks)
    .map<{
      project: Project
      projectIntegration?: ProjectIntegration
    }>((projectAndIntegrationId) => {
      const [projectIdString, integrationIdString] =
        projectAndIntegrationId.split('-')

      const projectId = Number(projectIdString)

      const firstTaskInGroup =
        groupedTasks[`${projectIdString}-${integrationIdString}`][0]

      const project =
        projectId === adminProject.id ? adminProject : firstTaskInGroup.project

      const projectIntegration = firstTaskInGroup.projectIntegration

      return { project, projectIntegration }
    })
    .sort((a, b) => {
      if (
        (a.project.id === b.project.id ||
          (a.project.isAdminProject && b.project.isAdminProject)) &&
        a.projectIntegration &&
        b.projectIntegration
      ) {
        // Same project or both admin projects,
        // sort by integration name
        return a.projectIntegration.name.localeCompare(
          b.projectIntegration.name,
        )
      } else {
        // Admin projects should always come last relative to
        // non-admin projects
        if (b.project.isAdminProject) return -1
        if (a.project.isAdminProject) return 1

        // Non-admin projects should be sorted by project name
        return a.project.name.localeCompare(b.project.name)
      }
    })
}
