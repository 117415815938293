import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FC } from 'react'
import { twMerge } from '@lib/tailwind-merge'
import { Crumb } from '../types'

interface Props {
  crumbs: Crumb[]
  hidden: boolean
  onHomeClick: () => void
}

export const Breadcrumbs: FC<Props> = ({ crumbs, hidden, onHomeClick }) => {
  if (hidden) return null

  const divider = (
    <FontAwesomeIcon
      icon={faChevronRight}
      size="2xs"
      className="text-neutral-500"
    />
  )

  return (
    <div className="flex flex-row flex-wrap items-center mb-6 text-neutral-700 px-9 gap-x-2 gap-y-1 font-medium">
      <button onClick={onHomeClick}>
        <FontAwesomeIcon icon={faHome} size="sm" />
      </button>
      {divider}
      {crumbs.map((crumb, index) => {
        const id = `${crumb.label}-${index}`
        const isClickable = Boolean(crumb.onClick)
        const lastCrumb = index === crumbs.length - 1

        return (
          <div key={id} className="flex items-center gap-2">
            <span className="flex items-center">
              <button
                key={id}
                onClick={crumb.onClick}
                className={twMerge(
                  'text-xs',
                  isClickable && 'hover:underline',
                  lastCrumb && 'text-neutral-400',
                )}
                disabled={!isClickable}
              >
                {crumb.label}
              </button>
            </span>

            {!lastCrumb && divider}
          </div>
        )
      })}
    </div>
  )
}
