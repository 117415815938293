import { useCallback, useEffect, useState, useMemo } from 'react'
import useKeyPress from './useKeyPress'

interface Options {
  enabled: boolean
  enableTabNavigation: boolean
  isFocusableCell: (cell: Cell) => boolean
}

export const NULL_CELL = { x: -1, y: -1 }

const defaultOptions: Options = {
  // Should keyboard navigation be enabled (events will not be attached unless this is true)
  enabled: true,

  // Should tab navigation be enabled (if false, tab presses will not be processed)
  enableTabNavigation: false,

  // Function to determine if a cell is focusable, defaults to always true
  isFocusableCell: () => true,
}

export const isSameCell = (a: Cell, b: Cell) => a.x === b.x && a.y === b.y

// Do not process tab presses when the target element
// is part of a form (this prevents obstructing tab behavior within a form)
const shouldProcessTabPress = (e: KeyboardEvent) =>
  !e.target || (e.target as HTMLElement).closest('form') == null

export const useKeyboardForGrid = (
  rows: number,
  columns: number,

  // IMPORTANT: options should be memoized to prevent re-triggering this hook on every re-render
  partialOptions: Partial<Options> = defaultOptions,
) => {
  const options = useMemo(
    () => ({ ...defaultOptions, ...partialOptions }),
    [partialOptions],
  )

  const [focusedCell, setFocusedCell] = useState<Cell>(NULL_CELL)
  const { keyPressed: downPress, repeat: downRepeating } = useKeyPress(
    'ArrowDown',
    {},
    { shouldAttachEvent: () => options.enabled },
  )
  const { keyPressed: upPress, repeat: upRepeating } = useKeyPress(
    'ArrowUp',
    {},
    { shouldAttachEvent: () => options.enabled },
  )
  const { keyPressed: rightPress, repeat: rightRepeating } = useKeyPress(
    'ArrowRight',
    {},
    { shouldAttachEvent: () => options.enabled },
  )
  const { keyPressed: leftPress, repeat: leftRepeating } = useKeyPress(
    'ArrowLeft',
    {},
    { shouldAttachEvent: () => options.enabled },
  )
  const { keyPressed: tabPress, repeat: tabRepeating } = useKeyPress(
    'Tab',
    {
      shift: false,
    },
    {
      shouldAttachEvent: () => options.enabled && options.enableTabNavigation,
      shouldProcessEvent: shouldProcessTabPress,
    },
  )
  const { keyPressed: shiftTabPress, repeat: shiftTabRepeating } = useKeyPress(
    'Tab',
    { shift: true },
    {
      shouldAttachEvent: () => options.enabled && options.enableTabNavigation,
      shouldProcessEvent: shouldProcessTabPress,
    },
  )

  const isOutOfBounds = useCallback(
    (cell: Cell) => {
      if (cell.x < 0 || cell.x >= columns) return true
      if (cell.y < 0 || cell.y >= rows) return true

      return false
    },
    [rows, columns],
  )

  const isFocusable = useCallback(
    (cell: Cell) => {
      if (isOutOfBounds(cell)) return false

      return options.isFocusableCell(cell)
    },
    [isOutOfBounds, options],
  )

  // Given a cell and an axis,
  // moves the cell along the provided axis to 0 if it was previously
  // at the NULL_CELL value
  const moveAxisOffNull = (cell: Cell, axis: keyof Cell) => {
    if (cell[axis] === NULL_CELL[axis]) return { ...cell, [axis]: 0 }
    return cell
  }

  const getNextFocusableCell = useCallback(
    ({
      prevCell,
      axis,
      mutation,
    }: {
      prevCell: Cell
      axis: keyof Cell
      mutation: number
    }) => {
      // Beginning with the desired cell,
      // check if the cell is focusable, and if it is, return it,
      // otherwise step 1 by 1 in the direction of the mutation
      // along the requested axis until a focusable cell is found,
      // or until the cell is out of bounds
      let nextCell = { ...prevCell, [axis]: prevCell[axis] + mutation }
      const step = mutation > 0 ? 1 : -1
      while (!isFocusable(nextCell)) {
        // If the next cell is out of bounds, we can't move, so just stay put
        if (isOutOfBounds(nextCell)) return prevCell

        nextCell = { ...nextCell, [axis]: nextCell[axis] + step }
      }

      return nextCell
    },
    [isFocusable, isOutOfBounds],
  )

  const moveDown = useCallback(() => {
    setFocusedCell((prevState) =>
      getNextFocusableCell({
        prevCell: moveAxisOffNull(prevState, 'x'),
        axis: 'y',
        mutation: 1,
      }),
    )
  }, [setFocusedCell, getNextFocusableCell])

  const focusCell = useCallback(
    (cell: Cell) => {
      setFocusedCell(cell)
    },
    [setFocusedCell],
  )

  const clearFocus = useCallback(() => {
    setFocusedCell(NULL_CELL)
  }, [setFocusedCell])

  useEffect(() => {
    if (downPress) {
      moveDown()
    }
  }, [moveDown, downPress, downRepeating])

  useEffect(() => {
    if (upPress) {
      setFocusedCell((prevState) =>
        getNextFocusableCell({
          prevCell: moveAxisOffNull(prevState, 'x'),
          axis: 'y',
          mutation: -1,
        }),
      )
    }
  }, [setFocusedCell, upPress, upRepeating, getNextFocusableCell])

  useEffect(() => {
    if (rightPress || tabPress) {
      setFocusedCell((prevState) =>
        getNextFocusableCell({
          prevCell: moveAxisOffNull(prevState, 'y'),
          axis: 'x',
          mutation: 1,
        }),
      )
    }
  }, [
    setFocusedCell,
    rightPress,
    tabPress,
    rightRepeating,
    tabRepeating,
    getNextFocusableCell,
  ])

  useEffect(() => {
    if (leftPress || shiftTabPress) {
      setFocusedCell((prevState) =>
        getNextFocusableCell({
          prevCell: moveAxisOffNull(prevState, 'y'),
          axis: 'x',
          mutation: -1,
        }),
      )
    }
  }, [
    setFocusedCell,
    leftPress,
    shiftTabPress,
    leftRepeating,
    shiftTabRepeating,
    getNextFocusableCell,
  ])

  return { clearFocus, focusCell, focusedCell, moveDown }
}
