import { createWorkdayProjectLink } from '@lib/api'
import { adminProjectsKeys } from '@lib/keys'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useCreateWorkdayProjectLink = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      projectId,
      workdayProjectId,
    }: {
      projectId: number
      workdayProjectId: string
    }) => createWorkdayProjectLink(projectId, workdayProjectId),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(adminProjectsKeys.all)
      },
    },
  )
}
