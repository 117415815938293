import classNames from 'classnames'
import { FC, ReactNode } from 'react'

interface Props {
  children: ReactNode
  loading?: boolean
  testId?: string
}

export const Section: FC<Props> = ({ children, loading = false, testId }) => (
  <div
    className={classNames('px-8 my-5', { 'fa-fade': loading })}
    data-testid={testId}
  >
    {children}
  </div>
)
