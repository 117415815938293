import { FC, useState, useMemo } from 'react'
import { HeaderSelector } from 'components/navigation/header-selector/HeaderSelector'
import { SearchSelection } from 'components/form/search-selection'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface Props {
  users: User[]
  onSelection?: () => void
}

export const UserPickerList: FC<Props> = ({ users, onSelection }) => {
  const [search, setSearch] = useState('')
  const { t } = useTranslation()

  const filteredUsers = useMemo(() => {
    return users.filter((user) => user.name.toLowerCase().includes(search))
  }, [users, search])

  return (
    <div className="flex flex-col absolute left-0 top-[110%]">
      <HeaderSelector
        title={t('features.timeLogging.changeUser')}
        lists={
          [
            {
              label: 'Users', // Not translated because this is only displayed when muiltiple lists are provided
              component: (
                <SearchSelection
                  key="users-list"
                  expectedItemHeight={36}
                  items={filteredUsers}
                  onSearch={setSearch}
                  autoFocusSearchField={true}
                  placeholder={t('common.searchByName')}
                  renderItem={(user, clickHandler) => (
                    <Link
                      className="py-2 text-sm text-neutral-900 hover:text-primary-600"
                      key={user.name}
                      onClick={() => {
                        onSelection && onSelection()
                        clickHandler()
                      }}
                      to={`/time-logger/user/${user.id}`}
                    >
                      {user.name}
                    </Link>
                  )}
                  totalItems={filteredUsers.length}
                  value={search}
                  variant="fixed-below"
                  searchFieldClassName="mb-2"
                />
              ),
            },
          ] as const
        }
      />
    </div>
  )
}
