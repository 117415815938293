import { Navigate, useRoutes } from 'react-router-dom'
import { unauthenticatedRoutes } from './unauthenticated'
import { authenticatedRoutes } from './authenticated'
import { FC } from 'react'
import { wrapUseRoutes } from '@sentry/react'

const useSentryRoutes = wrapUseRoutes(useRoutes)

export const AppRoutes: FC = () => {
  const commonRoutes = [
    {
      path: '/',
      element: <Navigate to={'/time-logger'} replace={true} />,
    },
    {
      path: '*',
      element: <Navigate to="/login" replace={true} />,
    },
  ]

  const element = useSentryRoutes([
    ...commonRoutes,
    ...unauthenticatedRoutes,
    ...authenticatedRoutes,
  ])

  return <>{element}</>
}
