import { FC } from 'react'
import project from 'assets/project.svg'
import { secondsToTimestamp } from '@utils/time'
import { TaskItem } from './types'
import { useTranslation } from 'react-i18next'

interface Props {
  name: string
  tasks: TaskItem[]
  taggedTime?: TaggedTime
}

export const Project: FC<Props> = ({ name, tasks }) => {
  const { t } = useTranslation()
  const total = secondsToTimestamp(
    tasks.reduce((sum, { secondsLogged }) => sum + secondsLogged, 0),
  )

  return (
    <div className="flex flex-col mt-6">
      <div className="flex flex-row items-center mb-2 text-xs font-medium uppercase text-neutral-600">
        <img src={project} className="w-8 h-8 mr-2" />
        <h3>{name}</h3>
      </div>
      {tasks.map((task, i) => (
        <div
          key={`${task.name}-${i}`}
          className="flex flex-row items-center justify-between py-1 border-b border-b-neutral-300"
        >
          <div className="flex flex-col">
            <span className="text-xs font-medium text-neutral-600">
              {task.label}
            </span>
            <span className="text-sm font-semibold text-neutral-900">
              {task.name}
            </span>
          </div>
          <span className="text-sm font-semibold text-neutral-900">
            {secondsToTimestamp(task.secondsLogged)}
          </span>
        </div>
      ))}
      <div className="flex flex-row justify-between py-1">
        <span className="text-sm font-medium text-neutral-600">
          {t('common.total')}
        </span>
        <div className="flex flex-col">
          <span className="text-sm font-semibold text-neutral-900">
            {total}
          </span>
        </div>
      </div>
    </div>
  )
}
