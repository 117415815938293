import { TFunction } from 'react-i18next'

const dateRangeWithIntlFallback = (
  startDate: DateTime,
  endDate: DateTime,
  locale: string,
  formatOptions: Intl.DateTimeFormatOptions,
) => {
  const dateTimeFormat = new Intl.DateTimeFormat(locale, formatOptions)

  // Our target JS version is ES2015 which doesn't include formatRange,
  // so TS doesn't include its types either. We're doing a few things here:
  // - Silencing the TS error and related eslint warnings
  // - Checking if the browser supports formatRange, and if not, falling back to a basic range format
  // eslint-disable-next-line
  // @ts-ignore
  if (typeof dateTimeFormat.formatRange === 'function') {
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    return dateTimeFormat.formatRange(
      startDate.toJSDate(),
      endDate.toJSDate(),
    ) as string
  } else {
    // Fallback if browser doesn't support formatRange
    return `${startDate.toLocaleString(formatOptions, {
      locale,
    })} - ${endDate.toLocaleString(formatOptions, { locale })}`
  }
}

export const renderDateRange = (
  startDate: DateTime | undefined,
  endDate: DateTime | undefined,
  t: TFunction<'translation', undefined>,
  locale: string,
  formatOptions: Intl.DateTimeFormatOptions,
) => {
  if (startDate && endDate) {
    // If both a start and end date are set,
    // and they are the same day, only display the one date
    if (startDate.hasSame(endDate, 'day'))
      return startDate.toLocaleString(formatOptions, { locale })

    // Otherwise, display the date range,
    // first preferencing a more succinct format,
    // but falling back on a more verbose one if the browser doesn't support it.
    return dateRangeWithIntlFallback(startDate, endDate, locale, formatOptions)
  }

  // If only an end date is set, display "up to" that date
  if (endDate)
    return t('common.upTo', {
      value: endDate.toLocaleString(formatOptions, { locale }),
    })

  // If only a start date is set, display "and after" that date
  if (startDate)
    return t('common.andAfter', {
      date: startDate.toLocaleString(formatOptions, { locale }),
    })

  // If no date is set, then display "All time"
  return t('common.allTimeDateRange')
}
