import { FC } from 'react'
import { EntityPicker, EntityItem } from '../entity-picker'
import { useUsers } from 'hooks/useUsers'
import { useTranslation } from 'react-i18next'

interface Props {
  columnNameTranslationKey: TranslationKey
  onChange: (ids: Set<number>) => void
  selectedIds: Set<number>
}
export const UserPicker: FC<Props> = ({
  columnNameTranslationKey,
  onChange,
  selectedIds,
}) => {
  const { t } = useTranslation()

  return (
    <EntityPicker
      className="w-96"
      columnNameTranslationKey={columnNameTranslationKey}
      filter={(user, search) => {
        const s = search.toLowerCase()

        return (
          user.email.toLowerCase().includes(s) ||
          user.name.toLowerCase().includes(s)
        )
      }}
      placeholder={t('common.searchByName')}
      isSelected={(user, selectedIds) => selectedIds.has(user.id)}
      renderSearchItem={(user) => (
        <EntityItem
          key={user.id}
          primaryText={user.name}
          secondaryText={user.email}
          onClick={() => onChange(new Set(selectedIds).add(user.id))}
          disabled={selectedIds.has(user.id)}
        />
      )}
      renderSelectedItem={(user) => (
        <EntityItem
          key={user.id}
          primaryText={user.name}
          onClick={() => {
            const s = new Set(selectedIds)
            s.delete(user.id)
            onChange(s)
          }}
          removable={true}
          secondaryText={user.email}
        />
      )}
      selectedIds={selectedIds}
      useQuery={useUsers}
    />
  )
}
