import { useEffect } from 'react'

/**
 * I noticed that if the ref or handler change very frequently, the browser
 * chokes on the number of calls to addEventListener and removeEventListener.
 * As a result, it's recommended that the handler prop is memoized so that its
 * reference doesn't change too often; thus, triggering too many calls to
 * addEventListener and removeEventListener
 */
export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: React.RefObject<T>,
  handler: (event: MouseEvent | TouchEvent) => void,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) return

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    const removeEventListeners = () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }

    return removeEventListeners
  }, [ref, handler])
}
