import { Status } from 'types'

export const getApprovalStatus = (
  approval: Pick<Approval, 'timeCard' | 'approved'>,
) => {
  if (approval.timeCard.sent_to_payroll) return Status.Sent
  if (approval.approved) return Status.Approved
  if (approval.timeCard.submitted) return Status.Submitted
  return Status.Open
}
