import { secondsToHHMM } from '@utils/time'
import { FC, useState } from 'react'
import { Display, GridCell } from 'components/time-entry-table'
import { IncrementDecrementButtons } from './IncrementDecrementButtons'
import { EntryInput } from 'components/time-entry-table/EntryInput'

type Props = {
  day: DayOfWeek
  duration: number
  editable: boolean
  isError?: boolean
  isFocused?: boolean
  onChange: (duration: number) => void
  onMouseMove?: () => void
  onMouseLeave?: () => void
  rowIndex: number
}

export const DurationCell: FC<Props> = ({
  day,
  duration,
  editable,
  isError = false,
  isFocused = false,
  onChange,
  onMouseMove = () => undefined,
  onMouseLeave = () => undefined,
  rowIndex,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isTyping, setIsTyping] = useState(false)

  return (
    <GridCell
      day={day}
      rowIndex={rowIndex}
      editable={editable}
      isEditing={isTyping}
      isError={isError}
      isFocused={isFocused}
      onMouseMove={() => {
        setIsHovered(true)
        onMouseMove()
      }}
      onMouseLeave={() => {
        setIsHovered(false)
        onMouseLeave()
      }}
      onClick={() => setIsTyping(true)}
    >
      {editable && isHovered && !isTyping && (
        <IncrementDecrementButtons
          className="mr-auto"
          decrementDisabled={duration <= 0}
          onDecrement={() => onChange(Math.max(0, duration - 900))}
          onIncrement={() => onChange(duration + 3600)}
        />
      )}
      {editable && isTyping && (
        <EntryInput
          initialValue={secondsToHHMM(duration)}
          onCancel={() => setIsTyping(false)}
          onSave={(newDuration) => {
            onChange(newDuration)
            setIsTyping(false)
          }}
        />
      )}
      {!isTyping && <Display contents={secondsToHHMM(duration)} />}
    </GridCell>
  )
}
