import { Spinner } from 'components/loaders'
import { FC, ReactNode, memo } from 'react'
import { twMerge } from '@lib/tailwind-merge'

interface BaseProps {
  body: ReactNode
  footer?: ReactNode
  header?: ReactNode
  rootClassName?: string
  tableClassName?: string
}

type Props =
  | BaseProps
  | (BaseProps & { numOfColumns: number; isPending: boolean })

export const ScrollableTable: FC<Props> = memo(function ScrollableTable({
  body,
  footer,
  header,
  rootClassName,
  tableClassName,
  ...props
}) {
  return (
    <div className={twMerge('relative z-0 flex-grow mx-8', rootClassName)}>
      <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col overflow-auto">
        <table
          className={twMerge(
            'min-w-full text-sm border-l border-separate border-spacing-0 border-neutral-300',
            tableClassName,
          )}
        >
          {header && (
            <thead
              className="sticky z-20 shadow"
              style={{ insetBlockStart: 0 }}
            >
              {header}
            </thead>
          )}
          <tbody>
            {'isPending' in props && props.isPending ? (
              <tr>
                <td colSpan={props.numOfColumns} className="p-6 text-center">
                  <Spinner className="text-2xl" />
                </td>
              </tr>
            ) : (
              body
            )}
          </tbody>
          {footer && (
            <tfoot className="sticky" style={{ insetBlockEnd: 0 }}>
              {footer}
            </tfoot>
          )}
        </table>
      </div>
    </div>
  )
})
