import React, { ReactNode } from 'react'
import * as Sentry from '@sentry/react'
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
  errorMessage?: string
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorMessage: error && error.message }
  }

  componentDidCatch(error: Error): void {
    Sentry.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="flex flex-col w-1/2 min-w-[250px] min-h-[250px] p-2">
            <div className="flex flex-row p-2 mb-2 rounded bg-error-200 border-error-500">
              <span className="font-bold">
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="mr-2"
                />{' '}
                Error
              </span>
            </div>
            <div className="flex flex-col text-center space-y-2">
              <p>
                There has been an error. It has been logged, and the maintainers
                are aware of it
              </p>
              <p>
                It&apos;s possible the error is the result of an outdated
                browser. Please be sure you&apos;re utilizing a modern,
                up-to-date browser to access Time Logger
              </p>
            </div>
            {this.state.errorMessage && (
              <code className="p-2 mt-8 rounded bg-neutral-200">
                {this.state.errorMessage}
              </code>
            )}
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
