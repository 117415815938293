interface GroupedApprovalsMap {
  [workdayWorkerId: number]: {
    [approvalDomainId: string]: {
      approvalDomain: ApprovalDomain
      approvals: Approval[]
    }
  }
}

// Create object map of workers, domains, and approvals
// {
//    [workdayWorkerId]: {
//      [approvalDomainId]: {
//        approvalDomain: ApprovalDomain,
//        approvals: Approval[]
//      }
//    }
export const groupApprovalsByDomainAndWorker = (approvals: Approval[]) => {
  return approvals.reduce<GroupedApprovalsMap>((acc, approval) => {
    let workerEntry = acc[approval.timeCard.workdayWorkerId]

    if (workerEntry === undefined) {
      workerEntry = {}
      acc[approval.timeCard.workdayWorkerId] = workerEntry
    }

    let domainEntry = workerEntry[approval.domain.id]

    if (domainEntry === undefined) {
      domainEntry = {
        approvalDomain: approval.domain,
        approvals: [],
      }
      workerEntry[approval.domain.id] = domainEntry
    }

    domainEntry.approvals.push(approval)

    return acc
  }, {})
}
