import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { noop } from '@utils/noop'
import { Button, IconButton } from 'components/buttons'
import { FC, ReactNode, useState } from 'react'
import { twMerge } from '@lib/tailwind-merge'

export interface Props {
  variant?: 'informational' | 'form' | 'custom'
  closeOnBackgroundClick?: boolean
  content?: ReactNode
  dismissable?: boolean
  hidden: boolean
  onClose?: () => void
  onPrimaryButtonClick?: (() => void) | (() => Promise<unknown>)
  onSecondaryButtonClick?: () => void
  primaryButtonPendingText?: string
  primaryButtonText?: string
  primaryButtonClassName?: string
  secondaryButtonText?: string
  title?: string
}

export const Modal: FC<Props> = ({
  variant = 'informational',
  closeOnBackgroundClick = true,
  content,
  dismissable = true,
  hidden,
  onClose = noop,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonPendingText,
  primaryButtonText,
  primaryButtonClassName,
  secondaryButtonText,
  title,
}) => {
  const [isPending, setIsPending] = useState(false)

  const handlePrimaryButtonClick = () => {
    if (!onPrimaryButtonClick) return

    setIsPending(true)
    const result = onPrimaryButtonClick() ?? {
      finally: (cb: () => unknown) => cb(),
    }
    result.finally(() => setTimeout(() => setIsPending(false), 2000))
  }

  if (hidden) return null

  return (
    <div
      data-testid="modal-background"
      onClick={(event) => {
        event.preventDefault()
        if (event.target === event.currentTarget && closeOnBackgroundClick)
          onClose()
      }}
      className="fixed top-0 bottom-0 left-0 right-0 z-[1000] flex flex-col items-center justify-center bg-neutral-900/70"
    >
      <div
        className={twMerge(
          'relative flex flex-col bg-white rounded-2xl drop-shadow',
          variant === 'informational' && 'w-[400px] items-center',
          variant === 'form' && 'min-w-[400px]',
          variant === 'custom' && 'min-w-[400px]',
        )}
      >
        <div className="flex justify-between w-full p-3">
          {(variant === 'form' || variant === 'custom') && title && (
            <h1 className="px-3 py-2 text-lg font-semibold">{title}</h1>
          )}
          <IconButton
            hidden={!dismissable}
            className="ml-auto text-neutral-700"
            icon={faTimes}
            onClick={onClose}
          />
        </div>

        {variant === 'informational' && (
          <div className="flex flex-col items-center px-8 pb-10">
            {title && (
              <h1 className="text-lg font-semibold text-center text-neutral-900">
                {title}
              </h1>
            )}
            {content && (
              <span className="mt-2 text-sm text-center text-neutral-700">
                {content}
              </span>
            )}

            {onPrimaryButtonClick && primaryButtonText && (
              <Button
                className={twMerge(
                  'w-full h-10 mt-12 text-sm',
                  primaryButtonClassName,
                )}
                disabled={isPending}
                onClick={handlePrimaryButtonClick}
                autoFocus={true}
              >
                {isPending && primaryButtonPendingText
                  ? primaryButtonPendingText
                  : primaryButtonText}
              </Button>
            )}
            {onSecondaryButtonClick && secondaryButtonText && (
              <Button
                variant="subtle"
                className="h-5 mt-4 text-sm font-normal font-weight-400 text-neutral-700"
                onClick={onSecondaryButtonClick}
              >
                {secondaryButtonText}
              </Button>
            )}
          </div>
        )}
        {variant === 'form' && content}
        {variant === 'custom' && content}
      </div>
    </div>
  )
}
