import { FC } from 'react'
import { Header } from '../components/Header'
import { useEmployees } from '@hooks/useEmployee'
import { Table } from '../components/Table'
import { Spinner } from 'components/loaders'
import { useProjectIntegrations } from '@hooks/useProjectIntegrations'

export const EmployeesAdmin: FC = () => {
  const { data: employees } = useEmployees()
  const { data: projectIntegrations } = useProjectIntegrations()

  return (
    <div className="flex flex-col flex-grow">
      <Header />

      {employees && projectIntegrations ? (
        <Table
          projectIntegrations={projectIntegrations}
          employees={employees}
        />
      ) : (
        <Spinner />
      )}
    </div>
  )
}
