import { Period } from './types'

export const coerceTo24Hour = (hour: number, period?: Period) => {
  if (hour === 12 && period === 'am') return 0
  if (hour === 12 && period === 'pm') return 12
  if (period === 'pm') return hour + 12

  return hour
}

export const convertTo12Hour = (hour: number) => {
  if (hour === 0) return 12
  if (hour > 12) return hour % 12

  return hour
}
