import { useCallback, useState } from 'react'
import { ReportTypeMap } from '../types'

export interface Sorted {
  column: ReportTypeMap[keyof ReportTypeMap]['columnId']
  direction: 'asc' | 'desc'
}

export const useSort = <RT extends keyof ReportTypeMap>(
  defaultSort: Sorted,
) => {
  const [sorted, setSorted] = useState<Sorted>(defaultSort)

  const toggleColumnSort = useCallback(
    (column: ReportTypeMap[RT]['columnId']) =>
      setSorted((sorted) => {
        // If the column is different, sort ascending
        if (sorted.column !== column) return { column, direction: 'asc' }

        // If the column is the same, toggle the direction
        return {
          column,
          direction: sorted.direction === 'asc' ? 'desc' : 'asc',
        }
      }),
    [setSorted],
  )

  return { sorted, setSorted, toggleColumnSort }
}
