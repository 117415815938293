import { FC, useCallback, useState } from 'react'
import { useCreateIntegrationProjectLink } from '../hooks/useCreateIntegrationProjectLink'
import { useTranslation } from 'react-i18next'
import { toast } from '@lib/toasts'
import { ModalForm } from 'components/notifications'
import { useSourceProjects } from '@features/admin/hooks/useSourceProjects'
import { RemoteProjectPicker } from './RemoteProjectPicker'
import { faRemove } from '@fortawesome/pro-solid-svg-icons'
import { IconButton } from 'components/buttons'

interface Props {
  onCancel: () => void
  onSave: () => void
  project: AdminProject
  projectIntegration: ProjectIntegration
}

export const LinkIntegrationProjectForm: FC<Props> = ({
  onCancel,
  onSave,
  project,
  projectIntegration,
}) => {
  const { t } = useTranslation()
  const createIntegrationProjectLink = useCreateIntegrationProjectLink()
  const integrationProjectsQuery = useSourceProjects(projectIntegration.id)

  const [sourceProject, setSourceProject] = useState<SourceProject | null>(null)

  const save = useCallback(async () => {
    try {
      if (sourceProject === null) return

      await createIntegrationProjectLink.mutateAsync({
        projectId: project.id,
        projectIntegrationId: projectIntegration.id,
        sourceProjectId: sourceProject.id,
      })

      onSave()

      toast({
        title: t('features.projectsAdmin.projectLinked'),
        variant: 'success',
        content: t(
          'features.projectsAdmin.successfullyLinkedIntegrationProject',
          {
            sourceProjectName: sourceProject.name,
            integrationName: projectIntegration.name,
            projectName: project.name,
          },
        ),
      })
    } catch (error) {
      toast({
        title: t('features.projectsAdmin.unableToLinkProject'),
        variant: 'error',
        content: t('features.projectsAdmin.failedToLinkProject'),
      })
    }
  }, [
    onSave,
    createIntegrationProjectLink,
    project,
    projectIntegration,
    t,
    sourceProject,
  ])

  const formFields = (
    <>
      {sourceProject ? (
        <div className="flex justify-between">
          {sourceProject.name}
          <IconButton icon={faRemove} onClick={() => setSourceProject(null)} />
        </div>
      ) : (
        <RemoteProjectPicker
          projectsQuery={integrationProjectsQuery}
          onSelect={setSourceProject}
          itemDisabled={(sourceProject) => sourceProject.linked}
        />
      )}
    </>
  )

  return (
    <ModalForm
      fields={formFields}
      onCancel={() => onCancel()}
      onSave={() => void save()}
      saveDisabled={sourceProject === null}
      isSaving={createIntegrationProjectLink.isLoading}
      saveButtonText={t('features.projectsAdmin.linkProject')}
    />
  )
}
