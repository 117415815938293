import { useCallback } from 'react'
import { useModal } from '@hooks/useModal'
import { NewProjectForm } from '../components/NewProjectForm'
import { useTranslation } from 'react-i18next'

export const useNewProject = () => {
  const modal = useModal()
  const { t } = useTranslation()

  const newProject = useCallback(() => {
    const id = modal.form({
      title: t('features.projectsAdmin.addProject'),
      content: (
        <NewProjectForm
          onCancel={() => modal.close(id)}
          onSave={() => modal.close(id)}
        />
      ),
    })
  }, [modal, t])

  return { newProject }
}
