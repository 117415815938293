export const SUPPORTED_LANGUAGES = ['en', 'fr', 'ko', 'de'] as const

export const deriveLanguage = (languageSearchString = '') => {
  const launguageSearch = languageSearchString.toLowerCase().trim()
  return SUPPORTED_LANGUAGES.find((supportedLanguage) =>
    launguageSearch.startsWith(supportedLanguage),
  )
}

// NOTE: Temporarily disabling browser language setting support until we announce behavior change
export const determineBrowserLanguage = () => 'en'
// deriveLanguage(navigator.languages[0]) ?? 'en'
