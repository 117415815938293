import {
  DateFilter,
  NumericRangeFilter,
  SearchFilter,
  ReportFilter,
  ValueFilter,
} from '@lib/api'
import { dateRangeForRelativeValue } from '@utils/dateRangeForRelativeValue'
import { ReportTypeMap } from '../types'
import {
  isSearchQuery,
  isDateRangeFilter,
  isValueFilter,
  isNumericRangeFilter,
} from './filterTypes'

export const convertToApiFilter = <RT extends keyof ReportTypeMap>(
  filter: ReportTypeMap[RT]['filters'],
): ReportFilter<RT> => {
  return Object.entries(filter).reduce<ReportFilter<RT>>((acc, [column, v]) => {
    if (v === undefined) return acc

    if (isSearchQuery(v))
      return [
        ...acc,
        {
          column,
          type: 'search_query',
          query: v.query,
        } as SearchFilter<RT>,
      ]

    if (isDateRangeFilter(v)) {
      if (v.relativeDateRange !== undefined) {
        const { startDate, endDate } = dateRangeForRelativeValue(
          v.relativeDateRange,
        )

        return [
          ...acc,
          {
            column,
            type: 'date',
            startDate,
            endDate,
          } as DateFilter<RT>,
        ]
      } else {
        return [
          ...acc,
          {
            column,
            type: 'date',
            startDate: v.start,
            endDate: v.end,
          } as DateFilter<RT>,
        ]
      }
    }

    if (isValueFilter(v))
      return [
        ...acc,
        {
          column,
          type: 'value',
          selected: v.values,
        } as ValueFilter<RT>,
      ]

    if ('statuses' in v)
      return [
        ...acc,
        {
          column,
          type: 'value',
          selected: v.statuses,
        } as ValueFilter<RT>,
      ]

    if (isNumericRangeFilter(v))
      return [
        ...acc,
        {
          column,
          type: 'numeric_range',
          from: v.numericRangeFrom,
          to: v.numericRangeTo,
        } as NumericRangeFilter<RT>,
      ]

    return acc
  }, [])
}
