import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, useRef } from 'react'
import { Column } from '@tanstack/react-table'

interface Props {
  placeholder: string
  column: Column<unknown>
}

// TODO: Debounce input
export const TableTextFilterField: FC<Props> = ({ placeholder, column }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <div
      className="border-neutral-400 border-[1.5px] rounded font-normal flex items-center px-3 gap-1.5 bg-white mt-3"
      // Don't propagate clicks, otherwise we'll trigger column sorting
      onClick={(e) => e.stopPropagation()}
    >
      <FontAwesomeIcon
        icon={faSearch}
        className="text-neutral-500"
        onClick={() => inputRef.current?.focus()}
      />
      <input
        ref={inputRef}
        type="text"
        className="grow border-none bg-transparent focus:ring-0 text-xs px-0 py-2 placeholder:text-neutral-600 text-neutral-900"
        onChange={(e) => column.setFilterValue(e.target.value)}
        placeholder={placeholder}
        value={(column.getFilterValue() as string | undefined) || ''}
      />
    </div>
  )
}
