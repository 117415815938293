import { useDebounce } from '@hooks/useDebounce'
import { useSearchExploreTasks } from '@hooks/useSearchTasks'
import { FC, ReactNode } from 'react'
import { NavItem } from '../../../components/NavItem'
import { Column } from '../Column'
import { getItemsAndTotalCount } from '../utils/getItemsAndTotalCount'

interface Props {
  children: ReactNode
  integrationId: number
  onItemClick: (item: { label: string }) => void
  onItemCountChange: (count?: number) => void
  onSearchChange: (search: string) => void
  path: string[]
  projectId: number
  search?: string
  searchError?: string
  shown: boolean
  heading?: string
  displaySearch?: boolean
  onSearchToggle: (displayed: boolean) => void
}

export const ExploreColumn: FC<Props> = ({
  children,
  integrationId,
  onItemClick,
  onItemCountChange,
  onSearchChange,
  path,
  projectId,
  search = '',
  searchError,
  shown,
  heading,
  displaySearch,
  onSearchToggle,
}) => {
  const debounced = useDebounce(search)

  const searchResult = useSearchExploreTasks({
    integrationId,
    projectId,
    q: debounced,
    subpath: path,
    onCountChange: onItemCountChange,
  })

  if (!shown) return null

  const { items, totalItems } = getItemsAndTotalCount(searchResult)

  return (
    <Column
      heading={heading}
      displaySearch={displaySearch}
      onSearchToggle={onSearchToggle}
      isFetching={searchResult.isFetchingNextPage || searchResult.isLoading}
      isInitiallyLoading={searchResult.isLoading}
      items={items}
      loadMoreItems={() => void searchResult.fetchNextPage()}
      onSearchChange={onSearchChange}
      renderItem={(item) => (
        <NavItem
          key={item.label}
          label={item.label}
          onClick={() => onItemClick(item)}
          showImage={false}
        />
      )}
      searchError={searchError}
      searchValue={search}
      totalItems={totalItems}
    >
      {children}
    </Column>
  )
}
