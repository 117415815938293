import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { IconButton } from 'components/buttons'
import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
  className?: string
  decrementDisabled: boolean
  onDecrement: () => void
  onIncrement: () => void
}

export const IncrementDecrementButtons: FC<Props> = ({
  className,
  decrementDisabled,
  onDecrement,
  onIncrement,
}) => {
  return (
    <div
      className={twMerge('flex flex-row items-center h-[30px]', className)}
      // Clicking enters edit mode, so we want to
      // stop propagation here to avoid entering edit mode on rapid clicking
      onClick={(e) => e.stopPropagation()}
    >
      <IconButton
        onClick={onDecrement}
        icon={faMinus}
        disabled={decrementDisabled}
        className="hover:bg-neutral-300 active:bg-neutral-300"
      />
      <div className="py-2 h-1/2 mx-1 w-[1px] border-r border-neutral-300"></div>
      <IconButton
        onClick={onIncrement}
        icon={faPlus}
        className="hover:bg-neutral-300 active:bg-neutral-300"
      />
    </div>
  )
}
