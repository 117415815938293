import { useCallback } from 'react'
import { useModal } from '@hooks/useModal'
import { AssignProjectApproversForm } from '../components/AssignProjectApproversForm'
import { useTranslation } from 'react-i18next'

export const useAssignApprovers = () => {
  const modal = useModal()
  const { t } = useTranslation()

  const assignApprovers = useCallback(
    (project: AdminProject) => {
      const id = modal.form({
        title: t('features.projectsAdmin.manageApprovers', {
          name: project.name,
        }),
        content: (
          <AssignProjectApproversForm
            onCancel={() => modal.close(id)}
            onSave={() => modal.close(id)}
            project={project}
          />
        ),
      })
    },
    [modal, t],
  )

  return { assignApprovers }
}
