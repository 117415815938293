import { ReportsList } from './ReportsList'
import { ViewReport } from './ViewReport'

export const reportingRoutes = [
  {
    path: '/reporting/time_log_reports',
    element: <ReportsList type="time_log" />,
  },
  {
    path: '/reporting/time_log_reports/new',
    element: <ViewReport type="time_log" />,
  },
  {
    path: '/reporting/time_log_reports/:reportId',
    element: <ViewReport type="time_log" />,
  },
  {
    path: '/reporting/audit_log_reports',
    element: <ReportsList type="audit_log" />,
  },
  {
    path: '/reporting/audit_log_reports/new',
    element: <ViewReport type="audit_log" />,
  },
  {
    path: '/reporting/audit_log_reports/:reportId',
    element: <ViewReport type="audit_log" />,
  },
]
