import { FC } from 'react'
import { useDateTimeToLocaleString as useDateTimeToLocaleStringImported } from 'hooks/useDateTimeWithLocale'
import { DateInput } from './components/DateInput'
import { MonthCalendar } from 'components/navigation/month-calendar'
import { DateTime } from 'luxon'

export interface Props {
  clearTitle?: string
  endDate?: DateTime
  initiallyDisplayedMonth?: DateTime
  onChange: (value: { startDate?: DateTime; endDate?: DateTime }) => void
  startDate?: DateTime
  useDateTimeToLocaleString?: typeof useDateTimeToLocaleStringImported
}

export const DateRangePicker: FC<Props> = ({
  clearTitle,
  endDate,
  initiallyDisplayedMonth,
  onChange,
  startDate,
  useDateTimeToLocaleString = useDateTimeToLocaleStringImported,
}) => {
  return (
    <div className="flex flex-col w-[280px]">
      <div className="flex flex-row items-end">
        <DateInput
          clearTitle={clearTitle}
          date={startDate}
          onChange={(startDate) => onChange({ startDate, endDate })}
          useDateTimeToLocaleString={useDateTimeToLocaleString}
        />
        <div className="mx-1 mb-2">-</div>
        <DateInput
          clearTitle={clearTitle}
          date={endDate}
          onChange={(endDate) => onChange({ startDate, endDate })}
          useDateTimeToLocaleString={useDateTimeToLocaleString}
        />
      </div>
      <MonthCalendar
        className="mt-4"
        initiallyDisplayedMonth={
          initiallyDisplayedMonth ?? startDate ?? endDate ?? DateTime.local()
        }
        useDateTimeToLocaleString={useDateTimeToLocaleString}
        selected={{ start: startDate, end: endDate }}
        onSelect={({ start: startDate, end: endDate }) =>
          onChange({ startDate, endDate })
        }
      />
    </div>
  )
}
