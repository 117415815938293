import { faCloudUpload, faCheckDouble } from '@fortawesome/pro-solid-svg-icons'
import {
  faCircleHalfStroke,
  faThumbsUp,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons'
import { Status } from 'types'

export const statusColors: StatusMap<
  'neutral' | 'warning' | 'success' | 'white'
> = {
  [Status.Open]: 'neutral',
  [Status.Submitted]: 'warning',
  [Status.Approved]: 'success',
  [Status.Sent]: 'white',
}

export const statusIcons: StatusMap<IconDefinition> = {
  [Status.Open]: faCircleHalfStroke,
  [Status.Submitted]: faThumbsUp,
  [Status.Approved]: faCheckDouble,
  [Status.Sent]: faCloudUpload,
}
