import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { searchAdminTimeCards, searchTimeCards } from '@lib/api'
import { timeCardKeys } from '@lib/keys'
import { useWeekDates } from './useWeekDates'

interface Options {
  userId: number
}

export const useTimeCards = ({ userId }: Options) => {
  const { start, end } = useWeekDates()

  return useQuery(timeCardKeys.search(userId, start, end), () =>
    searchTimeCards(userId, start, end),
  )
}

export const useAdminTimeCards = (startDate: DateTime, endDate: DateTime) => {
  return useQuery(timeCardKeys.adminSearch(startDate, endDate), () =>
    searchAdminTimeCards(startDate, endDate),
  )
}

export const useTimeCardById = (id: number, userId: number) => {
  const timeCardsQuery = useTimeCards({ userId })

  return useMemo(() => {
    if (timeCardsQuery.data === undefined) return

    return timeCardsQuery.data.find((card) => card.id === id)
  }, [timeCardsQuery.data, id])
}
