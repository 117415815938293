import { FC, ReactNode } from 'react'
import clipboard from 'assets/clipboard.svg'
import { useTranslation } from 'react-i18next'

interface Props {
  body: string
  button?: ReactNode
}

export const EmptyAllocationBanner: FC<Props> = ({ button, body }) => {
  const { t } = useTranslation()

  return (
    <div className="mt-10 text-neutral-900 w-80">
      <img src={clipboard} alt="Clipboard" className="mb-6" />
      <div>{t('components.timeEntryTable.startByAddingTasks')}</div>
      <div className="my-2 text-sm">{body}</div>
      {button}
    </div>
  )
}
