import { getTimeCardTaggedTime } from '@lib/api'
import { taggedTimeKeys } from '@lib/keys'
import { useQuery } from '@tanstack/react-query'

export const useTaggedTime = (timeCardId?: number) => {
  return useQuery(
    taggedTimeKeys.getTimeCardTaggedTime(timeCardId ?? 0),
    () => getTimeCardTaggedTime(timeCardId ?? 0),
    { enabled: timeCardId !== undefined },
  )
}
