import { FC, useState } from 'react'
import { SearchField } from 'components/form/search-field'
import { Projects } from './Projects'
import { UniversalSearchResults } from './UniversalSearchResults'
import { trim } from '@utils/trim'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '@hooks/useDebounce'
import { OnMultisearchItemClick } from '../types'
import { useMultisearch } from '@hooks/useMultisearch'
import { ViewingAll } from './ViewingAll'

interface Props {
  disabledTaskIds: Set<number>
  shown: boolean
  onProjectClick: (project: Project) => void
  onSearchChange: (search: string) => void
  onTaskClick: (task: Task, checked: boolean) => void
  onMultisearchItemClick: OnMultisearchItemClick
  search?: string
  searchError?: string
  selectedTaskIds: Set<number>
}

export const RootColumn: FC<Props> = ({
  disabledTaskIds,
  shown,
  onProjectClick,
  onSearchChange,
  onTaskClick,
  onMultisearchItemClick: onUnscopedResultItemClick,
  search,
  searchError,
  selectedTaskIds,
}) => {
  const { t } = useTranslation()
  const [viewingAll, setViewingAll] = useState<
    'sequences' | 'shots' | 'assets' | 'tasks'
  >()

  const debounced = useDebounce(search) ?? ''
  const trimmedDebounced = trim(debounced)

  const searchEverythingResult = useMultisearch(
    { q: trimmedDebounced },
    {
      enabled:
        searchError === undefined &&
        trimmedDebounced !== '' &&
        viewingAll === undefined,
    },
  )

  if (!shown) return null

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row">
        <SearchField
          autoFocus={true}
          error={searchError}
          value={search ?? ''}
          onChange={onSearchChange}
          placeholder={t('features.timeLogging.searchEverything')}
          className="w-full mx-9"
        />
      </div>
      <Projects
        hidden={trimmedDebounced !== '' || Boolean(viewingAll)}
        onProjectClick={onProjectClick}
        search={trimmedDebounced}
      />
      <UniversalSearchResults
        disabledTaskIds={disabledTaskIds}
        hidden={debounced.trim() === '' || Boolean(viewingAll)}
        onItemClick={onUnscopedResultItemClick}
        onTaskClick={onTaskClick}
        onViewAllClick={setViewingAll}
        results={searchEverythingResult.data}
        selectedTaskIds={selectedTaskIds}
        showLoading={searchEverythingResult.isLoading}
        search={trimmedDebounced}
      />
      {viewingAll && (
        <ViewingAll
          disabledTaskIds={disabledTaskIds}
          search={trimmedDebounced}
          onItemClick={onUnscopedResultItemClick}
          onTaskClick={onTaskClick}
          selectedTaskIds={selectedTaskIds}
          viewing={viewingAll}
        />
      )}
    </div>
  )
}
