import { useAuth } from '@hooks/useAuth'
import { FC } from 'react'
import { useParams, Navigate, Outlet, useLocation } from 'react-router-dom'

export const TimeLoggingRouteContext: FC = () => {
  const { user } = useAuth()
  const { userId: userIdParam } = useParams()
  const { pathname } = useLocation()

  if (!user?.id)
    throw new Error(
      'TimeLoggingRouteContext may only be used within the authenticated portion of the app',
    )

  if (pathname === '/time-logger' || pathname == '/time-logger/user')
    return (
      <Navigate
        to={`/time-logger/user/${userIdParam ?? user.id}`}
        replace={true}
      />
    )

  return <Outlet />
}
