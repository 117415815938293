import { determineBrowserLanguage } from '@utils/language'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from '../locales/en-us.json'
import fr from '../locales/fr-ca.json'
import ko from '../locales/ko-kr.json'
import de from '../locales/de.json'

void i18n.use(initReactI18next).init({
  debug: false,
  lng: determineBrowserLanguage(),
  resources: { en, fr, ko, de },
  defaultNS: 'translation',
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: 'en',
})

export default i18n
