import { useQuery } from '@tanstack/react-query'
import { searchPayPeriods } from '@lib/api'
import { payPeriodKeys } from '@lib/keys'

interface Options {
  startDate: DateTime
  endDate: DateTime
}

export const usePayPeriods = ({ startDate, endDate }: Options) => {
  return useQuery(payPeriodKeys.search(startDate, endDate), () =>
    searchPayPeriods(startDate, endDate),
  )
}
