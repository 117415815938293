import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { useOnClickOutside } from '@hooks/useOnClickOutside'
import { noop } from '@utils/noop'
import { FC, ReactNode, useRef, useState } from 'react'
import { twMerge } from '@lib/tailwind-merge'
import { Button, Props as ButtonProps } from './Button'
import { DropdownMenu, DropdownMenuProps } from 'components/menus'

interface Props {
  button?: (args: {
    disabled: boolean
    menuHidden: boolean
    toggleMenuHidden: () => void
  }) => ReactNode
  children?: ReactNode
  className?: string | ((options: { hidden: boolean }) => string)
  closeOnMouseLeave?: boolean
  disabled?: boolean
  dropdownMenuHeader?: string
  menuItems: DropdownMenuProps['items']
  title?: string
  buttonVariant?: ButtonProps['variant']
  onMenuHiddenChange?: (hidden: boolean) => void
  beforeMenuItems?: ReactNode
  menuItemTag?: DropdownMenuProps['itemTag']
  testid?: string
}

export const MenuButton: FC<Props> = ({
  button,
  children,
  className,
  closeOnMouseLeave = false,
  disabled = false,
  dropdownMenuHeader,
  menuItems,
  title,
  buttonVariant,
  beforeMenuItems,
  menuItemTag,
  onMenuHiddenChange = noop,
  testid = 'menu-button-wrapper',
}) => {
  const [menuHidden, setMenuHidden] = useState(true)

  const ref = useRef(null)

  const closeMenu = () => {
    setMenuHidden(true)
    onMenuHiddenChange(true)
  }

  const toggleMenu = () => {
    setMenuHidden((hidden) => {
      onMenuHiddenChange(!hidden)
      return !hidden
    })
  }

  useOnClickOutside(ref, closeMenu)

  const renderButton = () => {
    if (!button)
      return (
        <Button
          disabled={disabled}
          onClick={toggleMenu}
          icon={menuHidden ? faChevronDown : faChevronUp}
          title={title}
          variant={buttonVariant}
        >
          {children}
        </Button>
      )

    return button({
      toggleMenuHidden: toggleMenu,
      disabled,
      menuHidden,
    })
  }

  return (
    <div
      className={twMerge(
        'relative flex items-center text-sm justify-items-end w-max',
        typeof className === 'function'
          ? className({ hidden: menuHidden })
          : className,
      )}
      ref={ref}
      data-testid={testid}
    >
      {renderButton()}
      <DropdownMenu
        header={dropdownMenuHeader}
        hidden={menuHidden}
        beforeItems={beforeMenuItems}
        items={menuItems}
        onItemClick={closeMenu}
        onMouseLeave={() => closeOnMouseLeave && closeMenu()}
        className="top-[120%] z-30"
        itemTag={menuItemTag}
      />
    </div>
  )
}
