import { FC } from 'react'
import { ButtonInput, FilterRenderer } from '../components'
import { SearchField } from 'components/form/search-field'
import { useTranslation } from 'react-i18next'

interface Props {
  columnNameTranslationKey: TranslationKey
  onChange: (query?: string) => void
  query?: string
}

export const SearchQuery: FC<Props> = ({
  columnNameTranslationKey,
  onChange,
  query = '',
}) => {
  const { t } = useTranslation()
  const label = `${query}`.trim()

  return (
    <FilterRenderer
      columnNameTranslationKey={columnNameTranslationKey}
      renderDropdown={() => (
        <SearchField
          autoFocus={true}
          onChange={onChange}
          placeholder={t('common.search')}
          value={query ?? ''}
        />
      )}
      renderTrigger={(clickHandler) => (
        <ButtonInput onClick={clickHandler}>
          {label !== '' ? label : t('common.all')}
        </ButtonInput>
      )}
    />
  )
}
