import { EntityPicker, EntityItem } from '@features/entity-picker'
import { useApprovalGroups } from '@hooks/useApprovalGroups'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useManagedGroupOutletContext } from '../ManagedGroup'

export const ApprovalGroupPicker = memo(() => {
  const {
    adminManagedGroup,
    handleApprovalGroupClick: handleClick,
    selectedApprovalGroupIds: selectedIds,
    approvalGroups: { search, setSearch },
  } = useManagedGroupOutletContext()

  const { t } = useTranslation()

  return (
    <div className="flex flex-col">
      <p className="text-xs font-medium text-neutral-500 mb-2">
        {t('features.adminManagedGroups.approvalGroupsIncludedInGroup', {
          count: selectedIds.size,
        })}
      </p>
      <EntityPicker
        filter={(approvalGroup, search) => {
          return approvalGroup.name.toLowerCase().includes(search.toLowerCase())
        }}
        placeholder={t('common.search')}
        isSelected={(approvalGroup, selectedIds) =>
          selectedIds.has(approvalGroup.id)
        }
        renderNoItemSelected={() => (
          <p className="text-xs text-neutral-500">
            {t('features.adminManagedGroups.noApprovalGroupsSelected')}
          </p>
        )}
        renderSearchItem={(approvalGroup) => (
          <EntityItem
            key={approvalGroup.id}
            onClick={() => handleClick(approvalGroup, { selected: true })}
            primaryText={approvalGroup.name}
            disabled={
              selectedIds.has(approvalGroup.id) ||
              (approvalGroup.adminManagedGroupId !== undefined &&
                approvalGroup.adminManagedGroupId !== adminManagedGroup.id)
            }
          />
        )}
        renderSelectedItem={(approvalGroup) => (
          <EntityItem
            key={approvalGroup.id}
            onClick={() => handleClick(approvalGroup, { selected: false })}
            primaryText={approvalGroup.name}
            removable={true}
          />
        )}
        selectedIds={selectedIds}
        useState={() => [search, setSearch]}
        useQuery={useApprovalGroups}
      />
    </div>
  )
})

ApprovalGroupPicker.displayName = 'ApprovalGroupPicker'
