import { AuditLogEditField } from 'types'

export const translationForAuditLogEditField = (
  targetType: AuditLogEditField,
) => {
  switch (targetType) {
    case AuditLogEditField.Name:
      return 'features.reporting.name'
    case AuditLogEditField.AutoApproval:
      return 'features.reporting.auditLog.autoApproval'
    case AuditLogEditField.ApprovalGroup:
      return 'common.approvalGroup'
    case AuditLogEditField.Body:
      return 'features.reporting.auditLog.body'
    case AuditLogEditField.LockType:
      return 'features.reporting.auditLog.lockType'
  }
}
