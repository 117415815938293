import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SidebarError } from './SidebarError'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons'
import { WorkdayTimeBlockErrors } from './WorkdayTimeBlockErrors'
import { useModal } from '@hooks/useModal'

interface Props {
  timeCard: AdminTimeCard
}

export const WorkdaySendError: FC<Props> = ({ timeCard }) => {
  const { t } = useTranslation()
  const modal = useModal()

  const openTimeBlockErrorsModal = () => {
    const id = modal.open({
      title: t('features.admin.workdayTimeBlockErrors'),
      closeOnBackgroundClick: true,
      variant: 'custom',
      content: (
        <div>
          <WorkdayTimeBlockErrors
            timeCard={timeCard}
            className="max-h-80 overflow-auto m-6 mt-0 p-4 pt-2 bg-neutral-50 border border-neutral-300 rounded text-neutral-900 text-sm flex flex-col gap-2"
          />
          <div className="p-5 text-neutral-500 font-medium text-sm text-right border-t border-neutral-300">
            <a onClick={() => modal.close(id)} className="cursor-pointer">
              {t('common.close')}
            </a>
          </div>
        </div>
      ),
      onClose: () => modal.close(id),
    })
  }

  return (
    <SidebarError
      title={
        <div className="flex gap-2 items-center">
          {t('features.admin.workdayError')}
          <FontAwesomeIcon
            icon={faUpRightFromSquare}
            className="cursor-pointer"
            onClick={openTimeBlockErrorsModal}
          />
        </div>
      }
      content={t('features.admin.workdayFailedToImportTimeBlocksForThisWorker')}
    />
  )
}
