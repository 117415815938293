import { DetailedHTMLProps, FC } from 'react'
import { twMerge } from '@lib/tailwind-merge'

interface Props
  extends Omit<
    DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'disabled' | 'onChange'
  > {
  disabled?: boolean
  onChange: (value: string) => void
}

export const NativeTimeInput: FC<Props> = ({
  disabled,
  onChange,
  ...props
}) => {
  return (
    <input
      type="time"
      className={twMerge(
        'text-sm rounded px-2 py-1  border-neutral-400',
        disabled && 'text-neutral-300 border-neutral-300',
      )}
      disabled={disabled}
      onChange={(event) => onChange(event.target.value)}
      {...props}
    />
  )
}
