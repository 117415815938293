import { AxiosResponse } from 'axios'

interface ApiErrorCodeResponse extends AxiosResponse {
  data: {
    error: {
      code: string
    }
  }
}

type ApiErrorResponse = ApiErrorCodeResponse

export const isApiErrorResponse = (
  response: AxiosResponse | undefined,
): response is ApiErrorResponse =>
  response !== undefined &&
  'data' in response &&
  typeof response.data === 'object' &&
  response.data !== null &&
  'error' in response.data

export const isApiErrorResponseWithCode = (
  response: ApiErrorResponse,
): response is ApiErrorCodeResponse =>
  typeof response.data.error === 'object' && 'code' in response.data.error
