import { FC, useMemo } from 'react'
import { useEntries } from '@hooks/useEntries'
import {
  useSelectedTasks,
  useSelectTask,
  useDeselectTask,
} from '@features/time-logging/hooks/useTasks'
import { useFormatStartAndEnd } from '@utils/dates'
import { useTranslation } from 'react-i18next'
import { useEmployeeName } from '@features/time-logging/hooks/useEmployeeName'
import { useWeekDates } from '@hooks/useWeekDates'
import { useUserId } from '@features/time-logging/hooks/useUserId'
import { useDateTimeToLocaleString } from '@hooks/useDateTimeWithLocale'
import { TaskPicker } from '@features/task-picker'

const getTaskIdsFromEntries = (
  entries: Entry[] | undefined = [],
): Set<number> => {
  const taskIds = new Set<number>()
  entries.forEach((entry) => {
    /**
     * As of this writing, entries are 'deleted' by reducing the duration to 0.
     *
     * - AF 11/28/22
     */
    if (entry.duration > 0) taskIds.add(entry.task.id)
  })
  return taskIds
}

interface Props {
  hidden: boolean
  onClose: () => void
}

export const TimeLoggerTaskPicker: FC<Props> = ({ hidden, onClose }) => {
  const userId = useUserId()
  const entriesQuery = useEntries({ userId })
  const { start, end } = useWeekDates()
  const selectedTasksQuery = useSelectedTasks()
  const { t } = useTranslation()
  const toLocaleString = useDateTimeToLocaleString()
  const formatStartAndEnd = useFormatStartAndEnd(toLocaleString)

  const { isSelf, name } = useEmployeeName()

  const title = isSelf
    ? t('features.timeLogging.myWeeklyTasks')
    : t('features.timeLogging.usersWeeklyTasks', { name })

  const selectTaskMutation = useSelectTask()
  const deselectTask = useDeselectTask()

  const handleTaskClick = ({ id }: { id: number }, checked: boolean) => {
    const mutation = checked ? selectTaskMutation : deselectTask

    mutation.mutate(id)
  }

  const disabledTaskIds = useMemo(() => {
    return getTaskIdsFromEntries(entriesQuery.data)
  }, [entriesQuery.data])

  const selectedTaskIds = useMemo(() => {
    const ids = new Set<number>()
    const selectedTasks = selectedTasksQuery.data ?? []

    selectedTasks.forEach((task) => {
      ids.add(task.id)
    })

    return ids
  }, [selectedTasksQuery.data])

  return (
    <TaskPicker
      disabledTaskIds={disabledTaskIds}
      hidden={hidden}
      onClose={onClose}
      onTaskClick={handleTaskClick}
      selectedTaskIds={selectedTaskIds}
      subtitle={formatStartAndEnd(start, end)}
      tabs={['my-tasks', 'all-tasks', 'non-project'] as const}
      title={title}
    />
  )
}
