import { statusColors, statusIcons } from '@utils/statuses'
import { CircleProgress } from 'components/indicators/circle-progress'
import { FC, ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CharmButton } from './CharmButton'
import { MenuButton } from './MenuButton'
import { Status } from 'types'
import { Spinner } from 'components/loaders'
import { useLanguage } from '@hooks/useLanguage'

interface Props {
  allSelected: (status: Status) => boolean
  className?: string
  children?: ReactNode
  count?: (status: Status) => number
  onHide: (status: Status) => void
  onHideOthers: (status: Status) => void
  othersHidden: (status: Status) => boolean
  onSelect: (status: Status) => void
  onShow: (status: Status) => void
  onShowOthers: (status: Status) => void
  onUnselect: (status: Status) => void
  status: Status
  statusIsHidden: (status: Status) => boolean
  total?: number
  loading?: boolean
}

export const StatusMiniSilo: FC<Props> = ({
  allSelected: getAllSelected,
  className,
  children,
  count: countFn = () => 0,
  onHide,
  onHideOthers,
  onSelect,
  onShow,
  onShowOthers,
  onUnselect,
  othersHidden: getOthersHidden,
  status,
  statusIsHidden,
  total,
  loading,
}) => {
  const { t } = useTranslation()
  const language = useLanguage()

  const allSelected = getAllSelected(status)
  const hidden = statusIsHidden(status)
  const othersHidden = getOthersHidden(status)

  const menuItems = [
    allSelected
      ? {
          label: t('common.deselectAll'),
          onClick: () => onUnselect(status),
        }
      : {
          label: t('common.selectAll'),
          onClick: () => onSelect(status),
          disabled: hidden,
        },
    hidden
      ? {
          label: t('common.show'),
          onClick: () => onShow(status),
        }
      : {
          label: t('common.hide'),
          onClick: () => onHide(status),
        },
    othersHidden
      ? {
          label: t('common.showOthers'),
          onClick: () => onShowOthers(status),
        }
      : {
          label: t('common.hideOthers'),
          onClick: () => onHideOthers(status),
        },
  ]

  const count = countFn(status)

  return (
    <MenuButton
      className={className}
      menuItems={menuItems}
      dropdownMenuHeader={t(`common.statuses.${status}`)}
      testid={`silo-${status}`}
      button={({ disabled, menuHidden, toggleMenuHidden }) => (
        <CharmButton
          darken={hidden}
          disabled={disabled}
          onClick={toggleMenuHidden}
          icon={statusIcons[status]}
          isExpanded={!menuHidden}
          variant={statusColors[status]}
          mode="dropdown"
          // These gross hardcoded min widths help reduce UI jitter as filters
          // to the left of the StatusMiniSilos are toggled causing status counts
          // to update
          className={status === Status.Sent ? 'min-w-[220px]' : 'min-w-[135px]'}
        >
          {children ?? loading ? (
            <Spinner />
          ) : total === undefined ? (
            count.toLocaleString(language)
          ) : (
            <span className="flex items-center &>span">
              <Trans
                i18nKey="components.silo.quantityOfTotal"
                values={{ quantity: count, total }}
                components={[
                  <span key={1} className="mx-1 text-xs font-normal">
                    {children}
                  </span>,
                ]}
              />
              <CircleProgress
                percentGreen={(count / total) * 100}
                className="h-4 ml-2"
              />
            </span>
          )}
        </CharmButton>
      )}
    />
  )
}
