interface projectAndProjectIntegrationLike {
  project: { name: string }
  projectIntegration: { code: string }
}

export const pathFor = (item: projectAndProjectIntegrationLike): string[] => {
  return [item.project.name, item.projectIntegration.code]
}

export const pathForSequence = pathFor

export const pathForShot = (
  shot: projectAndProjectIntegrationLike & { sequence?: { label: string } },
): string[] => {
  return [...pathFor(shot), shot.sequence?.label].filter((v) => v) as string[]
}

export const pathForAsset = pathFor

export const pathForTask = (
  task: projectAndProjectIntegrationLike & Pick<Task, 'displayPath'>,
): string[] => {
  return [...pathFor(task), ...task.displayPath]
}
