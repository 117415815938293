import { useCallback } from 'react'
import { useModal } from '@hooks/useModal'
import { useTranslation } from 'react-i18next'
import { useDeleteIntegrationProjectLink } from './useDeleteIntegrationProjectLink'
import { toast } from '@lib/toasts'
import { isAxiosError } from 'axios'
import {
  isApiErrorResponse,
  isApiErrorResponseWithCode,
} from '@utils/isApiErrorResponse'

export const useUnlinkIntegrationProject = () => {
  const modal = useModal()
  const { t } = useTranslation()

  const deleteLink = useDeleteIntegrationProjectLink()

  const performDelete = useCallback(
    async (
      project: AdminProject,
      integrationProjectLink: IntegrationProjectLink,
    ) => {
      try {
        await deleteLink.mutateAsync({
          projectId: project.id,
          integrationProjectLinkId: integrationProjectLink.id,
        })

        toast({
          title: t('features.projectsAdmin.projectUnlinked'),
          variant: 'success',
          content: t(
            'features.projectsAdmin.successfullyUnlinkedIntegrationProject',
            {
              sourceProjectName: integrationProjectLink.sourceProject.name,
              integrationName: integrationProjectLink.projectIntegration.name,
              projectName: project.name,
            },
          ),
        })
      } catch (error) {
        if (
          isAxiosError(error) &&
          isApiErrorResponse(error.response) &&
          isApiErrorResponseWithCode(error.response) &&
          error.response.data.error.code === 'SOURCE_PROJECT_NOT_UNLINKABLE'
        ) {
          toast({
            title: t('features.projectsAdmin.unableToUnlinkProject'),
            variant: 'error',
            content: t('features.projectsAdmin.projectCannotBeUnlinked'),
            duration: 10000,
          })
        } else {
          toast({
            title: t('features.projectsAdmin.unableToUnlinkProject'),
            variant: 'error',
            content: t('features.projectsAdmin.failedToUnlinkProject'),
          })
        }
      }
    },
    [deleteLink, t],
  )

  const unlinkIntegrationProject = useCallback(
    (project: AdminProject, integrationLink: IntegrationProjectLink) => {
      modal.confirm({
        title: t('features.projectsAdmin.unlinkProject'),
        content: (
          <div>
            <p>
              {t('features.projectsAdmin.confirmUnlink', {
                foreignProjectName: integrationLink.sourceProject.name,
                integrationName: integrationLink.projectIntegration.name,
                projectName: project.name,
              })}
            </p>
            <p className="mt-4">
              {t(
                'features.projectsAdmin.tasksWithinTheProjectWillNoLongerBeSelectable',
                {
                  sourceProjectName: integrationLink.sourceProject.name,
                  integrationName: integrationLink.projectIntegration.name,
                },
              )}
            </p>
          </div>
        ),
        confirmText: t('features.projectsAdmin.unlinkProject'),
        onConfirm: () => void performDelete(project, integrationLink),
        primaryButtonClassName: 'bg-error-500 hover:bg-error-700',
      })
    },
    [modal, t, performDelete],
  )

  return { unlinkIntegrationProject }
}
