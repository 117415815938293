import './App.css'
import { AppProvider } from 'providers/AppProvider'
import { AppRoutes } from 'routes'
import { MaintenancePage } from 'features/maintenance-mode'
import { NewClientVersionNotification } from 'features/new-client-version-notification'

function App() {
  if (import.meta.env.VITE_MAINTENANCE_MODE_ENABLED === 'true')
    return <MaintenancePage />

  return (
    <AppProvider>
      <NewClientVersionNotification />
      <AppRoutes />
    </AppProvider>
  )
}

export default App
