import { searchAbsences } from '@lib/api'
import { leaveKeys } from '@lib/keys'
import { useQuery } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { useWeekDates } from '@hooks/useWeekDates'
import { dateIntersectsAbsence } from '@utils/absence'

interface Options {
  endDateOverride?: DateTime
  startDateOverride?: DateTime
  userId: number
}

export const useAbsences = (options: Options) => {
  const { start: startDate, end: endDate } = useWeekDates()

  const start = options.startDateOverride ?? startDate
  const end = options.endDateOverride ?? endDate

  return useQuery(leaveKeys.search(options.userId, start, end), () =>
    searchAbsences(options.userId, start, end),
  )
}

export const useAbsencesForDate = (date: DateTime, options: Options) => {
  const { data } = useAbsences(options)

  if (!data) return []

  return data.filter((absence) => dateIntersectsAbsence(date, absence))
}
