import { useInhibitKeyPressOverride } from '@hooks/useInhibitKeyPressOverride'
import { useWeekDates } from '@hooks/useWeekDates'
import { WeekSelector as WeekSelectorComponent } from 'components/navigation'
import { FC } from 'react'

const MENU_ID = 'week-selector'

export const WeekSelector: FC = () => {
  const { start, set } = useWeekDates()
  const { registerMenuClosed, registerMenuOpened } =
    useInhibitKeyPressOverride()

  return (
    <WeekSelectorComponent
      onMenuHiddenChange={(hidden) =>
        hidden ? registerMenuClosed(MENU_ID) : registerMenuOpened(MENU_ID)
      }
      onWeekSelect={(startOfWeek) => set(startOfWeek)}
      selectedWeek={start}
    />
  )
}
