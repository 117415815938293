import { toggleProjectAutoApproval } from '@lib/api'
import { adminProjectsKeys } from '@lib/keys'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useToggleAutoApproval = () => {
  const queryClient = useQueryClient()

  return useMutation((id: number) => toggleProjectAutoApproval(id), {
    onSuccess: () => {
      void queryClient.invalidateQueries(adminProjectsKeys.all)
    },
  })
}
