import { noop } from '@utils/noop'
import {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  useState,
} from 'react'
import { Input } from '../input'
import { convertTo12Hour } from '../util'
import { parse } from './parse'

const invalidParseResult: ParseResult = {
  hour: NaN,
  minute: NaN,
}

interface ParseResult {
  hour: number
  minute: number
}

const parseInput = (
  input: string,
  { assumePm }: { assumePm: boolean },
): ParseResult => {
  const { hour, minute } = parse(input, { assumePm })

  if (isNaN(hour) || isNaN(minute)) return { hour, minute }
  if (hour < 0) return invalidParseResult
  if (hour === 24 && minute !== 0) return invalidParseResult
  if (hour > 24) return invalidParseResult
  if (minute < 0) return invalidParseResult
  if (minute >= 60) return invalidParseResult

  return { hour, minute }
}

const pad = (number: number) => {
  if (number >= 10) return `${number}`

  return `0${number}`
}

const useFormat =
  (mode: '12' | '24') =>
  (
    hour: number | undefined | null = null,
    minute: number | undefined | null = null,
  ): string => {
    if (hour === null) return ''
    if (minute === null) return ''

    if (mode === '24') return `${hour}:${pad(minute)}`

    const twelveHour = convertTo12Hour(hour)
    const period = hour >= 12 ? 'PM' : 'AM'
    return `${twelveHour}:${pad(minute)} ${period}`
  }

interface Props
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'onChange' | 'value'
  > {
  assumePm?: boolean
  displayMode?: '12' | '24'
  hour: number | null // 0 - 24
  minute: number | null // 0 - 60
  onChange: (hour: number | null, minute: number | null) => void // NaN when invalid
}

export const TimeInput: FC<Props> = ({
  assumePm = false,
  displayMode = '12',
  hour,
  minute,
  onChange,
  onBlur = noop,
  onFocus = noop,
  ...props
}) => {
  const format = useFormat(displayMode)
  const [rawInput, setRawInput] = useState(format(hour, minute))
  const [isActive, setIsActive] = useState(false)

  const getDisplayValue = () => {
    if (isActive) return rawInput
    if (hour === null) return ''
    if (minute === null) return ''
    if (isNaN(hour)) return rawInput
    if (isNaN(minute)) return rawInput

    return format(hour, minute)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const raw = event.currentTarget.value
    setRawInput(raw)

    if (raw.trim() === '') return onChange(null, null)

    const parseResult = parseInput(raw, { assumePm })
    onChange(parseResult.hour, parseResult.minute)
  }

  const handleBlur: Props['onBlur'] = (event) => {
    setIsActive(false)
    onBlur(event)

    if (hour === null) return setRawInput('')
    if (minute === null) return setRawInput('')
    if (!isNaN(hour) && !isNaN(minute)) setRawInput(format(hour, minute))
  }

  const handleFocus: Props['onFocus'] = (event) => {
    setIsActive(true)
    onFocus(event)

    if (hour === null) return setRawInput('')
    if (minute === null) return setRawInput('')
    if (!isNaN(hour) && !isNaN(minute)) setRawInput(format(hour, minute))
  }

  return (
    <Input
      {...props}
      onChange={handleChange}
      value={getDisplayValue()}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  )
}
