import { DateTime } from 'luxon'

export const getTimeCardsWithinDateRange = (
  timeCards: AdminTimeCard[],
  dateRange: DateTime[],
  status?: Status,
) => {
  return dateRange.reduce<AdminTimeCard[]>((acc, day) => {
    const timeCard = timeCards.find((tc) => {
      const hasSameDay = tc.date.hasSame(day, 'day')

      return status !== undefined
        ? hasSameDay && tc.status === status
        : hasSameDay
    })

    return timeCard ? [...acc, timeCard] : acc
  }, [])
}
