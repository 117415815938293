import axios, { AxiosRequestTransformer, AxiosResponseTransformer } from 'axios'
import applyCaseMiddleware, {
  AxiosCaseMiddlewareOptions,
  ObjectTransformer,
} from 'axios-case-converter'
import { createObjectTransformers } from 'axios-case-converter/src/transformers'
import {
  CreateAxiosRequestTransformer,
  CreateAxiosResponseTransformer,
} from 'axios-case-converter/src/types'
import { DateTime } from 'luxon'
import traverse from 'traverse'

const baseURL = import.meta.env.VITE_API_BASE_URL

// Array of request or response data object properties
// that will not be transformed by axios-case-converter
const PRESERVED_PROPERTIES = [
  // This is very brittle, but we don't have many options at this level
  // to better target specific properties that we don't want to transform.
  // In this instance, we need to exclude saved report filters (both when
  // creating/saving and fetching them), since filters are stored
  // as objects wholly owned by the client, we expect them to contain
  // camelCased keys and maybe even nested objects with camelCased keys,
  // which we do not want to transform in either direction.
  'filters',
]

const applyFnWithPreservedProperties = (
  fn: ObjectTransformer,
  value: unknown,
  options?: AxiosCaseMiddlewareOptions,
) => {
  if (!value || Object.getPrototypeOf(value) !== Object.prototype) {
    // If the value is not a plain object or an array, just pass it directly to snake()
    // as the original transformer would have
    return fn(value, options)
  }

  const unpreserved = { ...value } as Record<string, unknown>
  const preserved = {} as Record<string, unknown>

  // Extract preserved properties from data
  for (const prop of PRESERVED_PROPERTIES) {
    if (prop in unpreserved) {
      preserved[prop] = unpreserved[prop]
      delete unpreserved[prop]
    }
  }

  // Apply transformation to unpreserved properties
  const transformed = fn(unpreserved, options) as Record<string, unknown>

  // Return preserved and transformed properties
  return { ...preserved, ...transformed }
}

const applyFnWithPreservedPropertiesToData = (
  fn: ObjectTransformer,
  data: unknown,
  options?: AxiosCaseMiddlewareOptions,
) => {
  // If the data is an array, apply the transformation to each item
  if (Array.isArray(data)) {
    return data.map((item) => {
      return applyFnWithPreservedProperties(fn, item, options)
    })
  }

  return applyFnWithPreservedProperties(fn, data, options)
}

// This is similar to the default request transformer,
// but it excludes certain keys and doesn't modify headers.
// https://github.com/mpyw/axios-case-converter/blob/39b650d1ccdc7c304dc0e4f242f6baf29f1c6e3a/src/middleware.ts#L25-L44
const createSnakeRequestTransformer: CreateAxiosRequestTransformer = (
  options?,
) => {
  const { snake } = createObjectTransformers()
  return (
    data: unknown,
  ): ReturnType<ReturnType<CreateAxiosRequestTransformer>> => {
    return applyFnWithPreservedPropertiesToData(snake, data, options)
  }
}

// This is similar to the default response transformer,
// but it excludes certain keys and doesn't modify headers
// https://github.com/mpyw/axios-case-converter/blob/39b650d1ccdc7c304dc0e4f242f6baf29f1c6e3a/src/middleware.ts#L45-L56
const createCamelResponseTransformer: CreateAxiosResponseTransformer = (
  options?,
) => {
  const { camel } = createObjectTransformers(options?.caseFunctions)
  return (
    data: unknown,
  ): ReturnType<ReturnType<CreateAxiosResponseTransformer>> => {
    return applyFnWithPreservedPropertiesToData(camel, data, options)
  }
}

export const axiosClient = applyCaseMiddleware(
  axios.create({
    baseURL,
    responseType: 'json',
    validateStatus: (status) => status < 400, // Response status greater than 400 will throw
    withCredentials: true,
    transformRequest: [
      /* eslint-disable */
      // Ignoring the 'any' type errors.  It's not easy to get the type after being transformed
      ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
      (data) => {
        return traverse(data).map(function (v) {
          if (v instanceof DateTime) return this.update(v.toISODate())
        })
      },
      /* eslint-enable */
    ],
    transformResponse: [
      /* eslint-disable */
      // Ignoring the 'any' type errors.  It's not easy to get the type after being transformed
      ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
      (data) => {
        return traverse(data).map(function (v) {
          if (typeof v !== 'string') return

          // TODO - remove this transformation when the caller provides it instead
          if (
            this.key?.toLowerCase().includes('date') ||
            this.key === 'sent_to_payroll_at' ||
            this.key === 'timestamp' ||
            this.key === 'created_at' ||
            this.key === 'sent_to_workday_timestamp'
          ) {
            const parsedDateTime = DateTime.fromISO(v)
            if (parsedDateTime.isValid) this.update(parsedDateTime)
          }
        })
      },
      /* eslint-enable */
    ],
  }),
  {
    caseMiddleware: {
      requestTransformer: createSnakeRequestTransformer(),
      responseTransformer: createCamelResponseTransformer({
        // We preserve this key because it's likely
        // referencing a Status, and thus needs to match
        // the underscored Status type value
        preservedKeys: ['sent_to_payroll'],
      }),
    },
  },
)
