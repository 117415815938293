import Button from './IncrementDecrementButton'
import {
  faArrowUpToLine,
  faArrowDownToLine,
  faMinus,
  faPlus,
  faXmark,
  faDoNotEnter,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faMinus as solidFaMinus,
  faPlus as solidFaPlus,
  faXmark as solidFaXmark,
  faArrowUpToLine as solidFaArrowUpToLine,
  faArrowDownToLine as solidFaArrowDownToLine,
  faDoNotEnter as solidFaDoNotEnter,
} from '@fortawesome/pro-solid-svg-icons'
import { FC, useMemo } from 'react'
import { useOptimisticMutation } from '@features/time-logging/hooks/useOptimisticMutation'
import { upsertOrDeleteEntry } from '@lib/api'
import debounce from '@utils/debounce'
import classNames from 'classnames'

interface Props {
  entry: Entry
  className?: string
  mode: 'step' | 'fill'
  fillAmount: number
}

// Adds/subtracts from the duration
// Returns the greater of duration or zero
const adjustDuration = (duration: number, seconds: number) => {
  return Math.max(0, duration + seconds)
}

export const IncrementDecrementButtons: FC<Props> = ({
  entry,
  className,
  mode = 'step',
  fillAmount,
}) => {
  const debouncedQuery = useMemo(() => debounce(upsertOrDeleteEntry, 300), [])

  // Optimistic mutations to keep the UI snappy
  // https://tanstack.com/query/v4/docs/guides/optimistic-updates
  const mutation = useOptimisticMutation({
    queryFunction: debouncedQuery,
  })

  const mutateDuration = (durationDelta: number) => {
    mutation.mutate({
      entry: {
        ...entry,
        duration: adjustDuration(entry.duration, durationDelta),
      },
    })
  }

  const clearEntry = () => {
    mutation.mutate({
      entry: {
        ...entry,
        duration: 0,
      },
    })
  }

  const [icon, boldIcon] = useMemo(() => {
    if (mode === 'step') return [faPlus, solidFaPlus]
    if (fillAmount > 0) return [faArrowUpToLine, solidFaArrowUpToLine]
    if (fillAmount < 0) return [faArrowDownToLine, solidFaArrowDownToLine]

    // When fillAmount is 0, there's no fill action
    return [faDoNotEnter, solidFaDoNotEnter]
  }, [mode, fillAmount])

  return (
    <div
      className={classNames('flex h-[30px]', className)}
      // Double clicking enters edit mode, so we want to
      // stop propagation here to avoid entering edit mode on rapid clicking
      onDoubleClick={(e) => e.stopPropagation()}
    >
      <Button
        onClick={() => (mode === 'step' ? mutateDuration(-900) : clearEntry())}
        icon={mode === 'step' ? faMinus : faXmark}
        boldIcon={mode === 'step' ? solidFaMinus : solidFaXmark}
        // Disable the decrement/clear button when entry is blank/0
        disabled={entry.duration === 0}
      />
      <div className="my-2 w-[1px] border-r border-neutral-300"></div>
      <Button
        onClick={() =>
          mode === 'step' ? mutateDuration(3600) : mutateDuration(fillAmount)
        }
        icon={icon}
        boldIcon={boldIcon}
        disabled={
          // Fill button should be disabled when there's nothing to fill (fillAmount === 0),
          // or the fillAmount is negative (over-allocated) but the entry is blank/0
          mode === 'fill' &&
          (fillAmount === 0 || (fillAmount < 0 && entry.duration === 0))
        }
      />
    </div>
  )
}
