import { noop } from './noop'

type Cancel = () => void

interface Cancelable<R> {
  promise: Promise<R>
  cancel: Cancel
}

function deferred<R>(ms: number): Cancelable<R> {
  let cancel: Cancel = noop

  const promise = new Promise<R>((resolve, reject) => {
    cancel = reject
    setTimeout(resolve, ms)
  })

  return { promise, cancel }
}

type Task<A, R> = (args: A) => Promise<R>

function debounce<R, A = unknown>(task: Task<A, R>, ms: number): Task<A, R> {
  let t: Cancelable<R> = {
    promise: new Promise<R>(noop as () => never),
    cancel: () => undefined,
  }

  return async (args: A) => {
    try {
      t.cancel()
      t = deferred(ms)
      await t.promise
      return task(args)
    } catch (e) {
      /* prevent memory leak */
      // This is totally tricking the compiler into thinking that things are ok.
      // TODO - find a better way of debouncing promise-based functions so that we don't have to rely on hacks
      return undefined as R
    }
  }
}

export default debounce
