import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  count: number
  total: number
}

export const EmployeeCountFooterCell: FC<Props> = ({ count, total }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col px-3 whitespace-nowrap">
      <span className="font-semibold text-neutral-900">
        {t('common.total')}
      </span>
      <span>
        <>{t('common.employeesCountAndTotal', { count, total })}</>
      </span>
    </div>
  )
}
