import { coerceIntoArray } from '@utils/coerceIntoArray'
import { TimeCardTimeTotals } from '../types'

export const getTotalsFromTimeCards = (
  timeCards?: (AdminTimeCard | undefined)[] | AdminTimeCard,
) =>
  coerceIntoArray(timeCards).reduce<TimeCardTimeTotals>(
    (totals, timeCard) => {
      if (timeCard === undefined) return totals

      return {
        total: totals.total + timeCard.totalSecondsLogged,
        tagged: totals.tagged + timeCard.totalSecondsTagged,
      }
    },
    { total: 0, tagged: 0 },
  )
