import { FC, ReactNode } from 'react'
import { NewHeader } from 'components/page-header'
import { useTranslation } from 'react-i18next'

interface Props {
  actionButton: ReactNode
}

export const Header: FC<Props> = ({ actionButton }) => {
  const { t } = useTranslation()

  return (
    <NewHeader
      title={t('common.approvalGroups')}
      renderRightSlot={() => actionButton}
    />
  )
}
