import { FC, useEffect, useRef } from 'react'

type Props = {
  children: React.ReactNode
  shadow?: boolean
}

/* This sits below the Top Nav and becomes stickied as the user scrolls
   (content flows beneath the children of this element */
export const StickiedPageHeader: FC<Props> = ({ children, shadow = true }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shadow) {
      const scrollHandler = () => {
        // The number of (scroll) pixels to phase the shadow in over
        const phaseIn = 100

        // The rate the shadow should be applied based on the current scroll position
        const rate = window.scrollY < phaseIn ? window.scrollY / phaseIn : 1

        if (ref.current) {
          // Phase in a Tailwind shadow-lg shadow
          ref.current.style.filter = `
            drop-shadow(0 ${10 * rate}px ${8 * rate}px rgb(0 0 0 / 0.04))
            drop-shadow(0 ${4 * rate}px ${3 * rate}px rgb(0 0 0 / 0.1))
          `
        }
      }

      document.addEventListener('scroll', scrollHandler)

      return () => document.removeEventListener('scroll', scrollHandler)
    }
  }, [shadow])

  return (
    <div
      className="sticky left-0 right-0 z-30 top-12 ease-in duration-75 transition-shadow"
      ref={ref}
    >
      {children}
    </div>
  )
}
