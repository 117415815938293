import { FC, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { abbreviateDayOfWeek } from '@utils/abbreviateDayOfWeek'

interface Props {
  children?: ReactNode
  className?: string
  day: DayOfWeek
}

export const DayCell: FC<Props> = ({ children, className, day }) => {
  return (
    <div className={twMerge(`grid-in-${abbreviateDayOfWeek(day)}`, className)}>
      {children}
    </div>
  )
}
