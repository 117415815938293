import {
  getSavedReports,
  getSavedReport,
  createSavedReport,
  updatedSavedReport,
  deleteSavedReport,
} from '@lib/api'
import { savedReportKeys } from '@lib/keys'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export const useSavedReports = () => {
  return useQuery(savedReportKeys.all, () => getSavedReports())
}

export const useSavedReport = (id: number) => {
  return useQuery(savedReportKeys.get(id), () => getSavedReport(id), {
    enabled: id !== 0,
  })
}

export const useCreateReport = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (...args: Parameters<typeof createSavedReport>) =>
      createSavedReport(...args),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(savedReportKeys.all)
      },
    },
  )
}

export const useUpdateReport = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (...args: Parameters<typeof updatedSavedReport>) =>
      updatedSavedReport(...args),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(savedReportKeys.all)
      },
    },
  )
}

export const useDeleteReport = () => {
  const queryClient = useQueryClient()

  return useMutation((id: number) => deleteSavedReport(id), {
    onSuccess: () => {
      void queryClient.invalidateQueries(savedReportKeys.all)
    },
  })
}
